<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-08 18:25:32
 * @LastEditTime: 2024-05-27 16:21:59
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\waterfall.vue
-->
<template>
  <div class="waterfull-page">
    <div class="waterfall px-container clearfix" :style="{'justify-content': waterList.length ? 'flex-start': 'center'}" v-cloak>
      <div v-if="waterList.length === 0" class="opt-empty mgr20" @click="addNew">
        <div>
          <svg-icon icon-class="opt-add" />
        </div>
        <div class="opt-add-text">{{currentTab === 'script' ? '点击新建脚本' : currentTab === 'cmd' ? '点击新建命令' : '点击新建任务'}}</div>
      </div>
      <template v-else>
        <div class="px-waterfall left" v-for="(el, i) in newWaterfallData" :key="i">
          <div v-for="(item, index) in el" :key="index">
            <div class="type-item">
              <div class="header">
                <svg-icon class="type-icon" icon-class="power-shell" />
                <span class="type-name">{{ item.lang }}</span>
                <span class="under-line"></span>
              </div>
              <div v-for="(child, childIndex) in item.children" :key="childIndex" @click="selectCode(child)">
                <div class="item-name">{{ child.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
    
<script>
export default {
  props: {
    currentTab: String,
    waterList: Array,
    clusterId: String,
  },
  data() {
    return {
      newWaterfallData: "",
      waterfallDataNumber: "",
      waterfallData: [],
    };
  },
  watch: {
    currentTab: {
      handler(val) {
        this.init();
      },
      deep: true,
      immediate: true,
    },
    waterList: {
      handler(val) {
        this.init();
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    selectCode(item) {
      let code = "";
      let self = this;
      console.log("log", item);
      if (this.currentTab === "script") {
        this.$axiosProxyGet(
          `side/filesystem/files/root${item.path + "/" + item.name}?cluster_id=${this.clusterId}`
        )
        .then((res) => {
          if ([200, 201, 204].includes(res.status)) { 
            return self.$decodeData(res.data)
          } else {
            throw new Error('Unexpected HTTP status code');
          }
        }).then(code =>{
          if (Object.prototype.toString.call(code) === '[object Object]') {
            code = JSON.stringify(code, null, " ");
          }
          this.$emit("getCode", code, item);
        }).catch(() => {});
      }
      if (this.currentTab === "cmd") {
        code = item.code;
        this.$emit("getCode", code, item);
      }
    },
    init() {
      let [...waterfallData] = this.waterList;
      let [...newWaterfallData] = [[], []];
      let leftNum = 0;
      let rightNum = 0;
      waterfallData.forEach((el, i) => {
        if (leftNum === 0) {
          newWaterfallData[0].push(el);
          leftNum += el.children.length;
        } else {
          if (rightNum > leftNum && leftNum > 0) {
            newWaterfallData[0].push(el);
            leftNum += el.children.length + 1;
          } else {
            newWaterfallData[1].push(el);
            rightNum += el.children.length + 1;
          }
        }
      });
      this.newWaterfallData = newWaterfallData;
    },
    addNew() {
      // this.$message.warning("敬请期待");
      this.$emit("addNew");
    },
  },

  // props: ["waterfallData"],
};
</script>
    
<style lang="less" scoped>
.waterfull-page {
  width: 376px;
  height: 346px;
  display: flex;
  flex-direction: column;
  .opt-empty {
    cursor: pointer;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 200px;
    height: 179px;
    background: rgba(238, 240, 245, 0.3);
    border: 1px solid rgba(238, 240, 245, 1);
    border-radius: 6px;
    svg {
      font-size: 139px;
    }
    &-text {
      color: #242b3a;
      font-weight: 400;
    }
  }
  .px-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px 0 10px 20px;
    display: flex;
    margin-bottom: 12px;
  }
  .clearfix:before,
  .clearfix:after {
    content: "";
    display: block;
    clear: both;
  }
  .clearfix {
    zoom: 1;
  }
  .left {
    // float: left;
  }

  [v-cloak] {
    display: none !important;
  }
  .px-waterfall {
    width: 50%;
    .header {
      position: relative;
      width: 140px;
      padding: 4px 0;
      border-bottom: 1px solid #ecf0f4;
      .type-icon {
        font-size: 16px;
        position: relative;
        top: 2px;
      }
      .type-name {
        font-size: 14px;
        color: #1d202d;
        font-weight: 600;
        margin-left: 4px;
      }
      .under-line {
        width: 18px;
        height: 1px;
        background: #81879f;
        position: absolute;
        top: 29px;
        left: 0;
      }
    }
    .item-name {
      padding: 0 0 0 10px;
      font-size: 14px;
      color: #54545b;
      line-height: 28px;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        background: #e6e8ed;
      }
    }
    &:nth-child(2n + 1) {
      // margin: 0 30px;
    }
  }
  .footer {
    height: 40px;
    border-top: 1px solid #ecf0f4;
    .btn-footer {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-item {
        font-size: 14px;
        color: #54545b;
        letter-spacing: 0;
        text-align: right;
        font-weight: 400;
        width: 52px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        &-active {
          background: #eef0f5;
        }
      }
      .btn-add {
        position: absolute;
        cursor: pointer;
        right: 16px;
        font-size: 24px;
      }
    }
  }
}
</style>