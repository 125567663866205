<!--
 * @Description: 
 * @Date: 2024-08-14 14:46:00
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-06 17:40:17
-->
<template>
  <div class='app-store h-full'>
    <div class="header">
      <a-input ref="userNameInput" v-model="keyword" class="search-input" @pressEnter="searchAppModel"  size="small" placeholder="搜索">
        <svg-icon icon-class="search" class="template-search" slot="prefix"></svg-icon>
      </a-input>
    </div>
    <div class="flex h-full">
      <left-menu />
       <right-app-store :keyword="keyword" />
    </div>
  </div>
</template>

<script>
import leftMenu from './leftMenu.vue'
import rightAppStore from './rightAppStore.vue'

export default {
  name: "AppStore",
  components: {leftMenu, rightAppStore},
  props: {
    propsData: {
      default: () => {
        return {}
      },
      type: Object
    },
    appLoaderOptions: {
      default: () => {
        return {}
      },
      type: Object
    },
  },
  data () {
    return {
      keyword: '',
      windowAppLoaderOptions: {},
      windowPropsData: {},
      autofitVm: null
    };
  },
  provide() {
    return {
      windowAppLoaderOptions: this.windowAppLoaderOptions,
      windowPropsData: this.propsData,
      editwindowAppLoaderOptions: this.editwindowAppLoaderOptions,
    }
  },
  watch: {
    appLoaderOptions: {
      handler (val) {
        this.windowAppLoaderOptions = _.cloneDeep(val)
      },
      immediate: true,
      deep: true
    },
    propsData: {
      handler (val) {
        this.windowPropsData = _.cloneDeep(val)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {

  },
  mounted () {
    const self = this
    // 监听窗口里面的头部方法
    this.$EventBus.$on("editwindowAppLoaderOptions", (data) => {
      if (data.id === self.windowAppLoaderOptions.id) self.editwindowAppLoaderOptions(data)
    });
  },
  methods: {
    searchAppModel () {
      this.$EventBus.$emit('changeKeyWord', this.keyword)
    },
    editwindowAppLoaderOptions (data) {
      this.windowAppLoaderOptions = data
      this.$set(this.windowAppLoaderOptions, 'currentRoute', data.currentRoute)
    }
  },
  beforeDestroy () {
  }
};
</script>


<style lang='less' scoped>
//@import url(); 引入公共css类
.app-store {
  .header {
    height: 35px;
    display: flex;
    background: rgb(236, 239, 245);
    align-items: center;
    justify-content:center;
  }
  .search-input {
    width: 30vw;
  }
}
</style>
