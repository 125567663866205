/*
 * @Description: 
 * @Date: 2024-07-22 11:03:44
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-07-22 11:04:31
 */
// resize.js
export default {
  inserted(el, binding) {
    const { items, itemWidth, updateItems } = binding.value;

    const updateVisibleItems = () => {
      const containerWidth = el.offsetWidth;
      const visibleItemCount = Math.floor(containerWidth / itemWidth);
      updateItems(visibleItemCount);
    };

    const resizeObserver = new ResizeObserver(updateVisibleItems);
    resizeObserver.observe(el);

    // 初始化时调用一次
    updateVisibleItems();

    // 在元素卸载时取消观察
    el._resizeObserver = resizeObserver;
  },
  unbind(el) {
    if (el._resizeObserver) {
      el._resizeObserver.disconnect();
      delete el._resizeObserver;
    }
  }
};
