<!--
 * @Description: 
 * @Date: 2024-07-24 17:25:56
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-25 17:17:27
-->
<template>
  <a-spin :spinning="spinning" style="min-height: 200px;">
    <div :class="['cluster-dialog-content', globalSetting.theme.mode]">
      <div class="cluster-list">
        <div
          :class="['cluster-item', clusterId === item.id ? 'cluster-item-selected' : '', item.status !== 'online' && 'disabled']"
          v-for="(item) in masterOptions"
          :key="item.id"
          @click="changeClusterId(item)"
          :value="item.id"
        >
          <span
            class=""
            :title="item.name"
          >
            <svg-icon
              class="mgr8"
              :icon-class="item.status === 'online' ? 'task-successful' : 'task-init'"
              :style="{color: item.status === 'online' ? '#5CB863' : '#ED4F50'}"
            ></svg-icon>
            <span class="mgr12">{{ item.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import store from '@/store/index'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
  },
  props: {
    visible: Boolean
  },
  data () {
    // 这里存放数据
    return {
      showCluster: false,
      masterOptions: [],
      clusterId: '',
      spinning: false
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState('setting', ['isMobile', 'theme', 'layout', 'footerLinks', 'copyright', 'fixedHeader', 'fixedSideBar',
      'masterOptionsVuex', 'fixedTabs', 'hideSetting', 'multiPage']),
    globalSetting () {
      return { ...store.state.globalSetting }
    },
  },
  // 监控data中的数据变化
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.getMasterList()
        } else {
          this.masterOptions = []
        }
      },
      immediate: true
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.clusterId = localStorage.getItem('CLUSTER_ID')
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    ...mapActions("fileManage", [
      "setClusterId",
    ]),
    changeClusterId (obj) {
      if (obj.status !== 'online') return false
      if (obj.id === this.clusterId) return false
      const val = obj.id
      const onlineCluster = this.masterOptions.filter(item => item.id === val)
      let DefaultMaster = {}
      if (onlineCluster.length > 0) {
        DefaultMaster = onlineCluster[0]
        const cluster_id = DefaultMaster?.id || undefined
        this.clusterId = cluster_id
        const doms = document.getElementsByClassName("winbox-cluster-name") || []
        for (let i = 0; i < doms.length; i++) {
          doms[i].innerText = '（' + DefaultMaster.name  + '）'
        }
        this.setClusterId(cluster_id)
      }
      localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr || DefaultMaster?.private_addr || '')
      window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr || DefaultMaster.private_addr || ''
      this.showCluster = false
    },

    setShowCluster () {
      // 开启的时候去拉取一次master列表
      if (!this.showCluster) {
        this.getMasterList()
      }
      this.showCluster = !this.showCluster
    },
    setMasterOptions (data) {
      this.masterOptions = data
    },
    getMasterList () {
      const params = {
        page_size: 10000,
        page: 1,
      };
      this.spinning = true
      this.$axiosGet(global.API.getMasterInfo, params).then((res) => {
        this.spinning = false
        if ([200, 201, 204, 202].includes(res.status)) {
          this.setMasterOptions(res.data.results)
        }
      }).catch(err => {
        this.spinning = false
      })
    },
    initClusterid () {
      if (!this.masterOptions) {
        this.setClusterId(undefined)
      }
      const onLlineCluster = this.masterOptions.filter(item => item.status === 'online')
      if (!onLlineCluster.length) this.setClusterId(undefined)

      const index = onLlineCluster.findIndex(item => item.id === localStorage.getItem("CLUSTER_ID"))
      if (index > -1) return this.setClusterId(localStorage.getItem("CLUSTER_ID"))
      // 如果之前没有设置clusterId就获取默认的值
      if (onLlineCluster.length > 0) {
        const DefaultMaster = onLlineCluster.find(item => (item.is_default))
        const cluster_id = DefaultMaster?.id || onLlineCluster[0]?.id || undefined
        this.setClusterId(cluster_id)
      }
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.cluster-dialog-content {
  padding-right: 16px;
  max-height: 300px;
  overflow-y: auto;
  .cluster-list {
    padding: 4px;
    width: 200px;
    border-radius: 6px;
    margin-top: 4px;
    .cluster-item {
      cursor: pointer;
      border-radius: 6px;
      padding: 2px 10px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      height: 32px;
      &:hover {
        background: #dee7f7;
      }
      &-selected {
        background: #dee7f7;
      }
    }
    .disabled {
      cursor: not-allowed;
    }
  }
}
</style>
