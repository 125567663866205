<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-06-27 16:19:45
 * @LastEditTime: 2025-01-09 10:11:12
 * @FilePath: \awx-ui\src\components\editor\index.vue
-->
<template>
  <div ref="editorRef" id="editor" :style="{height: editorHeight}"></div>
</template>
<script>
// 引用组件
import * as monaco from "monaco-editor";
// import * as monaco from "monaco-editor/esm/vs/editor/editor.main.js";
import "monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution";

export default {
  components: {},
  props: {
    propsCode: String,
    editorHeight: {
      type: String,
      default: '650px',
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: "vs-light",
    },
    language: {
      type: String,
      default: "powershell",
    },
  },
  data() {
    return {
      curCode: "",
      cmOptions: {
        value: "",
        mode: "text/javascript",
        theme: "ambiance",
        readOnly: this.readOnly,
      },
    };
  },
  watch: {
    propsCode: {
      handler(val) {
        if (val) {
          this.curCode = this.propsCode;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    resetCode (code) {
      this.curCode = code;
      // 获取当前光标位置
      const currentPosition = this.monacoEditor && this.monacoEditor.getPosition();
      this.monacoEditor && this.monacoEditor.setValue(this.curCode)
      // 恢复光标位置
      this.monacoEditor && this.monacoEditor.setPosition(currentPosition);
    },
    init() {
      // 使用 - 创建 monacoEditor 对象
      this.monacoEditor = monaco.editor.create(this.$refs.editorRef, {
        theme: this.theme, // 主题
        value: this.curCode, // 默认显示的值
        language: this.language,
        wordWrap: "on",
        folding: true, // 是否折叠
        foldingHighlight: true, // 折叠等高线
        foldingStrategy: "indentation", // 折叠方式  auto | indentation
        showFoldingControls: "always", // 是否一直显示折叠 always | mouseover
        disableLayerHinting: true, // 等宽优化
        emptySelectionClipboard: false, // 空选择剪切板
        selectionClipboard: false, // 选择剪切板
        automaticLayout: true, // 自动布局
        codeLens: true, // 代码镜头
        scrollBeyondLastLine: false, // 滚动完最后一行后再滚动一屏幕
        colorDecorators: true, // 颜色装饰器
        accessibilitySupport: "on", // 辅助功能支持  "auto" | "off" | "on"
        lineNumbers: "on", // 行号 取值： "on" | "off" | "relative" | "interval" | function
        lineNumbersMinChars: 6, // 行号最小字符   number
        enableSplitViewResizing: false,
        readOnly: this.readOnly, //是否只读  取值 true | false
        minimap: {
          enabled: true, // 不要小地图
        },
      });
      if (!this.readOnly) {
        this.monacoEditor.getModel().onDidChangeContent(() => {
          const value = this.monacoEditor.getValue();
          this.$emit("getEditorValue", value);
        });
      }
    },
    getEditorValue() {
      const value = this.monacoEditor.getValue();
      return value;
    },
  },
};
</script>
<style lang="less" scoped>
</style>
<style lang="less">
  #editor {
    .line-numbers {
      // text-align: left;
    }
  }
</style>