<template>
  <div ref="custom" class="flex-center bg-color-b3">
    <div class='big-circle bg-color-m2 bg-opacity-008'>
      <div class="border-circle">
        <div ref="circle" class="circle border-color-m2 border-opacity-60">
          <div ref="dot" class="dot bg-color-m2" @mousedown="handleMouseDown($event)"></div>
            <div class="small-circle bg-color-b3">
            <div class="angle-display text-color-t2">{{ angleText }}</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chroma from 'chroma-js';
import store from '@/store/index'

export default {
  name: 'GradientController',
    props: {
    initialAngle: {
      type: Number,
      default: 180
    },
    gradientColors: {
      type: Array,
      default: () => {
        return ['rgb(68, 28, 255)', 'rgb(85, 88, 224)', 'rgb(80, 128, 193)', 'rgb(43, 163, 161)']
      }
    }
  },
  data () {
    return {
      isDragging: false,
      startAngle: 0,
      currentAngle: 0,
      hue: 0.8, // Default to 1
      brightness: 1, // Default to 1
    };
  },
  computed: {
    angleText () {
      return this.currentAngle.toFixed(0) + '°' || ''
    },
    gradientStyles () {
      return {
        'background-image': `linear-gradient(${this.currentAngle}deg, ${this.gradientColors.join(', ')})`,
      };
    },
  },
  watch: {
    currentAngle(newAngle) {
      console.log(newAngle.toFixed(0), '我当前的角度')
      this.$emit('setAngel', newAngle)
    },
  },
  methods: {
    getAngle (e) {
      const { left, top, width, height } = this.$refs.circle.getBoundingClientRect();
      const centerX = left + width / 2;
      const centerY = top + height / 2;
      const dx = e.clientX - centerX;
      const dy = e.clientY - centerY;
      let angle = Math.atan2(dy, dx) * (180 / Math.PI);
      return angle >= 0 ? angle : 360 + angle;
    },

     updateElements (e) {
      if (!this.isDragging) return;
      this.currentAngle = this.getAngle(e);
      const radius = this.$refs.circle.offsetWidth / 2;
      const newX = this.$refs.circle.offsetWidth / 2 + radius * Math.cos(this.currentAngle * Math.PI / 180) + 4;
      const newY = this.$refs.circle.offsetHeight / 2 + radius * Math.sin(this.currentAngle * Math.PI / 180) + 2;
      this.$refs.dot.style.left = newX - this.$refs.dot.offsetWidth / 2 + 'px';
      this.$refs.dot.style.top = newY - this.$refs.dot.offsetHeight / 2 + 'px';
      this.updateBackgroundGradient(this.currentAngle);
    },

     updateBackgroundGradient (angle) {
      const dom = document.getElementsByClassName('desktop-container')[0]
      const colors = this.gradientColors
      // if (dom) dom.style.backgroundImage = `linear-gradient(${this.currentAngle}deg, ${colors.join(', ')})`;
       const wallpaper = store.state.globalSetting.wallpaper
       wallpaper.backgroundImage = `linear-gradient(${this.currentAngle}deg, ${colors.join(', ')})`
       store.commit("globalSetting/setWallpaper", wallpaper)
    },

     handleMouseDown (e) {
      this.isDragging = true;
      this.startAngle = this.getAngle(e);
      document.addEventListener('mousemove', this.updateElements);
    },

     handleMouseUp () {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.updateElements);
    },
    // 初始化圆点位置、角度显示和背景渐
    adjustColorsForGradient() {
      // 调整固定颜色的亮度和色彩
      const adjustedColors = this.gradientColors.map(color => {
        const chromaColor = chroma(color);

        // 应用亮度和色彩调整
        return chromaColor
          // .set('hsl.h', chromaColor.get('hsl.h') + (this.hue - 0.5) * 360) // 色调调整
          .set('hsl.l', chromaColor.get('hsl.l') + (this.brightness - 0.5) * 0.5) // 亮度调整
          .hex();
      });

      return adjustedColors;
    }
},
mounted() {
  this.currentAngle = this.initialAngle
  this.startAngle = this.initialAngle
  this.updateElements({ clientX: this.$refs.circle.offsetWidth / 2, clientY: this.$refs.circle.offsetHeight / 2})
  const radius = this.$refs.circle.offsetWidth / 2;
  const newX = this.$refs.circle.offsetWidth / 2 + radius * Math.cos(this.currentAngle * Math.PI / 180) + 4;
  const newY = this.$refs.circle.offsetHeight / 2 + radius * Math.sin(this.currentAngle * Math.PI / 180) + 2;
  this.$refs.dot.style.left = newX - this.$refs.dot.offsetWidth / 2 + 'px';
  this.$refs.dot.style.top = newY - this.$refs.dot.offsetHeight / 2 + 'px';
  document.addEventListener('mouseup', this.handleMouseUp);
},
};
</script>

<style scoped lang='less'>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.big-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  border-radius: 50%;
}
.border-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.08);
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  position: relative;
  border-width: 1px;
  border-style: dashed;
}
.small-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.dot {
  cursor: pointer;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.angle-display {
  font-size: 12px;
  user-select: none;
}
</style>