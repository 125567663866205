<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-16 14:52:58
 * @LastEditTime: 2025-01-21 11:27:03
 * @FilePath: \awx-ui\src\pages\login\phone.vue
-->
<template>
  <div class="phone-login">
    <a-form @submit="onSubmit" :form="form">
      <a-form-item>
        <a-input class="login-input" @input="updateButtonStatus" autocomplete="off" placeholder="手机号" v-decorator="['phone', {rules: [{ required: true, message: '请输入手机号', whitespace: true}]}]"></a-input>
      </a-form-item>
      <div class="code">
        <a-form-item class="code-num">
          <a-input class="login-input mgt14" @input="updateButtonStatus"  autocomplete="off" placeholder="验证码" v-decorator="['code', {rules: [{ required: true, message: '验证码', whitespace: true}]}]"></a-input>
        </a-form-item>
        <div :class="['code-btn text-color-t1', isCode && 'code-btn-send']" @click="sendCode">{{ isCode ? codeTime : '发送验证码' }}</div>
      </div>
      <a-form-item>
          <a-button :loading="logging" class="btn-login text-color-t1" htmlType="submit" :disabled="disabledLogin" type="primary">{{ logging ? '正在登录中...' : '立即登录' }}</a-button>
      </a-form-item>
    </a-form>
    <div class="register">
      <div class="text-color-t3">还没有账号？<span @click="register">立即注册</span></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState, mapActions } from "vuex";
import { formatRoutes } from "@/utils/routerUtil";
import dynamicComponents from "@/utils/dynamic-components";
import ROUTER_DATA from './routerData.json'

export default {
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      isCode: false,
      codeTime: 60,
      disabledLogin: true,
      timer: null,
    };
  },
  components: {},
  mounted() {},
  computed: {
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setPermissions"]),
    register () {
      this.$emit('register')
    },
    testPhone () {
      const phoneNumberRegex = /^1[3456789]\d{9}$/;
      let flag = true
      const phone = this.form.getFieldValue("phone");
      if (!phone) {
        this.$message.error("请输入手机号码");
        return false
      }
      if (!phoneNumberRegex.test(phone)) {
        this.$message.error("请输入正确的手机号码");
        return false
      } 
      return flag
    },
    sendCode() {
      const phone = this.form.getFieldValue("phone");
      if (!this.testPhone()) return false
      if (this.isCode) return false;
      this.isCode = true;
      this.$axiosJsonPost(global.API.smsSend, {phone: phone}).then((res) => {
        this.codeTime--;
        this.timer = setInterval(() => {
          if (this.codeTime === 1) {
            this.isCode = false;
            if (this.timer) clearInterval(this.timer);
            this.codeTime = 60;
            return false;
          } else {
            this.codeTime--;
          }
        }, 1000);
      });
    },
    updateButtonStatus() {
      // 获取表单字段的值
      const { getFieldDecorator, getFieldValue } = this.form;
      const phone = this.form.getFieldValue("phone");
      const code = this.form.getFieldValue("code");
      // 根据表单值更新按钮状态
      this.disabledLogin = !phone || !code;
    },
    onSubmit(e) {
      e.preventDefault();
      // 修复用户登录菜单不刷新BUG
      // this.$store.commit('setting/setMenuData', undefined)
      this.form.validateFields((err) => {
        if (!err) {
          const phone = this.form.getFieldValue("phone");
          const code = this.form.getFieldValue("code");
          const phoneNumberRegex = /^1[3456789]\d{9}$/;
          if (!phoneNumberRegex.test(phone)) {
            this.$message.error("请输入正确的手机号码");
            return false
          } 
          this.logging = true;
          let config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          setTimeout(() => {
            this.logging = false
          }, 4000)
          const self = this;
          axios.post(global.API.smsLogin, {phone, code}, config).then(function (res) {
            if ([200, 201, 204, 202].includes(res.status)) {
              if ([0, 200].includes(res.data.code)) {
                localStorage.setItem("token", res.data.token);
                /*这里做处理.....*/
                self.getUserInfo(res);
              } else {
                self.$message.error(res.data.msg)
              }
            }
          });
        }
      });
    },
    getUserInfo() {
      const self = this;
      this.$axiosGet(global.API.getUserInfo, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          self.afterLogin(res);
        }
      });
    },
    afterLogin(res) {
      this.logging = false;
      // setAuthorization({ sessionId: loginRes.sessionId });
      const username = this.form.getFieldValue("phone");
      const isSuperuser = res.data.is_superuser;
      this.setUser({...res.data, username, isSuperuser });
      this.$emit('getMaster')
      this.getRouter();
      this.$message.success("登录成功");
      this.$emit('closeModal')
      this.$emit('openSyncSetting', res.data)
    },
    getRouter() {
      const self = this;
      const results = ROUTER_DATA
      localStorage.setItem("routerData", JSON.stringify(results));
    },
    // 路由过滤  遍历路由 转换为组件对象和路径
    filterASyncRoutes(data) {
      const routes = data.filter((item) => {
        const getComp = dynamicComponents[`${item.component}`];
        item["component"] = getComp;
        // 路由递归，转换组件对象和路径
        if (item["children"] && item["children"].length > 0) {
          item["children"] = this.filterASyncRoutes(item.children);
        }
        return true;
      });
      // 排序
      // routes.sort((a, b) => a['id'] - b['id'])
      return routes;
    },
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>
<style scoped lang='less'>
.phone-login {
  margin-top: 28px;
  /deep/ .ant-input {
    font-size: 14px;
  }
  .login-input {
    width: 100%;
    height: 42px;
    margin-top: 10px;
    background-color: rgba(var(--color-m2), 0.06);
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 8px;
    text-indent: 12px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgb(var(--color-t2));
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
    &:focus {
      border-color: @primary-color;
    }
    &::placeholder {
      // color: rgba(255,255,255,0.50);
    }
  }
  /deep/ .has-error {
    .login-input {
      border-color: #f5222f;
    }
  }
  .register {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin-top: 14px;
    span {
      cursor: pointer;
      color: #3974F4;
    }
  }
  .code {
    display: flex;
    .code-num {
      flex: 1;
    }
    .code-btn {
      margin-top: 14px;
      width: 120px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      background-color: rgba(var(--color-m2), 0.06);
      border: 1px solid rgba(255,255,255,0.12);
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      color: rgb(var(--color-t3));
      cursor: pointer;
      margin-left: 20px;
    }
    .code-btn-send {
      color: rgba(0, 0, 0, 0.45);
      border: 1px solid rgba(196, 204, 219, 1);
    }
  }
  .btn-login {
    margin-top: 28px;
    border-radius: 8px;
    width: 100%;
    height: 42px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
    border: none;
    color: rgb(var(--color-white));
  }
  .ant-btn-primary[disabled] {
    background: rgba(var(--color-m2), 0.06);
    color: rgb(199, 199, 204);
  }
}
</style>