<!--
 * @Author: zhang·xiao
 * @Date: 2023-01-18 15:43:04
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-08-15 16:22:23
 * @Description: 描述文件功能
-->
<template>
  <div class="step-create" :style='stepCreateStyle'>
    <div class="step-btns">
        <a-steps :current="step">
            <a-step title="基本信息" />
            <a-step title="表单设计" />
            <a-step title="模版设计" />
        </a-steps>
    </div>
    <div class="step-content" :style="contentStyle">
      <div class="step-1" v-if="step === 0">
        <SchemaForm
          ref="schemaForm1"
          v-bind="step1Schema"
          v-model="step_1_values"
        />
      </div>
      <CustomEditor 
        class="step-2"
        ref="schemaCustomForm2"
        @save="saveSchemaJson"
        @change="schemaChange"
        v-show="step === 1 && form_type === '1'" 
      />
      <Editor class="step-2"
        ref="schemaForm2"
        @save="saveSchemaJson"
        @change="schemaChange"
        v-show="step === 1 && form_type !== '1'"  /> 
      <div class="step-3" v-show="step === 2">
        <SchemaForm
          ref="schemaForm3"
          v-bind="step3Schema"
          v-model="step_3_values"
        />
      </div>
    </div>
    <div class="step-bttom-btns">
      <a-button class="pre" v-show="isAppOption === 'add'" @click="onCancel(false)">取消</a-button>
      <a-button class="pre" type="primary" v-if="step > 0" @click="onStepChange(false)">上一步</a-button>
      <a-button calss="pre" type="primary" v-if="step < 2" @click="onStepChange(true)">下一步</a-button>
      <a-button class="pre" type="primary" v-if="step === 2" @click="onStepChange(true)">完成</a-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { step1SchemaProps, step3SchemaProps } from './stepSchema';
import { SchemaForm, CustomEditor,  Editor} from "@formEngine/index";
import { mapState, mapGetters } from "vuex";
let oldSchemaValue = {};
export default {
  name: "StepManage",
  components: { SchemaForm, CustomEditor, Editor },
  provide() {
    return {
      handleCancel: this.handleCancel,
      onSearch: null,
    };
  },
  props: {
    appDetail: Object,
    showPage: String,
    isComponet: Boolean
  },
  data() {
    return {
      isAppOption: "",
      step: 0,
      form_type: '0',
      step_1_values: {},
      step_2_values: {},
      step_3_values: {},
      schemaDisabled: false,
      schemaChanged: false,
      step1Schema: step1SchemaProps,
      step3Schema: step3SchemaProps,
    };
  },
  mounted() {
    this.initData();
  },
  destroyed() {
    this.schemaChanged = false;
    this.schemaDisabled = false;
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions', 'refreshStepList'],
  watch: {
    appDetail: {
      handler (val) {
        if (val && val?.id) {
          this.step = 0
          this.isAppOption = 'edit'
          this.$axiosGet(global.API.getStepDetail(val.id)).then((res) => {
            const { name, categories, form_type, op_type, tags, type, description, module_version } = res.data;
            try {
              this.step_1_values = { name, categories, form_type: form_type ? form_type : '0', op_type: op_type ? op_type : '0',  application_id: this.windowPropsData.appId, tags: tags ? Array.isArray(tags) ? tags: tags.split(',') : undefined, type, description, module_version };
              this.step_2_values = { schema: res.data.schema || {}, model: res.data.model || {}}
              this.step_3_values = { template_orch: res.data.template_orch || "{}" }
              if(res.data.form_type !== '1') this.$refs.schemaForm2.importSchema(JSON.stringify(this.step_2_values.schema), () => console.log('导入成功'));
              if (res.data.form_type === '1') this.$refs.schemaCustomForm2.echoComponents(res.data)
            } catch (error) {
              console.error(error);
            }
          });
        }
      },
      immediate: true,
      deep: true
    },
    // 從應用裏面過來的新建步驟
    showPage: {
      handler(val) {
        if (val && !this.appDetail) {
          this.$nextTick(() => {
            this.isAppOption = 'add'
            this.step_1_values = {
              ...this.step_1_values,
              application_id: this.windowPropsData.stepId,
              op_type: val.includes('auth') ? '2' : val.includes('opt') ? '0'  : '1'  || this.step_1_values.op_type
            }
            this.step_3_values = { template_orch: "{}" }
          })
        }
      },
      immediate: true
    },
    step_1_values: {
      handler(val) {
        this.form_type = val?.form_type || '0'
      },
      deep: true,
      immediate: true
    },
    applicationId: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.step_1_values = {
              ...this.step_1_values,
              application_id: val,
              op_type: this.$route.params.opType || this.step_1_values.op_type
            }
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
    }),
    stepCreateStyle () {
      let style = {'height': this.isComponet ? 'calc(100% - 69px)' : '100%'}
      return style
    },
    contentStyle () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      let width = winbox?.window?.width || 1200
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max || full) {
        height = document.documentElement.clientHeight
        width = document.documentElement.clientWidth
      }
      let style = {
        height: this.isComponet ? height - 215 + 'px' : 'calc(100% - 147px)',
        'overflow-y': 'auto',
        'overflow-x': 'auto',
        'max-width': width - 32 + 'px'
      }
      return style
    },
    applicationId () {
      return this.windowPropsData.appId || ''
    }
  },
  methods: {
    initData() {
      if (this.windowPropsData.stepId) {
        this.id = this.windowPropsData.stepId;
        this.$axiosGet(global.API.getStepDetail(this.id)).then((res) => {
          const { name, categories, application_id, form_type, op_type, tags, type, description, module_version } = res.data;
          try {
            this.step_1_values = { name, categories, application_id, form_type: form_type ? form_type : '0', op_type: op_type ? op_type : '0', tags: tags ? Array.isArray(tags) ? tags: tags.split(',') : undefined, type, description, module_version };
            this.step_2_values = { schema: res.data.schema || {}, model: res.data.model || {}}
            this.step_3_values = { template_orch: res.data.template_orch || "{}" }
            if(res.data.form_type !== '1') this.$refs.schemaForm2.importSchema(JSON.stringify(this.step_2_values.schema), () => console.log('导入成功'));
            if (res.data.form_type === '1') this.$refs.schemaCustomForm2.echoComponents(res.data)
          } catch (error) {
            console.error(error);
          }
        });
      } else {
        this.step_3_values = { template_orch: "{}" }
      }
    },
    schemaChange(v) {
      // 在初始化阶段缓存加载好的Schema
      if (this.step !== 1) {
        oldSchemaValue = v;
      // 在编辑过程中比对Schema是否变化
      } else if (this.step === 1) {
        if (JSON.stringify(oldSchemaValue) === JSON.stringify(v)) {
          this.schemaChanged = false;
          this.schemaDisabled = false;
        } else {
          this.schemaChanged = true;
          this.schemaDisabled = _.isEmpty(v?.schema?.properties) ? true : false;
        }
      }
    },
    saveSchemaJson(val) {
      try {
        const schemaBody = {
          schema: JSON.parse(val.schema),
          model: JSON.parse(val.model)
        }
        if (!Object.keys(schemaBody?.schema?.schema?.properties || {}).length) {
          this.$message.error('请尝试编辑表单内容并保存');
          throw new Error('表单未编辑！')
        } else {
          this.step_2_values = schemaBody;
        }
        this.schemaChanged = false;
      } catch (error) {
        console.error(error, 'schema ------ 保存出错')
      }
    },
    onCancel() {
      if(this.isAppOption === 'add' && this.refreshStepList) return this.refreshStepList(this.showPage)
    },
    onStepChange(flag) {
      if (!flag) {
        this.step = --this.step;
      } else {
        if (this.step === 0) {
          this.$refs.schemaForm1.submit().then(
            () => {
              this.step = ++this.step;
            }, () => {
              console.warn('基本信息表单填写不完整')
            }
          );
        } else if (this.step === 1) {
          if (this.form_type !== '1') {
            if (this.schemaDisabled) {
              this.$message.warn('请拖动表单设计填充步骤内容')
            } else if (this.schemaChanged) {
              this.$message.warn('请点击保存变动、然后进行下一步')
            } else {
              this.step = ++this.step;
            }
          } else {
            if (!this.$refs.schemaCustomForm2.componentList.length) {
              return this.$message.warn('请拖动一个组件')
            } 
            const schemaBody = {
              schema: {
                schema: {
                  required: []
                }
              },
              model: this.$refs.schemaCustomForm2.model
            }
            if (this.$refs?.schemaCustomForm2?.model?.scheduletype === 'timer') {
              schemaBody.schema['schema']['required'] = ['scheduletype', 'start_time', 'end_time', 'cron']
            } else if (this.$refs?.schemaCustomForm2?.model?.scheduletype === 'once') {
              schemaBody.schema['schema']['required'] = ['runtime']
            } else {
              schemaBody.schema['schema']['required'] = []
            }
            this.step_2_values = schemaBody;
            this.step = ++this.step;
          }
        } else if (this.step === 2) {
          this.$refs.schemaForm3.submit().then(
            () => {
              this.saveStepData();
            }
          );
        }
      };
      // 按钮失焦处理
      setTimeout(() => {
        document.querySelectorAll('button.ant-btn')
          .forEach(el => el.blur())
      })
    },
    saveStepData() {
      return new Promise((resolve, reject) => {
        const data = {
          ...this.step_1_values,
          ...this.step_2_values,
          ...this.step_3_values,
          form_component: this.$refs.schemaCustomForm2.componentName,
          tags: this.step_1_values.tags?.join(',') || ''
        }
        let feach = this.windowPropsData.stepId ? this.$axiosPatch : this.$axiosJsonPost;
        let url = this.windowPropsData.stepId ? (global.API.createStep + this.windowPropsData.stepId) : global.API.createStep;
        if (this.isAppOption) {
          feach = this.isAppOption === 'edit' ? this.$axiosPatch : this.$axiosJsonPost;
          url = this.appDetail?.id ? (global.API.createStep + this.appDetail?.id) : global.API.createStep;
        }
        feach(url, data).then(rs => {
          this.$message.success('保存成功');
            setTimeout(() => {
            // this.$router.push('/app-manage/template-step')
            if (this.isAppOption) {
              if(this.isAppOption === 'add' && this.refreshStepList) this.refreshStepList(this.showPage, data)
            }
          }, 1000)
        }).catch(e => {
          this.$message.error('保存失败');
        });
      })
    },
    
  },
};
</script>

<style lang="less" scoped>
.step-create {
  padding: 0;
  width: calc(100%);
  height: calc(100% - 69px);
  background: #fff;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  .step-btns {
    position: relative;
    margin: auto;
    width: 100%;
    padding: 22px calc(50% - 300px);
    background: #FFFFFF;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #E4E7EF;
    z-index: 4;
  }
  .step-content {
    height: calc(100vh - 232px);
    z-index: 1;
    /deep/.genFromComponent {
      padding: 0 9px 9px;
      border-top: none;
      border-bottom: none;
      background: #F7F8FB;
      border-radius: 0;
      > .dragArea::after {
        content: '';
      }
    }
    .step-2 {
      min-width: 100%;
    }
    .step-1,
    .step-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 800px;
      margin: auto;
      height: 100%;
      /deep/.genFromComponent {
        width: 100%;
        background: #fff;
        border: none;
      }
    }
  }
  .step-bttom-btns {
    height: 70px;
    line-height: 70px;
    text-align: center;
    background: #fff;
    border-top: 1px solid #E4E7EF;
    border-radius: 0 0 4px 4px;
    z-index: 4;
    .pre {
      margin-right: 10px
    }
  }
}
</style>
