/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-22 21:45:52
 * @LastEditTime: 2024-08-22 08:39:05
 * @FilePath: \awx-ui\src\utils\baseUtils.js
 */
/* 
工具类
*/
import { message } from 'ant-design-vue';

// 生成十位字母加数字随机数
const getRandom = () => {
  // 生成十位字母加数字随机数
  let arr = [];
  for (let i = 0; i < 1000; i++) {
    let n = Math.random()
      .toString(36)
      .substr(2, 5);
    arr.push(n);
  }
  // 去重
  let ukeys = [];
  for (let i = 0; i < arr.length; i++) {
    if (ukeys.indexOf(arr[i]) === -1) {
      ukeys.push(arr[i]);
    }
  }
  let keys = "";
  for (let i = 0; i < ukeys.length; i++) {
    keys += ukeys[i];
  }
  return keys.substr(0, 5);
};

const formatBytes = (a, b) =>{
  if (0 == a) return "0 B";
  var c = 1024,
    d = b || 2,
    e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f]
}
const mapFileIcon = (key) =>{
  const paramsType = key.toLowerCase()
  return iconJson[paramsType] || ''
}
const mapFileManageIcon = (key) =>{
  const paramsType = key.toLowerCase()
  return fileIcon[paramsType] || ''
}
const mapFileTreeIcon = (key) =>{
  const paramsType = key.toLowerCase()

  return treeIcon[paramsType] || ''
}
const IsColorIcon = (icon) => {
  return icon ? icon.charAt(0) === '#' : false
}
const IsNetWorkIcon = (icon) => {
  return icon ? icon.includes('network-') : false
}
const iconNetWorkName = (name) => {
  let iconName = ''
  if (name) {
    iconName = name.includes('network-') ? name.split('network-')[1] : ''
  } else {
    iconName = ''
  }
  return iconName
} 
const iconColorName = (name) => {
  let iconName = ''
  if (name) {
    iconName = name.length === 1 ? name : name.substring(0, 2)
  } else {
    iconName = ''
  }
  return iconName
}

/**
 * 编辑器支持哪些语言
   "javascript": JavaScript
    "typescript": TypeScript
    "html": HTML
    "css": CSS
    "json": JSON
    "markdown": Markdown
    "python": Python
    "java": Java
    "php": PHP
    "ruby": Ruby
    "swift": Swift
    "csharp": C#
    "sql": SQL
    "yaml": YAML
    "plaintext": 普通文本
 */
const getIcon = (name) => {
  const arr = name.split(".");
  let key = "";
  if (arr.length > 0) key = arr[arr.length - 1];
  return mapFileManageIcon(key)
    ? mapFileManageIcon(key)
    : "file-question2";
}
const getFileType = (file) => {
  const arr = file.name.split(".");
  // 没有后缀名不给编辑
  if (!arr.length) return 'plaintext'
  let name = "";
  if (arr.length > 0) name = arr[arr.length - 1];
  return name
}
const isPreviewFile = (file) => {
  const filetype = getFileType(file)
  const previewfile =
  [
    'svg', 'png', 'jpg', 'jpeg', 'csv', 'xls', 'xlsx', 'doc', 'docx', 'dot', 'pdf', 'mp4', 'rmvb', 'flv', 'mp3', 'gif'
  ]
  const paramsType = filetype.toLowerCase()
  if (previewfile.includes(paramsType)) return true
  return false
}
const EditorLanguage = {
  "abap": 'abap',
  "cls": "apex",
  "dockerfile": 'dockerfile',
  "go": 'go',
  "js": 'javascript',
  "jsx": 'javascript',
  "es6": 'javascript',
  "mjs": 'javascript',
  "ts": 'typescript',
  "tsx": 'typescript',
  "sb": 'sb',
  "sh": 'shell',
  "bash": 'shell',
  "less": 'less',
  "scss": 'scss',
  "html": 'html',
  "htm": 'html',
  "vue": 'html',
  "shtml": 'html',
  "xhtml": 'html',
  "jsp": 'html',
  "asp": 'html',
  "aspx": 'html',
  "jshtm": 'html',
  "mdoc": 'html',
  "css": 'css',
  "md": 'markdown',
  "markdown": 'markdown',
  "java": 'java',
  "php": 'php',
  "swift": 'swift',
  "cs": 'csharp',
  "sql": 'sql',
  "yaml": 'yaml',
  "yml": 'yaml',
  "txt": 'plaintext',
  "json": 'json',
  "xml": 'xml',
  "log": 'shell'
}
const getEditorLanguage = (file) => {
  const arr = file.name.split(".");
  // 没有后缀名不给编辑
  if (!arr.length) return 'plaintext'
  let name = "";
  if (arr.length > 0) name = arr[arr.length - 1];
  const language = Object.prototype.hasOwnProperty.call(EditorLanguage, name) ? EditorLanguage[`${name}`] : "shell"
  return language
}
const iconJson = {
  'xls':'excel',
  'xlsx':'excel',
  'zip':'zip',
  'doc':'worder',
  'docx':'worder',
  'dot':'worder',
  'dotx':'worder',
  'pot':'ppt',
  'ppt':'ppt',
  'pptx':'ppt',
  'txt': 'txt',
  'svg': 'file-jpg',
  'js': 'file-code',
  'json': 'file-code',
  'html': 'file-code',
  'vue': 'file-code'
}
const fileIcon = {
  'xls':'file-excel',
  'xlsx':'file-excel',
  'csv':'file-excel',
  'zip':'file-rar',
  'rar': 'file-rar',
  'gz': 'file-rar',
  'doc':'file-word',
  'docx':'file-word',
  'dot':'file-word',
  'dotx':'file-word',
  'pot':'file-ppt',
  'ppt':'file-ppt',
  'pptx':'file-ppt',
  'txt': 'file-txt2',
  'js': 'file-code',
  'html': 'file-code',
  'vue': 'file-code',
  'sql': 'file-sql',
  'mp3': 'file-mp3',
  'json': 'file-json',
  'xmind': 'file-xmind',
  'jpg': 'file-jpg',
  'png': 'file-jpg',
  'svg': 'file-jpg',
  'jpeg': 'file-jpg',
  'gif': 'file-jpg',
  'mp4': 'file-mp4',
  'avi': 'file-mp4',
  'md': 'file-txt2',
  'log': 'file-txt2',
  'sh': 'file-txt2',
  'conf': 'file-txt2',
  'ini': 'file-txt2',
  'rmvb': 'file-mp4',
  'pdf': 'file-pdf',
  'yaml': 'file-yaml',
  'py': 'file-python',
  'java': 'file-code',
}
const treeIcon = {
  'java': 'tree-code',
  'xls':'tree-excel',
  'xlsx':'tree-excel',
  'csv':'tree-excel',
  'zip':'tree-rar',
  'rar': 'tree-rar',
  'gz': 'tree-rar',
  'doc':'tree-word',
  'docx':'tree-word',
  'dot':'tree-word',
  'dotx':'tree-word',
  'pot':'tree-ppt',
  'ppt':'tree-ppt',
  'pptx':'tree-ppt',
  'txt': 'tree-txt2',
  'js': 'tree-code',
  'html': 'tree-code',
  'vue': 'tree-code',
  'sql': 'tree-sql2',
  'mp3': 'tree-mp3',
  'json': 'tree-json',
  'xmind': 'tree-xmind',
  'jpg': 'tree-jpg',
  'png': 'tree-jpg',
  'svg': 'tree-jpg',
  'jpeg': 'tree-jpg',
  'gif': 'tree-jpg',
  'mp4': 'tree-mp4',
  'avi': 'tree-mp4',
  'md': 'tree-txt2',
  'log': 'tree-txt2',
  'sh': 'tree-txt2',
  'conf': 'tree-txt2',
  'ini': 'tree-txt2',
  'rmvb': 'tree-mp4',
  'pdf': 'tree-pdf',
  'yaml': 'tree-yaml',
  'py': 'tree-python'
}
const  generateUUID = () =>{
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}
   // 复制
const copyText =  (text, e) => {
  if (e) e.stopPropagation()
  const input = document.createElement('input');
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
  message.success('复制成功');
}
 function ensureProtocol (url) {
  try {
    // 尝试使用URL构造函数
    const parsedUrl = new URL(url);
    return parsedUrl.href;
  } catch (error) {
    // 如果URL构造失败，那么URL字符串可能不包含协议
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      // 添加默认的协议
      return 'http://' + url;
    }
    return url; // 如果URL已经包含协议，则返回原样
  }
}
export default {
  getRandom, // 生成十位字母加数字随机数
  formatBytes,
  mapFileIcon,
  mapFileManageIcon,
  mapFileTreeIcon,
  IsColorIcon,
  iconColorName,
  treeIcon,
  getEditorLanguage,
  isPreviewFile,
  generateUUID,
  copyText,
  IsNetWorkIcon,
  iconNetWorkName,
  getIcon,
  ensureProtocol
}