<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-08-13 17:12:54
 * @FilePath: \awx-ui\src\components\workflow-design\components\operationBox.vue
-->
<template>
  <div :class="['operation-box', taskType === 'flow' && 'operation-flow-box']">
    <div class="operation-box-container" v-show="showtabs">
      <div class="center" v-show="toolSelected === 'steps' && nodeComponentType === 'task'">
        <div class="chooes-steps">
          <a-steps :current="currentStepsIndex" @change="changeCurrent">
          <a-step :class="['show-icon']">
            <template slot="title">
              <span>选择应用</span>
            </template>
          </a-step>
          <a-step :class="[winboxTask.currentOptIndex > 1 ?'show-icon': '']">
            <template slot="title">
              <span>选择操作</span>
            </template>
          </a-step>
          <a-step :class="[winboxTask.currentOptIndex > 2 ?'show-icon': '']">
            <template slot="title">
              <span>配置授权</span>
            </template>
          </a-step>
          <a-step :class="[winboxTask.currentOptIndex > 3 ?'show-icon':'']">
            <template slot="title">
              <span>配置参数</span>
            </template>
          </a-step>
        </a-steps>
        </div>
        <App v-show="currentStepsIndex === 0" :taskType="taskType" />
        <SelectOpt v-if="currentStepsIndex === 1" :winboxHeight="winboxHeight" />
        <AuthConfig v-if="currentStepsIndex === 2" />
        <common-form ref="commonFormRef" v-if="currentStepsIndex > 2" :taskType="taskType" />
      </div>
      <div class="center logic" v-show="toolSelected === 'steps' && nodeComponentType !== 'task'">
        <div class="chooes-steps">
          <a-steps :current="0">
            <a-step>
              <template slot="title">
                <span>配置参数</span>
              </template>
            </a-step>
          </a-steps>
        </div>
        <component v-if="nodeComponentType !== 'task'" class="node-container" :is='nodeComponent'></component>
      </div>
      <div class="center" v-show="toolSelected === 'params'">
        <paramSetting :winboxHeight="winboxHeight" />
      </div>
      <div class="center" v-show="toolSelected === 'notice'">
        <notice :winboxHeight="winboxHeight" />
      </div>
      <div class="center" v-show="toolSelected === 'log'">
        <Log type="flow" :taskId="taskId" :editorHeight="editorHeight" :noTips='true' />
      </div>
    </div>
    <div class="left-tabs">
      <div :class="['left-tabs-cate', toolSelected === 'steps' && 'left-tabs-cate-selected']" @click="selectTool('steps')">
        <div>
          <svg-icon class="left-tabs-cate-icon" :class="['left-tabs-cate-icon', toolSelected === 'steps' && 'left-tabs-cate-icon-selected']" icon-class="steps"></svg-icon>
        </div>
        <div :class="['left-tabs-cate-name', toolSelected === 'steps' && 'left-tabs-cate-name-selected']">步骤</div>
      </div>
      <div :class="['left-tabs-cate', toolSelected === 'params' && 'left-tabs-cate-selected']" @click="selectTool('params')">
        <div>
          <svg-icon class="left-tabs-cate-icon" :class="['left-tabs-cate-icon', toolSelected === 'params' && 'left-tabs-cate-icon-selected']" icon-class="task-params"></svg-icon>
        </div>
        <div :class="['left-tabs-cate-name', toolSelected === 'params' && 'left-tabs-cate-name-selected']">参数</div>
      </div>
      <div :class="['left-tabs-cate', toolSelected === 'notice' && 'left-tabs-cate-selected']" @click="selectTool('notice')">
        <div>
          <svg-icon class="left-tabs-cate-icon" :class="['left-tabs-cate-icon', toolSelected === 'notice' && 'left-tabs-cate-icon-selected']" icon-class="task-notice"></svg-icon>
        </div>
        <div :class="['left-tabs-cate-name', toolSelected === 'notice' && 'left-tabs-cate-name-selected']">通知</div>
      </div>
      <div :class="['left-tabs-cate', toolSelected === 'log' && 'left-tabs-cate-selected']" @click="selectTool('log')">
        <div>
          <svg-icon class="left-tabs-cate-icon" :class="['left-tabs-cate-icon', toolSelected === 'log' && 'left-tabs-cate-icon-selected']" icon-class="flow-log"></svg-icon>
        </div>
        <div :class="['left-tabs-cate-name', toolSelected === 'log' && 'left-tabs-cate-name-selected']">日志</div>
      </div>
      <div class="collapse-icon" @click="changeShowTabs">
        <a-icon v-if="!showtabs" type="menu-fold" />
        <a-icon v-else type="menu-unfold" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import CommonForm from "./commonForm.vue";
import paramSetting from "./paramSetting/paramContainer.vue";
import notice from "./notice/index.vue";
import App from './app.vue'
import SelectOpt from './selectOpt.vue';
import IfNode from '@/components/workflow-design/components/node/if.vue'
import LoopNode from '@/components/workflow-design/components/node/loop.vue'
import SwitchNode from '@/components/workflow-design/components/node/switch.vue'
import ParallelNode from '@/components/workflow-design/components/node/parallel.vue'
import Log from '@/components/createTask/components/log.vue'
import AuthConfig from './authConfig/index.vue'

import { validateStep } from './validate'


export default {
  name: "operationBox",
  props: {
    taskType: {
      type: String,
      default: "task",
    },
  },
  provide() {
    return {
      selectTool: this.selectTool
    };
  },
  components: { App, CommonForm, SelectOpt, paramSetting, notice, IfNode, LoopNode, ParallelNode, SwitchNode, Log, AuthConfig },
  watch: {
    taskId: {
      handler(val) {
        if (val) {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    // 从模板那边进来的 隐藏右边的模板
    taskType: {
      handler(val) {
        if (val === "template") {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    'winboxTask.currentOptIndex': {
      handler(val) {
        // this.setCurrentPageIndex(val)
      },
      // immediate: true
    }
  },
  data() {
    return {
      toolSelected: 'steps',
      stepsType: "newcreatetemplate",
      interval: 0,
      currentSteps: 1,
      eidtFlag: false,
      showtabs: true
      // currentStepsIndex: -1,
    };
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],

  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
    }),
    winboxHeight () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max) height = document.documentElement.clientHeight
      if (full) height = document.documentElement.clientHeight
      return height
    },
    editorHeight () {
      return `${this.winboxHeight - 156}px`
    },
    currentTaskTYpe () {
      const obj = this.winboxTask?.stepInfo
      return this.winboxTask.selectTemplateIndex === 0 ? obj?.type : 'normal'
    },
    currentStepsIndex () {
      let currentStepsIndex = 0
      currentStepsIndex =  this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.properties['currentStepsIndex'] || 0;
      return currentStepsIndex
    },
    nodeComponentType () {
      let nodeComponentType = this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.type || 'task'
      return nodeComponentType
    },
    nodeComponent () {
      return this.nodeComponentType + '-node'
    },
    currentWinboxKey () {
      // const currentFocusWindow = this.windowStore.currentFocusWindow;
      // let key = null
      // for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
    taskId() {
      const taskId = this.windowPropsData.id || "";
      return taskId;
    },
  },
  methods: {
    ...mapActions("task", [
      "setCurrentPageIndex",
      "setStepInfo"
    ]),
      ...mapActions("winboxFlowModule", [
      "setWinboxCurrentPageIndex",
      "setWinboxStepInfo"
    ]),
    changeShowTabs () {
      this.showtabs = !this.showtabs
      this.$emit('changeShowTabs', this.showtabs)
      let x = this.showtabs ? 600 : 0
      this.$EventBus.$emit('setOffsetX', x)
    },
    selectTool (type) {
      this.showtabs = true
      this.$emit('changeShowTabs', this.showtabs)
      let x = this.showtabs ? 600 : 0
      this.$EventBus.$emit('setOffsetX', x)
      this.toolSelected = type
    },
    changeCurrent (val) {
      if (!this.winboxTask.selectStepId) return false
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo);
      const properties = stepInfo?.taskTemplateDetail?.stepInfo?.properties || {}
      // 选了应用的才能点击到第二步
      if (val === 1 && !properties.application_id) return false
      // 选了应用和操作的才能到第三步
      if (val === 2 && (!properties.application_id || !properties.id)) return false
      properties['currentStepsIndex'] = val
      this.setStepInfo(stepInfo);
      this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});

     // todo: 是否需要同步
    //  this.$EventBus.$emit('updateStepsProperties', taskInfo.taskTemplateDetail.stepInfo.properties) 
    },
    savePublish() {},
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.operation-box {
  background: rgb(251, 253, 254) ;
  border-radius: 4px;
  display: flex;
  padding: 24px 24px 0 24px;
  .left-tabs {
    position: relative;
    .left-tabs-cate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 90px;
        background: rgba(247,248,250,0.50);
        border: 1px solid rgba(207,213,222,0.7);
        border-left: none;
        border-bottom: none;
        cursor: pointer;
        &-name {
          font-size: 13px;
          color: #34343C;
          letter-spacing: 0;
          font-weight: 400;
          margin-top: 0px;
        }
        &-name-selected {
          // color: @primary-color;
          font-weight: 550;
        }
        &-icon {
          font-size: 23px;
          margin-bottom: 5px;
          color: #9DA7BF;
          &-selected {
            color: #3974F4;
          }
        }
        &-selected {
          background: #fff;
          position: relative;
          width: 53px;
          border-right: 3px solid @primary-color;
          right: 1px;
        }
        &:first-child {
          border-radius: 0px 4px 0px 0px;
        }
        &:nth-child(4) {
          border-radius: 0px 0px 4px 0px;
          border-bottom: 1px solid rgba(207,213,222,0.7);
        }
      }
      .collapse-icon {
        position: absolute;
        bottom: 10px;
        right: 14px;
        cursor: pointer;
        i { 
          font-size: 20px;
        }
      }
  }
  .operation-box-container {
    display: flex;
    width: 100%;
    background: rgba(255,255,255,0.15);
    border: 1px solid rgba(209,215,225,1);
    box-shadow: 0px 2px 8px 0px rgba(227,234,250,0.7);
    border-radius: 4px 0px 4px 4px;
  }
  .center {
    flex: 1;
    padding: 20px 0px 20px 24px;
    .chooes-steps {
      margin-right: 55px;
      /deep/ .ant-steps-item-icon {
        position: relative;
        top: 4px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        // border-color: rgb(209,212,217);
        font-size: 12px;
        margin-right: 10px;
      }
    }
    .task-steps {
      font-size: 20px;
      position: relative;
      top: -3px;
    }

    /deep/ .ant-steps-item-title::after {
      background: #cfd5de;
    }
    /deep/ .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
      background: @primary-color;
    }
    /deep/ .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      font-size: 16px;
      color: #1D202D;
    }
    /deep/ .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      font-size: 16px;
      color: #6E788C;
    }
  }
  .logic {
    max-height: 830px;
    height: 830px; 
      .node-container {
        margin-top: 20px;
      }
  }
  .rf {
    width: 400px;
    background: #f9fafb;
    box-shadow: -1px 0px 0px 0px rgba(209, 210, 215, 0.9);
    padding: 20px;
  }
}
.operation-flow-box {
  padding: 0;
  height: 100%;
  margin-right: 53px;
  .left-tabs {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
  }
  .operation-box-container {
    height: calc(100% - 0px);
  }
}
</style>