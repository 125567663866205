<!--
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2025-01-22 16:01:04
 * @FilePath: \awx-ui\src\pages\overview\index.vue
-->

<template>
  <div class="overview-page">
    <!-- to -->
    <div class="overview-card">
      <div class="overview-card-item" @click="toRoute('master')">
        <div class="overview-card-icon">
          <svg-icon icon-class="dashboard-host"></svg-icon>
        </div>
        <div class="flex-warp-overview">
          <div class="sta-word word-red">{{ Statistics.cluster }}</div>
          <div class="title-word">网络</div>
        </div>
      </div>
      <div class="overview-card-item" @click="toRoute('template')">
        <div class="overview-card-icon">
          <svg-icon icon-class="dashboard-template"></svg-icon>
        </div>
        <div class="flex-warp-overview" >
          <div class="sta-word word-blue">{{ Statistics.template }}</div>
          <div class="title-word">模板</div>
        </div>
      </div>
      <div class="overview-card-item" @click="toRoute('task')">
        <div class="overview-card-icon">
          <svg-icon icon-class="dashboard-task"></svg-icon>
        </div>
        <div class="flex-warp-overview">
          <div class="sta-word word-red">{{ Statistics.task }}</div>
          <div class="title-word">任务</div>
        </div>
      </div>
      <div class="overview-card-item" @click="toRoute('dispatch')">
        <div class="overview-card-icon">
          <svg-icon icon-class="dashboard-dispatch"></svg-icon>
        </div>
        <div class="flex-warp-overview">
          <div class="sta-word word-blue">{{ Statistics.scheduler }}</div>
          <div class="title-word">调度</div>
        </div>
      </div>
      <div class="overview-card-item" @click="toRoute('host')">
        <div class="overview-card-icon">
          <svg-icon icon-class="dashboard-host"></svg-icon>
        </div>
        <div class="flex-warp-overview">
          <div class="sta-word word-red">{{ Statistics.host }}</div>
          <div class="title-word">主机</div>
        </div>
      </div>
    </div>
    <!-- 图 -->
    <div class="chart">
      <div class="menu">
        <div class="title">作业状态</div>
        <div class="flex-container">
          <customDatePicker :currentSearchDate="currentSearchDate" :currentSearchDateText="currentSearchDateText" :setting="setting" @getTime="getTime" @getType="getType" />
        </div>
      </div>
      <!-- 为 ECharts 准备一个定义了宽高的 DOM -->
      <div class="main" v-resize="handleResize"></div>
    </div>
    <!-- 表格 -->
    <div class="tab">
      <div class="tab1">
        <div class="flex-bewteen-container">
          <div class="title">定时调度</div>
          <div class="see-more mgr10" @click="toRoute('dispatch')">
            查看更多
            <!-- <svg-icon style="font-size: 16px;color:#2b66ca;" icon-class="right-arrow" /> -->
          </div>
        </div>
        <div v-resizeExtend="{ itemWidth, updateItems: setVisibleCount }" class="table-info task-table zebra-table">
          <a-table :columns="visibleColumns" :dataSource="livelyList" :loading="liveLoading" row-key="id" :pagination="false">
            <span slot="recent_jobs" class="s" slot-scope="text,record">
              <span :class="['beat', 'down', item.status=='successful' && 'beat-success', item.status=='failed' && 'beat-fail']" v-for="item in record.detail.recent_jobs" :key="item.id">
              </span>
            </span>
          </a-table>
        </div>
      </div>
      <!-- 表格二 -->
      <div class="tab1">
        <div class="flex-bewteen-container">
          <div class="title">最近任务</div>
          <div class="see-more mgr10" @click="toRoute('task')">
            查看更多
            <!-- <svg-icon style="font-size: 16px;color:#2b66ca" icon-class="right-arrow" /> -->
          </div>
        </div>
        <div v-resizeExtend="{ itemWidth, updateItems: setVisibleCount }" class="table-info zebra-table">
          <a-table :columns="visibleColumns2" :dataSource="runList" :loading="runLoading" :rowKey="(record)=>record['task_id']" :pagination="false"></a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import customDatePicker from '@/components/customDatePicker'
import { mapState, mapMutations, mapActions } from "vuex";
import * as echarts from 'echarts';
import { DASHBOARD_COMPONENT } from '../../components/winbox/dashboard'
import _ from "lodash";

export default {
  name: "overviewList",
  components: {
    customDatePicker,
      MyDynamicComponent: () => import('@/components/winbox/RenderComponent')
  },
  data() {
    return {
      visibleCount: 0,
      itemWidth: 150, // 假设每列的宽度为150px
      windowWidth : 1200,
      currentClutsterId: localStorage.getItem('CLUSTER_ID'),
      setting: {
        isDashBoard: true,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        showMinute: false,
        showTwoMonth: false,
        showThreeMonth: false,
        allowClear: false,
        clearDate: false,
        disabledDate: true,
        // timeForMat: 'HH'
        timeForMat: 'HH:mm:ss',
      },
      date: "week",
      type: "all",
      menuList: {
        month: "month",
        two_weeks: "two_weeks",
        day: "day",
        week: "week",
      },
      changeType: 'day',
      params: {
        start_time: '',
        end_time: ''
      },
      runList: [],
      livelyList: [],
      liveLoading: false,
      runLoading: false,
      successfulArr: [],
      successfulList: [],
      Statistics: [],
      failedArr: [],
      failedList: [],
      seriesArr: [],
      currentSearchDate: [],
      currentSearchDateText: '半天',
      series2Arr: [],
      columns: [
        {
          title: "调度名称",
          dataIndex: "name",
          ellipsis: true,
          key: "name",
          customRender: (text, record) => {
            return (
              <span>
                <span class="task-status">
                  <svg-icon style="color: red" icon-class={record.enabled ? 'schedule' : 'task-init'} /> 
                </span>
                <span
                  class="name"
                  onClick={() => this.toRouteSingle(record, "调度")}
                >
                  {text}
                </span>
              </span>
           
            );
          },
        },
        {
          title: "执行结果",
          dataIndex: "recent_jobs",
          ellipsis: true,
          key: "recent_jobs",
          scopedSlots: { customRender: "recent_jobs" },
        },
        {
          title: "下次执行时间",
          dataIndex: "next_run",
          ellipsis: true,
          key: "next_run",
          customRender: (text, row) => {
            return (
              <span class="ell" title={row.next_run ? moment(row.next_run).format("YYYY-MM-DD HH:mm:ss") : '-'}>{row.next_run ? moment(row.next_run).format("YYYY-MM-DD HH:mm:ss") : '-'}</span>
            );
          },
        },
      ],
      columns2: [
        {
          title: "任务名称",
          key: "name",
          dataIndex: "name",
          ellipsis: true,
          // task-successful
          customRender: (text, record) => {
            return (
              <span
                class="task-status name"
                title={text}
                onClick={() => this.toRouteSingle(record, "任务")}
              >
                {record.status == "successful" ? (
                  <svg-icon icon-class="task-successful" />
                ) : record.status == "running" ? (
                  <svg-icon class="task-running " icon-class="task-running" />
                ) : (
                  <svg-icon icon-class="task-failed" />
                )}
                {text}
              </span>
            );
          },
        },
        {
          title: "运行时间",
          ellipsis: true,
          key: "started",
          dataIndex: "started",
          customRender: (text) => {
            return (
              <span title={moment(text).format("YYYY-MM-DD HH:mm:ss")}>
                <svg-icon
                  style="font-size: 16px;position: relative; top: -1px; margin-right: 6px;"
                  icon-class="task-time"
                />
                {moment(text).format("YYYY-MM-DD HH:mm:ss")}
              </span>
            );
          },
        },
        {
          title: "操作",
          key: "opt",
          dataIndex: "opt",
          width: 90,
          customRender: (text, row) => {
            return (
              <span class="task-zhixing" onClick={() => this.startTask(row)}>
                <svg-icon
                  style="font-size: 16px;position: relative; top: -1px; margin-right: 6px"
                  icon-class="task-torun"
                />{" "}
                执行
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapState('schedule', ['scheduleDict']),
    ...mapState({
      fileManage: (state) => state.fileManage, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    visibleColumns() {
      return this.columns.slice(0, this.visibleCount);
    },
    visibleColumns2() {
      return this.columns2.slice(0, this.visibleCount);
    },
  },
  watch: {
    'fileManage.clusterId': {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.linelist()
          this.count();
          this.lively();
          this.run();
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapActions("task", ["setTaskInfo", "changeFirstEdit", "setJumpTaskDetail", "setTaskIndexShow", "setFlowIndexShow"]),
    setVisibleCount(count) {
      this.visibleCount = count;
    },
    initDate () {
      const currentDate = moment();
      // 计算最近两周的起始日期（往前推14天）
      const startDate = currentDate.clone().subtract(11, 'hours').format('YYYY-MM-DD HH:mm:ss');
      // 计算最近两周的结束日期
      const endDate = currentDate.clone().format('YYYY-MM-DD HH:mm:ss');
      this.currentSearchDate = [startDate, endDate]
      this.changeType = 'hours'
      this.linelist();
    },
    getType (type) {
      this.changeType = type
    },
    getTime (date, text, type) {
      this.currentSearchDate = date
      this.currentSearchDateText = text
      // 增加参数 事件查询
      // this.params.period = val;
      this.linelist();
    },
    toRouteSingle(record, route) {
      if (route === '任务' && record.job_type === '3') {
        this.setFlowIndexShow('history')
         return this.$router.push({
          name: '流程',
          params: {
            obj: record,
          },
        });
      }
      this.setTaskIndexShow('history')
      this.$router.push({
        name: route,
        params: {
          obj: record,
        },
      });
    },
    toRoute(route) {
      let appLoaderOptions = DASHBOARD_COMPONENT.get(route)
      const component = this.$options.components['MyDynamicComponent'];
      this.$openWindow(appLoaderOptions.id, this.$options.components, {propsData: appLoaderOptions}, {}, {...appLoaderOptions});
    },
    lineEcharts() {
      const dom = document.querySelector(".main")
      if (!dom) return false
      let myChart = echarts.init(dom);
      let option = {
        title: {
          text: "",
        },
        color: ["#F92D26", "#57A850"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["失败", "成功"],
          icon: "rect",
          top: 0,
          left: "5%",
          itemWidth: 12,
          itemHeight: 12,
        },
        grid: {
          top: 32,
          left: "1%",
          right: "1.%",
          bottom: "1%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.failedArr,
        },
        yAxis: {
          name: "数量",
          type: "value",
        },
        series: [
          {
            name: "失败",
            type: "line",
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
              },
            },
            data: this.failedList,
          },
          {
            name: "成功",
            type: "line",
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
              },
            },
            data: this.successfulList,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.myChart = myChart
      // window.onresize = function () {
      //   myChart.resize();
      // };
    },
    handleResize({ width, height }) {
      this.throttleUpdateWindowState(width, height)
    },
    throttleUpdateWindowState: _.debounce(function(width, height) {
      this.windowWidth = width
      // 在这里处理尺寸变化后的逻辑
      if (this.myChart) this.myChart.resize();
    }, 500),
    handlerScheduleIcon (id) {
      const obj = this.scheduleDict.find(item => item.id === id) 
      const icon = obj?.icon || ''
      return icon
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    linelist() {
      this.failedList = [];
      this.seriesArr = [];
      this.failedArr = [];
      this.successfulList = [];
      this.series2Arr = [];
      this.successfulArr = [];
      let params = {
        start_time: this.currentSearchDate[0],
        end_time: this.currentSearchDate[1]
      }
      // if (this.currentSearchDateText === '半天') {
      //   params.start_time = moment(this.currentSearchDate[0]).format('YYYY-MM-DD HH:00:00')
      //   params.end_time = moment(this.currentSearchDate[1]).format('YYYY-MM-DD HH:00:00')
      // }
      this.$axiosGet(global.API.getAnnouncementOperation, params).then(
        (res) => {
          if (![200, 201, 202, 204].includes(res.status)) return false
          // 失败
          res.data.failed.map((item) => {
            return this.failedList.push(item[1]);
          });
          res.data.failed.map((item) => {
            return this.seriesArr.push(item[0]);
          });
          this.seriesArr.map((item) => {
            return this.changeType === "hours"
              ? this.failedArr.push(moment(item * 1000).format("HH:mm"))
              : this.failedArr.push(moment(item * 1000).format("MM/DD"));
          });
          // 成功
          res.data.successful.map((item) => {
            return this.successfulList.push(item[1]);
          });
          res.data.successful.map((item) => {
            return this.series2Arr.push(item[0]);
          });
          this.series2Arr.map((item) => {
            return this.changeType === "hours"
              ? this.successfulArr.push(moment(item * 1000).format("HH:mm"))
              : this.successfulArr.push(moment(item * 1000).format("MM/DD"));
          });
          this.lineEcharts();
        }
      );
    },
    //公告计数
    count() {
      this.$axiosGet(global.API.getCount).then((res) => {
        this.Statistics = res.data;
      });
    },
    //活跃
    lively() {
      this.liveLoading = true;
      const obj = {
        page: 1,
        page_size: 5,
        schedule_type: 'timer'
      };
      this.$axiosGet(global.API.getDispatchList, obj).then((res) => {
        this.liveLoading = false;
        this.livelyList = res.data.results;
      });
    },
    // 执行任务
    async startTask(task) {
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${task.task_id}/start`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("提交成功");
        this.pollingSearch();
      }
    },
    // 执行之后定时去刷
    pollingSearch() {
      this.run(); // 先立马刷一次
      let self = this;
      if (self.timer) clearInterval(self.timer);
      self.timer = setInterval(() => {
        let list = this.runList.filter((item) => item.status === "running");
        if (this.runList.length > 0 && list && list.length < 1) {
          clearInterval(self.timer);
          return false;
        }
        self.run(true);
      }, global.intervalTime);
    },
    // 运行
    run(flag) {
      if (!flag) this.runLoading = true;
      const obj = {
        page: 1,
        page_size: 5,
        order_by: '-start_time'
      };
      this.$axiosGet(global.API.getRun, obj).then((res) => {
        this.runLoading = false;
        this.runList = res.data.results;
      });
    },
  },
  mounted() {
    if (!this.fileManage.clusterId) return false
    this.initDate()
    this.count();
    this.lively();
    this.run();
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
.overview-page {
  height: 100%;
  box-shadow: none;
  .convert-size-multiple(padding, 16px 14px 14px 16px);
  overflow-y: auto;
  min-width: 1000px;
  min-height: 600px;
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  
  .task-table {
      /deep/ .ant-table-tbody > tr > td {
      .convert-size-multiple(padding, 8px 16px 7px);
    }
  }
  .beat {
    display: inline-block;
    border-radius: 50rem;
    .convert-size(width, 7px);
    .convert-size(height, 21px);
    .convert-size(margin, 4px);
    background-color: #d0cfcf;
    --hover-scale: 1.5;
    &-success {
      background-color: #5cdd8b;
    }
    &-fail {
      background-color: #f92d26;
    }
  }
  .title {
    // .convert-size(font-size, 14px);
    font-size: 14px;
    color: #1d202d;
    letter-spacing: 0;
    font-weight: 600;
  }
  .task-zhixing {
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .task-running {
    transform: rotate(360deg);
    animation: rotation 2s linear infinite;
  }
  .see-more {
    // .convert-size(font-size, 12px);
    font-size: 12px;
    color: #34343C;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;
    
  }
  .task-status {
    svg {
      font-size: 18px;
      margin-right: 10px;
    }
  }
  .name {
    color: #2b66ca;
  }
  .name:hover {
    color: #2b66ca;
    text-decoration: underline;
    cursor: pointer;
  }
  .overview-card {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // .convert-size(height, 100px);
    // .convert-size(min-height, 100px);
    height: 11%;
    min-height: 11%;
    .convert-size(margin-bottom, 12px);
    .overview-card-item {
      width: calc(20% - 14px);
      background: #ffffff;
      border: 1px solid rgba(217,217,217,1);
      .convert-size(border-radius, 4px);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .overview-card-icon svg {
        // .convert-size(font-size, 40px);
        font-size: 40px;
        .convert-size(margin-right, 30px);
      }
      .flex-warp-overview {
        font-size: 14px;
        .convert-size(height, 50px);
        .convert-size(min-height, 50px);
        .convert-size(max-height, 50px);
        color: #34343c;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
        .sta-word {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 0px;
          .convert-size(height, 30px);
          .convert-size(line-height, 30px);
        }
        .title-word {
           .convert-size(height, 20px);
          .convert-size(line-height, 20px);
        }
        .word-red {
          color: #f3372d;
        }
        .word-blue {
          color: #2b66ca;
        }
      }
    }
    .ant-select {
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 100;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .chart {
    margin: auto;
    width: 100%;
    flex: 1;
    background: #ffffff;
    .convert-size-multiple(padding, 16px 20px);
    position: relative;
    border: 1px solid rgba(217,217,217,1);
    border-radius: 4px;
    .menu {
      display: flex;
      justify-content: space-between;
      .ant-select-selection--single {
        margin-right: 10px;
      }
    }
    .main {
      width: 100%;
      height: 88%;
    }
  }
  .tab {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    height: 290px;
    min-height: 290px;
    //  .convert-size(height, 304px);
    //  .convert-size(min-height, 304px);
    border-radius: 4px;
    .tab1 {
      width: calc(50% - 8px);
      background: #FFFFFF;
      border: 1px solid rgba(217,217,217,1);
      border-radius: 4px;
      .convert-size-multiple(padding, 16px 20px);
      .table-info {
        height: 88%;
        overflow-y: auto;
        margin-top: 12px;
      }
    }
  }
  .time-menu {
    width: 30px;
  }
  .grid {
    display: flex;
    flex-direction: column;
    margin: 1px;
    span {
      width: 20px;
      height: 8px;
      background: #f1f6fa;
      border: 1px solid rgba(222, 226, 228, 1);
      border-radius: 1px;
    }

    .active {
      background: #f92d26;
      border: 1px solid #f92d26;
    }
    .active2 {
      background: #57a850;
      border: 1px solid #57a850;
    }
  }
  .s {
    display: flex;
  }
}
/deep/ .ant-table-tbody > tr > td {
  padding: 10px 16px 9px;
}
/deep/ .ant-table {
  font-size: 14px;
}
</style>
