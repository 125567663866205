import { render, staticRenderFns } from "./addIconToDesktop.vue?vue&type=template&id=1fc42319&scoped=true"
import script from "./addIconToDesktop.vue?vue&type=script&lang=js"
export * from "./addIconToDesktop.vue?vue&type=script&lang=js"
import style0 from "./addIconToDesktop.vue?vue&type=style&index=0&id=1fc42319&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fc42319",
  null
  
)

export default component.exports