<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-07-18 10:37:21
 * @FilePath: \awx-ui\src\components\workflow-design\components\commonForm.vue
-->
<template>
  <div class="common-template steps">
    <div id="create-task" style="max-height: 100%; overflow-y: auto;padding-right: 44px">
      <a-form :form="form" :label-col="{span: 5}" :wrapper-col="{span: 19}" :class="['form-content', 'mgh160', isQuick ? 'qiick-content' : '']">
      </a-form>
      <FormDesign v-if="selectedTemplateObj.form_type !== '1'" designerType='flow' :key="task.selectStepId" :selectedTemplateObj="selectedTemplateObj.schema" :model="model" />
      <div v-else>
        <component v-if="isComponentLoaded" :is="dynamicComponent" :source="taskType"></component>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import FormDesign from "../../../pages/formRenderDemo";
import {configTools, loadComponents} from "@/utils/customComponents";

export default {
  name: "CommonTemplate",
  props: { templateData: Array, isQuick: { type: Boolean, default: false }, taskType: {type: String, default: 'task'} },
  components: { FormDesign },
  data() {
    const self = this;
    return {
      changeFlag: false,
      testData: [],
      hostGroupList: [],
      hostList: [],
      controllerList: [],
      initFormFiledFlag: false,
      isComponentLoaded: false,
      dynamicComponent: null,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      model: {},
      selectedTemplateObj: { name: "", form_type: '0' },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {
          if (self.initFormFiledFlag) {
            self.initFormFiledFlag = false;
            return false;
          }
          self.syncFormdata(fileds);
        },
      }),
    };
  },
  watch: {
    "winboxTask.selectStepId": {
      handler(val) {
        this.selectedTemplateObj = this.winboxTask.stepInfo?.taskTemplateDetail?.stepInfo.properties || { name: "", description: "" };
        if (this.selectedTemplateObj && this.selectedTemplateObj.model) this.model = this.selectedTemplateObj.model || {}
        // 动态表单
        if (this.selectedTemplateObj.form_type !== '1') {
          // this.initFormFiled()
        } else {
          this.echoComponents()
        }
      },
      immediate: true,
    },
    templateData: {
      handler(val) {},
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
    }),
    currentWinboxKey () {
      // const currentFocusWindow = this.windowStore.currentFocusWindow;
      // let key = null
      // for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  methods: {
    ...mapActions("task", ["setTaskInfo"]),
    // 回显自定义组件
    getComponents(item) {
      this.componentList = [{ ...item }];
      this.loadComponent(item);
    },
    echoComponents  () {
      const data = this.selectedTemplateObj
      const componentItem = configTools[0].componentList.find(item => item.componentPack === data.form_component)
      if (componentItem?.componentPack) {
        this.getComponents(componentItem)
      }
    },
    async loadComponent(componentItem) {
      const component = loadComponents[`${componentItem.componentPack}`]
      component().then((item) => {
        this.isComponentLoaded = true;
        this.componentName = componentItem.componentPack
        this.dynamicComponent = item.default;
      })
    },
    syncFormdata(fileds) {
      const currentIndex = this.winboxTask.selectTemplateIndex;
      const taskInfo = _.clone(this.winboxTask.taskInfo);
      const taskTemplateDetail = taskInfo.taskTemplateDetail;
      const operations = taskTemplateDetail.operations;
      for (var key in fileds) {
        // 名字比较特殊 特殊处理
        if (key === "name") {
          operations[currentIndex].name = fileds[key];
        }
      }
      debugger
      this.setTaskInfo(taskInfo);
    },
    // 获取主机组
    getGroupHost() {
      const params = {
        page_size: 10000,
        page: 1,
        keyword: "",
      };
      this.$axiosGet(global.API.getHostGroups, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.hostGroupList = res.data.results;
        }
      });
    },
    // 获取主机
    getHosts() {
      const params = {
        page_size: 1000,
        page: 1,
        keyword: "",
      };
      this.$axiosGet(global.API.getHostList, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.hostList = res.data.results;
        }
      });
    },
  },
  created() {
    // this.getGroupHost();
    // this.getHosts();
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.common-template {
  max-height: calc(100vh - 180px); 
  height: calc(100vh - 180px);
  margin-top: 16px;
  /deep/ .el-select__tags {
    max-width: 483px!important;
  }
  // 头部
  .drag-template-item {
    margin-top: 16px;
    margin-bottom: 30px;
    display: flex;
    position: relative;
    align-items: center;
    z-index: 1;
    border: 1px solid transparent;
    padding: 0 0 0 14px;
    img {
      height: 42px;
      margin: 0px 20px 0 0;
      width: 42px;
    }
    .action-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    .action-text-wrap {
      width: 100%;
      height: 24px;
      line-height: 24px;
      margin-bottom: 4px;
      white-space: nowrap;
      .action-label {
        display: inline-block;
        font-weight: 700;
        max-width: 65%;
        font-size: 16px;
        color: #1d202d;
        letter-spacing: 0;
        font-weight: 600;
        margin-right: 10px;
        min-width: 10px;
      }
    }
    .action-desc {
      font-size: 14px;
      color: #6f737d;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .mgh160 {
    // margin: 0 110px;
  }
  .form-content {
    max-height: 660px;
    overflow-y: auto;
    // padding: 0 22px 0 48px;
    .flow-date {
      .ant-calendar-picker {
        min-width: 176px!important;
      }
    }
    .reduce-icon {
      cursor: pointer;
      font-size: 18px;
      color: @primary-color;
      margin-left: 6px;
    }
    .form-multiple-item:last-child {
      margin-bottom: 12px;
    }
    .form-item-container {
      position: relative;
      // margin-bottom: 6px;
      .filed-name-tips {
        display: flex;
        align-items: center;
        position: absolute;
        font-size: 12px;
        top: 32px;
        left: 12px;
        width: 20%;
        &-word {
          display: inline-block;
          max-width: calc(100% - 0px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .filed-name-tips-icon {
        cursor: pointer;
      }
      .slider-icon {
        position: relative;
        top: -28px;
      }
    }
  }
  .qiick-content {
    max-height: 660px;
  }
  .ant-collapse-borderless {
    background: #fff;
    .ant-collapse-item {
      border-bottom: none;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px;
  }
}
</style>
<style lang="less">
#create-task {
  .genFromComponent {
    border: none;
    padding: 0px;
    .el-form-item__label {
      width: 20.8% !important;
    }
    .el-form-item__content {
      margin-left: 20.8% !important;
    }
  }
}
</style>