/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2024-11-14 11:11:47
 * @FilePath: \awx-ui\src\router\config.js
 */
import TabsView from '@/layouts/tabs/TabsView'
import BlankView from '@/layouts/BlankView'
import PageView from '@/layouts/PageView'


// 路由配置
const options = {
  routes: [
  {
    path: '/403',
    name: '403',
    component: () => import('@/pages/exception/403'),
  },
  {
      "id": "c2c7d922-24b5-419e-8e9c-b793ac6f1aae",
      "description": "",
      "create_time": "2023-07-06T14:15:59.479+08:00",
      "update_time": "2023-07-06T14:15:59.475+08:00",
      "owner": "",
      "path": "/",
      "name": "安全运维管理平台",
      "redirect": "/dashboard",
      "component": () => import("@/layouts/tabs/TabsView"),
      "parent_id": "root",
      "children": [
          {
              "id": "dashboard",
              "description": "",
              "create_time": "2023-07-06T14:15:59.476+08:00",
              "update_time": "2023-07-06T14:15:59.476+08:00",
              "owner": "",
              "path": "/",
              "name": "安全运维管理平台",
              "redirect": "/",
              "component": () => import("@/layouts/PageView"),
              "parent_id": "c2c7d922-24b5-419e-8e9c-b793ac6f1aae",
              "children": [
                  {
                      "id": "dashboard_index",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.476+08:00",
                      "update_time": "2023-07-06T14:15:59.476+08:00",
                      "owner": "",
                      "path": "/",
                      "name": "概览",
                      "redirect": "",
                      "component": () => import("@/pages/overview/index"),
                      "parent_id": "dashboard",
                      "children": [],
                      "menu_order": "001",
                      "meta": {
                          "icon": "third",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "6712c067-3c98-4e9f-8ca7-b39ce9782b6f",
                      "description": "",
                      "create_time": "0001-01-01T00:00:00Z",
                      "update_time": "2023-11-08T20:01:02.211+08:00",
                      "owner": "",
                      "path": "/dashboard/limix",
                      "name": "监控",
                      "redirect": "",
                      "component":() => import("@/layouts/ThirdPartyPage/index"),
                      "parent_id": "dashboard",
                      "children": [],
                      "menu_order": "009",
                      "meta": {
                          "icon": "third",
                          "notAlive": true,
                          "externalUrl": "http://47.92.78.18:3000/d/limix/linuxe4b8bb-e69cba-e8afa6-e68385?orgId=1\u0026kiosk=false"
                      }
                  }
              ],
              "menu_order": "001",
              "meta": {}
          },
          {
              "id": "ab76cf0a-2fec-410d-95d4-3fb69ed3af36",
              "description": "",
              "create_time": "2023-07-06T14:15:59.476+08:00",
              "update_time": "2023-07-06T14:15:59.476+08:00",
              "owner": "",
              "path": "resource",
              "name": "资源管理",
              "redirect": "/resource/group-manage",
              "component": () => import("@/layouts/PageView"),
              "parent_id": "c2c7d922-24b5-419e-8e9c-b793ac6f1aae",
              "children": [
                  {
                      "id": "461237eb-1154-47b0-9e6c-8d2f2c34b0cd",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2024-01-25T14:48:46.91+08:00",
                      "owner": "",
                      "path": "group-manage",
                      "name": "主机",
                      "redirect": "",
                      "component": () => import("@/pages/groupManage/index"),
                      "parent_id": "ab76cf0a-2fec-410d-95d4-3fb69ed3af36",
                      "children": [],
                      "menu_order": "1",
                      "meta": {
                          "icon": "home",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "8df02c72-b0fc-4bd8-93ee-f0cc9a65f471",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "master",
                      "name": "网络",
                      "redirect": "",
                      "component": () => import("@/pages/securityCenter/master"),
                      "parent_id": "ab76cf0a-2fec-410d-95d4-3fb69ed3af36",
                      "children": [],
                      "menu_order": "2",
                      "meta": {
                          "icon": "safety-certificate"
                      }
                  },
                  {
                      "id": "8df02c72-b0fc-4bd8-93ee-f0cc9a65f472",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "cluster",
                      "name": "集群",
                      "redirect": "",
                      "component":() => import("@/pages/securityCenter/cluster"),
                      "parent_id": "ab76cf0a-2fec-410d-95d4-3fb69ed3af36",
                      "children": [],
                      "menu_order": "2",
                      "meta": {
                          "icon": "safety-certificate"
                      }
                  }
              ],
              "menu_order": "002",
              "meta": {
                  "icon": "menu-host"
              }
          },
          {
              "id": "8e8d62bf-84c0-4efb-a480-90ef44698639",
              "description": "",
              "create_time": "2023-07-06T14:15:59.476+08:00",
              "update_time": "2023-07-06T14:15:59.476+08:00",
              "owner": "",
              "path": "task-manage",
              "name": "任务管理",
              "redirect": "/task-manage/deployman",
              "component": () => import("@/layouts/PageView"),
              "parent_id": "c2c7d922-24b5-419e-8e9c-b793ac6f1aae",
              "children": [
                  {
                      "id": "2af45f6c-24a6-49ad-a7d7-5f3caa5ea700",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-11-03T14:42:13.465+08:00",
                      "owner": "",
                      "path": "pipeline-edit",
                      "name": "编辑流程",
                      "redirect": "",
                      "component": () => import("@/pages/pipeline/createFlowDesign"),
                      "parent_id": "8e8d62bf-84c0-4efb-a480-90ef44698639",
                      "children": [],
                      "menu_order": "",
                      "meta": {
                          "icon": "task-manage",
                          "notAlive": true,
                          "invisible": true
                      }
                  },
                  {
                      "id": "cbcd2848-ff84-46eb-a631-d2af9c0bc81e",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "deployman",
                      "name": "任务",
                      "redirect": "",
                      "component":() =>  import("@/pages/taskManage/index"),
                      "parent_id": "8e8d62bf-84c0-4efb-a480-90ef44698639",
                      "children": [],
                      "menu_order": "1",
                      "meta": {
                          "icon": "calendar",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "160f452e-a61d-4043-b92e-e98f15877fd3",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-11-03T14:40:51.806+08:00",
                      "owner": "",
                      "path": "pipeline-new",
                      "name": "新建流程",
                      "redirect": "",
                      "component": () => import("@/pages/pipeline/createFlowDesign"),
                      "parent_id": "8e8d62bf-84c0-4efb-a480-90ef44698639",
                      "children": [],
                      "menu_order": "2",
                      "meta": {
                          "icon": "task-manage",
                          "notAlive": true,
                          "invisible": true
                      }
                  },
                  {
                      "id": "4e378b70-1ec3-4f97-bbda-9f88a16f5208",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "pipeline",
                      "name": "流程",
                      "redirect": "",
                      "component": () => import("@/pages/pipeline/index"),
                      "parent_id": "8e8d62bf-84c0-4efb-a480-90ef44698639",
                      "children": [],
                      "menu_order": "2",
                      "meta": {
                          "icon": "task-manage",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "d99312cb-914d-4b95-8807-b6dd4e0f7c26",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "dispatch-manage",
                      "name": "调度",
                      "redirect": "",
                      "component": () => import("@/pages/dispatch/index"),
                      "parent_id": "8e8d62bf-84c0-4efb-a480-90ef44698639",
                      "children": [],
                      "menu_order": "3",
                      "meta": {
                          "icon": "dispatch",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "d99312cb-914d-4b95-8807-b6dd4e0f7c00",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "template-shop",
                      "name": "模版",
                      "redirect": "",
                      "component": () => import("@/pages/templateShop/index"),
                      "parent_id": "8e8d62bf-84c0-4efb-a480-90ef44698639",
                      "children": [],
                      "menu_order": "4",
                      "meta": {
                          "icon": "menu-shop",
                          "notAlive": true
                      }
                  }
              ],
              "menu_order": "004",
              "meta": {
                  "icon": "task-manage"
              }
          },
          {
              "id": "91d850aa-1a6e-4e27-918e-8096a01f3d18",
              "description": "",
              "create_time": "2023-07-06T14:15:59.476+08:00",
              "update_time": "2023-07-06T14:15:59.476+08:00",
              "owner": "",
              "path": "app-manage",
              "name": "应用管理",
              "redirect": "/app-manage/index",
              "component": () => import("@/layouts/PageView"),
              "parent_id": "c2c7d922-24b5-419e-8e9c-b793ac6f1aae",
              "children": [
                  {
                      "id": "b71d1293-f2b6-48af-b3dc-8246393fb0de",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "app-edit",
                      "name": "编辑应用",
                      "redirect": "",
                      "component": () => import("@/pages/appManage/editApp/index"),
                      "parent_id": "91d850aa-1a6e-4e27-918e-8096a01f3d18",
                      "children": [],
                      "menu_order": "",
                      "meta": {
                          "icon": "menu-dashboard",
                          "notAlive": false,
                          "invisible": true
                      }
                  },
                  {
                      "id": "b71d1293-f2b6-48af-b3dc-8246393fb0df",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "template-step-new",
                      "name": "新建步骤",
                      "redirect": "",
                      "component": () => import("@/pages/templateStep/modifyStep"),
                      "parent_id": "91d850aa-1a6e-4e27-918e-8096a01f3d18",
                      "children": [],
                      "menu_order": "",
                      "meta": {
                          "icon": "table-step",
                          "notAlive": false,
                          "invisible": true
                      }
                  },
                  {
                      "id": "91d850aa-1a6e-4e27-918e-8096a01f3d19",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.476+08:00",
                      "update_time": "2023-07-06T14:15:59.476+08:00",
                      "owner": "",
                      "path": "index",
                      "name": "应用",
                      "redirect": "",
                      "component":() =>  import("@/pages/appManage/index"),
                      "parent_id": "91d850aa-1a6e-4e27-918e-8096a01f3d18",
                      "children": [],
                      "menu_order": "0071",
                      "meta": {
                          "icon": "menu-dashboard",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "06bcb526-e770-47d7-aa12-cc236b514b28",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "template-step",
                      "name": "步骤",
                      "redirect": "",
                      "component": () => import("@/pages/templateStep/index"),
                      "parent_id": "91d850aa-1a6e-4e27-918e-8096a01f3d18",
                      "children": [],
                      "menu_order": "0078",
                      "meta": {
                          "icon": "table-step",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "06bcb526-e770-47d7-aa12-cc236b514b29",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-12-15T15:39:43.755+08:00",
                      "owner": "",
                      "path": "authManage",
                      "name": "授权",
                      "redirect": "",
                      "component": () => import("@/pages/authManage/index"),
                      "parent_id": "91d850aa-1a6e-4e27-918e-8096a01f3d18",
                      "children": [],
                      "menu_order": "0078",
                      "meta": {
                          "icon": "safety-certificate",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "410036e7-3c6d-4bdf-b147-ade8c900e3f3",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "template-step-edit",
                      "name": "编辑步骤",
                      "redirect": "",
                      "component":() => import("@/pages/templateStep/modifyStep"),
                      "parent_id": "91d850aa-1a6e-4e27-918e-8096a01f3d18",
                      "children": [],
                      "menu_order": "0079",
                      "meta": {
                          "icon": "table-step",
                          "notAlive": false,
                          "invisible": true
                      }
                  }
              ],
              "menu_order": "0071",
              "meta": {
                  "icon": "menu-dashboard",
                  "notAlive": true
              }
          },
          {
              "id": "e6c6b99a-3b1b-4a6b-bcc8-1d34ab70648e",
              "description": "",
              "create_time": "2023-07-06T14:15:59.476+08:00",
              "update_time": "2023-07-06T14:15:59.476+08:00",
              "owner": "",
              "path": "system-manage",
              "name": "系统管理",
              "redirect": "/system-manage/user",
              "component": () => import("@/layouts/PageView"),
              "parent_id": "c2c7d922-24b5-419e-8e9c-b793ac6f1aae",
              "children": [
                  {
                      "id": "37dfccb4-5967-47bd-87f0-fbd6608d80bd",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "user",
                      "name": "用户",
                      "redirect": "",
                      "component": () => import("@/pages/securityCenter/user"),
                      "parent_id": "e6c6b99a-3b1b-4a6b-bcc8-1d34ab70648e",
                      "children": [],
                      "menu_order": "1",
                      "meta": {
                          "icon": "user-management"
                      }
                  },
                  {
                      "id": "d15bbf50-cf79-4643-a1e1-b22115c0e294",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "my-quota",
                      "name": "配额",
                      "redirect": "",
                      "component": () => import("@/pages/management/myquota"),
                      "parent_id": "e6c6b99a-3b1b-4a6b-bcc8-1d34ab70648e",
                      "children": [],
                      "menu_order": "2",
                      "meta": {
                          "icon": "my-quota",
                          "notAlive": true
                      }
                  },
                  {
                      "id": "d383f7bb-0a53-4687-9506-62136041e3b9",
                      "description": "",
                      "create_time": "2023-07-06T14:15:59.477+08:00",
                      "update_time": "2023-07-06T14:15:59.477+08:00",
                      "owner": "",
                      "path": "programme",
                      "name": "方案",
                      "redirect": "",
                      "component": () => import("@/pages/management/index"),
                      "parent_id": "e6c6b99a-3b1b-4a6b-bcc8-1d34ab70648e",
                      "children": [],
                      "menu_order": "3",
                      "meta": {
                          "icon": "audit",
                          "notAlive": true
                      }
                  }
              ],
              "menu_order": "009",
              "meta": {
                  "icon": "menu-quota",
                  "notAlive": true
              }
          }
      ],
      "menu_order": "",
      "meta": {}
  },
  {
    path: '*',
    name: '404',
    redirect: '/dashboard',
    component: () => import('@/pages/exception/404'),
  },
  ]
}

export default options
