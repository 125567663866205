<!--
 * @Description: 
 * @Date: 2024-07-24 10:35:36
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-20 17:11:04
-->
<template>
  <div class='desktop-setting-icon' @click="openSettingBox" @contextmenu.stop="() => {}">
    <svg-icon icon-class='setting'></svg-icon>
  </div>
</template>

<script>
import SettingBoxVue from './SettingBox/index.vue';
import store from '@/store/index'

// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    openSettingBox () {
      const self = this;
      let width = 720;
      let title = "";
      let content = <SettingBoxVue  router={self.$router} />
      const className = ['desktop-setting-box']
      this.$confirm({
        width: width,
        title: title,
        content: content,
        class: className,
        closable: false,
        mask: false,
        maskClosable: true,
        icon: () => {
          return <div />;
        },
      });
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
  .desktop-setting-icon {
    position: fixed;
    bottom: 30px;
    left: 20px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    svg {
      color: rgba(255, 255, 255, .7);
      font-size: 26px;
    }
    &:hover {
      background-color: #00000026;
        svg {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
</style>
