var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['app-item-network', _vm.selectAppId === _vm.appItem.id ? 'app-item-network-selected' : '', _vm.appItem.id === 'add' && 'add-item'],on:{"click":function($event){$event.stopPropagation();return _vm.selectedTemplate(_vm.appItem)}}},[(_vm.appItem.installed && _vm.appItem.installed > 0)?_c('svg-icon',{staticClass:"collect",attrs:{"icon-class":"installed"}}):_vm._e(),_c('div',{staticClass:"plus-to-desktop"},[(_vm.appItem.id !== 'add')?_c('svg-icon',{staticClass:"plus-to-desktop-icon",attrs:{"icon-class":"file-add"}}):_vm._e()],1),_c('span',{staticClass:"app-logo"},[(_vm.appItem.id !== 'add' && !_vm.IsColorIcon(_vm.appItem.icon) && !_vm.IsNetWorkIcon(_vm.appItem.icon))?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.appItem.icon),expression:"appItem.icon"}],attrs:{"alt":""}}):_vm._e(),(_vm.appItem.id !== 'add' && _vm.IsColorIcon(_vm.appItem.icon) && !_vm.IsNetWorkIcon(_vm.appItem.icon))?_c('div',{staticClass:"icon-item",style:({
      'background': _vm.appItem.icon,
      'text-align': 'center',
      'width': '54px',
      'height': '54px',
      'border-radius': '8px',
      'margin': 'auto'
    })},[_c('span',{style:({
        'color': '#fff',
        'font-size': _vm.iconColorName(_vm.appItem.name).length === 1 ? '26px' : '20px',
        'width': '100%',
        'display': 'block',
        'height': '100%',
        'line-height': '54px'
      })},[_vm._v(_vm._s(_vm.iconColorName(_vm.appItem.name)))])]):_vm._e(),(_vm.appItem.id !== 'add' && !_vm.IsColorIcon(_vm.appItem.icon) && _vm.IsNetWorkIcon(_vm.appItem.icon))?_c('iconifyIcon',{staticStyle:{"font-size":"40px"},attrs:{"icon":_vm.iconNetWorkName(_vm.appItem.icon)}}):_vm._e(),(_vm.appItem.id === 'add')?_c('a-icon',{staticStyle:{"font-size":"38px","margin":"auto","display":"inline-block","color":"#3974F4"},attrs:{"type":"plus"}}):_vm._e()],1),(_vm.appItem.id !== 'add')?_c('div',{staticClass:"app-text-info"},[_c('div',{staticClass:"app-title over-flow-ellipsis",style:({'font-size': _vm.appType === 'app' ? '15px': '15px'}),attrs:{"title":_vm.appItem.name}},[_vm._v(_vm._s(_vm.appItem.name))]),(_vm.appItem.description)?_c('p',{staticClass:"app-description over-flow-ellipsis",attrs:{"title":_vm.appItem.description}},[_vm._v(_vm._s(_vm.appItem.description))]):_vm._e(),(_vm.appItem.id !== 'add' && !_vm.appItem.description)?_c('div',{staticClass:"app-categories"},_vm._l((_vm.appcategories),function(item,index){return _c('div',{key:index,staticClass:"app-categories-item"},[_vm._v(_vm._s(_vm.handler(item))+" "),(index !== _vm.appcategories.length - 1)?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e()],1)}),0):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }