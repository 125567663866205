<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-08-12 17:25:52
 * @FilePath: \awx-ui\src\pages\taskManage\runHistory.vue
-->

<template>
  <div class="task-history h-full">
    <a-card class="table-card h-full">
     <div :style="{height: ['1', '2'].includes(taskDetail.type) ? 'calc(100% - 160px)' : '100%'}">
        <div class="flex-bewteen-container" style="margin-bottom: 13px">
          <div class="task-name" @click="back">
            <a-icon type="arrow-left" />
            <span class="mgl10">{{taskDetail.name}} / 执行记录</span>
          </div>
          <div class="opt-warp">
            <a-button class="mgl12" @click="editTask">编辑任务</a-button>
            <a-button class="mgl12" type="primary" @click="runTask">执行任务</a-button>
            <span class="more-action mgl12">
              <a-popover overlayClassName="popover-task" placement="rightTop">
                <template slot="content">
                  <div v-for="item in topButtonTree" :key="item.key" class="more-menu-btn">
                    <a @click="addMenu(item.key)" style="color: #666;padding: 5px 0; display: block">{{item.name}}</a>
                  </div>
                </template>
                <a-button class="sider-header-btn" :icon="'more'" style="font-size:14px;border-width: 0px;background:rgba(0,0,0,0);width:18px"></a-button>
                <span class="curpon">更多操作</span>
              </a-popover>
            </span>
          </div>
        </div>
        <div v-if="['1', '2'].includes(taskDetail.type)" class="table-info custom-type-table">
          <a-table @change="tableChange" :scroll="scrollTable" :columns="columns" :loading="loading" :dataSource="dataSource" :customRow="handleClickRow" rowKey="id" :pagination="pagination"></a-table>
        </div>
        <div v-if="!['1', '2'].includes(taskDetail.type)" class="table-info">
          <a-table class="table-15" :scroll="scrollTable" @change="tableChange" :columns="columns" :loading="loading" :dataSource="dataSource" :customRow="handleClickRow" rowKey="id" :pagination="pagination"></a-table>
        </div>
     </div>
     <a-drawer
          v-if="drawerVisible"
          title=""
          class="run-task-drawer"
          :class="[isFullScreen? 'hasfullScreen': '']"
          placement="bottom"
          :closable="false"
          :mask="false"
          :height="isFullScreen? '100%' : 226"
          :visible="drawerVisible"
          :get-container="false"
          :wrap-style="{ position: 'absolute', height: isFullScreen ? '100%' : '0%' }"
          @close="onClose"
        >
          <ResultTable  v-if="['1'].includes(taskDetail.type)" :resultColumns="resultColumns" :resultLoading="resultLoading" :resultData="resultData" :currentHistory="currentHistory" :isFullScreen="isFullScreen" @closeFullScreen="closeFullScreen" @fullScreen="fullScreen" @onClose="onClose" />
          <ResultTable  v-if="['2'].includes(taskDetail.type)" :resultColumns="resultColumns2" :resultLoading="resultLoading" :resultData="resultData" :currentHistory="currentHistory" :isFullScreen="isFullScreen" @closeFullScreen="closeFullScreen" @fullScreen="fullScreen" @onClose="onClose" />
        </a-drawer>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations, mapActions } from "vuex";
import DeleteTask from "./components/deleteTask.vue";
import {formatDuring} from '@/utils/util'
import ResultTable from '@/components/runTaskResult'

export default {
  name: "TaskHistory",
  components: {ResultTable},
  provide() {
    return {
      handleCancel: this.handleCancel,
      onSearch: null,
    };
  },
  props: {
    taskDetail: Object,
    currentPage: String
  },
  data() {
    return {
      fefreshFlag: true,
      drawerVisible: false,
      isFullScreen: false,
      setSelectedIndex: 0,
      timer: null,
      params: {},
      topButtonTree: [
        // { name: "复制", key: "copy" },
        { name: "删除", key: "del" },
      ],
      pagination: {
        total: 0,
        pageSize: 14,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      currentHistory: {
        detail: {},
        stat: {},
      },
      resultLoading: false,
      resultData:[],
      visible: false,
      confirmLoading: false,
      dataSource: [],
      loading: false,
      resultColumns2: [
        { title: "源文件", key: "src", dataIndex: "src" },
        {
          title: "源类型",
          key: "src_type",
          width: 100,
          dataIndex: "src_type",
        },
        {
          title: "目标主机",
          key: "tgt_name",
          dataIndex: "tgt_name",
          ellipsis: true,
          sorter: (a, b) => {
            return b.tgt_name.localeCompare(a.tgt_name, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "目标文件",
          key: "dest",
          dataIndex: "dest",
        },
        {
          title: "同步结果",
          key: "status",
          width: 110,
          dataIndex: "status",
          sorter: (a, b) => {
            return b.status.localeCompare(a.status, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row, index) => {
            return (
              <span>
                <svg-icon style="font-size: 16px" icon-class={text === "success" ? "task-successful" : "task-failed"} />
                <span class='mgl4'>{text === "success" ? "成功" : "失败"}</span>
              </span>
            );
          },
        },
        {
          title: "备注",
          key: "msg",
          ellipsis: true,
          dataIndex: "msg",
          customRender: (text, row, index) => {
            return (
              <span
                title={text ? text : "-"}
                style={{
                  color: row.result === "false" ? "#e74e4f" : "#000000a6",
                }}
              >
                {text ? text : "-"}
              </span>
            );
          },
        },
      ],
      resultColumns: [
        { title: "目标主机", key: "tgt_name", dataIndex: "tgt_name" },
        {
          title: "执行结果",
          key: "result",
          dataIndex: "result",
          width: 100,
          customRender: (text, row, index) => {
            return <span>{text === 'success' ? "成功" : "失败"}</span>;
          },
        },
        {
          title: "消息",
          key: "msg",
          ellipsis: true,
          dataIndex: "msg",
          customRender: (text, row, index) => {
            return (
               <span title={text}>{text? text : '-'}</span>
            );
          },
        },
      ],
      columns: [
        {
          title: "执行编号",
          key: "state",
          dataIndex: "state",
          customRender: (text, row) => {
            // {row.job_status}
            return (
              <div class="flex-container">
                {row.state === "history" && (
                  <div class="mgr10">
                    { (row.job_status && row.job_status === 'running') && <svg-icon class="task-running" style="font-size: 20px" icon-class="task-running" />}
                    { (row.job_status && ['failed', 'error'].includes(row.job_status)) &&  <svg-icon style="font-size: 20px" icon-class="task-failed" />}
                    { (row.job_status && row.job_status === 'successful') && <svg-icon style="font-size: 20px" icon-class="task-successful" />}
                  </div>
                )}
                <div>
                  {row.release_id
                    ?
                    <div class="curpon word-pointer" onClick={(e) => this.toHistoryDetail(row, e)}>
                      {"#" + row.release_id}
                      {/* <svg-icon style="font-size: 18px;margin-left: 10px" icon-class="task-owner" /> */}
                     <span style="margin-left: 10px">{row.owner || "-"}</span>
                    </div>
                    : "-"}
                  <div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          title: "任务类型",
          key: "type",
          dataIndex: "type",
          customRender: (text, row) => {
            return (
              <div>
                {text === "0"
                  ? "普通任务"
                  : text === "1"
                  ? "快速命令"
                  : text === "2"
                  ? "快速分发"
                  : text === "3"
                  ? "流程任务" : "-"}
              </div>
            );
          },
        },
        {
          title: "开始时间",
          key: "started",
          dataIndex: "started",
          ellipsis: true,
          customRender: (text, row) => {
            return (
              <div>
                <div>
                  {row.started
                    ? <div><svg-icon style="font-size: 18px;margin-right: 5px" icon-class="task-start" />{moment(row.started).format('YYYY-MM-DD HH:mm:ss')}</div>
                    : "-"}
                </div>
              </div>
            );
          },
        },
        {
          title: "执行时长",
          key: "elapsed",
          dataIndex: "elapsed",
          ellipsis: true,
          customRender: (text, row) => {
            return (
              <div>
                <div>
                  {Number(row.elapsed) > 0
                    ? <div><svg-icon style="font-size: 18px;margin-right: 5px" icon-class="task-time" />{ formatDuring(row.elapsed)}</div>
                    : "-"}
                </div>
              </div>
            );
          },
        },
        {
          title: "查看日志",
          key: "lod",
          ellipsis: true,
          dataIndex: "lod",
          customRender: (text, row) => {
            return (
                <a class="curpon" title={row.job_msg} onClick={(e) => this.toHistoryDetail(row, e)}>{row.job_msg ? row.job_msg : '查看日志'}</a>
            );
          },
        },
      ],
    };
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
    }),
    scrollTable () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max) height = document.documentElement.clientHeight
      if (full) height = document.documentElement.clientHeight 
     let scroll = {}
      if (this.dataSource.length > 0)  {
        scroll = {
          // x: 1632,
          y: height - 256
        }
      }
      return scroll
    }
  },
  methods: {
    ...mapActions("task", ["setTaskInfo"]),
    ...mapActions("runHistory", ["getHistoryList", "setRunningFlag", "setRunTab", "setNumAdd"]),
    fullScreen() {
      this.isFullScreen = true;
    },
    closeFullScreen() {
      this.isFullScreen = false;
    },
    onClose () {
      this.drawerVisible = false
      this.isFullScreen = false
      this.setSelectedIndex = -1
    },
    handleClickHistory(item) {
      this.$axiosGet(global.API.quickTaskDetail + item.id).then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          this.drawerVisible = true
          this.isFullScreen = false
          this.currentHistory = res.data;
          this.resultData =
            res.data && res.data.stat && res.data.stat.result
              ? res.data.stat.result
              : [];
        }
      });
    },
    handleClickRow(record, index){
      if (!['1', '2'].includes(this.taskDetail.type)) return {}
      return {
        style: {
        'background-color': index === this.setSelectedIndex ? '#e4eefd ' : '#fff',
        },
        on: {
          click: () => {
          this.setSelectedIndex = index
          this.handleClickHistory(record)
          }
        }
      }
    },
    onChange(val) {
      console.log(val);
    },
    tableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pollingSearch();
    },
    getVal(val, filed) {  
      this.params[`${filed}`] = val.target.value;
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.pollingSearch();
    },
    addMenu(key) {
      if (key === "del") {
        this.deleteTask(this.taskDetail);
      }
    },
    editTask() {
      const obj = _.cloneDeep(this.taskDetail)
      obj.id = obj.task_id
      this.$emit("createTask", obj);
    },
    back() {
      this.$emit("goBack", this.currentPage);
    },
    toHistoryDetail (row, e) {
      e.stopPropagation()
      this.$emit('toHistoryDetail', row)
    },
    deleteTask(obj) {
      const self = this;
      let width = 400;
      let content = (
        <DeleteTask
          sysTypeTxt="任务"
          detail={obj}
          callBack={() => self.getTaskList()}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    handleCancel(e) {
      this.visible = false;
      this.getTaskList();
    },
    // 发布任务
    async publishTask(task) {
      console.log(task);
      // 这个执行 调用哪个接口
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${task.task_id}/publish`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("发布成功");
      }
    },
    // 执行任务
    async runTask(task) {
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${this.taskDetail.task_id}/start`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("提交成功");
        this.onClose()
        this.pollingSearch()
      }
    },
    // 执行之后定时去刷
    pollingSearch() {
      this.getTaskList(true, 'interval'); // 先立马刷一次
      let self = this;
      if (self.timer) clearInterval(self.timer);
      self.timer = setInterval(() => {
        // 不存在正在部署的任务
        let list = this.dataSource.filter(item => (item.state === 'history' || item.state === 'started') && (item.job_status === 'running' || item.job_status === 'waiting'))
        if (this.dataSource.length > 0 && (list && list.length < 1)) {
          clearInterval(self.timer);
          return false
        }
        self.getTaskList(true);
      }, 3000);
    },
    getTaskList(flag, type) {
      if (!flag) this.loading = true;
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        excludes: 'operations,stat,detail'
      };
      let self = this;
      this.$axiosGet(
        global.API.curdTasks + `${this.taskDetail.task_id}/history`,
        params
      ).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.dataSource = res.data.results;
          this.pagination.total = res.data.total;
          if (!flag && !type) {
            if (!['1', '2'].includes(self.taskDetail.type)) return false
            if (self.dataSource.length > 0) {
              self.setSelectedIndex = 0
              self.handleClickHistory(self.dataSource[0])
            }
          } else {
            let list = this.dataSource.filter(item => (item.state === 'history' || item.state === 'started') && (item.job_status === 'running' || item.job_status === 'waiting'))
            if (!['1', '2'].includes(self.taskDetail.type)) return false
            if (this.dataSource.length > 0 && (list && list.length < 1)) {
              self.setSelectedIndex = 0
              self.handleClickHistory(self.dataSource[0])
            }
          }
        }
      });
    },
  },
  mounted() {
    this.pollingSearch();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  destroyed () {
    clearInterval(this.timer);
  }
  
};
</script>
<style lang="less" scoped>
.task-history {
  /deep/ .ant-card-body {
    padding: 13px 20px 16px;
    .ant-table-pagination.ant-pagination {
      margin: 16px 0 0 0;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .task-name {
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
    margin: 0 5px;
  }
  .state-icon, .curpon{
    cursor: pointer;
  }
  .draft-color {
    color: @configured-status-color;
  }
  .published-color {
    color: #8a8e99;
  }
  .started-color {
    color: @primary-color;
  }
  .start-failed-color {
    color: @error-color;
  }
  .word-pointer {
    cursor: pointer;
    &:hover {
      color: #344899;
      text-decoration: underline;
    }
  }
  @keyframes rotation{
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
  }
  .task-running {
    transform: rotate(360deg);
    animation: rotation 2s linear infinite;
  }
  .file-center-footer {
        height: 268px;
        padding-right: 16px;
        border-top: 1px solid rgba(209, 210, 215, 1);
        // padding: 0 20px 20px 20px;

        .center-footer-options {
          height: 50px;
          display: flex;
          // padding: 0 16px;
          align-items: center;

          .center-footer-option {
            position: relative;
            & + .center-footer-option {
              margin-left: 40px;

              &::before {
                content: "";
                position: absolute;
                left: -20px;
                width: 1px;
                height: 18px;
                background: #e2e4e5;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
          .full-screen-option {
            flex: 1;
            .option {
              width: 24px;
              height: 24px;
              border: 1px solid rgba(209, 210, 215, 1);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              float: right;
            }
          }
        }

        .center-footer-log {
          height: calc(100% - 50px);
          overflow: auto;
          word-break: break-all;
          // border: 1px dotted rgba(209, 210, 215, 1);
          // border-radius: 4px;
          background: #fff;
          .log-option {
            width: 24px;
            height: 24px;
            border: 1px solid rgba(209, 210, 215, 1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: fixed;
            right: 24px;
            top: 24px;
          }
          &.fullScreen {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;

            
            left: 0;
            z-index: 9999;
            padding-top: 48px;
            /deep/ .jv-code {
              padding-top: 0;
            }
          }
        }
      }
}
.run-task-drawer {
    /deep/ .ant-drawer-body {
      padding: 0 24px;
    }
    /deep/ .ant-drawer-close {
      width: 20px;
      height: 20px;
      right: 20px;
    }
    &.hasfullScreen {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9999;
      padding-top: 48px;
      /deep/ .jv-code {
        padding-top: 0;
      }
    }
}
/deep/ .ant-modal-body {
  padding: 0;
}
/deep/ .ant-modal {
  top: 40px;
  .ant-modal-content {
    border-radius: 4px;
  }
}
.custom-type-table {
 /deep/ .ant-table-content {
    // max-height: 400px;
    // overflow-y: auto;
  }
}
</style>
