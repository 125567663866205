<!--
 * @Description: 
 * @Date: 2024-07-25 14:30:01
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 10:35:29
-->
<template>
  <div class="theme-setting h-full flex flex-col">
    <div class="hi-scroll ph70 pv40 h-full">
      <div class="hi-cell-group border-color-m2 border-opacity-008 pdb20 mgb20">
        <div class="font-ali-75 text-16 text-color-t1">主题</div>
        <div class="pt16">
          <div class="hi-cell mgb10">
            <div class="flex items-center justify-between">
              <div class="theme-img flex flex-col items-center justify-center cursor-pointer" @click="onChange('light')">
                <img src="@/assets/img/theme/light.png" alt="">
                <div class="text-color-t2 pt7">浅色</div>
                <a-checkbox :checked="theme === 'light'" class="checkbox" >
                </a-checkbox>
              </div>
              <div class="theme-img flex flex-col items-center justify-center cursor-pointer" @click="onChange('dark')">
                <img src="@/assets/img/theme/dark.png" alt="">
                <div class="text-color-t2 pt7">深色</div>
                <a-checkbox :checked="theme === 'dark'" class="checkbox">
                </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import store from '@/store/index'
import themeUtil from '@/utils/themeUtil';

// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
  },
  data () {
    // 这里存放数据
    return {
      theme: 'light'
    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.theme = this.globalSetting.theme.mode
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.getPhotosBytag()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    onChange (val) {
      const theme = this.globalSetting.theme
      theme.mode = val
      this.theme = val
      store.commit("globalSetting/setTheme", theme)
      // let closeMessage = this.$message.loading(`您选择了主题模式 ${val}, 正在切换...`)
      // themeUtil.changeThemeColor('#3974f4', val).then(closeMessage)
    },
  }
}
</script>

<style lang='less' scoped>
  .theme-setting {
    border-radius: 12px;
    z-index: 10;
    .theme-img {
      img {
        width: 162px;
        height: 92px;
      }
    }

    /deep/ .checkbox {
      margin-top: 4px;
      .ant-checkbox {
        display: inline-block;
          border-radius: 50%;
        .ant-checkbox-inner {
          border-radius: 50%;
        }
      }
      .ant-checkbox-checked::after {
        border: none;
      }
    }
  }
  @media (max-width: 720px) {
    .theme-setting > div.hi-scroll {
      padding: 30px 20px;
    }
  }
</style>
