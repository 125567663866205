<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-16 14:52:58
 * @LastEditTime: 2025-01-21 10:55:49
 * @FilePath: \awx-ui\src\pages\login\account.vue
-->
<template>
  <div class="account-login">
    <a-form ref="myForm" @submit="onSubmit" :form="form">
      <a-form-item>
        <a-input class="login-input" @input="updateButtonStatus" autocomplete="off" placeholder="输入账号" v-decorator="['name', {rules: [{ required: true, message: '请输入账号', whitespace: true}]}]"></a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-if="!isShow"
          type="password"
          class="login-input"
          autocomplete="off"
          @input="updateButtonStatus"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="输入密码"
        />
        <a-input
          v-else
          class="login-input"
          autocomplete="off"
          @input="updateButtonStatus"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="请输入密码"
        />
        <span  style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isShow = !isShow">
          <svg-icon :icon-class="!isShow ? 'eye-invisible' : 'eye'" style="font-size: 18px; color: rgba(255, 255, 255, 0.5)"></svg-icon>
        </span>
      </a-form-item>
      <a-form-item>
        <a-button :loading="logging" class="btn-login text-color-t1" htmlType="submit" :disabled="disabledLogin" type="primary">{{ logging ? '正在登录中...' : '立即登录' }}</a-button>
      </a-form-item>
    </a-form>
    <div class="register">
      <div class="text-color-t3">还没有账号？<span @click="register">立即注册</span></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState, mapActions } from "vuex";
import { formatRoutes } from "@/utils/routerUtil";
import dynamicComponents from "@/utils/dynamic-components";
import ROUTER_DATA from './routerData.json'
export default {
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      isShow: false,
      disabledLogin: true
    };
  },
  components: {},
  mounted() {
  },
  computed: {
    ...mapState("setting", ["footerLinks", "copyright"]),
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setRoles"]),
    ...mapActions("masterMd", ["setHasMaster"]),
    ...mapActions("fileManage", [
      "setClusterId",
    ]),
    register () {
      this.$emit('register')
    },
    updateButtonStatus() {
      // 获取表单字段的值
      const { getFieldDecorator, getFieldValue } = this.form;
      const name = getFieldValue('name');
      const password = getFieldValue('password');
      // 根据表单值更新按钮状态
      this.disabledLogin = !name || !password;
    },
    onSubmit(e) {
      e.preventDefault();
      // 修复用户登录菜单不刷新BUG
      // this.$store.commit('setting/setMenuData', undefined)
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true
          const username = this.form.getFieldValue("name");
          const password = this.form.getFieldValue("password");
          let formData = new FormData();
          formData.append("username", username);
          formData.append("password", password);
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          const self = this;
          setTimeout(() => {
            this.logging = false
          }, 4000)
          axios.post(global.API.login, formData, config).then(function (res) {
            if ([200, 201, 204, 202].includes(res.status)) {
              localStorage.setItem("token", res.data.token);
              /*这里做处理.....*/
              self.getUserInfo(res);
            } else {
              this.logging = false
            }
          });
        }
      });
    },
    getUserInfo() {
      const self = this;
      this.$axiosGet(global.API.getUserInfo, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          self.afterLogin(res);
        }
      });
    },
    afterLogin(res) {
      const username = this.form.getFieldValue("name");
      const password = this.form.getFieldValue("password");
      const isSuperuser = res.data.is_superuser;
      this.setUser({ ...res.data, username, isSuperuser });
      this.$emit('getMaster')
      this.getRouter();
      this.$message.success("登录成功");
      this.$emit('closeModal')
      this.$emit('openSyncSetting', res.data)
    },
    getRouter() {
      const self = this;
      const results = ROUTER_DATA
      localStorage.setItem("routerData", JSON.stringify(results));
    },
    // 路由过滤  遍历路由 转换为组件对象和路径
    filterASyncRoutes(data) {
      const routes = data.filter((item) => {
        const getComp = dynamicComponents[`${item.component}`];
        item["component"] = getComp;
        // 路由递归，转换组件对象和路径
        if (item["children"] && item["children"].length > 0) {
          item["children"] = this.filterASyncRoutes(item.children);
        }
        return true;
      });
      // 排序
      // routes.sort((a, b) => a['id'] - b['id'])
      return routes;
    },
  },
};
</script>
<style scoped lang='less'>
.account-login {
  margin-top: 28px;
  /deep/ .ant-input {
    font-size: 14px;
  }
  .login-input {
    width: 100%;
    height: 42px;
    margin-top: 10px;
    background-color: rgba(var(--color-m2), 0.06);
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 8px;
    text-indent: 12px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgb(var(--color-t2));
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
    &:focus {
      border-color: @primary-color;
    }
    &::placeholder {
      // color: red;
    }
  }
  /deep/ .has-error {
    .login-input {
      border-color: #f5222f;
    }
  }
  .register {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: 400;
    margin-top: 14px;
    span {
      cursor: pointer;
      color: #3974F4;
    }
  }
  .btn-login {
    margin-top: 28px;
    border-radius: 8px;
    width: 100%;
    height: 42px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
    border: none;
    color: rgb(var(--color-white));
  }
  .ant-btn-primary[disabled] {
    background: rgba(var(--color-m2), 0.06);
    color: rgb(199, 199, 204);
  }
}
</style>