/*
 * @Description: 
 * @Date: 2024-08-02 15:03:00
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 15:29:22
 */
// store/plugins/localStorage.js

export default function localStoragePlugin(store) {
  const historyWinboxSize = localStorage.getItem('historyWinboxSize');
  if (historyWinboxSize) {
    store.commit('windowStore/historyWinboxSize', JSON.parse(historyWinboxSize));
  }
  // 防抖函数：确保在指定时间内只调用一次
  let debounceTimer = null;
  const debounce = (fn, delay) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(() => {
      fn();
    }, delay);
  };
  const BLANK = ['globalSetting/setSettingBoxTab','globalSetting/setSettingStep', 'globalSetting/setWallpaperTab', 'globalSetting/setSearchBoxHistoryList']
  // 监听 mutations 以同步 settings 数据到 localStorage
  store.subscribe((mutation) => {
    if (mutation.type.startsWith('globalSetting/') && !BLANK.includes(mutation.type)) {
      const settings = store.state.globalSetting;
      localStorage.setItem('globalSetting', JSON.stringify(settings));
      debounce(() => {
        console.log('globalSetting!!!!!!!!!!!!!!', mutation.type);
        store.dispatch('globalSetting/syncSettings', settings);
      }, 2000); // 设置防抖延时为 3000ms（3秒）
    }
    if (mutation.type.includes('historyWinboxSize')) {
      const historyWinboxSize = store.state.windowStore.historyWinboxSize;
      localStorage.setItem('historyWinboxSize', JSON.stringify(historyWinboxSize));
    }
  });
}
