<!--
 * @Description: 
 * @Date: 2024-11-08 17:40:21
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-11-14 14:43:30
-->
<template>
  <div :class="['message-box', messageBox.messageBoxShow && 'message-box-show']">
    <div :class="['flex items-center message-container']">
      <svg-icon :class="['sync-icon', messageBox.messageBoxLoading  && 'sync-loading']" :icon-class="messageBox.messageBoxLoading ? 'sync-loading' : messageBox.messageBoxText === '同步完成' ? 'sync-success' : 'sync-failed'" class="icon" width="20px" height="20px"></svg-icon>
      <div>{{messageBox.messageBoxText}}</div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { mapState, mapMutations, mapActions } from "vuex";


export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState({
      messageBox: (state) => state.messageBox, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {

  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.message-box {
  position: fixed;
  left: -108px;
  top: 18px;
  width: 108px;
  height: 38px;
  line-height: 38px;
  background: rgba(0,0,0,0.4);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  padding-left: 10px;
  transition: left 0.5s ease-in-out;
}

.message-box-show {
  left: 10px!important;
  .sync-icon {
    margin-right: 8px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.2);
  }
  .sync-loading {
    transform: rotate(360deg);
    animation: rotation 2s linear infinite;
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
