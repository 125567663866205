<!--
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2025-01-07 14:02:21
 * @FilePath: \awx-ui\src\components\menu\SideMenu.vue
-->
<template>
  <div :class="['side-menu']">
    <div class="menu-list-wrap">
       <div v-for="(item, index) in childMenu" :key="index" :class="['menu-item']" @click="($event) => handleClick(item, $event)">
          <div :class="['menu-item-container', currentRoute?.name === item?.name && 'menu-item-container-selected']" >
              <div><svg-icon class="menu-icon" :iconClass="item?.name === '网络' ? 'network' : item?.name === '集群' ? 'cluster' : item.meta.icon" /></div>
             <div>{{item.name}}</div>
          </div>
          <a-divider v-if="index !== childMenu.length - 1" style="background: rgba(0,0,0,0.10);margin: 0px" type="vertical" />
       </div>
      </div>
  </div>
</template>

<script>
const clientWidth = document.documentElement.clientWidth

import SvgIcon from '@/icons/SvgIcon.vue'
export default {
  name: 'SideMenu',
  components: {
    SvgIcon
  },
  props: {
    windowAppLoaderOptions: Object,
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
  },
  data() {
    return {
      clientWidth,
      currentRoute: {},
      currentWindowId: ''
    }
  },
  // inject: ['windowAppLoaderOptions', 'editwindowAppLoaderOptions'],
  created() {
      this.currentWindowId = this.windowAppLoaderOptions.id
      const routeArr = this.options.filter(item => !(item.fullPath.includes('-new') || item.fullPath.includes('-edit')))
      this.selectedKeys = [routeArr[0].fullPath]
      const routerData = JSON.parse(localStorage.getItem('routerData'))
      let groupId = this.isMenu ? this.windowAppLoaderOptions[`${this.windowAppLoaderOptions.fileCompontentType}`].FileGroupName : this.windowAppLoaderOptions.id 
      groupId = groupId.includes('awx-') ? groupId.replace(/awx-/g, '') : groupId
      groupId = groupId === 'dashboard' ? '' : groupId
      const group = routerData[0].children.find(item => item.path === groupId)
      let currentRoute = group.children.find(item => item.path === routeArr[0].path)
      const data = {...this.windowAppLoaderOptions}
      if (data.id === 'dashboard' || data.id === 'awx') {
        currentRoute = group.children[0]
      }
      data[`${data.fileCompontentType}`].FileComponentPath = currentRoute.component
      data['currentRoute'] = {...currentRoute}
      this.currentRoute = currentRoute
      this.$EventBus.$emit('editwindowAppLoaderOptions', data)
      this.$EventBus.$emit(`editMenuOptions-${this.currentWindowId}`, data, this.currentWindowId)
  },
  computed: {
    childMenu() {
      let path = '/' + this.windowAppLoaderOptions[`${this.windowAppLoaderOptions.fileCompontentType}`].FileGroupName
      path = path.includes('awx-') ? path.replace(/awx-/g, '') : path
      // dashboard的的fullPath 为空
      let currentMenu = null
      if (path === '/dashboard') {
        currentMenu = this.menuData.filter(item => item.id === 'dashboard')
      } else {
        currentMenu  = this.menuData.filter(item => path.includes(item.fullPath) && item.fullPath !== '/')
      }
      let arr = currentMenu[0].children &&  currentMenu[0].children.length ?  currentMenu[0].children : [...currentMenu]
      return arr.filter(item => !(item.fullPath.includes('-new') || item.fullPath.includes('-edit')))
    },
    options () {
      const arr = _.cloneDeep(this.childMenu)
      let options =  arr
      return options
    },
    isMenu () {
      let isMenu = this.windowAppLoaderOptions[`${this.windowAppLoaderOptions.fileCompontentType}`].isMenu
      return isMenu
    },
  },
  methods: {
     shouldShowDivider(index) {
      const selectedIndex = this.childMenu.findIndex(item => this.currentRoute?.name === item?.name)
      if (selectedIndex === null) return false;
      
      // 如果选中第一个元素，则所有后续元素之间有分隔线
      if (selectedIndex === 0 && index < this.childMenu.length - 1) {
        return index > 0;
      }
      // 如果选中第二个元素，从第三个元素开始所有元素之间有分隔线
      if (selectedIndex === 1 && index < this.childMenu.length - 1) {
        return index > 1;
      }
      // 如果选中第三个元素，前两个元素之间有分隔线，后面所有元素之间有分隔线
      if (selectedIndex >= 2) {
        if (index === 0 || index === selectedIndex - 2 || (index > selectedIndex && index < this.childMenu.length - 1)) {
          return true;
        }
      }
      return false;
    },
    handleClick (menu, e) {
      e.stopPropagation();
      const routerData = JSON.parse(localStorage.getItem('routerData'))
      const path = menu.fullPath.split('/')[1]
      const key = menu.fullPath.split('/')[menu.fullPath.split('/').length - 1]
      let groupId = this.isMenu ? this.windowAppLoaderOptions[`${this.windowAppLoaderOptions.fileCompontentType}`].FileGroupName : this.windowAppLoaderOptions.id 
      groupId = groupId.includes('awx-') ? groupId.replace(/awx-/g, '') : groupId
      groupId = groupId === 'dashboard' ? '' : groupId
      const group = routerData[0].children.find(item => item.path === groupId)
      const currentRoute = group.children.find(item => (item.path === key || item.path === menu.fullPath))
      const data = {...this.windowAppLoaderOptions}
      data[`${data.fileCompontentType}`].FileComponentPath = currentRoute.component
      data['currentRoute'] = {...currentRoute}
      this.currentRoute = currentRoute
      this.$EventBus.$emit('editwindowAppLoaderOptions', data)
      this.$EventBus.$emit(`editMenuOptions-${this.currentWindowId}`, data, this.currentWindowId)
    },
    flat(arr,parentIcon){
      let newArr=[];//申请新数组
      for(let i=0;i<arr.length;i++){
        // if (parentIcon) arr[i].meta.icon = parentIcon
        if(arr[i].children&&arr[i].children.length){//children存在
          newArr.push(...this.flat(arr[i].children, arr[i].meta.icon));
          //递归，调用flat方法，并将返回结果push到新数组
        }
        newArr.push({...arr[i]});//三个点，展开对象，push新对象  
      }  
      return newArr;//返回数组
    },
  },
  beforeDestroy() {
  },
}
</script>

<style lang="less" scoped>
  .side-menu {
    margin: 0 auto;
  }
 .menu-list-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .menu-item {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    &-container {
      cursor: pointer;
      padding: 0 16px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #656568;
      font-weight: 400;
      div {
        line-height: 22px;
      }
      .menu-icon {
        font-size: 15px;
        margin-right: 6px;
        position: relative;
      }
      &-selected {
        // background: #FFFFFF;
        border-radius: 4px;
        color: @primary-color;
        font-weight: 500;
      }
    }
  }
}
</style>
