<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-06-29 16:12:37
 * @LastEditTime: 2024-08-22 08:39:28
 * @FilePath: \awx-ui\src\layouts\ThirdPartyPage\index.vue
-->
<template>
  <div class="browser-page h-full">
    <!-- <iconifyIcon icon="material-symbols:mail-sharp" style="font-size: 40px;" /> -->
    <iframe
      :src="linkUrlother"
      width="100%"
      height="100%"
      style="border: none; border-width: 0px"
    ></iframe>
  </div>
</template>

<script>
export default {
  data () {
    return {
      linkUrlother: ''
    }
  },
  props: {
    propsData: {
      default: () => {
        return {}
      },
      type: Object
    },
    appLoaderOptions: {
      default: () => {
        return {}
      },
      type: Object
    },
  },
  watch: {
    appLoaderOptions: {
      handler (val) {
        if (val && val?.componentName) {
          const url = val?.[`${val.componentName}`]?.externalUrl
          this.linkUrlother = global.utils.ensureProtocol(url)
        }
      },
      deep: true,
      immediate: true
    }
  },
  components: {
  },
  mounted () {
  },
  methods: {

  },
}

</script>
<style scoped lang='less'>
.browser-page {
  height: calc(100% - 35px);
  padding: 0px;
  overflow-y: hidden;
}
</style>