import { ref, onMounted, reactive, watch, onUnmounted, computed } from 'vue';

import { join } from 'path';

import {
  ICON_CACHE,
  ICON_CACHE_EXTENSION,
  SESSION_FILE,
} from '@/utils/constants';
import index from "../../../public/.index/fs.9p.json";
const UNKNOWN_STATE_CODES = new Set(["EIO", "ENOENT"]);

const IDX_SIZE = 1;

export const get9pSize = (path) => get9pData(path, IDX_SIZE);

import { configure, BFSRequire,  Stats, FileType } from 'browserfs';

const isExistingFile = (
  { birthtimeMs, ctimeMs } = {}
) => Boolean(birthtimeMs && birthtimeMs === ctimeMs);

const mapReduce9pArray = (
  array,
  mapper
  // eslint-disable-next-line unicorn/no-array-callback-reference
) => array.map(mapper).reduce((a, b) => Object.assign(a, b), {});

// eslint-disable-next-line unicorn/no-unreadable-array-destructuring
const parse9pEntry = ([name, , , pathOrArray]) => ({
  [name]: Array.isArray(pathOrArray)
    ? mapReduce9pArray(pathOrArray, parse9pEntry)
    : null,
});

export const fs9pToBfs = () => mapReduce9pArray(index.fsroot, parse9pEntry);
const options = fs9pToBfs();
const FileSystemConfig = (writeToMemory = false) => ({
  fs: "IndexedDB",
  options: {
    storeName: "browserfs" // 自定义的 store 名称
  }
  // fs: "MountableFileSystem",
  // options: {
  //   "/mnt": {
  //     fs: "OverlayFS",
  //     options: {
  //       readable: {
  //         fs: "HTTPRequest",
  //         options: {
  //           index: "/.index.json",
  //           baseUrl: "/public"
  //         }
  //       },
  //       writable: {
  //         fs: "IndexedDB",
  //         options: {}
  //       }
  //     }
  //   }
  // }
});

export default function useAsyncFs() {
  const fs = ref(null);
  const fsRef = reactive({
    current: null
  });
  // const asyncFs = ref(null)
  const rootFs = ref(null);
  const mockFsCallQueue = ref([]);

  const asyncFs = computed(() => ({
    exists: (path) =>
      new Promise((resolve) => {
        fs.value?.exists(path, resolve);
      }),
    lstat: (path) =>
      new Promise((resolve, reject) => {
        fs.value?.lstat(path, (error, stats = Object.create(null)) =>
          error ? reject(error) : resolve(stats)
        );
      }),
    mkdir: (path, overwrite = false) =>
      new Promise((resolve, reject) => {
        fs.value?.mkdir(path, { flag: overwrite ? 'w' : 'wx' }, (error) =>
          error ? reject(error) : resolve(true)
        );
      }),
    readFile: (path) =>
      new Promise((resolve, reject) => {
        fs.value?.readFile(path, (error, data = Buffer.from('')) => {
          if (!error || UNKNOWN_STATE_CODES.has(error.code)) {
            console.log(data, '!!!!!!!!!')
            return resolve(data);
          }

          if (error.code === 'EISDIR' && rootFs.value?.mntMap[path]) {
            const mountData =
              rootFs.value.mntMap[path]._data || rootFs.value.mntMap[path].data;

            if (mountData) return resolve(mountData);
          }

          return reject(error);
        });
      }),
    readdir: (path) =>
      new Promise((resolve, reject) => {
        fs.value?.readdir(path, (error, data = []) =>
          error ? reject(error) : resolve(data)
        );
      }),
    rename: (oldPath, newPath) =>
      new Promise((resolve, reject) => {
        fs.value?.rename(oldPath, newPath, (renameError) => {
          if (!renameError) {
            resolve(true);
          } else if (renameError.code === 'ENOTSUP') {
            fs.value.lstat(
              oldPath,
              (_statsError, stats = Object.create(null)) => {
                if (stats.isDirectory()) {
                  reject(new Error('Renaming directories is not supported.'));
                } else {
                  fs.value.readFile(oldPath, (readError, data) =>
                    fs.value.writeFile(newPath, data, (writeError) =>
                      readError || writeError
                        ? reject(
                            readError ||
                              writeError ||
                              new Error('Failed to rename file.')
                          )
                        : resolve(false)
                    )
                  );
                }
              }
            );
          } else if (renameError.code === 'EISDIR') {
            rootFs.value?.umount(oldPath);
            asyncFs.value.rename(oldPath, newPath).then(resolve, reject);
          } else if (UNKNOWN_STATE_CODES.has(renameError.code)) {
            resolve(false);
          } else {
            reject(renameError);
          }
        });
      }),
    rmdir: (path) =>
      new Promise((resolve, reject) => {
        fs.value?.rmdir(path, (error) => (error ? reject(error) : resolve(true)));
      }),
    stat: (path) =>
      new Promise((resolve, reject) => {
        fs.value?.stat(path, (error, stats = Object.create(null)) => {
          if (error) {
            return UNKNOWN_STATE_CODES.has(error.code)
              ? resolve(new Stats(FileType.FILE, -1))
              : reject(error);
          }

          return resolve(
            stats.size === -1 && isExistingFile(stats)
              ? new Stats(
                  FileType.FILE,
                  get9pSize(path),
                  stats.mode,
                  stats.atimeMs,
                  stats.mtimeMs,
                  stats.ctimeMs,
                  stats.birthtimeMs
                )
              : stats
          );
        });
      }),
    unlink: (path) =>
      new Promise((resolve, reject) => {
        fs.value?.unlink(path, (error) => {
          if (error) {
            return UNKNOWN_STATE_CODES.has(error.code)
              ? resolve(false)
              : reject(error);
          }

          return resolve(true);
        });
      }),
    writeFile: (path, data, overwrite = false) =>
      new Promise((resolve, reject) => {
        fs.value?.writeFile(
          path,
          data,
          { flag: overwrite ? 'w' : 'wx' },
          (error) => {
            if (error && (!overwrite || error.code !== 'EEXIST')) {
              if (error.code === 'ENOENT' && error.path === '/') {
                // import('contexts/fileSystem/functions').then(
                //   ({ resetStorage }) =>
                //     resetStorage(rootFs.value).finally(() =>
                //       window.location.reload()
                //     )
                // );
              }

              reject(error);
            } else {
              resolve(!error);

              try {
                if (path !== SESSION_FILE) {
                  const cachedIconPath = join(
                    ICON_CACHE,
                    `${path}${ICON_CACHE_EXTENSION}`
                  );

                  fs.value?.exists(
                    cachedIconPath,
                    (exists) => exists && fs.value?.unlink(cachedIconPath)
                  );
                }
              } catch {
                // Ignore icon cache issues
              }
            }
          }
        );
      }),
  }));

  const runQueuedFsCalls = (fs) => {
    if (mockFsCallQueue.value.length > 0) {
      const [name, args] = mockFsCallQueue.value.shift();

      if (name in fs.value) {
        const fsCall = fs.value[name];

        if (typeof fsCall === 'function') {
          fsCall(...args);
        }
      }

      runQueuedFsCalls(fs);
    }
  };
  const setupFs = (writeToIndexedDB) => {
    configure(FileSystemConfig(!writeToIndexedDB), () => {
      const loadedFs = BFSRequire('fs');
      fsRef.current = loadedFs;
      fs.value = loadedFs;
      rootFs.value = loadedFs.getRootFS();
      if ("getRootFS" in fs.value) {
        runQueuedFsCalls(fs);
        // console.log(fs, fsRef, rootFs, asyncFs, "wocao!!!!!");
      }
    });
  };
  const queueFsCall = (name) => (...args) => {
    if (fsRef.current) {
      const fsCall = fsRef.current[name];
      if (typeof fsCall === 'function') {
        fsCall(...args);
      }
    } else {
      mockFsCallQueue.value.push([name, args]);
    }
  };

  const onInitFs = () => {
    if (!fs.value) {
      fs.value = {
        exists: queueFsCall('exists'),
        lstat: queueFsCall('lstat'),
        mkdir: queueFsCall('mkdir'),
        readFile: queueFsCall('readFile'),
        readdir: queueFsCall('readdir'),
        rename: queueFsCall('rename'),
        rmdir: queueFsCall('rmdir'),
        stat: queueFsCall('stat'),
        unlink: queueFsCall('unlink'),
        writeFile: queueFsCall('writeFile'),
      };
      setupFs(false);
    }
  };

  return {
    fs: fs,
    rootFs: rootFs,
    asyncFs: asyncFs,
    onInitFs
  };
}
