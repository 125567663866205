<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-08-08 16:36:15
 * @FilePath: \awx-ui\src\pages\management\myquota.vue
-->

<template>
  <div class="my-quoto-page">
    <a-row type="flex" align="middle">
      <a-col :span="8">
        <a-button @click="onSearch">刷新</a-button>
        <a-button class="mgl8" type="primary" @click="openApplyModal">申请配额</a-button>

      </a-col>
      <a-col :span="16" style="text-align: right;">
        <a-input-search placeholder="请输入关键词查询" class="w470" @change="(value) => getVal(value, 'name')" v-model="title" allowClear @search="onSearch" />
      </a-col>
    </a-row>
    <div class="table-info">
      <a-table class="table-15" :scroll="scrollTable" @change="tableChange" :columns="columns" :loading="loading" :dataSource="dataSource" rowKey="id" :pagination="pagination"></a-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Addment from "./commponents/addment.vue";
import Deletemanagement from "./commponents/deletemanagement.vue";
import moment from "moment";
import ApplyQuotaVue from "@/components/quota/index.vue";
export default {
  name: "MYQUOTO",
  data() {
    return {
      title: "",
      params: {},
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      dataSource: [],
      loading: false,
    };
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  computed: {
    ...mapGetters("account", ["user"]),
    ...mapState({
      windowStore: (state) => state.windowStore,
    }),
    scrollTable () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max) height = document.documentElement.clientHeight
      if (full) height = document.documentElement.clientHeight
     let scroll = {}
      if (this.dataSource.length > 0)  {
        scroll = {
          y: height - 220,
          x: '100%'
        }
      }
      console.log(scroll, 'dataSource')
      return scroll
    },
    columns() {
      let arr = [
        {
          title: "序号",
          key: "index",
          dataIndex: "index",
          width: 70,
          customRender: (text, row, index) => {
            return (
              <span>
                {parseInt(
                  this.pagination.current === 1
                    ? index + 1
                    : index +
                        1 +
                        this.pagination.pageSize * (this.pagination.current - 1)
                )}
              </span>
            );
          },
        },
        { title: "名称", key: "name", dataIndex: "name", ellipsis: true, },
        {
          title: "时长(月)",
          key: "month",
          width: 90,
          dataIndex: "month",
        },
        {
          title: "用户",
          ellipsis: true,
          key: "owner",
          dataIndex: "owner",
        },
        {
          title: "状态",
          ellipsis: true,
          key: "state",
          dataIndex: "state",
          customRender: (text) => {
            return (
              <span>
                {text === 0
                  ? "申请中"
                  : text === 1
                    ? "已生效"
                    : text === 2
                      ? "已驳回"
                      : text === 3
                        ? "已失效"
                        : "-"}
              </span>
            );
          },
        },
        {
          title: "申请时间",
          key: "create_time",
          ellipsis: true,
          dataIndex: "create_time",
          customRender: (text) => {
            return <span title={moment(text).format("YYYY-MM-DD HH:mm:ss")}>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>;
          },
        },
        {
          title: "生效时间",
          key: "effect_time",
          ellipsis: true,
          dataIndex: "effect_time",
          customRender: (text) => {
            return (
              <span title={text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          title: "失效时间",
          ellipsis: true,
          key: "expire_time",
          dataIndex: "expire_time",
          customRender: (text) => {
            return (
              <span title={text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          title: "主机配额",
          key: "max_hosts",
          ellipsis: true,
          dataIndex: "max_hosts",
          customRender: (text) => {
            return <span title={text ? text : '-'}>{text ? text : '-'}</span>;
          },
          
        },
        {
          ellipsis: true,
          title: "任务配额",
          key: "max_tasks",
          dataIndex: "max_tasks",
          customRender: (text) => {
            return <span title={text ? text : '-'}>{text ? text : '-'}</span>;
          },
        },
        {
          title: "调度配额",
          ellipsis: true,
          key: "max_schedules",
          dataIndex: "max_schedules",
          customRender: (text) => {
            return <span title={text ? text : '-'}>{text ? text : '-'}</span>;
          },
        },
      ];
      // if (this.user.isSuperuser) {
      //   arr.push({
      //     title: "操作",
      //     key: "action",
      //     width: 120,
      //     customRender: (text, row, index) => {
      //       return row.state === 0 ? (
      //         <span class="flex-container">
      //           <a class="btn-opt" onClick={() => this.ok(row)}>
      //             同意
      //           </a>
      //           <a-divider type="vertical" />
      //           <a class="btn-opt" onClick={() => this.back(row)}>
      //             驳回
      //           </a>
      //         </span>
      //       ) : (
      //         <span class="flex-container">
      //           <a class="btn-opt" style="color: #bbb">
      //             同意
      //           </a>
      //           <a-divider type="vertical" />
      //           <a class="btn-opt" style="color: #bbb">
      //             驳回
      //           </a>
      //         </span>
      //       );
      //     },
      //   });
      // }
      return arr;
    },
  },
  methods: {
    openApplyModal (item) {
      const self = this;
      let width = 902;
      let title = '申请配额'
      let content = (
        <ApplyQuotaVue quotaType={'apply'} callBack={() => {
          self.getProjectList()
          }} />
      );
      this.$confirm({
        width: width,
        title: title,
        class:'quota-apply-modal',
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    tableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getProjectList();
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.getProjectList();
    },
    //同意
    ok(row) {
      this.$axiosJsonPost(
        global.API.getQuotaRecordList + `/${row.id}/approve`
      ).then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          this.$message.success("操作成功");
          this.getProjectList();
        }
      });
    },
    // 驳回
    back(row) {
      this.$axiosJsonPost(
        global.API.getQuotaRecordList + `/${row.id}/reject`
      ).then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          this.$message.success("操作成功");
          this.getProjectList();
        }
      });
    },
    getProjectList() {
      this.loading = true;
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        keyword: this.params.name || "",
      };
      console.log(params);
      this.$axiosGet(global.API.getQuotaRecordList, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204].includes(res.status)) {
          this.dataSource = res.data.results;
          this.pagination.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.getProjectList();
  },
};
</script>

<style lang="less" scoped>
.my-quoto-page {
  height: 100%;
  box-shadow:none;
  padding: 16px 14px 16px 16px;
  min-width: 980px;
  overflow-x: auto;
  .table-info {
    margin-top: 10px;
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
}
</style>
