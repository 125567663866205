<template>
  <div
    style="height: 96%;"
    ref="terminal"
    v-resize="handleResize" 
    @contextmenu.prevent="(e) => contextmenuShow(e)"
  ></div>
</template>

<script>
import { Terminal } from 'xterm';
import { AttachAddon } from 'xterm-addon-attach';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css' // Import xterm.css
import { mapActions, mapState } from "vuex";
import { getChannel, blobToArrayBuffer } from '@/utils/axios-peer.js'
export default {
  name: 'SshTerminal',

  mounted () {
    const self = this
    this.sshFileSystemId = _.cloneDeep(self.fileSystemId)
    this.$EventBus.$on("runCommand", (selectRowKeys, code) => {
      if (selectRowKeys.includes(self.sshFileSystemId)) {
        if (self.channel) self.channel.send(`${code}\r`)
      }
    });
    if (this.winboxHost.showItem.fromTo === 'ssh' && this.winboxHost.showItem.fromToSshFirst && self.activeKey === 'terminal' && this.sshFileSystemId === this.winboxHost.showItem.id && this.sshFileSystemId !== 'groupAllHost') {
      console.log(this.winboxHost.showItem.fromTo, this.winboxHost.showItem.fromToSshFirst, self.activeKey, self.sshFileSystemId, '!!!!!!!!!!!!!')
      self.initTerminal()
      setTimeout(() => {
        if (self.fitAddon && self.fitAddon.fit) {
          self.fitAddon.fit();
          self.sendSize()
        }
        if (self.terminal) self.terminal.focus();
      }, 1000)
    }
  },
  data () {
    return {
      terminal: null,
      sshFileSystemId: '',
      fitAddon: null,
      channel: null,
      webSocket: null,
      reconnectInterval: 2000, // 重连间隔时间（毫秒）
      maxReconnectAttempts: 10, // 最大重连次数
      reconnectAttempts: 0, // 当前重连次数
      timer: null,
      init: false,
      hasPasted: false
    }
  },
  computed: {
    ...mapState({
      fileManage: (state) => state.fileManage,
      winboxHostModule: (state) => state.winboxHostModule,
    }),
    // 当前winbox的ID
    currentWinboxKey () {
      return this.windowAppLoaderOptions.id
    },
    winboxHost () {
      return this.winboxHostModule[this.currentWinboxKey]
    },
  },
  inject: ['windowAppLoaderOptions'],
  props: { fileCode: String, file: Object, fileSystemId: [String, Number],clusterId: String, fileType: String, callBack: Function, activeKey: String, connectType: { type: String, default: 'fs_id' } },
  watch: {
    sshFileSystemId: {
      handler (val) {
        // console.log(val, 'nbm')
      }
    },
    fileSystemId: {
      handler (val) {
        if (this.activeKey === 'terminal' && this.fileSystemId === this.winboxHost.showItem.id) {
          const self = this
          setTimeout(() => {
            if (self.activeKey === 'terminal' && this.fileSystemId === this.winboxHost.showItem.id && val !== 'groupAllHost') {
              if (self.fitAddon && self.fitAddon.fit) {
                self.fitAddon.fit();
                self.sendSize()
              }
              if (self.terminal) self.terminal.focus();
            }
          }, 1000)
        }
      }
    },
    activeKey: {
      handler (val) {
        if (val === 'terminal' && this.sshFileSystemId === this.winboxHost.showItem.id) {
          if (!this.init) this.initTerminal()
          setTimeout(() => {
            if (this.fitAddon && this.fitAddon.fit) {
              this.fitAddon.fit();
              this.sendSize()
            }
            if (this.terminal) this.terminal.focus();
          }, 1000)
        }
      }
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    if (this.channel && this.channel.close) this.channel.close()
    this.channel = null
    if (this.terminal && this.terminal.dispose) this.terminal.dispose()
    this.terminal = null
    if (this.fitAddon && this.fitAddon.dispose) this.fitAddon.dispose()
    this.fitAddon = null
    window.removeEventListener('resize', this.resizeScreen);
  },
  methods: {
    ...mapActions("fileManage", [
      "changeRealEditList",
      "changeShowItem",
    ]),
    ...mapActions("winboxHostModule", [
      "changeWinboxRealEditList",
      "changeWinboxShowItem",
    ]),
    handleResize({ width, height }) {
      console.log('terminalterminal:', width, 'terminalterminal:', height)
      this.throttleUpdateWindowState(width, height)
    },
    throttleUpdateWindowState: _.debounce(function(width, height) {
      if(width && height && this.init) {
        if (this.activeKey !== 'terminal') return false
        if (this.fitAddon && this.fitAddon.fit) {
          this.fitAddon.fit();
          this.sendSize()
        }
      }
    }, 500),
    changeSshFromTo () {
      const showItem = this.winboxHost.showItem
      this.changeShowItem({
        ...showItem,
        fromToSshFirst: false
      })
      this.changeWinboxShowItem({id: this.currentWinboxKey, value: {
        ...showItem,
        fromToSshFirst: false
      }});
      const list = this.winboxHost.realEditList
      const index = list.findIndex(item => item.id === this.winboxHost.showItem.id)
      if (index > 0) {
        list.splice(index, 1, {
          ...showItem,
          fromToSshFirst: false
        });
        this.changeRealEditList(list)
        this.changeWinboxRealEditList({id: this.currentWinboxKey, value:list});
      }
    },
    handlePaste (event) {
      // 在这里处理粘贴事件
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData('text');
      console.log('当前粘贴板')
      if (pastedText) {
        this.hasPasted = true
      } else {
        this.hasPasted = false
      }
    },
    contextmenuShow (event) {
      event.stopPropagation()
      let items = [
        {
          icon: 'contextmenu-reconnect',
          label: "重连",
          onClick: () => {
            this.reconnectTerminal()
          },
        },
        {
          icon: 'contextmenu-copy',
          label: "复制",
          onClick: () => {
            if (!this.terminal) { return }
            let text = this.terminal.getSelection()
            if (text) global.utils.copyText(text, event);
          },
        },
        {
          icon: 'contextmenu-paste',
          label: "粘贴",
          onClick: () => {
            if (!this.terminal) { return }
            if (navigator.clipboard && typeof navigator.clipboard.readText === 'function') {
              navigator.clipboard.readText()
                .then(text => {
                  console.log('剪贴板中的文本内容：', text);
                  if (text) {
                    this.channel.send(text)
                    this.terminal.focus()
                  }
                })
                .catch(error => {
                  console.error('读取剪贴板失败：', error);
                });
            } else {
              console.warn('浏览器不支持 navigator.clipboard API');
            }
          },
        },
        {
          icon: 'contextmenu-clearall',
          label: "清屏",
          onClick: () => {
            if (!this.terminal) { return }
            if (!this.channel) { return }
            this.channel.send('clear\r')
            this.terminal.focus()
          },
        },
      ]
      this.$contextmenu({
        items: items,
        event,
        customClass: "custom-contextmenu",
        zIndex: 3000,
        minWidth: 80,
      });
      return false;
    },
    reconnectTerminal () {
      this.terminal.reset();
      this.terminal.dispose()
      this.terminal = new Terminal({ cursorBlink: true, convertEol: true });
      this.fitAddon = new FitAddon();
      this.terminal.loadAddon(this.fitAddon);
      this.terminal.open(this.$refs.terminal);
      this.connectWebSocket();
      // const attachAddon = new AttachAddon(this.channel);
      // this.terminal.loadAddon(attachAddon);
      if (this.fitAddon && this.fitAddon.fit) {
        this.fitAddon.fit();
        this.sendSize()
      }
      if (this.terminal) this.terminal.focus()
    },
    // 连接ws
    connectWebSocket () {
      if (this.activeKey !== 'terminal' || this.fileSystemId !== this.winboxHost.showItem.id) return false
      const params = {
        "target": this.fileSystemId + '', // 表示的目标的主机
        // "type": this.connectType,
        "type": "host_id",
        "pwd": "/",
        "init": "whoami",
        "mode": "terminal",
      };
      const encoder = new TextEncoder();
      const data = encoder.encode(JSON.stringify(params));
      const base64Params = btoa(String.fromCharCode.apply(null, data));
      const queryString = `${encodeURIComponent(base64Params)}`;
      // const url = new URL('ws://' + "alstra.cn:8866" + '/terminal');
      const arr = localStorage.getItem('FILE_SYSTEM_URL').split('//')
      const domin = arr[arr.length - 1]
      if (!domin) return false
      const url = new URL('ws://' + domin + '/terminal');
      url.search = "q=" + queryString + "&cluster_id=" + this.clusterId;
      let wsUrl = url.toString()
      // 获取一个仅供websocket使用的channel
      getChannel({ url: wsUrl }, "terminal" + Math.random(0,1)).then(channel => {
        this.channel = channel
        // 回显服务器信息
        let isReady = false
        let isReadyPromise = new Promise((resolve) => {
          channel.on('data', (data) => {
            const decoder = new TextDecoder();
            const text = decoder.decode(data);
            this.terminal.write(text);
            if (isReady || text.startsWith("远程连接已建立\r\n")) {
              resolve(true)
            }
          });
        });
        isReadyPromise.then((ready) => {
          // 发送认证信息
          const authinfo = { token: localStorage.getItem('token') }
          const blob = new Blob([JSON.stringify(authinfo)], { type: 'application/json' });
          blobToArrayBuffer(blob).then(buffer => {
            channel.send(buffer)
          })

          // 发送窗口设置大小信息
          const windowSize = { high: this.terminal.rows, width: this.terminal.cols };
          const sizeBlob = new Blob([JSON.stringify(windowSize)], { type: 'application/json' });
          blobToArrayBuffer(sizeBlob).then(buffer => {
            channel.send(buffer)
          })

          // 发送键盘输入信息
          this.terminal.onData((data) => {
            channel.send(data);
          });
        });
      }).catch(error => {
        this.terminal.write("连接失败" + error)
      });
    },
    initTerminal () {
      const self = this
      this.init = true
      const terminal = new Terminal({ cursorBlink: true, convertEol: true, });
      this.terminal = terminal
      const fitAddon = new FitAddon();
      this.fitAddon = fitAddon
      this.terminal.loadAddon(this.fitAddon);
      this.terminal.open(this.$refs.terminal);
      if (this.fitAddon && this.fitAddon.fit) this.fitAddon.fit();
      // // 在此添加任何其他配置或自定义逻辑
      // 连接ws 
      this.connectWebSocket();
      const resizeScreen = () => {
        if (self.activeKey !== 'terminal') return false
        if (self.fitAddon && self.fitAddon.fit) {
          self.fitAddon.fit();
          self.sendSize()
        }
      }
      this.resizeScreen = resizeScreen
      window.addEventListener('resize', this.resizeScreen, false);
      // const attachAddon = new AttachAddon(this.channel);
      // this.terminal.loadAddon(attachAddon);
    },
    sendSize () {
      if (this.activeKey !== 'terminal') return false
      if (!this.terminal) return false
      const windowSize = { high: this.terminal.rows, width: this.terminal.cols };
      console.log('窗口', windowSize)
      const blob = new Blob([JSON.stringify(windowSize)], { type: 'application/json' });
      blobToArrayBuffer(blob).then(buffer => {
        if(this.channel) this.channel.send(buffer)
      })

      // CONNECTING (0)：表示 WebSocket 正在进行连接过程中。
      // OPEN (1)：表示 WebSocket 已经建立连接并且可以进行通信。
      // CLOSING (2)：表示 WebSocket 正在关闭连接。
      // CLOSED (3)：表示 WebSocket 已经关闭连接或连接无法建立。
    }
  },
};
</script>
<style lang="less">
.xterm-screen {
  // width: calc(100vw - 300px) !important;
  // height: calc(100vh - 128px) !important;
}
</style>