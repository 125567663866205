<!--
 * @Description: 
 * @Date: 2024-06-24 17:25:55
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-14 15:55:57
-->
<template>
  <div :class="['render-component', isMenu && 'pdl0']" :id="'render-component' + currentWindowId">
    <div class="render-component-menu" v-if="isMenu">
      <FirstLevelMenu :menuData='menuData' :windowAppLoaderOptions="windowAppLoaderOptions" :menuWindowOptions="menuWindowOptions" :currentWindowId="currentWindowId" />
    </div>
    <!-- 根据currentView的值动态加载对应的组件 -->
    <!-- <SideMenu :menuData='menuData' :windowAppLoaderOptions="windowAppLoaderOptions" /> -->
    <LoadableComponent :propsData="propsData" :class="[isMenu && 'container']">
      <component v-if="isLoginFlag" :is="currentComponent" :windowAppLoaderOptions="windowAppLoaderOptions"></component>
      <div v-else class="flex items-center justify-center h-full">
        <a-button @click="openLoginModal">去登录</a-button>
      </div>
    </LoadableComponent>
  </div>
</template>

<script>
import FirstLevelMenu from './firstLevelMenu.vue'
import autofit from 'autofit.js'
import { mapGetters, mapState } from 'vuex'
import LoadableComponent from './LoadableComponent.vue';
import dynamicComponents from '@/utils/dynamic-components'
import { validateStep } from '@/components/createTask/deploystep/validate';
export default {
  name: "TaskbarEntriesRef",
  components: {
    LoadableComponent,
    FirstLevelMenu
  },
  props: {
    propsData: {
      default: () => {
        return {}
      },
      type: Object
    },
    appLoaderOptions: {
      default: () => {
        return {}
      },
      type: Object
    },
    // windowEventBus: {
    //   type: Object,
    // },
  },
  data () {
    return {
      currentView: '@/pages/overview/index2',
      windowAppLoaderOptions: {},
      windowPropsData: {},
      autofitVm: null,
      menuWindowOptions: {},
      currentWindowId: ''
    };
  },
  provide() {
    return {
      windowAppLoaderOptions: this.windowAppLoaderOptions,
      menuWindowOptions: this.menuWindowOptions,
      windowPropsData: this.propsData,
      editwindowAppLoaderOptions: this.editwindowAppLoaderOptions,
      editMenuOptions: this.editMenuOptions
    }
  },
  watch: {
    appLoaderOptions: {
      handler (val) {
        this.currentWindowId = val.id
        this.menuWindowOptions[`${val.id}`] = val
        this.windowAppLoaderOptions = _.cloneDeep(val)
      },
      immediate: true,
      deep: true
    },
    propsData: {
      handler (val) {
        this.windowPropsData = _.cloneDeep(val)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    isLoginFlag () {
      const isLoginFlag  = this.$store.state.account?.user?.username
      return isLoginFlag
    },
    menuData () {
      return this.$store.state.setting.menuData
    }, // 使用computed来动态获取组件
    
    currentComponent () {
      const window = this.menuWindowOptions[`${this.currentWindowId}`]
      let currentView = window[`${window.fileCompontentType}`].FileComponentPath
      if (currentView === '@/pages/overview/index') currentView= '@/pages/overview/index2'
      // 从dynamicComponents中获取组件
      console.log(currentView,this.currentWindowId,'this.currentWindowId')
      return dynamicComponents[currentView];
    },
    childMenu() {
      let path = '/' + this.windowAppLoaderOptions[`${this.windowAppLoaderOptions.fileCompontentType}`].FileGroupName
      // dashboard的的fullPath 为空
      let currentMenu = null
      if (path === '/dashboard') {
        currentMenu = this.menuData.filter(item => item.id === 'dashboard')
      } else {
        currentMenu  = this.menuData.filter(item => path.includes(item.fullPath) && item.fullPath !== '/')
      }
      let arr = currentMenu[0].children &&  currentMenu[0].children.length ?  currentMenu[0].children : [...currentMenu]
      return arr.filter(item => !(item.fullPath.includes('-new') || item.fullPath.includes('-edit')))
    },
    options () {
      const arr = _.cloneDeep(this.childMenu)
      let options =  arr
      return options
    },
    // 
    isMenu () {
      const window = this.menuWindowOptions[`${this.currentWindowId}`]
      let isMenu = window?.[`${window.fileCompontentType}`]?.isMenu || false
      return this.currentWindowId === 'awx'
    }
  },
  mounted () {
    const self = this
    // 监听窗口里面的头部方法
    this.$EventBus.$on("editwindowAppLoaderOptions", (data) => self.editwindowAppLoaderOptions(data));
    this.$EventBus.$on(`editMenuOptions-${this.currentWindowId}`, (data, id) => self.editMenuOptions(data, id , '1'));
    this.$EventBus.$on(`editFirstMenuOptions-${this.currentWindowId}`, (data, id) => self.editMenuOptions(data, id, '2'));
  },
  methods: {
    editMenuOptions (data, id, num) {
      if (!id || id !== this.currentWindowId) return false
      const self = this
      self.menuWindowOptions = {
        ...self.menuWindowOptions,
        [id]: data
      }
    },
    editwindowAppLoaderOptions (data) {
      const self = this
      const groupId = this.windowAppLoaderOptions.id 
      if (data.id === groupId || this.isMenu) {
        this.windowAppLoaderOptions = data
        this.$set(this.windowAppLoaderOptions, 'currentRoute', data.currentRoute)
      }
    },
    openLoginModal () {
      this.$store.commit("account/setShowLogin", true)
    },
  },
  beforeDestroy () {
    // this.$EventBus.$off("editwindowAppLoaderOptions");
    this.$EventBus.$off(`editMenuOptions-${this.currentWindowId}`);
    this.$EventBus.$off(`editFirstMenuOptions-${this.currentWindowId}`);
  }
};
</script>

<style scoped lang="less">
/deep/ .ant-layout {
  background: transparent;
}
.render-component-menu {
  float: left;
  height: 100%;
}
.container {
  border-top-left-radius: 8px;
  padding: 10px;
  height: calc(100%);
}
.render-component {
  height: 100%;
  background: #fff;
  padding: 14px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.pdl0 {
  padding: 0;
}
</style>