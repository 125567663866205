/**
 * 根据布局模式（垂直或水平）和指定的表格列数和行数，
 * 计算并返回新的布局数组。只有当当前行完全被占据时，
 * 才向下追加一行。同时以传入的 tableRows 为基础进行扩展。
 *
 * @param {Array} layout - 包含项目信息的数组，每个项目包含 content、width 和 height 属性。
 * @param {number} tableColumns - 表格的列数。
 * @param {number} tableRows - 表格的初始行数。
 * @param {string} mode - 布局模式，可以是 'horizontal' 或 'vertical'。
 * @returns {Array} 新的布局数组，每个项目包含原始属性及 x 和 y 坐标。
 */

// tablecols = 8
// rows = 3
// const layouts8 = [
//   {
//     content: 'A',
//     w: 2,
//     h: 1,
//     x: 0, 
//     y: 0
//   },
//   {
//     content: 'B',
//     w: 1,
//     h: 1,
//     y: 0,
//     x: 3
//   },
//   {
//     content: 'C',
//     w: 2,
//     h: 1,
//     x: 4, 
//     y: 0
//   },
//   {
//     content: 'D',
//     w: 2,
//     h: 1,
//     x: 6, 
//     y: 0
//   },
//   {
//     content: 'E',
//     w: 1,
//     h: 1,
//     x: 7, 
//     y: 0
//   },
//   {
//     content: 'F',
//     w: 1,
//     h: 1,
//     x: 0, 
//     y: 1
//   },
//   {
//     content: 'G',
//     w: 2,
//     h: 2,
//     x: 1, 
//     y: 1
//   },
//   {
//     content: 'H',
//     w: 1,
//     h: 1,
//     x: 3, 
//     y: 1
//   },
//   {
//     content: 'I',
//     w: 2,
//     h: 1,
//     x: 4, 
//     y: 1
//   },
//   {
//     content: 'J',
//     w: 2,
//     h: 1,
//     x: 6, 
//     y: 1
//   },
//   {
//     content: 'K',
//     w: 1,
//     h: 1,
//     x: 0, 
//     y: 2
//   },
//   {
//     content: 'L',
//     w: 1,
//     h: 1,
//     x: 3, 
//     y: 2
//   }
// ]
// const layouts9 = [
//   {
//     content: 'A',
//     w: 2,
//     h: 1,
//     x: 0, 
//     y: 0
//   },
//   {
//     content: 'B',
//     w: 1,
//     h: 1,
//     y: 0,
//     x: 3
//   },
//   {
//     content: 'C',
//     w: 2,
//     h: 1,
//     x: 4, 
//     y: 0
//   },
//   {
//     content: 'D',
//     w: 2,
//     h: 1,
//     x: 6, 
//     y: 0
//   },
//   {
//     content: 'E',
//     w: 1,
//     h: 1,
//     x: 7, 
//     y: 0
//   },
//   {
//     content: 'F',
//     w: 1,
//     h: 1,
//     x: 8, 
//     y: 0
//   },
//   {
//     content: 'G',
//     w: 2,
//     h: 2,
//     x: 0, 
//     y: 1
//   },
//   {
//     content: 'H',
//     w: 1,
//     h: 1,
//     x: 2, 
//     y: 1
//   },
//   {
//     content: 'I',
//     w: 2,
//     h: 1,
//     x: 3, 
//     y: 1
//   },
//   {
//     content: 'J',
//     w: 2,
//     h: 1,
//     x: 5, 
//     y: 1
//   },
//   {
//     content: 'K',
//     w: 1,
//     h: 1,
//     x: 7, 
//     y: 1
//   },
//   {
//     content: 'L',
//     w: 1,
//     h: 1,
//     x: 8, 
//     y: 1
//   }
// ]
// const layouts9 = [
//   {
//     content: 'A',
//     w: 2,
//     h: 1,
//     x: 0, 
//     y: 0
//   },
//   {
//     content: 'B',
//     w: 1,
//     h: 1,
//     y: 0,
//     x: 3
//   },
//   {
//     content: 'C',
//     w: 2,
//     h: 1,
//     x: 4, 
//     y: 0
//   },
//   {
//     content: 'D',
//     w: 2,
//     h: 1,
//     x: 6, 
//     y: 0
//   },
//   {
//     content: 'E',
//     w: 1,
//     h: 1,
//     x: 7, 
//     y: 0
//   },
//   {
//     content: 'F',
//     w: 1,
//     h: 1,
//     x: 8, 
//     y: 0
//   },
//   {
//     content: 'H',
//     w: 1,
//     h: 1,
//     x: 9, 
//     y: 0
//   },
//   {
//     content: 'G',
//     w: 2,
//     h: 2,
//     x: 0, 
//     y: 1
//   },
//   {
//     content: 'I',
//     w: 2,
//     h: 1,
//     x: 2, 
//     y: 1
//   },
//   {
//     content: 'J',
//     w: 2,
//     h: 1,
//     x: 4, 
//     y: 1
//   },
//   {
//     content: 'K',
//     w: 1,
//     h: 1,
//     x: 6, 
//     y: 1
//   },
//   {
//     content: 'L',
//     w: 1,
//     h: 1,
//     x: 7, 
//     y: 1
//   }
// ]

// function calculateLayout(layout, tableColumns, tableRows, mode) {
//   const realLayout = _.cloneDeep(layout)
//   const colWidth = 1; // 每一列的宽度
//   const rowHeight = 1; // 每一行的高度
//   let arr = [];
//   let occupiedSpaces = []; // 维护一个已占据的空间列表
//   let totalColumns = tableColumns;
//   let currentRows = tableRows;

//   // 逐个放置元素
//   layout.forEach((item, index) => {
//     let x, y;
//     let foundPosition = false;

//     // 查找下一个可用的位置
//     if (mode === 'vertical') {
//       // 垂直模式：逐列查找
//       for (x = 0; x < totalColumns && !foundPosition; x++) {
//         for (y = 0; y < currentRows; y++) {
//           // 检查当前位置是否足够放置当前元素
//           if (canFitHere(x, y, item.w, item.h)) {
//             // 放置元素
//             arr.push({
//               ...item,
//               x: x * colWidth,
//               y: y * rowHeight,
//               i: index
//             });

//             // 更新已占据的空间列表
//             occupySpace(x, y, item.w, item.h);

//             foundPosition = true;
//             break;
//           }
//         }
//         if (foundPosition) {
//           break;
//         }
//       }
//     } else if (mode === 'horizontal') {
//       // 水平模式：逐行查找
//       for (y = 0; y < currentRows && !foundPosition; y++) {
//         // 计算当前行剩余的空位
//         let remainingSpaceInRow = totalColumns - occupiedSpaces.filter(space => space.y === y).length;

//         // 如果当前行剩余的空位不足以容纳当前元素的宽度，则跳过当前行并尝试下一行为起点
//         if (remainingSpaceInRow < item.w) {
//           continue;
//         }

//         for (x = 0; x <= totalColumns - item.w; x++) { // 调整 x 的上限以确保有足够的空间
//           // 检查当前位置是否足够放置当前元素
//           if (canFitHere(x, y, item.w, item.h)) {
//             // 放置元素
//             arr.push({
//               ...item,
//               x: x * colWidth,
//               y: y * rowHeight,
//               i: index
//             });

//             // 更新已占据的空间列表
//             occupySpace(x, y, item.w, item.h);

//             foundPosition = true;
//             break;
//           }
//         }
//         if (foundPosition) {
//           break;
//         }
//       }

//       // 如果没有找到位置，将元素放置在新的一行
//       if (!foundPosition) {
//         y = currentRows;
//         currentRows++;
//         x = 0;

//         // 放置元素
//         arr.push({
//           ...item,
//           x: x * colWidth,
//           y: y * rowHeight,
//           i: index
//         });

//         // 更新已占据的空间列表
//         occupySpace(x, y, item.w, item.h);

//         foundPosition = true;
//       }
//     }
//   });

//   // 返回布局数组
//   return arr;

//   // 辅助函数
//   function isOccupied(x, y) {
//     return occupiedSpaces.some(space => space.x === x && space.y === y);
//   }

//   function canFitHere(x, y, width, height) {
//     for (let dx = 0; dx < Math.ceil(width / colWidth); dx++) {
//       for (let dy = 0; dy < Math.ceil(height / rowHeight); dy++) {
//         if (isOccupied(x + dx, y + dy)) {
//           return false;
//         }
//       }
//     }
//     return true;
//   }

//   function occupySpace(x, y, width, height) {
//     for (let dx = 0; dx < Math.ceil(width / colWidth); dx++) {
//       for (let dy = 0; dy < Math.ceil(height / rowHeight); dy++) {
//         occupiedSpaces.push({ x: x + dx, y: y + dy });
//       }
//     }
//   }
// }
function calculateLayout(realLayout, tableColumns, tableRows, mode) {
  // 这个地房需要将添加按钮排除 然后在最后数据处理的时候将添加按钮追加到最后一个地方
  let layout = _.cloneDeep(realLayout).filter(item => item.data?.fileCompontentType !== 'AddIcon');
  const addIconItem = realLayout.find(item => item.data?.fileCompontentType === 'AddIcon');
  layout.push({...addIconItem, i: layout.length});
  const colWidth = 1; // 每一列的宽度
  const rowHeight = 1; // 每一行的高度
  let arr = [];
  let occupiedSpaces = []; // 维护一个已占据的空间列表
  let totalColumns = tableColumns;
  let currentRows = tableRows;

  // 逐个放置元素
  layout.forEach((item, index) => {
    let x, y;
    let foundPosition = false;

    // 查找下一个可用的位置
    if (mode === 'vertical') {
      // 垂直模式：逐列查找
      for (x = 0; x < totalColumns && !foundPosition; x++) {
        // 计算当前列剩余的空位
        let remainingSpaceInColumn = currentRows - occupiedSpaces.filter(space => space.x === x).length;
        // 如果当前列剩余的空位不足以容纳当前元素的高度，则尝试下一列为起点
        if (remainingSpaceInColumn < item.h) {
          continue;
        }
        for (y = 0; y <= currentRows - item.h; y++) { // 调整 y 的上限以确保有足够的空间
          // 检查当前位置是否足够放置当前元素
          if (canFitHere(x, y, item.w, item.h)) {
            // 放置元素
            arr.push({
              ...item,
              x: x * colWidth,
              y: y * rowHeight,
              i: index
            });
            // 更新已占据的空间列表
            occupySpace(x, y, item.w, item.h);
            if (totalColumns - x < item.w) {
              foundPosition = false
              break;
            }
            foundPosition = true;
            break;
          }
        }
        if (foundPosition) {
          break;
        }
      }
      if (!foundPosition) {
        // 检查当前行的最大宽度元素是否可以重新放置
        const maxItem = arr.reduce((max, currentItem) => {
          return (max.w > currentItem.w) ? max : currentItem;
        }, { w: 0 });

        if (maxItem.w > 0) {
          // 移除最大宽度元素
          const indexToRemove = arr.findIndex(e => e.i === maxItem.i);
          if (indexToRemove !== -1) {
            arr.splice(indexToRemove, 1);
            // 清除其占据的空间
            occupiedSpaces = occupiedSpaces.filter(space => !(space.x >= maxItem.x && space.x < maxItem.x + maxItem.w &&
                                                              space.y >= maxItem.y && space.y < maxItem.y + maxItem.h));
          }

          // 重新放置最大宽度元素
          for (y = 0; y < currentRows && !foundPosition; y++) {
            // 检查当前位置是否足够放置当前元素
            for (x = 0; x < totalColumns; x++) {
              if (canFitHere(x, y, maxItem.w, maxItem.h) && !(totalColumns - x < item.w)) {
                // 放置元素
                arr.push({
                  ...maxItem,
                  x: x * colWidth,
                  y: y * rowHeight,
                  i: maxItem.i
                });

                // 更新已占据的空间列表
                occupySpace(x, y, maxItem.w, maxItem.h);

                foundPosition = true;
                break;
              }
            }
            if (foundPosition) {
              break;
            }
          }
        }

        // 如果仍然没有找到位置，则放置在新的一行
        if (!foundPosition) {
          y = currentRows;
          currentRows++;
          x = 0;

          // 放置元素
          arr.push({
            ...item,
            x: x * colWidth,
            y: y * rowHeight,
            i: index
          });

          // 更新已占据的空间列表
          occupySpace(x, y, item.w, item.h);

          foundPosition = true;
        }
      }
    } else if (mode === 'horizontal') {
      // 水平模式：逐行查找
      for (y = 0; y < currentRows && !foundPosition; y++) {
        // 计算当前行剩余的空位
        let remainingSpaceInRow = totalColumns - occupiedSpaces.filter(space => space.y === y).length;

        // 如果当前行剩余的空位不足以容纳当前元素的宽度，则跳过当前行并尝试下一行为起点
        if (remainingSpaceInRow < item.w) {
          continue;
        }

        for (x = 0; x <= totalColumns - item.w; x++) { // 调整 x 的上限以确保有足够的空间
          // 检查当前位置是否足够放置当前元素
          if (canFitHere(x, y, item.w, item.h)) {
            // 放置元素
            arr.push({
              ...item,
              x: x * colWidth,
              y: y * rowHeight,
              i: index
            });

            // 更新已占据的空间列表
            occupySpace(x, y, item.w, item.h);

            foundPosition = true;
            break;
          }
        }
        if (foundPosition) {
          break;
        }
      }

      // 如果没有找到位置，将元素放置在新的一行
      if (!foundPosition) {
        y = currentRows;
        currentRows++;
        x = 0;

        // 放置元素
        arr.push({
          ...item,
          x: x * colWidth,
          y: y * rowHeight,
          i: index
        });

        // 更新已占据的空间列表
        occupySpace(x, y, item.w, item.h);

        foundPosition = true;
      }
    }
  });
  // 返回布局数组
  return arr;

  // 辅助函数
  function isOccupied(x, y) {
    return occupiedSpaces.some(space => space.x === x && space.y === y);
  }

  function canFitHere(x, y, width, height) {
    for (let dx = 0; dx < Math.ceil(width / colWidth); dx++) {
      for (let dy = 0; dy < Math.ceil(height / rowHeight); dy++) {
        if (isOccupied(x + dx, y + dy)) {
          return false;
        }
      }
    }
    return true;
  }

  function occupySpace(x, y, width, height) {
    for (let dx = 0; dx < Math.ceil(width / colWidth); dx++) {
      for (let dy = 0; dy < Math.ceil(height / rowHeight); dy++) {
        occupiedSpaces.push({ x: x + dx, y: y + dy });
      }
    }
  }
}

export {
  calculateLayout
};