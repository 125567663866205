/*
 * @Description: 
 * @Date: 2024-07-25 10:57:36
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-07-25 10:57:47
 */
// jump.js

export default {
  inserted(el) {
    function startJump() {
      el.classList.add('jump');
    }

    function stopJump() {
      el.classList.remove('jump');
    }

    el.addEventListener('mouseenter', startJump);
    el.addEventListener('mouseleave', stopJump);

    // 可选：如果希望组件销毁时自动移除事件监听器，可以使用钩子函数
    el.__jump = { startJump, stopJump };
  },
  
  unbind(el) {
    if (el.__jump) {
      el.removeEventListener('mouseenter', el.__jump.startJump);
      el.removeEventListener('mouseleave', el.__jump.stopJump);
      delete el.__jump;
    }
  }
};
