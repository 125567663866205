<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-06-29 16:12:37
 * @LastEditTime: 2024-07-11 17:52:17
 * @FilePath: \awx-ui\src\layouts\ThirdPartyPage\index.vue
-->
<template>
  <div class="third-party-page">
    <iframe :src="linkUrlother" width="100%" height="100%" style="border: none; border-width: 0px"></iframe>
  </div>
</template>

<script>
export default {
  data () {
    return {
      linkUrlother: ''
    }
  },
  props:{
    windowAppLoaderOptions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    windowAppLoaderOptions: {
      handler (val) {
        if (val && val?.currentRoute && val?.currentRoute?.meta && val?.currentRoute?.meta.externalUrl) {
          this.linkUrlother = val?.currentRoute?.meta.externalUrl
        }
      },
      deep: true,
      immediate: true
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {
  },
}

</script>
<style scoped lang='less'>
.third-party-page {
    height: calc(100vh - 48px);
    padding: 0px;
    overflow-y: hidden;
}
</style>