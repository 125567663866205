/*
 * @Description: 
 * @Date: 2024-06-24 15:24:57
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-07-30 16:46:37
 */
import WinBox from "../winboxCustom/output/winbox.bundle.min";
import Vue from 'vue';
import store from '@/store'; // 引入 Vuex store
import GlobalWindow from './index.vue';
import {initRouter} from '@/router'
const router = initRouter(store.state.setting.asyncRoutes)

const GlobalWindowConstructor = Vue.extend(GlobalWindow);

let instance;

const initInstance = () => {
  instance = new GlobalWindowConstructor({
    store, // 传递 Vuex store
    router,
    el: document.createElement('div')
  });
  document.body.appendChild(instance.$el);
};

const GlobalWindowPlugin = {
  install(Vue) {
    if (!instance) {
      initInstance();
    }

    Vue.prototype.$openWindow = (windowId, components, propsData = {}, windowOptions = {}, appLoaderOptions = {}) => {
      instance.windowId = windowId;
      instance.components = components;
      instance.propsData = propsData;
      instance.windowOptions = windowOptions;
      instance.appLoaderOptions = appLoaderOptions;
      instance.showWindow();
    };
  }
};

export default GlobalWindowPlugin;
