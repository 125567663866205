<template>
  <a-layout :class="['loadable-components', 'beauty-scroll']">
    <a-layout class="loadable-components-main beauty-scroll">
      <a-layout-content class="loadable-components-content">
        <div style="position: relative; height: 100%;">
          <slot></slot>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import store from '@/store/index'
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex'
import SideMenu from '@/components/menu/desktopMenu/SideDesktop'
import menu from '@/components/menu/desktopMenu/menu'

export default {
  name: 'LoadableComponent',
  components: {SideMenu},
  data () {
    return {
      minHeight: window.innerHeight - 64 - 122,
      collapsed: true,
      showSetting: false,
      drawerOpen: false,
      menuDataOptions: [],
    }
  },
  provide() {
    return {
      adminLayout: this
    }
  },
  inject: ['windowAppLoaderOptions'],
  watch: {
    $route(val) {
      this.setActivated(val)
    },
    layout() {
      this.setActivated(this.$route)
    },
    isMobile(val) {
      if(!val) {
        this.drawerOpen = false
      }
    },
    // menuData
    menuData: {
      handler(val) {
        this.menuDataOptions = this.menuData
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState('setting', ['isMobile', 'theme', 'layout', 'footerLinks', 'copyright', 'fixedHeader', 'fixedSideBar',
      'fixedTabs', 'hideSetting', 'multiPage']),
    ...mapGetters('setting', ['firstMenu', 'subMenu', 'menuData']),
    sideMenuWidth() {
      return this.collapsed ? '54px' : '54px'
    },
    isGroup () {
      return this.windowAppLoaderOptions[`${this.windowAppLoaderOptions.fileCompontentType}`].isFileGroup
    },
    headerStyle() {
      let width = (this.fixedHeader && this.layout !== 'head' && !this.isMobile) ? `calc(100% - ${this.sideMenuWidth})` : '100%'
      let position = this.fixedHeader ? 'fixed' : 'static'
      return `width: ${width}; position: ${position};`
    },
    headMenuData() {
      const {layout, menuDataOptions, firstMenu} = this
      return layout === 'mix' ? firstMenu : menuDataOptions
    },
    sideMenuData() {
      const {layout, menuDataOptions, subMenu} = this
      return layout === 'mix' ? subMenu : menuDataOptions
    },
  },
  methods: {
    ...mapMutations('setting', ['correctPageMinHeight', 'setActivatedFirst']),
    toggleCollapse () {
      this.collapsed = !this.collapsed
    },
    onMenuSelect () {
      this.toggleCollapse()
    },
    toPath (menu) {
      this.$router.push({
        path: menu.fullPath,
      });
    },
    setActivated(route) {
      if (this.layout === 'mix') {
        let matched = route.matched
        matched = matched.slice(0, matched.length - 1)
        const {firstMenu} = this
        for (let menu of firstMenu) {
          if (matched.findIndex(item => item.path === menu.fullPath) !== -1) {
            this.setActivatedFirst(menu.fullPath)
            break
          }
        }
      }
    },
  },
  created() {
    this.correctPageMinHeight(this.minHeight - 24)
    this.setActivated(this.$route)
  },
  beforeDestroy() {
    this.correctPageMinHeight(-this.minHeight + 24)
  }
}
</script>

<style lang="less" scoped>
  .loadable-components{
    background: transparent;
    height: 100%;
    .side-menu{
      &.fixed-side{
        height: 36px;
        line-height: 36px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .virtual-side{
      transition: all 0.2s;
    }
    .virtual-header{
      transition: all 0.2s;
      opacity: 0;
      &.fixed-tabs.multi-page:not(.fixed-header){
        height: 0;
      }
    }
    .loadable-components-main{
       border: 1px solid rgba(217,217,217,1);
       border-radius: 4px; 
      //  padding-right: 2px;
      //  padding-left: 2px;
      .admin-header{
        top: 0;
        right: 0;
        overflow: hidden;
        transition: all 0.2s;
        &.fixed-tabs.multi-page:not(.fixed-header){
          height: 0;
        }
      }
    }
    .loadable-components-content{
      overflow-y: auto;
      margin: 0;
      // border-top-left-radius: 12px;
      border-radius: 4px;
      padding: 0 0px 0 0;
    }
    // 面包屑
    .breadcrumb {
      margin-top: -16px;
      margin-bottom: 4px;
      /deep/ .ant-breadcrumb-link {
        font-size: 12px;
      }
    }
  }
</style>
