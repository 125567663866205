/*
 * @Description: 
 * @Date: 2024-10-09 18:14:43
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-10-10 18:18:44
 */
// 首先根据rows和cols将layout中的元素分为inside，outside两个数组
function categorizeElements(cols, rows, elements) {
  const inside = [];
  const outside = [];

  elements.forEach(element => {
      if (element.x < cols && element.y < rows) {
          inside.push(element); // 在界内
      } else {
          outside.push(element); // 在界外
      }
  });

  return { inside, outside };
}

// 根据inside最后一个元素找到前置位所有的空位
// function findEmptySlotsAbove(elements, lastElement, rows, cols) {
//   const emptySlots = [];
  
//   // 从最后一个元素的上方开始找
//   for (let y = lastElement.y; y >= 0; y--) { // 包括当前行
//       for (let x = 0; x < cols; x++) {
//           // 检查该位置是否为空（即不在已占用的元素中）
//           const isOccupied = elements.some(element => element.x === x && element.y === y);
//           // 检查是否为后置位元素
//           const isPostElement = (y === lastElement.y && x > lastElement.x);

//           if (!isOccupied && !isPostElement) {
//               emptySlots.push({ x, y });
//           }
//       }
//   }
//   return emptySlots;
// }
// 根据开始节点和结束节点找到之间的空位
function findEmptySlotsBetween(startNode, endNode, elements, rows, cols) {
  const emptySlots = [];

  // 创建一个集合以快速查找已占用的位置
  const occupied = new Set(elements.map(element => `${element.x},${element.y}`));

  // 遍历行
  for (let y = 0; y < rows; y++) {
      // 只处理目标区域内的行
      if (y >= startNode.y && y <= endNode.y) {
          for (let x = 0; x < cols; x++) {
              // 检查该位置是否为空且在目标区域内
              if (!occupied.has(`${x},${y}`) && 
                  (y > startNode.y || x > startNode.x) && 
                  (y < endNode.y || x < endNode.x)) {
                  emptySlots.push({ x, y });
              }
          }
      }
  }

  return emptySlots;
}
// 根据x，y坐标排序
function sortEmptySlots(slots) {
  return slots.sort((a, b) => {
      // 先比较 y 坐标
      if (a.y !== b.y) {
          return a.y - b.y; // 根据 y 升序
      }
      // 如果 y 坐标相同，比较 x 坐标
      return a.x - b.x; // 根据 x 升序
  });
}
// 排序改变索引
function sortEmptySlotsChangeIndex(slots) {
  // 首先，按照 y 和 x 坐标进行排序
    slots.sort((a, b) => {
        if (a.y !== b.y) {
            return a.y - b.y; // 根据 y 升序
        }
        return a.x - b.x; // 根据 x 升序
    });
    
    // 然后，更新每一项的 i 属性为当前索引
    slots.forEach((slot, index) => {
        slot.i = index; // 将 i 属性设置为当前索引
    });
  return slots; // 返回排序并更新后的数组
}

function getElementsBetween(elements, startElement, lastElement, includeStart = false, includeEnd = false) {
  return elements.filter(element => {
      const afterStart = includeStart ? 
          (element.y > startElement.y || (element.y === startElement.y && element.x >= startElement.x)) : 
          (element.y > startElement.y || (element.y === startElement.y && element.x > startElement.x));
      
      const beforeEnd = includeEnd ? 
          (element.y < lastElement.y || (element.y === lastElement.y && element.x <= lastElement.x)) : 
          (element.y < lastElement.y || (element.y === lastElement.y && element.x < lastElement.x));

      return afterStart && beforeEnd;
  });
}

function insertBeforeElemet (beforeElement, afterElement, rows, cols, insertElement) { 
 
  // 根据beforeElement里面的最后一个元素找到前置位所有的空位 
  // 但是前置位可能没有元素
  // const beforeLastElement = beforeElement[beforeElement.length - 1] || insertElement
  const beforeLastElement = insertElement
  const beforeBmptySlots = findEmptySlotsBetween({x:-1, y: 0}, beforeLastElement, beforeElement, rows, cols);
  const SortedSlots = sortEmptySlots(beforeBmptySlots).reverse();
  const splitFirstElement = {x:-1, y: 0}
  // 一次插入一个 找第一个空位 如果没有空位
  if (SortedSlots.length === 0 ) {
    // 插入的元素已经是最后一个了
    if (afterElement.length === 0) {
      if (insertElement.x > cols - 1) {
        insertElement.x  = 0
        insertElement.y  += 1
      }
      return [...beforeElement, insertElement]
    }
    const afterSortedSlots = findEmptySlotsBetween(insertElement, afterElement[afterElement.length - 1], afterElement, rows, cols);
  
    let insertBefore = []
    let insertAfter =[]
    if (afterSortedSlots.length === 0) {
      insertBefore = [...afterElement]
    } else {
      let splitElement = afterSortedSlots[0]
      let splitLastElement = afterElement.length ? afterElement[afterElement.length - 1] : splitElement
      insertBefore = getElementsBetween(afterElement, splitFirstElement, splitElement, true, false);
      insertAfter = getElementsBetween(afterElement, splitElement, splitLastElement, false, true);
    }

    let arr = [insertElement, ...insertBefore];
    let insertArr = sortEmptySlotsChangeIndex(arr)
    const newLayouts = [...beforeElement];
    let currentX =  insertElement.x;
    let currentY = insertElement.y;
    if (currentX > cols - 1) {
      currentX = 0
      currentY += 1
    }
    // 将insertArr中的每个元素插入到inSideArr的最后一行
    insertArr.forEach(element => {
        // 插入元素
        newLayouts.push({...element, i: newLayouts.length, x: currentX, y: currentY });
  
        // 更新坐标
        if (currentX === cols - 1) {
            currentX = 0; // 重置 x
            currentY += 1; // y 增加
        } else {
            currentX += 1; // x 增加
        }
    });
    const realLayout = [...newLayouts, ...insertAfter];
    return realLayout
  }

  // 如果有空位 就往前置位插入
  let splitElement = SortedSlots[0]
  let splitLastElement = beforeElement.length ? beforeElement[beforeElement.length - 1] : splitElement

  let insertBefore = getElementsBetween(beforeElement, splitFirstElement, splitElement, true, false);
  const insertAfter = getElementsBetween(beforeElement, splitElement, splitLastElement, false, true);
  let arr = [...insertAfter, insertElement];
  let insertArr = sortEmptySlotsChangeIndex(arr)
  // 计算最后一个元素的坐标值
  // let lastElement = insertBefore.length > 0 ?  insertBefore[insertBefore.length - 1] : splitElement
  let lastElement =  splitElement
  if (insertBefore.length + insertAfter.length < insertArr.length && SortedSlots.length > 1) {
    lastElement = SortedSlots[insertArr.length - (insertBefore.length + insertAfter.length)]
  }
  if (SortedSlots.length === 1 && insertBefore.length === 0) {
    lastElement = {x: -1, y: 0}
  }
  // 获取最后一个元素的坐标
  let currentX =  lastElement.x;
  let currentY = lastElement.y;
  if (insertBefore.length === 0) currentX += 1
  if (currentX > cols - 1) {
    currentX = 0
    currentY += 1
  }
  // 创建新的布局数组
  const newLayouts = [...insertBefore];
  // 将insertArr中的每个元素插入到inSideArr的最后一行
  insertArr.forEach((element) => {
      // 插入元素
      newLayouts.push({...element, i: newLayouts.length + 1, x: currentX, y: currentY });

      // 更新坐标
      if (currentX === cols - 1) {
          currentX = 0; // 重置 x
          currentY += 1; // y 增加
      } else {
          currentX += 1; // x 增加
      }
  });
  const reaLayout = [...newLayouts, ...afterElement];

  return reaLayout;
}
function insertElementsIntoGrid(layout, rows, cols, outsideElement) {
 const normalLayout = _.cloneDeep(layout)
  const elements = sortEmptySlotsChangeIndex(layout)
  const { inside } = categorizeElements(cols, rows, elements);
  let outside = outsideElement ? outsideElement : categorizeElements(cols, rows, elements).outside;
   // 如果没有外部元素需要插入，结束递归
   if (outside.length === 0) {
      return normalLayout;
  }
  const insertElements = outside[0]
  const splitFirstElement = {x:-1, y: 0}
  const inSideLastElement = inside[inside.length - 1];
  // 根据要插入的元素将inside分为在插入元素之前的和之后的两个数组
  let beforeElement = getElementsBetween(inside, splitFirstElement, insertElements, true, false);
  const afterElement = getElementsBetween(inside, insertElements, inSideLastElement, false, true);
  // 插入元素并返回新的布局
  const newLayout = insertBeforeElemet(beforeElement, afterElement, rows, cols, insertElements);
  const realRows = newLayout.reduce((max, element) => {
      return element.y > max ? element.y : max;
  }, -Infinity);
  console.log(realRows, 'realRows')
  const rowParam = Math.max(realRows + 1, rows);
   // 递归调用以插入下一个元素
   outside.shift();
   return insertElementsIntoGrid(newLayout, rowParam, cols, outside);
}  
// 示例使用
const inSideArr = [
  { x: 0, y: 0, value: 'A' },
  { x: 1, y: 0, value: 'B' },
  { x: 2, y: 0, value: 'C' },
  { x: 3, y: 0, value: 'D' },
  { x: 4, y: 0, value: 'E' },
  { x: 0, y: 1, value: 'F' },
  { x: 1, y: 1, value: 'G' },
  { x: 2, y: 1, value: 'H' },
  { x: 3, y: 1, value: 'I' },
  { x: 4, y: 1, value: 'J' },
  { x: 0, y: 2, value: 'K' },
  { x: 1, y: 2, value: 'L' },
  { x: 2, y: 2, value: 'M' },
  { x: 3, y: 2, value: 'N' },
  { x: 4, y: 2, value: 'O' }, // {x:4, y:2}
  // { x: 5, y: 2, value: 'P' },
  { x: 0, y: 3, value: 'P' },
  { x: 1, y: 3, value: 'Q' },
  { x: 2, y: 3, value: 'R' },
  { x: 3, y: 3, value: 'S' }, // {x:3, y: 4}
  { x: 4, y: 3, value: 'T' },
  { x: 0, y: 4, value: 'U' },
  { x: 1, y: 4, value: 'V' }, //  {x: 1, y: 4}
  { x: 2, y: 4, value: 'W' },
  { x: 3, y: 4, value: 'X' }, // {x: 3, y;4}
  { x: 4, y: 4, value: 'Y' },
  { x: 5, y: 4, value: 'Z' }, // {x: 4, y;4}
  // { x: 6, y: 4, value: 'AA' }, // 
  // { x: 7, y: 5, value: 'BB' }, // 
  // { x: 8, y: 4, value: 'CC' }, // 
  // { x: 9, y: 4, value: 'DD' }, // 
  // { x: 10, y: 4, value: 'EE' }, // 
  // { x: 11, y: 4, value: 'FF' }, // 
];


// 执行插入操作
// const newLayouts = insertElementsIntoGrid(inSideArr, 5, 5);
// console.log(newLayouts, 'newLayouts');
// const newLayouts2 = insertElementsIntoGrid(newLayouts, 6, 5);
// console.log(newLayouts, 'newLayouts');


export{
  insertElementsIntoGrid,
}