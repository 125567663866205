<template>
  <div class="common-bottom-tab">
    <a-tabs
      type="card"
      v-model="selectKey"
      class="common-bottom-bottom-tab-container"
    >
      <a-tab-pane
        :tab="getTab(item,index)"
        v-for="(item,index) in panesList"
        :key="item.key"
      >
        <slot name="rightContent"></slot>
      </a-tab-pane>
    </a-tabs>
    <div style="width: 100%; height: 1px;background: #dee2e7;position: relative;top: -1px"></div>
  </div>
</template>
  <script>
import leftIcon from '../../assets/img/fileManage/bottom_left.jpg'
import rightIcon from '../../assets/img/fileManage/bottom_right.jpg'
export default {
  name: "Tables",
  components: {},
  props: {
    bottomTabsArr: Array,
    tabsLength: {
      type: Number,
      default: 100,
    },
    activeKey: String,
    showIcon: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    // 判断是从数据集成还是数据开发还是实时计算过来的
    tabsType: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      collapsed: false,
      searchVal: "",
      panesList: _.cloneDeep(this.bottomTabsArr || []),
      newPanesList: _.cloneDeep(this.bottomTabsArr || []),
      selectKey: this.activeKey,
    };
  },
  beforeCreate () { },
  created () { },
  beforeMount () { },
  mounted () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  watch: {
    activeKey (val) {
      this.selectKey = this.activeKey
    },
  },
  computed: {
    currentIndex () {
      let index = this.bottomTabsArr.findIndex(item => item.key === this.activeKey) || 0
      return index
    }
  },
  methods: {
    getTab (item, index) {
      return (
        <div
          onClick={() => this.changeTable(item, index)}
          class={["bottom-tab-layout", index === 0 && "first-tab-layout"]}
          style={{
            "width": this.selectKey === item.key ? this.tabsLength + 10 + 'px' : this.tabsLength + 10 + 'px'
          }}
        >
        {this.selectKey === item.key && <span class="bottom-icon"><img src={leftIcon} /></span>}
          <div
            class="bottom-tab-container"
            style={{
              background: this.selectKey === item.key ? "#fff!important" : "#F7F8FA",
            }}
          >
            <div style="display:flex;align-items:center;width:calc(100%)">
              <div class={["bottom-tab-title-layout", (this.selectKey !== item.key) && 'bottom-tab-title-layout-normal']} style={{'margin': '0 4px 0'}}>
                <svg-icon
                  icon-class={this.selectKey === item.key ? `${item.svgIcon}-selected` : item.svgIcon}
                  style={{
                    color: this.selectKey === item.key ? "#2872E0" : "#2872E0",
                    margin: "0 8px 0 0",
                    "font-size": "14px",
                  }}
                />
                {this.isEll(`workName${index}`, item.name) ? (
                  <span id={`workName${index}`} title={item.name}>
                    {item.name}
                  </span>
                ) : (
                  <span id={`workName${index}`} title={item.name}>
                    {item.name}
                  </span>
                )}
              </div>
            </div>
            {(this.selectKey !== item.key && this.currentIndex - 1 !== index) && (
              <div
                style={{
                  height: "18px",
                  width: "1px",
                  background: "#DEE1E5",
                  position: "relative",
                  left: "0px",
                  "z-index": "1",
                }}
              />
            )}
          </div>
          {this.selectKey === item.key && <span class="bottom-icon"><img src={rightIcon} /></span>}
        </div>
      );
    },
    isEll (id, name) {
      let width = document.getElementById(id);
      if (!width) return false;
      if (width.offsetWidth > 100) {
        width.setAttribute("title", name);
        return true;
      } else {
        return false;
      }
    },
    delTable (item, index, e) {
      if (e) e.stopPropagation();
      this.$emit("clickDelIcon", { item, index, e });
    },
    delAllTable () {
      this.$emit("delAll");
    },
    changeTable (item, index) {
      // if (!['file', 'terminal'].includes(item.key)) return false
      this.selectKey = item.key;
      this.$emit("getActiveKey", item.key);
    },
    tableClass (item, index) {
      let classArr = [];
      classArr.push(index == this.selectItem ? "table-click" : "");
      return classArr.join(" ");
    },
  },
  filters: {},
};
</script>
  <style lang="less" scoped>
.tabs-container.ant-popover-placement-bottomRight {
  top: 76px !important;
}
.ant-popover-inner-content {
  padding: 0;
}
.tab-search {
  width: 168px;
  margin: 1px 0;
  .ant-input {
    border-width: 0px;
  }
}
.tab-content {
  .tab-content-del {
    color: rgba(0, 0, 0, 0);
  }
}
.tab-content:hover {
  background: #f4f5f7;
  color: rgba(0, 0, 0, 0.85);
  .tab-content-del {
    color: #000000a6;
    background: #f4f5f7 !important;
  }
}
.common-bottom-tab {
  background: #f7f8fa;
  .common-bottom-bottom-tab-container {
    background: #f7f8fa;
  }
  .tab-right-layout {
    width: 50px;
    height: 30px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .right-button-layout {
      border-width: 0px;
      background: #e6e8ed;
      width: 23px;
      height: 23px;
      font-size: 10px;
      transform: rotate(90deg);
    }
  }
  /deep/ .ant-tabs-bar {
    margin: 0;
    height: 30px;
    background: #fff;
  }
  /deep/ .ant-tabs-nav {
    background: #f7f8fa;
    width: 100%;
    border-top: 1px solid #dee2e7;
  }
  /deep/ .ant-tabs-nav-container-scrolling {
    padding: 0px;
  }
  /deep/ .ant-tabs-tab-prev {
    display: none;
  }
  /deep/ .ant-tabs-tab-next {
    display: none;
  }
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-extra-content {
    line-height: 20px;
  }
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin-right: 0px;
    padding: 0;
    border-width: 0px;
    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .bottom-tab-layout {
    width: 160px;
    display: flex;
    align-items: center;
    background-color: #f7f8fa;
    .bottom-icon {
      img {
        width: 12px;
        height: 30px;
      }
    }
    .bottom-tab-container {
      position: relative;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 0px;
      margin: 0 0px;
     
    }
    .bottom-tab-title-layout {
      width: 100%;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .run-state {
        position: absolute;
        left: 20px;
        top: 12px;
      }
      svg {
        position: relative;
        top: -1px;
      }
    }
    .bottom-tab-title-layout-normal {
      &:hover {
        background: #cfd5de;
      }
    }
  }
  /deep/ .ant-tabs-tab-active {
    .bottom-tab-layout  {
      height: 30px !important;
      position: relative;
      top: -2px;
      color: #3974f4 !important;
      span {
        color: #3974f4;
      }
      .bottom-tab-container {
        padding-top: 0px;
      }
      .bottom-tab-title-layout {
         svg {
           position: relative;
           top: 1px;
         }
         span {
           position: relative;
           top: 1px;
         }
      }
    }
  }
}
</style>
  