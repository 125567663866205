<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-11-06 17:08:39
 * @FilePath: \awx-ui\src\pages\pipeline\index.vue
-->

<template>
  <div class="task-list">
    <div class="task-list-card" v-if="winboxTask.flowIndexShow  === 'list'">
      <a-row type="flex" align="middle">
        <a-col :span="clientWidth < 1700 ? 12 : 12">
        <a-button @click="delBatch()">删除</a-button>
        <a-button class="mgl10" @click="runBatch('start')">执行</a-button>
        <a-button class="mgl10" @click="runBatch('stop')">停止</a-button>
        <a-button style="margin-left: 10px" @click="refreshList()" :loading="refreshLoading">刷新</a-button>
        <a-button class="mgl10" style="height: 30px" type="primary" @click="createTask({})"><svg-icon class="btn-before-icon mgr6" icon-class="file-add"></svg-icon>新建流程</a-button>
      </a-col>
        <a-col :span="clientWidth < 1700 ? 12 : 12" style="text-align: right;justify-content: flex-end;" class="flex-container">
           <customDatePicker ref="customDatePickerRef" :currentSearchDate="currentSearchDate" :currentSearchDateText="currentSearchDateText" @getTime="getTime" />
            <a-select placeholder="请选择流程状态" :class="[clientWidth < 1700 ? 'w180' : 'w220', 'mgl12']" allowClear @change="(value) => getVal(value, 'state')">
              <a-select-option value="draft">草稿</a-select-option>
              <a-select-option value="published">已发布</a-select-option>
              <a-select-option value="publish_failed">发布失败</a-select-option>
              <a-select-option value="running">运行中</a-select-option>
              <a-select-option value="successful">执行成功</a-select-option>
              <a-select-option value="failed">执行失败</a-select-option>
            </a-select>
           <a-input-search placeholder="请输入流程名称查询" :class="[clientWidth < 1700 ? 'w180' : 'w220', 'mgl12']" @change="(value) => getVal(value, 'name')" allowClear @search="onSearch" />
        </a-col>
      </a-row>
      <div class="table-info">
        <a-table class="table-15 opt-table" @change="tableChange" :scroll="scrollTable" :customRow="handleClickRow" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :loading="loading" :dataSource="dataSource" rowKey="id" :pagination="pagination"></a-table>
      </div>
    </div>
    <div v-else-if="winboxTask.flowIndexShow  === 'history'">
      <run-history :taskDetail="taskDetail" :currentPage="'list'" @goBack="goBack" @createTask="createTask" @toHistoryDetail="toHistoryDetail" />
    </div>
  </div>
</template>

<script>
import customDatePicker from '@/components/customDatePicker'
import moment from "moment";
import { mapState, mapMutations, mapActions } from "vuex";
import DeleteTask from "./components/deleteTask.vue";
import { formatDuring } from "@/utils/util";
import RunHistory from "./history/runHistory.vue";
import cronstrue from 'cronstrue/i18n';
import winboxFlowModule from '@/store/modules/winboxFlowModule';

const clientWidth = document.documentElement.clientWidth
export default {
  name: "TASKMANAGE",
  components: { 
     customDatePicker,
     RunHistory,
    MyDynamicComponent: () => import('@/components/winbox/RenderComponent')
   },
  provide() {
    return {
      handleCancel: this.handleCancel,
      onlyCancel: this.onlyCancel,
      onSearch: null,
    };
  },
  data() {
    return {
      clientWidth,
      keyRfresh: 0,
      currentSearchDateText: '请选择',
      currentSearchDate: [],
      timer: null,
      taskModalTitle: "新建流程",
      params: {
        type: 3
      },
      setSelectedIndex: -1,
      pagination: {
        total: 0,
        pageSize:clientWidth < 1700 ? 13 : 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      taskDetail: {},
      visible: false,
      confirmLoading: false,
      configViseible: false,
      confirmConfigLoading: false,
      currentTaskConfigs: [],
      currentTask: {},
      dataSource: [],
      loading: false,
      refreshLoading: false,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 62,
          customRender: (text, row, index) => {
            return (
              <span>
                {parseInt(
                  this.pagination.current === 1
                    ? index + 1
                    : index +
                        1 +
                        this.pagination.pageSize * (this.pagination.current - 1)
                )}
              </span>
            );
          },
        },
        {
          title: "流程名称",
          key: "name",
          dataIndex: "name",
          width: 300,
          ellipsis: true,
          sorter: (a, b) => {
            return b.name.localeCompare(a.name, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row) => {
            return (
              <div style="display: flex">
                <span style="display: inline-block; width: 20px">
                {row.state === "draft" && (
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>草稿状态的流程不允许查看详情</span>
                    </template>
                    <svg-icon
                      class="task-draft"
                      style="font-size: 20px"
                      icon-class="task-draft"
                    />
                  </a-tooltip>
                )}
                {row.state === "publish_failed" && (
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>发布失败的流程不允许查看详情</span>
                    </template>
                    <svg-icon
                      style="font-size: 20px"
                      icon-class="task-failed"
                    />
                  </a-tooltip>
                )}
                {row.state === "publishing" && (
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>发布中</span>
                    </template>
                    <svg-icon
                      class="task-running"
                      style="font-size: 20px"
                      icon-class="task-running"
                    />
                  </a-tooltip>
                )}
                {row.state === "published" && (
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>已发布</span>
                    </template>
                    <svg-icon style="font-size: 20px;color: #8A9CC3;" icon-class="task-init" />
                  </a-tooltip>
                )}
                {row.state === "started" && (
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>
                        {!row.job_status
                          ? "未知状态"
                          : row.job_status === "running"
                          ? "正在执行"
                          : row.job_status === "successful"
                          ? "执行成功"
                          : "执行失败"}
                      </span>
                    </template>
                    {row.job_status && row.job_status === "running" && (
                      <svg-icon
                        class="task-running"
                        style="font-size: 20px"
                        icon-class="task-running"
                      />
                    )}
                    {row.job_status &&
                      ["failed", "error"].includes(row.job_status) && (
                        <svg-icon
                          style="font-size: 20px"
                          icon-class="task-failed"
                        />
                      )}
                    {row.job_status &&
                      row.job_status === "successful" && (
                        <svg-icon
                          style="font-size: 20px"
                          icon-class="task-successful"
                        />
                      )}
                  </a-tooltip>
                )}
                {row.state === "start-failed" && (
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>执行失败</span>
                    </template>
                    <a-icon class="state-icon start-failed-color" type="sync" />
                  </a-tooltip>
                )}
                </span>
                <span
                style=" overflow: hidden;text-overflow: ellipsis; display: inline-block;width: 360px;white-space: nowrap;"
                title={text}
                  class={[
                    "mgl10",
                    !["draft", "publish_failed", "publishing"].includes(
                      row.state
                    ) && "word-pointer",
                  ]}
                  onClick={(e) => this.showHistory(row, e)}
                >
                  {text}
                </span>
              </div>
            );
          },
        },
        {
          title: "流程状态",
          key: "state",
          width: 110,
          dataIndex: "state",
          sorter: (a,b) => {
            const name1 = a.state === "draft"
              ? "草稿"
              : a.state === "publish_failed"
              ? "发布失败"
              : a.state === "published"
              ? "已发布"
              : a.state === "publishing"
              ? "发布中"
              : a.state === "stated" && a.job_status
              ? "未知状态"
              : a.job_status === "running"
              ? "正在执行"
              : a.job_status === "successful"
              ? "执行成功"
              : "执行失败"
            const name2 = b.state === "draft"
              ? "草稿"
              : b.state === "publish_failed"
              ? "发布失败"
              : b.state === "published"
              ? "已发布"
              : b.state === "publishing"
              ? "发布中"
              : b.state === "stated" && b.job_status
              ? "未知状态"
              : b.job_status === "running"
              ? "正在执行"
              : b.job_status === "successful"
              ? "执行成功"
              : "执行失败"
              return name2.localeCompare(name1, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row) => {
            return (
              <div>
                {text === "draft"
                  ? "草稿"
                  : text === "publish_failed"
                  ? "发布失败"
                  : text === "published"
                  ? "已发布"
                  : text === "publishing"
                  ? "发布中"
                  : text === "stated" && row.job_status
                  ? "未知状态"
                  : row.job_status === "running"
                  ? "正在执行"
                  : row.job_status === "successful"
                  ? "执行成功"
                  : "执行失败"}
              </div>
            );
          },
        },
        {
          title: "最后一次执行",
          key: "release_id",
          ellipsis: true,
          width: 150,
          dataIndex: "release_id",
          customRender: (text, row) => {
            return (
              <div>
                {["draft", "publish_failed", "published"].includes(
                  row.state
                ) && (
                  <div>
                    <span class="mgr12">该流程尚未执行</span>
                  </div>
                )}

                {["started"].includes(row.state) && (
                  <div class="flex-container word-pointer" onClick={(e) => this.toHistoryDetail(row, e)}>
                    {row.release_id ? (
                      <span style="margin-right: 10px;display: inline-block"
                        class="curpon"
                      >
                        {"#" + row.release_id}
                      </span>
                    ) : (
                      "-"
                    )}
                      {row.owner || "-"}
                  </div>
                )}
              </div>
            );
          },
        },
        {
          title: "执行时间",
          key: "started",
          ellipsis: true,
          dataIndex: "started",
          sorter: (a, b) => {
            const format = "YYYY-MM-DD HH:mm:ss";
            const start = a.started ? moment(a.started).format(format) : ''
            const end = b.started ? moment(b.started).format(format) : ''
            if (!start || start === "") {
              return 1;
            } else if (!end || end === "") {
              return -1;
            } else {
              // 如果 a 和 b 都不为空，则转换为时间戳进行比较
              var timestampA = new Date(start).getTime();
              var timestampB = new Date(end).getTime();
              return timestampB - timestampA;
            }
          },
          width: 190,
          customRender: (text, row) => {
            return (
              <div>
                {["started"].includes(row.state) ? (
                  <div>
                    <div>
                      {row.started ? (
                        <div title={moment(row.started).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}>
                          <svg-icon
                            style="font-size: 18px;margin-right: 5px"
                            icon-class="task-start"
                          />
                          {moment(row.started).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                ) : (
                  "--"
                )}
              </div>
            );
          },
        },
        {
          title: "执行时长",
          key: "elapsed",
          dataIndex: "elapsed",
          width: 130,
          ellipsis: true,
          sorter: (a, b) => {
            return b.elapsed - a.elapsed
          },
          customRender: (text, row) => {
            return (
              <div>
                {["started"].includes(row.state) ? (
                  row.elapsed ? (
                    <div class='flex-container'>
                      <div style="min-width: 18px;margin-right: 5px"><svg-icon
                        style="font-size: 18px;"
                        icon-class="task-time"
                      /></div>
                      <div class='ell' title={formatDuring(row.elapsed, 'hide')}>{formatDuring(row.elapsed, 'hide')}</div>
                    </div>
                    ) : (
                      "-"
                    )
                ) : (
                  "--"
                )}
              </div>
            );
          },
        },
        {
          title: "执行周期",
          key: "schedule",
          dataIndex: "schedule",
          width: 140,
          ellipsis: true,
          sorter: (a, b) => {
            const name1 =a.schedule ? a.schedule === 'timer' ? this.humanReadable(a) : a.schedule === 'once' ? '一次性调度' : '手动调度' : '-'
            const name2 =b.schedule ? b.schedule === 'timer' ? this.humanReadable(b) : b.schedule === 'once' ? '一次性调度' : '手动调度' : '-'
            return name1.localeCompare(name2, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row) => {
            return (
              <div class="ell" title={text ? text === 'timer' ? this.humanReadable(row) : text === 'once' ? '一次性调度' : '手动调度'  : '-'}>{text ? text === 'timer' ? this.humanReadable(row) : text === 'once' ? '一次性调度' : '手动调度'  : '-'}</div>
            );
          },
        },
        {
          title: "下次执行时间",
          key: "next_time",
          dataIndex: "next_time",
          width: 170,
          ellipsis: true,
          sorter: (a, b) => {
            const format = "YYYY-MM-DD HH:mm:ss";
            const start = a.next_time ? moment(a.next_time).format(format) : ''
            const end = b.next_time ? moment(b.next_time).format(format) : ''
            if (!start || start === "") {
              return 1;
            } else if (!end || end === "") {
              return -1;
            } else {
              // 如果 a 和 b 都不为空，则转换为时间戳进行比较
              var timestampA = new Date(start).getTime();
              var timestampB = new Date(end).getTime();
              return timestampB - timestampA;
            }
          },
          customRender: (text, row) => {
            return (
              <div class="ell" title={row.next_time ? moment(row.next_time).format("YYYY-MM-DD HH:mm:ss") : '-'}>{row.next_time ? moment(row.next_time).format("YYYY-MM-DD HH:mm:ss") : '-'}</div>
            );
          },
        },
        {
          title: "创建时间",
          key: "create_time",
          dataIndex: "create_time",
          width: 170,
          ellipsis: true,
          sorter: (a, b) => {
            const format = "HH:mm:ss MM/D/YYYY";
            const start = moment(a.create_time).format(format);
            const end = moment(b.create_time).format(format);
            const diff = moment(end).diff(moment(start), "seconds");
            return diff;
          },
          customRender: (text, row) => {
            return (
              <div class="ell" title={moment(row.create_time).format("YYYY-MM-DD HH:mm:ss")}>{moment(row.create_time).format("YYYY-MM-DD HH:mm:ss")}</div>
            );
          },
        },
        {
          title: "操作",
          key: "action",
          width: 210,
          fixed:'right',
          customRender: (text, row, index) => {
            return (
              <span class="flex-container">
                {["draft", "publish_failed"].includes(row.state) ? (
                  <span>
                    <a class="btn-opt" onClick={(e) => this.publishTask(row, e)}>
                      发布
                    </a>
                    <a-divider type="vertical" />
                  </span>
                ) : (
                ["published"].includes(row.state) ? (
                    <span>
                      <a class="btn-opt" onClick={(e) => this.validateConfigs(row, e)}>
                        执行
                      </a>
                      <a-divider type="vertical" />
                    </span>
                  ) : (
                   (!["publishing"].includes(row.state) &&
                    (!["running"].includes(row.job_status))) && (
                      <span>
                        <a class="btn-opt" onClick={(e) => this.validateConfigs(row, e)}>
                          执行
                        </a>
                        <a-divider type="vertical" />
                      </span>
                    )
                  )
                )}
                { ["draft", "published"].includes(row.state) || (!["publishing"].includes(row.state) &&
                 (!["running"].includes(row.job_status))) ? (
                  <a class="btn-opt" onClick={(e) => this.createTask(row, e)}>
                    编辑
                  </a>
                ) : (
                  <a class="btn-opt" style="color: #bbb">
                    编辑
                  </a>
                )}
                <a-divider type="vertical" />
                {
                 ['timer', 'once'].includes(row.schedule) ? ( 
                  row.schedule_enabled ? <a class="btn-opt" onClick={(e) => this.startOrStop(row, e, 'stop')}>
                      停用
                    </a> : 
                    <a class="btn-opt" onClick={(e) => this.startOrStop(row, e, 'start')}>
                      启用
                    </a>) : (
                      row.schedule_enabled ? <a class="btn-opt" style="color: #bbb">
                      停用
                    </a> : 
                    <a class="btn-opt" style="color: #bbb">
                      启用
                    </a>
                  )
                }
                <a-divider type="vertical" />
                {
                ["draft", "published"].includes(row.state) || (!["publishing"].includes(row.state) &&
                 (!["running"].includes(row.job_status))) ?
                  <a-dropdown>
                    <a class="btn-opt" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                      更多 <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a class="btn-opt" onClick={(e) => this.copyTask(row, e)}>
                          复制
                        </a>
                      </a-menu-item>
                      <a-menu-item>
                        <a class="btn-opt" onClick={(e) => this.deleteTask(row, e)}>
                          删除
                        </a>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                  : <span>
                    <a class="btn-opt" style="color: #bbb">
                      复制
                    </a>
                    <a-divider type="vertical" />
                    <a class="btn-opt" style="color: #bbb">
                      删除
                    </a>
                  </span>
                }
              </span>
            );
          },
        },
      ],
    };
  },
  watch: {
    // '$route': {
    //   handler(val) {
    //     // 是从dashBoard进来的
    //     if(val.params && val.params.obj) {
    //       this.showTaskHistory(val.params.obj)
    //     } else {
    //       this.setFlowIndexShow('list')
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // },
    'fileManage.clusterId': {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.refreshList()
        }
      },
      deep: true
    },
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],

  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      fileManage: (state) => state.fileManage, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化,
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
    }),
    currentWinboxKey () {
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.$store.state.winboxFlowModule[this.currentWinboxKey];
    },
     currentPage() {
      const currentWinboxKey = this.currentWinboxKey; // 你的当前窗口键
      return this.$store.state.winboxFlowModule[currentWinboxKey]?.flowIndexShow || 'list';
    },
    scrollTable () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max) height = document.documentElement.clientHeight
      if (full) height = document.documentElement.clientHeight
     let scroll = {}
      if (this.dataSource.length > 0)  {
        scroll = {
          x: 1632,
          y: height - 220
        }
      }
      return scroll
    }
  },
  methods: {
    ...mapActions("task", ["setStepInfo", "changeFirstEdit", "setIsCreateFlowFlag", "setFlowIndexShow"]),
    ...mapActions("winboxFlowModule", ["setWinboxStepInfo", "changeWinboxFirstEdit", "setWinboxIsCreateFlowFlag", "setWinboxFlowIndexShow"]),
    ...mapActions("runHistory", ["getHistoryList", "setRunningFlag", "setRunTab", "setNumAdd"]),
    clearSelectIndex () {
      this.setSelectedIndex = -1
    },
    initDate () {
      const currentDate = moment();
      // 计算最近两周的起始日期（往前推14天）
      const startDate = currentDate.clone().subtract(13, 'days').format('YYYY-MM-DD HH:mm:ss');
      // 计算最近两周的结束日期
      const endDate = currentDate.clone().format('YYYY-MM-DD HH:mm:ss');
      this.currentSearchDate = [startDate, endDate]
      console.log(this.currentSearchDate, 'this.currentSearchDatethis.currentSearchDate')
    },
    getTime (date, text, empty) {
      if (empty) return this.onSearch()
      this.currentSearchDate = date
      this.currentSearchDateText = text
      this.onSearch()
    },
    humanReadable(record) {
      const cron = record.cron
      // 使用 cronstrue 将 Cron 表达式转换为人类可读的形式
      let str = '-'
      try {
        str = cronstrue.toString(cron, { locale: "zh_CN", use24HourTimeFormat: true })
      } catch (err) {
        str = '-'
      }
      return str
    },
    handleClickRow(record, index){
      return {
        style: {
        'background-color': index === this.setSelectedIndex ? '#e4eefd ' : '#fff',
        },
        on: {
          click: () => {
          // if (record.job_status === 'running') return false
          // this.setSelectedIndex = index
          // this.createTask(record)
          }
        }
      }
    },
    startOrStop (val, e) {
      if (e) e.stopPropagation()
      if (!val.schedule_enabled) {
        this.$axiosPatch(
          global.API.getDispatchList + `/${val.task_id}/enable`,
          {}
        ).then((res) => {
          if ([200, 201, 204].includes(res.status)) {
            this.getTaskList()
            this.$message.success("操作成功");
          }
        });
        return;
      }
      if (val.schedule_enabled) {
        this.$axiosPatch(
          global.API.getDispatchList + `/${val.task_id}/disable`,
          {}
        ).then((res) => {
          if ([200, 201, 204].includes(res.status)) {
            this.getTaskList()
            this.$message.success("操作成功");
          }
        });
      }
    },
    delBatch () {
      if (!this.selectedRowKeys.length) return this.$message.warning('请选择一条数据')
      const obj = {
        ids: this.selectedRowKeys,
      }
      const self = this;
      let width = 400;
      let content = (
        <DeleteTask
          sysTypeTxt="流程"
          sysType="选中"
          detail={obj}
          callBack={() => {
            self.getTaskList()
            this.selectedRowKeys = []
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows
      this.clearSelectIndex()
    },
   async runBatch (type) {
      if (!this.selectedRowKeys.length) return this.$message.warning('请选择一条数据')
      const ids = this.selectedRowKeys.join(',')
      // 这个执行 调用哪个接口
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${ids}/${type}`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("操作成功")
        this.pollingSearch();
        this.selectedRowKeys = []
      }
    },
    tableChange(pagination, filters, sorter) {
      if (this.pagination.current !== pagination.current) {
        this.pagination.current = pagination.current;
        this.pollingSearch();
      }
    },
    getVal(val, filed) {
      if (['type', 'state'].includes(filed)) {
        this.params[`${filed}`] = val
        this.onSearch()
      } else {
        this.params[`${filed}`] = val.target.value;
      }
    },
    refreshList () {
      this.setIsCreateFlowFlag(false)
      this.setWinboxIsCreateFlowFlag({id: this.currentWinboxKey, value: false})
      this.$refs.customDatePickerRef.getCurrentTime()
    },
    //   查询
    onSearch(type) {
      this.pagination.current = 1;
      this.clearSelectIndex()
      this.pollingSearch(type);
      this.setIsCreateFlowFlag(false)
      this.setWinboxIsCreateFlowFlag({id: this.currentWinboxKey, value: false})
    },
    async copyTask(obj, e) {
      if (e) e.stopPropagation()
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${obj.task_id}/copy`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("复制成功");
        this.pollingSearch();
      }
    },
    createTask(obj, e) {
      if (e) e.stopPropagation()
      // @/pages/pipeline/createFlowDesign
      let appLoaderOptions = {
        url: require("@/assets/img/desktop/desktop-task.svg"),
        name: "任务",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isFileGroup: false,
          FileGroupName: 'task-manage',
          FileComponentPath: '@/pages/pipeline/createFlowDesign'
        }, 
        componentName: 'MyDynamicComponent',
        id: 'pipeline-new',
      }
      const propData = {
        ...obj,
      }
      if (JSON.stringify(obj) === "{}") {
        appLoaderOptions.name = '新建流程'
        this.$openWindow('pipeline-new', this.$options.components, {propsData: propData}, {}, {...appLoaderOptions});
      } else {
         const stepInfo = {
          template_id: "",
          task_id: obj.task_id,
          name: obj.name,
          taskTemplateDetail: {
            operations: obj.operations,
            configs: obj.configs,
            notify: obj.notify,
            stepInfo: {
              properties: {
                currentStepIndex: 0
              }
            }
          },
        };
        appLoaderOptions.name = '编辑流程'
        appLoaderOptions.id = 'pipeline-edit' + obj.task_id
        this.$openWindow('pipeline-edit' + obj.task_id, this.$options.components, {propsData: propData}, {}, {...appLoaderOptions});
        this.setWinboxStepInfo({id: 'pipeline-edit' + obj.task_id, value: stepInfo})
      }
    },
    // 获取流程详情
    async getTaskDetail (obj) {
      let res = await this.$axiosGet(
        global.API.curdTasks + `${obj.task_id}/`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) { 
        const stepInfo = {
          template_id: "",
          task_id: res.data.task_id,
          name: res.data.name,
          taskTemplateDetail: {
            operations: res.data.operations,
            configs: res.data.configs,
            notify: res.data.notify,
            stepInfo: {
              properties: {
                currentStepIndex: 0
              }
            }
          },
        };
        this.setStepInfo(stepInfo);
        this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo})
      } else {
        this.$message.error('获取流程详情失败')
      }
    },
    deleteTask(obj, e) {
      if (e) e.stopPropagation()
      const self = this;
      let width = 400;
      let content = (
        <DeleteTask
          sysTypeTxt="流程"
          detail={obj}
          callBack={() => self.getTaskList()}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    onlyCancel() {
      this.visible = false;
    },
    onlyConfigCancel() {
      this.configViseible = false;
      this.currentTaskConfigs = []
      this.currentTask = {}
    },
    handleCancel(e) {
      if (this.currentPage === 'list') {
        // this.clearSelectIndex()
      } else {
        this.taskDetail = e
      }
      this.pollingSearch();
      this.visible = false;
    },
    // 查看历史流程
    showTaskHistory(row, e) {
      if (e) e.stopPropagation()
      // this.setSelectedIndex = this.dataSource.findIndex(item => item.task_id === row.task_id)
      if (["draft", "publish_failed"].includes(row.state)) return false;
      // 获取流程详情去跳转
     this.$axiosGet(
        global.API.curdTasks + `${row.task_id}/`,
        {id: row.task_id}
      ).then(res => {
        this.setFlowIndexShow('history')
        this.$store.dispatch('winboxFlowModule/setWinboxFlowIndexShow', { id: currentWinboxKey, value: 'history' });
        this.taskDetail = res.data;
      }) 
    },
      // 查看历史流程
    showHistory(row, e) {
      if (e) e.stopPropagation()
      // this.setSelectedIndex = this.dataSource.findIndex(item => item.task_id === row.task_id)
      if (["draft", "publish_failed"].includes(row.state)) return false;
      this.setFlowIndexShow('history')
      this.setWinboxFlowIndexShow({id: this.currentWinboxKey, value: 'history'})
      // this.keyRfresh = Math.random()
      this.taskDetail = row;
    },
    // 查看单次历史执行日志
    toHistoryDetail(row, e) {
      if (e) e.stopPropagation()
      // this.setSelectedIndex = this.dataSource.findIndex(item => item.task_id === row.task_id)
      if (this.currentPage === "list") { 
        this.taskDetail = row;
      }
      this.setFlowIndexShow('detail')
      this.setWinboxFlowIndexShow({id: this.currentWinboxKey, value: 'detail'})
      this.taskHistoryDetail = row
    },
    goBack(currentPage) {
      this.setFlowIndexShow(currentPage)
      this.setWinboxFlowIndexShow({id: this.currentWinboxKey, value: currentPage})
      if (currentPage === "list") {
        this.taskDetail = {};
      }
    },
    // 发布流程
    async publishTask(task, e) {
      if (e) e.stopPropagation()
      // 这个执行 调用哪个接口
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${task.id}/publish`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("发布成功");
        this.pollingSearch();
      }
    },
    validateConfigs (task, e) {
      if (e) e.stopPropagation()
      this.$axiosGet(
        global.API.curdTasks + `${task.id}/configs`,
        {}
      ).then((res => {
        if ([200, 201, 204, 202].includes(res.status)) { 
          console.log(res.data)
          let configs = []
          if (res.data) {
            configs = res.data.filter(item => item.runtime)
          }
          if (!configs.length) {
            this.runTask(task, res.data)
          } else {
            res.data.forEach(item => {
              if (item.type === 'enum' && !item.value) item.value = undefined
            })
            this.currentTaskConfigs = res.data
            this.currentTask = task
            this.configViseible = true
          }
        }
      }))
    },
    // 执行流程
    async runTask(task, configs, configType) {
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${task.id}/start`,
        {
          configs
        }
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("执行成功");
        if (configType === 'configs') this.onlyConfigCancel()
        const type = task.type === '0' ? 'task' : task.type === '1' ? 'cmd' :  task.type === '2' ? 'file' : 'task'
        this.setRunningFlag(true)
        this.setRunTab(type)
        // 刷新列表 把分页置位1
        this.setNumAdd(true)
        this.pollingSearch();
      }
    },
    // 执行之后定时去刷新列表，直到没有执行中的流程
    pollingSearch(type) {
      let self = this;
      if (self.timer) clearTimeout(self.timer);
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        name: this.params.name || "",
        type: this.params.type || "",
        state: this.params.state || "",
        start_time: this.currentSearchDate.length > 1 ? this.currentSearchDate[0] : '',
        end_time: this.currentSearchDate.length > 1 ? this.currentSearchDate[1] : '',
        excludes: 'operations,detail,configs,notify',
      };
      if (self.task.isCreateFlowFlag) {
        params.order_by = '-create_time'
      }
      this.$axiosGet(global.API.curdTasks, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.dataSource = res.data.results;
          this.pagination.total = res.data.total;
          let arr = res.data.results.filter(
            (item) => item.job_status === "running"
          );
          if (arr.length > 0) {
            self.timer = setTimeout(() => {
              self.pollingSearch()
            }, 3000);
          } else {
            clearTimeout(self.timer);
          }
        }
      });
    },
    // 获取流程列表
    getTaskList(flag) {
      if (!flag) this.loading = true;
      // this.clearSelectIndex()
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        name: this.params.name || "",
        type: this.params.type || "",
        state: this.params.state || "",
        start_time: this.currentSearchDate.length > 1 ? this.currentSearchDate[0] : '',
        end_time: this.currentSearchDate.length > 1 ? this.currentSearchDate[1] : '',
        excludes: 'operations,detail,configs,notify'
      };
      this.$axiosGet(global.API.curdTasks, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.dataSource = res.data.results;
          // todo 返回的时候根据什么标识去
          this.pagination.total = res.data.total;
        }
      });
    },
  },
  created () { 
    // this.initDate()
  },
  mounted() {
    if (!this.fileManage.clusterId) return false
    this.pollingSearch();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  destroyed () {
    clearTimeout(this.timer);
  }
};
</script>

<style lang="less" scoped>
.task-list {
  height: 100%;
  min-width: 980px;
  overflow-x: auto;
  box-shadow: none;
  padding: 16px 14px 16px 16px;
  &-card {
    .table-info {
      margin-top: 10px;
      /deep/ .ant-table {
        overflow-y: auto;
      }
    }
  }
  .btn-task-add {
    position: absolute;
    right: 0px;
    top: -38px
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 14px;
    color: #3065D9;
    letter-spacing: 0;
    font-weight: 400;
  }
  .state-icon,
  .curpon {
    cursor: pointer;
  }
  .draft-color {
    color: @configured-status-color;
  }
  .published-color {
    color: #8a8e99;
  }
  .started-color {
    color: @primary-color;
  }
  .start-failed-color,
  .error-color {
    color: @error-color;
  }
  .success-color {
    color: @success-color;
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .task-running {
    transform: rotate(360deg);
    animation: rotation 2s linear infinite;
  }
  .word-pointer {
    cursor: pointer;
    &:hover {
      color: #344899;
      text-decoration: underline;
    }
  }
}
.task-model {
  /deep/ .ant-modal-header {
    // background: rgb(238, 240, 245);
  }
}

.configs-model {
  /deep/ .ant-modal-header {
    padding: 10px 24px;
    background:linear-gradient(to right,rgba(226, 235, 250, 0.4), rgba(240, 243, 247, 0.4))
  }
}
/deep/ .ant-modal-body {
  padding: 0;
}
/deep/ .ant-modal {
  top: 40px;
  .ant-modal-content {
    border-radius: 4px;
  }
}
</style>
