/**
 * 该插件可根据菜单配置自动生成 ANTD menu组件
 * menuOptions示例：
 * [
 *  {
 *    name: '菜单名称',
 *    path: '菜单路由',
 *    meta: {
 *      icon: '菜单图标',
 *      invisible: 'boolean, 是否不可见, 默认 false',
 *    },
 *    children: [子菜单配置]
 *  },
 *  {
 *    name: '菜单名称',
 *    path: '菜单路由',
 *    meta: {
 *      icon: '菜单图标',
 *      invisible: 'boolean, 是否不可见, 默认 false',
 *    },
 *    children: [子菜单配置]
 *  }=
 * ]
 *
 * i18n: 国际化配置。系统默认会根据 options route配置的 path 和 name 生成英文以及中文的国际化配置，如需自定义或增加其他语言，配置
 * 此项即可。如：
 * i18n: {
 *   messages: {
 *     CN: {dashboard: {name: '监控中心'}}
 *     HK: {dashboard: {name: '監控中心'}}
 *   }
 * }
 **/
import Menu from 'ant-design-vue/es/menu'
import Icon from 'ant-design-vue/es/icon'
import fastEqual from 'fast-deep-equal'
import { getI18nKey } from '@/utils/routerUtil'
 
const { Item, SubMenu } = Menu
 
const resolvePath = (path, params = {}) => {
  let _path = path
  Object.entries(params).forEach(([key, value]) => {
    _path = _path.replace(new RegExp(`:${key}`, 'g'), value)
  })
  return _path
}
 
const toRoutesMap = (routes) => {
  const map = {}
  routes.forEach(route => {
    map[route.fullPath] = route
    if (route.children && route.children.length > 0) {
      const childrenMap = toRoutesMap(route.children)
      Object.assign(map, childrenMap)
    }
  })
  return map
}
 
export default {
  name: 'IMenu',
  props: {
    options: {
      type: Array,
      required: true
    },
    menuNum: {
      type: Number,
      default: 8
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    i18n: Object,
    openKeys: Array
  },
  data() {
    return {
      selectedKeys: [],
      sOpenKeys: [],
      currentRoute: {},
      cachedOpenKeys: [],
      iconSize: 16,
      currentHoverItem: {
        icon: '',
        name: ''
      }
    }
  },
  inject: ['windowAppLoaderOptions', 'editwindowAppLoaderOptions'],
  computed: {
    menuTheme() {
      return this.theme == 'light' ? this.theme : 'dark'
    },
    routesMap() {
      return toRoutesMap(this.options)
    }
  },
  created() {
    if (this.options.length > 0) {
      this.formatOptions(this.options, '')
      const routeArr = this.options.filter(item => !(item.fullPath.includes('-new') || item.fullPath.includes('-edit')))
      this.selectedKeys = [routeArr[0].fullPath]
      const routerData = JSON.parse(localStorage.getItem('routerData'))
      const group = routerData[0].children.find(item => item.path === this.windowAppLoaderOptions.id)
      const currentRoute = group.children.find(item => item.path === routeArr[0].path)
      const data = {...this.windowAppLoaderOptions}
      data[`${data.fileCompontentType}`].FileComponentPath = currentRoute.component
      data['currentRoute'] = {...currentRoute}
      this.currentRoute = currentRoute
      this.editwindowAppLoaderOptions(data)
    }
    // 自定义国际化配置
    if (this.i18n && this.i18n.messages) {
      const messages = this.i18n.messages
      Object.keys(messages).forEach(key => {
        this.$i18n.mergeLocaleMessage(key, messages[key])
      })
    }
  },
  mounted () {
    // if (this.options.length > 0) {
    //   this.formatOptions(this.options, '')
    //   this.selectedKeys = [this.options[0].fullPath]
    //   const routerData = JSON.parse(localStorage.getItem('routerData'))
    //   const group = routerData[0].children.find(item => item.redirect === this.options[0].fullPath)
    //   const currentRoute = group.children.find(item => item.path === this.options[0].fullPath)
    //   const data = {...this.windowAppLoaderOptions}
    //   data[`${data.fileCompontentType}`].FileComponentPath = currentRoute.component
    //   data['currentRoute'] = {...currentRoute}
    //   this.currentRoute = currentRoute
    //   this.editwindowAppLoaderOptions(data)
    //   console.log('windowAppLoaderOptions', this.windowAppLoaderOptions, currentRoute)
    // }
  },
  watch: {
    options(val) {
      if (val.length > 0 && !val[0].fullPath) {
        this.formatOptions(this.options, '')
      }
    },
    i18n(val) {
      if (val && val.messages) {
        const messages = this.i18n.messages
        Object.keys(messages).forEach(key => {
          this.$i18n.mergeLocaleMessage(key, messages[key])
        })
      }
    },
    collapsed(val) {
      if (val) {
        this.cachedOpenKeys = this.sOpenKeys
        this.sOpenKeys = []
        this.iconSize = 16
      } else {
        this.sOpenKeys = this.cachedOpenKeys
        this.iconSize = 16
      }
    },
    sOpenKeys(val) {
      this.$emit('openChange', val)
      this.$emit('update:openKeys', val)
    }
  },
  methods: {
    renderIcon: function (h, icon, key,name) {
      if (this.$scopedSlots.icon && icon && icon !== 'none') {
        const vnodes = this.$scopedSlots.icon({ icon, key })
        vnodes.forEach(vnode => {
          vnode.data.class = vnode.data.class ? vnode.data.class : []
          vnode.data.class.push('anticon')
        })
        return vnodes
      }
      return !icon || icon == 'none' ? null : h('svg-icon', { props: { iconClass: this.currentRoute.meta.icon === icon && this.currentRoute.name === name  ? icon + '-1' : icon },class: 'side-menu-icon', style: `font-size: ${this.iconSize}px`})
    },
    renderName : function (h, name) {
      return h('div', { class: 'side-menu-name ell', style: `font-size: 11px;color: #34343C;`}, [name])
    },
    renderClusterMenu: function (h, menu) {
      let tag = 'router-link'
      const path = resolvePath(menu.fullPath, menu.meta.params)
      let config = { props: { to: { path, query: menu.meta.query }, }, attrs: { style: 'overflow:hidden;white-space:normal;text-overflow:clip;', class: 'cluster-menu2' } }
      if (menu.meta && menu.meta.link) {
        tag = 'a'
        config = { attrs: { style: 'overflow:hidden;white-space:normal;text-overflow:clip;', href: menu.meta.link, target: '_blank' } }
      }
      return h(
        Item, { key: menu.fullPath },
        [
          h(tag, config,
            [
              <div class={'flex-bewteen-container cluster-menu-item'}>
                <div class={'flex-container'}>
                  <span class={'circle-point mgr10'}></span>
                  {menu.name}
                </div>
              </div>
            ]
          )
        ]
      )
    },
    getMoreMenu(props) {
      let arr = [{ name: 'name' }]
      return (
        arr.map((item, index) => {
          return <div key={index}>
            <a-button class="more-menu-btn" style="border-width:0px;min-width:100px;" onClick={() => { }}>{item.name}</a-button>
          </div>
        })
      )
    },
    renderMenuItem: function (h, menu) {
      console.log(this.currentRoute, menu, '!!!!!!!!!!!!', this.currentRoute.meta.icon === menu.meta.icon)
      // let tag = 'router-link'
      // const path = resolvePath(menu.fullPath, menu.meta.params)
      // let config = { props: { to: { path, query: menu.meta.query }},attrs: { style: 'overflow:hidden;white-space:normal;text-overflow:clip;', class: 'cluster-menu' }}
      let key = menu.fullPath
      if (!menu.fullPath.includes(menu.parent_id)) key = '/' + menu.parent_id + menu.fullPath
      let tag = 'div'
      let config = { props: {},attrs: { style: 'overflow:hidden;white-space:normal;text-overflow:clip;display: flex', class: `${this.currentRoute.name === menu.name ? 'cluster-menu-seleted cluster-menu' : 'cluster-menu'}` }}
      if (menu.meta && menu.meta.link) {
        tag = 'a'
        config = { attrs: { style: 'overflow:hidden;white-space:normal;text-overflow:clip;', href: menu.meta.link, target: '_blank' } }
      }
      return h(
        Item, {
          key: key,
          props: {
            draggable: true,
          },
          on: {
            dragstart: () => {
            //  this.$emit('dragstart', menu)
            },
            dragenter: (e) => {
            //  this.$emit('dragenter', menu, e)
            },
            dragend: (e) => {
            //  this.$emit('dragend', menu, e)
            },
            dragover: (e) => {
            //  this.$emit('dragover', e)
            },
            mouseover: (e) => {
            // this.currentHoverItem = {
            //   icon: menu.meta.icon,
            //   name: menu.name
            // }
            },
            mouseleave: (e) => {
            // this.currentHoverItem = {
            //   icon: menu.meta.icon,
            //   name: menu.name
            // }
            }
          }
        },
        [
          h(tag, config,
            [
              this.isShowIcon && this.renderIcon(h, menu.meta ? menu.meta.icon : 'none', menu.fullPath,menu.name),
              this.renderName(h, menu.name)
            ]
          )
        ]
      )
    },
    renderSubMenu: function (h, menu) {
      let this_ = this
      let subItem = [h('span', { slot: 'title', attrs: { style: 'overflow:hidden;white-space:normal;text-overflow:clip;', class: 'cluster-menu3' } },
        [
          this.isShowIcon&&this.renderIcon(h, menu.meta ? menu.meta.icon : 'none', menu.fullPath,menu.name),
          // this.$t(getI18nKey(menu.fullPath)) // 这个地方需要去优化
          menu.name === '快速访问中心' ? '快速访问' : menu.name 
        ]
      )]
      let itemArr = []
      menu.children.forEach(function (item) {
        itemArr.push(this_.renderItem(h, item))
      })
      return h(SubMenu, { key: menu.fullPath },
        subItem.concat(itemArr)
      )
    },
    renderItem: function (h, menu) {
      const meta = menu.meta
      if (!meta || !meta.invisible) {
        let renderChildren = false
        const children = menu.children
        if (children != undefined) {
          for (let i = 0; i < children.length; i++) {
            const childMeta = children[i].meta
            if (!childMeta || !childMeta.invisible) {
              renderChildren = true
              break
            }
          }
        }
        return (menu.children && renderChildren) ? this.renderSubMenu(h, menu) : (menu.meta && menu.meta.obj) ? this.renderClusterMenu(h, menu) : this.renderMenuItem(h, menu)
      }
    },
    renderMenu: function (h, menuTree) {
      let this_ = this
      let menuArr = []
      menuTree.forEach(function (menu, i) {
        menuArr.push(this_.renderItem(h, menu, '0', i))
      })
      return menuArr
    },
    formatOptions(options, parentPath) {
      options.forEach(route => {
        let isFullPath = route.path.substring(0, 1) == '/'
        route.fullPath = isFullPath ? route.path : parentPath + '/' + route.path
        if (route.children) {
          this.formatOptions(route.children, route.fullPath)
        }
      })
    },
    updateMenu() {
      // this.selectedKeys = this.getSelectedKeys()
    },
  },
  render(h) {
    return h(
      Menu,
      {
        props: {
          theme: this.menuTheme,
          mode: this.$props.mode,
          selectedKeys: this.selectedKeys,
          openKeys: this.openKeys ? this.openKeys : this.sOpenKeys
        },
        on: {
          'update:openKeys': (val) => {
            this.sOpenKeys = val
          },
          click: (obj) => {
            this.selectedKeys = [obj.key]
            const routerData = JSON.parse(localStorage.getItem('routerData'))
            const path = obj.key.split('/')[1]
            const key = obj.key.split('/')[obj.key.split('/').length - 1]
            const group = routerData[0].children.find(item => item.path === this.windowAppLoaderOptions.id)
            const currentRoute = group.children.find(item => (item.path === key || item.path === obj.key))
            const data = {...this.windowAppLoaderOptions}
            data[`${data.fileCompontentType}`].FileComponentPath = currentRoute.component
            data['currentRoute'] = {...currentRoute}
            this.currentRoute = currentRoute
            this.editwindowAppLoaderOptions(data)
            this.$emit('select', obj)
          }
        }
      }, this.renderMenu(h, this.options)
    )
  }
}
 