const default_setting = {
  settingBoxTab: 'basic',
  theme: {
    mode: 'light'
  },
  wallpaperTab: 'photos',
  wallpaper: {
    photos: {},
    gradient: {},
    backgroundImage: 'linear-gradient(316deg, rgb(241, 56, 0), rgb(245, 119, 0), rgb(245, 166, 0), rgb(241, 210, 0))'
  },
  // 常用任务栏
  fixedTabar: [
    {
      url: require("@/assets/img/desktop/desktop-task.svg"),
      name: "任务管理",
      fileCompontentType: "customPageComponent",
      customPageComponent: {
        isFileGroup: true,
        FileGroupName: 'task-manage',
        FileComponentPath: '@/pages/taskManage/index'
      }, 
      componentName: 'MyDynamicComponent',
      id: 'task-manage',
    },
     {
      url: require("@/assets/img/desktop/desktop-app.svg"),
      name: "应用管理",
      fileCompontentType: "customPageComponent",
      customPageComponent: {
        isFileGroup: true,
        FileGroupName: 'app-manage',
        FileComponentPath: '@/pages/appManage/index'
      }, 
      componentName: 'MyDynamicComponent',
      id: 'app-manage',
    },
     {
      url: require("@/assets/img/desktop/desktop-dashboard.svg"),
      name: "仪表盘",
      fileCompontentType: "customPageComponent",
      customPageComponent: {
        isFileGroup: true,
        FileGroupName: 'dashboard',
        FileComponentPath: '@/pages/overview/index2'
      }, 
      componentName: 'MyDynamicComponent',
      id: 'dashboard',
    },
    {
      url: require("@/assets/img/desktop/desktop-resource.svg"),
      name: "资源管理",
      fileCompontentType: "customPageComponent",
      customPageComponent: {
        isFileGroup: true,
        FileGroupName: 'resource',
        FileComponentPath: '@/pages/groupManage/index'
      }, 
      componentName: 'MyDynamicComponent',
      id: 'resource',
    },
     {
      url: require("@/assets/img/desktop/desktop-system.svg"),
      name: "系统管理",
      fileCompontentType: "customPageComponent",
      customPageComponent: {
        isFileGroup: true,
        FileGroupName: 'system-manage',
        FileComponentPath: '@/pages/securityCenter/user'
      }, 
      fileRenderType: "system-manage",
      componentName: 'MyDynamicComponent',
      id: 'system-manage'
    }
  ],
  message: {},
  userInfo: {},
  screenSize: {
    width: window.innerWidth,
    height: window.innerHeight
  },
  settingStep: 2,
  basic: {
    controlBar: {
      sideBar: {
        displayMode: 'autoHide',
        displayPosition: 'left',
        displayPositionDict: [
          { label: '左侧', value: 'left' },
          { label: '右侧', value: 'right' },
          { label: '底部', value: 'bottom' }
        ],
        displayModeDict: [
          { label: '一直显示', value: 'alwaysShow' },
          { label: '自动隐藏', value: 'autoHide' },
          { label: '一直隐藏', value: 'alwaysHide' }
        ]
      }
    },
  },
  desktopSize: {
    width: 1200,
    height: 764,
    x: 'center',
    y: 'center'
  },
  desktopIcon: {
    iconWidth: 100,
    iconLock: false,
    iconNameShow: true,
    desktopIconSize: 'small',
    desktopIconSizeDict: [
      { label: '小', value: 'small' },
      { label: '中', value: 'medium' },
      { label: '大', value: 'large' }
    ],
  },
  searchBoxHistoryList: [],
  searchBox: {
    searchBoxWidth: 100,
    searchBoxOpacity: 100,
    searchSuggestShow: true,
    searchHistoryShow: true,
    historyList: []
  },
  desktopArrange: 'horizontal', // vertical
  desktopLayout: [
    {
      "x": 0, "y": 0, "w": 1, "h": 1, "i": "0",
      data: {
        url: require("@/assets/img/desktop/desktop-all.svg"),
        name: "awx",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isMenu: true,
          MenuName: 'dashboard',
          isFileGroup: true,
          FileGroupName: 'dashboard',
          FileComponentPath: '@/pages/overview/index2'
        }, 
        componentName: 'MyDynamicComponent',
        id: 'awx',
      }
    },
    {
      "x": 1, "y": 0, "w": 1, "h": 1, "i": "1",
      data: {
        url: require("@/assets/img/desktop/desktop-task.svg"),
        name: "任务管理",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isFileGroup: true,
          FileGroupName: 'task-manage',
          FileComponentPath: '@/pages/taskManage/index'
        }, 
        componentName: 'MyDynamicComponent',
        id: 'task-manage',
      }
    },
    {
      "x": 2, "y": 0, "w": 1, "h": 1, "i": "2",
      data: {
        url: require("@/assets/img/desktop/desktop-app.svg"),
        name: "应用管理",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isFileGroup: true,
          FileGroupName: 'app-manage',
          FileComponentPath: '@/pages/appManage/index'
        }, 
        componentName: 'MyDynamicComponent',
        id: 'app-manage',
      }
    },
    {
      "x": 3, "y": 0, "w": 1, "h": 1, "i": "3",
      data: {
        url: require("@/assets/img/desktop/desktop-dashboard.svg"),
        name: "仪表盘",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isFileGroup: true,
          FileGroupName: 'dashboard',
          FileComponentPath: '@/pages/overview/index2'
        }, 
        componentName: 'MyDynamicComponent',
        id: 'dashboard',
      }
    },
    {
      "x": 4, "y": 0, "w": 1, "h": 1, "i": "4",
      data: {
        url: require("@/assets/img/desktop/desktop-resource.svg"),
        name: "资源管理",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isFileGroup: true,
          FileGroupName: 'resource',
          FileComponentPath: '@/pages/groupManage/index'
        }, 
        componentName: 'MyDynamicComponent',
        id: 'resource',
      }
    },
    {
      "x": 5, "y": 0, "w": 1, "h": 1, "i": "5",
      data: {
        url: require("@/assets/img/desktop/desktop-system.svg"),
        name: "系统管理",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isFileGroup: true,
          FileGroupName: 'system-manage',
          FileComponentPath: '@/pages/securityCenter/user'
        }, 
        fileRenderType: "system-manage",
        componentName: 'MyDynamicComponent',
        id: 'system-manage'
      },
    },
    {
      "x": 6, "y": 0, "w": 2, "h": 2, "i": "6",
      data: {
        url: require("@/assets/img/desktop/desktop-system.svg"),
        name: "模拟2*2",
        fileCompontentType: "ExternalComponent",
        ExternalComponent: {
          externalUrl: 'https://www.coze.cn/',
          openWith: '_blank'
        }, 
        componentName: 'ExternalComponent',
        id: 'system-manage'
      },
    },
    {
      "x": 8, "y": 0, "w": 1, "h": 1, "i": "7",
      data: {
        name: "添加图标",
        fileCompontentType: "AddIcon",
        componentName: 'AddIcon',
        id: 'add-icon-todesktop'
      },
    },
  ]
}
export default default_setting
