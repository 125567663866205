<!--
 * @Description: 
 * @Date: 2023-12-10 18:53:20
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-08-15 16:17:23
-->
<template>
  <div>
    <modifyStepVue :appDetail="appDetail" :isComponet='true' :showPage="showPage"/>
  </div>
</template>

<script>
import modifyStepVue from '@/pages/templateStep/modifyStep.vue'
export default {
  data () {
    return {
    }
  },
  props: {
    appDetail: Object,
    showPage: String
  },
  components: {
    modifyStepVue
  },
  mounted() {
  },
  methods: {
  },
}

</script>
<style scoped lang='less'>
</style>