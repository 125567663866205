<template>
  <div class="pipeline-page h-full" v-resize="handleResize">
    <div v-if="!editFlag" class="task-steps-name">
      <div>
        {{ currentWinboxKey}}
        <!-- <span class="flow-back mgr10" @click="backFlow"><svg-icon class="flow-back-icon" icon-class="file-back"></svg-icon></span> -->
        {{winboxTask.stepInfo.name || ''}}
        <span v-if="!readOnly" style="cursor: pointer;" @click="editName">
          <svg-icon class="mgl10 task-steps-name-edit" icon-class="flow-edit"></svg-icon>
        </span>
      </div>
      <div class="btn-task-header">
        <a-popconfirm
          overlayClassName="pop-confrim-modal"
          title="确定删除吗?"
          ok-text="是"
          cancel-text="否"
          @confirm="delTemplate"
        >
          <a-button v-if="fromType === 'template' && canDelete && !readOnly" class="btn-gray" :loading="deleteTemplateLoading">删除</a-button>
        </a-popconfirm>
        <a-button class='mgl8' v-if="!readOnly && fromType !=='template'" :loading="collecLoading" @click="openCollectModal">收藏</a-button>
        <a-button type="primary" class='mgl8' v-if="!readOnly && fromType ==='template'" :loading="nextLoading" @click="editTemplate('save')">保存</a-button>
        <a-button type="primary" class='mgl8' v-if="!readOnly  && fromType !=='template'" :loading="nextLoading" @click="save('save')">保存</a-button>
        <a-button type="primary" class='mgl8' @click="submit"><svg-icon class="mgr8" icon-class='flow-run' style="color: #fff" /> 运行</a-button>
      </div>
    </div>
    <div v-else class="task-steps-name jus-start">
      <span class="flow-back mgr10" @click="backFlow"><svg-icon class="flow-back-icon" icon-class="file-back"></svg-icon></span>
      <a-form :form="form" :label-col="{ span: 0 }" :wrapper-col="{ span: 6 }">
        <a-form-item label style="margin-bottom: 0px">
          <a-input placeholder="请输入流程名称" ref="myInput" @blur="getName" style="margin-top: 6px; width: 252px;" @focus="handleFocus" v-decorator="['taskName', { rules: [{ required: true, message: '流程名称不能为空' }] }]" />
        </a-form-item>
      </a-form>
    </div>
    <div class="pipeline">
      <FlowDesign class="flow-design" :ref="FlowDesignRef" :readOnly="readOnly" />
      <div class="right-container" v-if="!readOnly" :style="{width: showtabs ? '' : '53px'}">
        <OperationBox :taskType="'flow'" @changeShowTabs="changeShowTabs" />
      </div>
      <div v-if="readOnly" class="log-editor">
        <editor id="editor" ref="formEditor" style="position: relative top: 8px;" :editorHeight="editorHeight" :propsCode="step.log" :readOnly="true" theme="vs-dark" language="json" />
      </div>
       <!-- 日志抽屉 -->
      <div v-if="visible">
        <a-drawer
          :title="step.name"
          placement="right"
          :closable="false"
          :visible="visible"
          wrapClassName='step-log-drawer'
          :mask="false"
          :width="600"
          @close="onClose"
          :get-container="false"
          :wrap-style="{ position: 'absolute' }"
        >
          <json-viewer v-if="step.log" v-model="step.log"></json-viewer>
        </a-drawer>
      </div> 
    </div>
  </div>
</template>

<script>
import editor from "@/components/editor";

import { mapState, mapMutations, mapActions } from "vuex";
import OperationBox from "@/components/workflow-design/components/operationBox.vue";
import FlowDesign from "./flow-design.vue";
import { createTaskStep } from '@/utils/createComponents.js'
import TemplateModal from './components/templateModal.vue'
import ConfrimTaskNameModal from '@/components/createTask/confirmName/index.vue'

export default {
  data() {
    return {
      step: {
        name: '',
        log: ''
      },
      theme: 'erlang-dark',
      visible: false,
      editFlag: false,
      deleteTemplateLoading: false,
      form: this.$form.createForm(this, { taskName: "" }),
      nextLoading: false,
      nextRunLoading: false,
      isFocus: false,
      collecLoading: false,
      showtabs: true,
      winboxHeight: 764
    };
  },
  components: {
    FlowDesign,
    OperationBox,
    editor
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
    }),
    taskId() {
      const taskId = this.windowPropsData.id || "";
      return taskId;
    },
    readOnly () {
      const readOnly = this.windowPropsData?.readOnly
      return readOnly
    },
    fromType () {
      const from = this.windowPropsDatax?.from || ''
      return from
    },
    editorHeight () {
      let height = this.winboxHeight
      return `${height - 110}px`
    },
    canDelete () {
      let canDelete = false
      if (this.winboxTask.taskInfo.task_type === '1')  {
        canDelete = true
      } else {
        if (this.isSuperuser) canDelete = true
      }
      return canDelete
    },
    currentWinboxKey () {
      // const currentFocusWindow = this.windowStore.currentFocusWindow;
      // let key = null
      // for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
    FlowDesignRef () {
      return 'FlowDesignRef' + this.currentWinboxKey
    }
  },
  watch: {
    'windowPropsData.id': {
      handler(val) {
        if (val) {
          this.getTaskDetail(val)
        }
      }
    }
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  created () {
    if (this.fromType === 'template') {
      this.isSuperuser = JSON.parse(
        localStorage.getItem("admin.user")
      ).isSuperuser; //判读是不是超级用户
    }
    if (this.fromType && this.fromType === 'template') return this.getTaskDetailByTemplate()
    const taskId = this.windowPropsData.id
    !taskId ? this.createTask() : this.getTaskDetail(taskId)
    if (this.readOnly) this.getAllLog(taskId)
  },
  mounted() {
    this.$EventBus.$on("handlerMouseFocus", () => {
      this.handlerMouseFocus()
    });
    this.$EventBus.$on("openLogByStepId", (stepId, step) => {
      this.openLogByStepId(stepId, step);
    });
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setCurrentOptIndex",
      "setStepInfo",
      "setIsCreateFlowFlag",
      "setSelectStepId"
    ]),
    ...mapActions("winboxFlowModule", [
      "setWinboxTaskInfo",
      "setWinboxShowStepsList",
      "setWinboxSelectTemplateIndex",
      "setWinboxCurrentOptIndex",
      "setWinboxStepInfo",
      "setWinboxIsCreateFlowFlag",
      "setWinboxSelectStepId"
    ]),
    changeShowTabs (flag) {
      this.showtabs = flag
    },
    handleResize({ width, height }) {
      this.winboxHeight = document.getElementById(`${this.currentWinboxKey}`)?.clientHeight || 764
      // 更新画布试图
      window?.[this.currentWinboxKey]?.designer?.api?.workspace?.updateCanvasSize()
      if (this.readOnly) {
        window?.[this.currentWinboxKey]?.designer?.api?.viewport?.resetViewport()
      } else {
        const x = this.showtabs ? 600 : 0 
        window?.[this.currentWinboxKey]?.designer?.state?.setOffsetX(x);
        window?.[this.currentWinboxKey]?.designer?.api?.viewport?.resetViewport(x)
      }
    },
    onClose () {
      this.step = {
        name: '',
        log: ''
      }
      this.$refs.formEditor.resetCode(this.step.log)
      this.visible = false
    },
    async getAllLog (taskId) {
      let res = await this.$axiosGet(
        global.API.curdTasks + `${taskId}/op_runs`,
        {exclude: 'result'}
      );
      if ([200, 201, 204, 202].includes(res.status)) { 
        this.step = {
          name: '',
          log: JSON.stringify(res.data, null, 2)
        }
        this.$refs.formEditor.resetCode(this.step.log)
        // this.visible = true
      } else {
        this.$message.error('获取流程日志失败')
      }
    },
    async openLogByStepId (stepId, step) {
      if (!stepId || !step) {
       return this.getAllLog(this.windowPropsData.id)
      }
      let res = await this.$axiosGet(
        global.API.curdTasks + `${this.windowPropsData.id}/op_runs`,
        {exclude: 'result', uuid: stepId}
      );
      if ([200, 201, 204, 202].includes(res.status)) { 
        if (res.data[`${stepId}`].status === 'unknown') {
          this.onClose()
          return this.$message.warning('暂无执行日志')
        }
        this.step = {
          name: '执行详情 / ' + step.step.name,
          log: JSON.stringify(res.data[`${stepId}`].result, null, 2)
        }
        this.$refs.formEditor.resetCode(this.step.log)
        // this.visible = true
      } else {
        this.$message.error('获取流程日志失败')
      }
    },
    async delTemplate () {
    this.deleteTemplateLoading = true
    let res = await this.$axiosDelete(
        global.API.onlyTemplate + this.winboxTask.stepInfo.template_id + '/',
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("删除成功");
        this.deleteTemplateLoading = false;
        this.$router.push('/task-manage/template-shop')
      }
   },
    openCollectModal () {
      const self = this;
      let width = 520;
      let title = "收藏";
      let content = <TemplateModal ref="TemplateModalRef" templateName={this.winboxTask.stepInfo.name} callBack={(form) => self.saveCustomTemplate(form)} />
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
      // 存为模板
    async saveCustomTemplate(form) {
      const definition = window[this.currentWinboxKey].designer.getDefinition()
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo)
      const param = this.handlerParms(definition, stepInfo)
      param.name = form.name
      param.icon = form.icon
      param.description =form.description
      param.categories =(form.categories && form.categories.length) ? form.categories.join(',') : ''
      param.tags = (form.tags && form.tags.length) ? form.tags.join(',') : ''
      delete param.taskTemplateDetail
      delete param.id
      param.task_type = '1'
      let res = await this.$axiosJsonPost(
        global.API.curdTaskTemplate,
        param
      );
      this.$refs.TemplateModalRef.loading = false
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("收藏成功");
        this.$refs.TemplateModalRef.formCancel()
      }
    },
    processArrayItem(item, index) {
      return item.slice(1, index).join('_');
    },
    async editTemplate () {
      this.nextLoading = true;
      const self = this;
      const definition = window[this.currentWinboxKey].designer.getDefinition()
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo)
      const saveParams = this.handlerParms(definition, stepInfo)
      // if (!saveParams.name) return self.$message.warning('请输入流程名称')
      const NameArr = saveParams.operations.map(item => {return item.name})
      const NameArrIndex =  NameArr.length < 4 ? NameArr.length : 4
      if (!saveParams.name) {
        saveParams.name = this.processArrayItem(NameArr, NameArrIndex)
      }
      let res = await this.$axiosPut(
        global.API.curdTaskTemplate + stepInfo.template_id,
        saveParams
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("保存成功");
        this.nextLoading = false;
        this.$emit('refreTemplate')
      }
    },
    handleFocus () {
      this.isFocus = true
    },
    handlerMouseFocus () {
      this.editFlag = false
      if (this.isFocus) this.$refs.myInput.blur();
      this.isFocus = false
    },
    // 获取流程详情
    async getTaskDetail (taskId) {
      // taskId = '6ef3d4c2-b2f6-431e-ab90-13484f98a84b/'
      let res = await this.$axiosGet(
        global.API.curdTasks + `${taskId}/`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        const stepInfo = {
          template_id: "",
          task_id: res.data.task_id,
          name: res.data.name,
          taskTemplateDetail: {
            operations: res.data.operations,
            configs: res.data.configs,
            notify: res.data.notify,
            stepInfo: {
              properties: {
                currentStepIndex: 0
              }
            }
          },
        };
        if (this.readOnly) return this.getStepStatus(stepInfo, taskId)
        this.setStepInfo(stepInfo);
        this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});
        this.$nextTick(() => {
          this.$refs[this.FlowDesignRef].initDesigner('edit', null)
        })
      } else {
        this.$message.error('获取流程详情失败')
      }
   },
    // 查看日志獲取狀態
    async getStepStatus (stepInfo, taskId) {
      let res = await this.$axiosGet(
        global.API.curdTasks + `${taskId}/op_runs`,
        {exclude: 'result'}
      );
      if ([200, 201, 204, 202].includes(res.status)) { 
        this.setStepInfo(stepInfo);
        this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});
        this.$nextTick(() => {
          this.$refs[this.FlowDesignRef].initDesigner('edit', null, res.data)
        })
      } else {
        this.$message.error('获取流程日志失败')
      }
    }, 
    getTaskDetailByTemplate () {
      this.$nextTick(() => {
        this.$refs[this.FlowDesignRef].initDesigner('edit', null)
      })
    },
   async createTask() {
      let templateItem = {}
      const res = await this.$axiosGet(global.API.getConfigByStepsId + "/" + 'manual' +'/')
      const obj =res.data
      obj['application_name'] = '调度方式'
      obj.disabledDelete = true
      templateItem = {name: '手动调度', icon: obj.icon, template_id: '13bbddee-79ba-45a6-b2c9-2ccb1abbccf6', properties: {...obj, currentStepIndex: 2}}
      const step = createTaskStep(templateItem)
      let stepInfo = _.cloneDeep(this.winboxTask.stepInfo)
       stepInfo = {
        template_id: "",
        taskTemplateDetail: {
          operations: [],
          configs: stepInfo.taskTemplateDetail?.configs || [],
          notify: stepInfo.taskTemplateDetail?.notify || [],
          stepInfo: {
            properties: {
              currentStepIndex: 0
            }
          }
        },
      };
      this.setStepInfo(stepInfo);
      this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});
      this.setSelectStepId(null);
      this.setSelectStepId({id: this.currentWinboxKey, value: null});
      this.$nextTick(() => {
        this.$refs[this.FlowDesignRef].initDesigner('edit', step)
      })
    },
    editName() {
      this.editFlag = true;
      this.form.getFieldsValue(["taskName"]);
      this.form.setFieldsValue({
        taskName: this.winboxTask.stepInfo.name,
      });
    },
    getName() {
      const _this = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          this.editFlag = false;
          this.setStepInfo({ ...this.winboxTask.stepInfo, name: values.taskName });
          this.setWinboxStepInfo({id: this.currentWinboxKey, value: { ...this.winboxTask.stepInfo, name: values.taskName }});
        }
      });
    },
    backFlow () {
      if (this.formType === 'tab') return this.$router.push('/task-manage/pipeline')
      this.$router.go(-1)
    },
    // 在没哟设置任务名称的时候需要去确认自动带入的名称
    confirmName (type, saveParams, stepInfo) {
      const self = this
      const NameArr = saveParams.operations.map(item => {return item.name})
      const NameArrIndex =  NameArr.length < 4 ? NameArr.length : 4
      const paramName = this.processArrayItem(NameArr, NameArrIndex)
      let width = 600;
      let content = (
        <ConfrimTaskNameModal
          taskType="flow"
          taskName={paramName}
          callBack={(name) => {
            saveParams.name = name
            self.confirmSave(type, saveParams, stepInfo)
          }}
        />
      );
      this.$confirm({
        width: width,
        title: '流程名称确认',
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    async confirmSave (type, saveParams, stepInfo) {
      const self = this
      if (type === 'save') self.nextLoading = true;
      if (type === 'start') self.nextRunLoading = true
      let url = null
      let res = null
      try {
        if (this.winboxTask.stepInfo.task_id) {
          url = global.API.curdTasks + `${this.winboxTask.stepInfo.task_id}`
          saveParams.task_id = this.winboxTask.stepInfo.task_id
          saveParams.id = this.winboxTask.stepInfo.task_id
          res = await self.$axiosPut(url, saveParams)
        } else {
          url = global.API.curdTasks
          res = await self.$axiosJsonPost(url, saveParams)
        }
        if (type === 'save') self.nextLoading = false;
        if (type === 'start') self.nextRunLoading = false
        if ([200, 201, 204, 202].includes(res.status)) {
          stepInfo.task_id = res.data.task_id
          if (!self.winboxTask.stepInfo.task_id) {
            self.setIsCreateFlowFlag(true)
            self.setIsCreateFlowFlag({id: this.currentWinboxKey, value: true});
            self.setStepInfo(stepInfo);
            self.setWinboxStepInfo({id: this.currentWinboxKey, value: { ...this.winboxTask.stepInfo, name: values.taskName }});
          } else {
            self.setIsCreateFlowFlag(false)
            self.setIsCreateFlowFlag({id: this.currentWinboxKey, value: false});
          }
          if (type === "start") {
            self.runTask();
            return false;
          }
          self.$message.success("保存成功");
        }
      } catch (error) {
        // 捕捉异常
        self.$message.error("操作失败");
        if (type === 'save') this.nextLoading = false;
        if (type === 'start') this.nextRunLoading = false;
      }
    },
    async save (type) {
      const definition = window[this.currentWinboxKey].designer.getDefinition()
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo)
      const saveParams = this.handlerParms(definition, stepInfo)
      if (!saveParams.name) {
        this.confirmName(type, saveParams, stepInfo)
      } else {
        this.confirmSave(type, saveParams, stepInfo)
      }
    },
    deepObj(tgr, rs) {
      for (let i = 0; i < tgr.length; i++) {
        const new_branches = [];
        const item = tgr[i];
        const new_item = item.properties;
        new_item['branches'] = {};
        new_item['model'] = {
          ...new_item['model'],
          nodeObj: {
            ...item
          }
        }
        if (['switch', 'container'].includes(item.componentType)) {
          new_item['id'] = item.type
          new_item['type'] = 'serverless'
          new_item['op_type'] = '0'
          new_item['form_type'] = '1'
          new_item['application_id'] = 'system'
        }
        delete new_item['model']['nodeObj'].branches
        delete new_item['model']['nodeObj'].properties
        rs.push(new_item);
        const branches = Object.values(tgr[i].branches || {});
        const keys = Object.keys(tgr[i].branches || {});
        if (branches.length) {
          for (let index = 0; index < branches.length; index++) {
            const new_child_branches = [];
            const child_branches = branches[index];
            if (child_branches.length) {
              this.deepObj(child_branches, new_child_branches);
            }
            new_branches.push(new_child_branches);
          }
        }
        // .....
        keys.forEach((key, ii) => {
          new_item.branches[key] = new_branches[ii]
        })
      }
    },
    handlerParms (definition, task) {
      const operations = []
      this.deepObj(definition.sequence, operations)
      const saveParams = {
        name: task.name,
        configs: task.taskTemplateDetail.configs,
        notify: task.taskTemplateDetail.notify,
        operations,
        description: '',
        type: '3'
      }
      if (task.task_id) saveParams.task_id = task.task_id
      return saveParams
    },
    // 执行流程
    async runTask() {
      this.nextRunLoading = true
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${this.taskId}/start`,
        {}
      );
      this.nextRunLoading = false
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("运行成功");
        this.backFlow()
      }
    },
    submit () {
      if (this.taskId) {
        this.runTask()
      } else {
        this.save('start')
      }
    }
  },
  beforeDestroy() {
    this.$EventBus.$off("handlerMouseFocus");
    this.$EventBus.$off("openLogByStepId");
  },
};
</script>
<style scoped lang='less'>
.pipeline-page {
   overflow-y: hidden;
   overflow-x: hidden;
   background: #fff;
  .flow-back {
    cursor: pointer;
    &-icon {
      font-size: 20px;
      color: #333;
    }
  }
  .task-steps-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #1d202d;
    font-weight: 600;
    padding: 0px 0 0 24px;
    height: 47px;
    padding-right: 16px;
    line-height: 47px;
    white-space: nowrap;
    background: linear-gradient(
      to right,
      rgba(226, 235, 250, 0.4),
      rgba(240, 243, 247, 0.4)
    );
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #dee8f5;
    &-edit {
      cursor: pointer;
    }
    .task-steps-name-edit {
      color: @primary-color;
      font-size: 16px;
    }
  }
  .jus-start {
    justify-content: start;
  }
  .pipeline {
    position: relative;
    display: flex;
    align-items: center;
    height: calc(100% - 48px);
    .flow-design {
      height: 100%;
      flex: 1;
    }
    .right-container {
      position: absolute;
      right: 0px;
      bottom: 0px;
      top: 0px;
      width: 720px;
    }
    .log-editor {
      background: #000;
      padding-right: 0px;
      margin-right: 16px;
      width: 70%;
      height: calc(100% - 0px);
    }
  }
}
</style>
<style lang="less">
  .step-log-drawer {
    .ant-drawer-body {
      max-height: 780px;
      overflow-y: auto;
      padding: 0!important;
    }
  }
</style>