<template>
  <div class="file-manage-page">
    <a-layout
      v-if="hasMaster === 'true'"
      id="realtime-components-layout-home"
    >
      <a-layout id="components-layout-demo">
        <a-layout-sider
          :trigger="null"
          collapsible
          v-show="visible"
          class="tree-sider lf"
          ref="leftTreeRef"
        >
          <GroupTree
            ref="leftTree"
            :masterList="masterList"
            @getFilePath="getFilePath"
            @createServices="createServices"
            @getMaster="getMaster"
          />
      
          <div class="touch-div" ref="moveRef">
            <div
                v-if="visible"
                class='left-icon'
                @click="visible= false"
              >
                <svg-icon
                  style="height:40px;margin-right: 0;"
                  :icon-class="'arrow-left'"
                />
            </div>
          </div>
        </a-layout-sider>
        <a-layout class="rf" ref="rightContainerRef">
          <div
            v-if="!visible"
            class='right-icon'
            @click="visible= true"
          >
            <svg-icon
              style="font-size:40px;margin-right: 0;"
              :icon-class="'arrow-right'"
            />
          </div>
          <a-layout-content :style="{ height: 'calc(100% - 64px)' }">
            <Tables
              v-if="winboxHost['realEditList'].length"
              :value="winboxHost['realEditList']"
              :tabsLength="180"
              :defaultSelectedKeys="winboxHost.showItem"
              @changeTable="changeTable"
              @delAll="delAll"
              @delOption="delOption"
              @clickDelIcon="clickDelIcon"
            >
              <template slot="rightContent">
                <GroupContainer
                  :ref="'rightContentRef'+winboxHost.showItem.key"
                  :file="selectFile"
                  :treeVisible="visible"
                />
              </template>
            </Tables>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <div
      v-else
      style="height: 100%;display: flex; justify-content: center; align-items: center"
    >
      <HostEmpty
        :hasMaster="hasMaster"
        @getHostGroup="getHostGroup"
        @creatMaster="creatMaster"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";

import _ from "lodash";
import Tables from "@/components/commonTabs";
import GroupTree from "./groupTree";
import HostEmpty from '@/components/empty-host/index.vue'
import GroupContainer from './groupContainer.vue'
import AddServces from "./fileModule/servicesManage/index.vue";

export default {
  name: "groupManage",
  // 注册组件
  components: {
    HostEmpty,
    GroupTree,
    GroupContainer,
    Tables
  },
  // 实例数据
  data () {
    return {
      treeSpain: false,
      showPage: false,
      visible: true,
      leftTreeRef: null,
      clientStartX: 0,
      treeLayoutWidth: 0,
      selectFile: {},
      panesList: [],
      masterList: []
    };
  },
  provide () {
    return {
      convertTreeData: this.convertTreeData,
      onSelectFileTree: this.onSelectFileTree
    }
  },
  inject: ["windowEventBus", "windowAppLoaderOptions"],
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage,
      winboxHostModule: (state) => state.winboxHostModule,
    }),
    // 当前winbox的ID
    currentWinboxKey () {
      return this.windowAppLoaderOptions.id
    },
    winboxHost () {
      return this.winboxHostModule[this.currentWinboxKey]
    },
    ...mapGetters("masterMd", ["hasMaster"]),
  },
  watch: {
    'winboxHost': {
      handler (val) {
        // this.panesList = val;
      },
      deep: true,
      immediate: true
    },
  },
  beforeCreate () { },
  created () {
  },
  beforeMount () { },
  mounted () { 
    this.getMaster()
    this.initDrag()
  },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () {
    this.changeRealEditList([]);
    this.changeWinboxRealEditList({id: this.currentWinboxKey, value: []});
    // 清除 Tabs容器里面的 evemtBus
    const refs = this.$refs
    for (let i in refs) {
      if (i.includes('rightContentRef') && refs[i]) {
        if (refs[i].$refs['fileRef'].$refs['rightContentRef']) {
          refs[i].$refs['fileRef'].$refs['rightContentRef'].windowEventBus.$off("onGetProxyFile");
          refs[i].$refs['fileRef'].$refs['rightContentRef'].windowEventBus.$off("changeLoading");
          refs[i].$refs['fileRef'].$refs['rightContentRef'].windowEventBus.$off("onGetFirstTree");
          refs[i].$refs['fileRef'].$refs['rightContentRef'].windowEventBus.$off("onGetEditFile");
          refs[i].$refs['fileRef'].$refs['rightContentRef'].windowEventBus.$off("setFileRequestLoading");
          refs[i].$refs['fileRef'].$refs['rightContentRef'].windowEventBus.$off("onOpenFile");
        }
        refs[i].$refs['fileRef'].windowEventBus.$off("setActiveKey");
        refs[i].$refs['fileRef'].windowEventBus.$off("setActiveFile");
      }
    }
  },
  destroyed () { },
  methods: {
    ...mapActions("fileManage", [
      "changeRealEditList",
    ]),
    ...mapActions("winboxHostModule", [
      "changeWinboxRealEditList",
    ]),
    ...mapActions("masterMd", ["setHasMaster"]),
    initDrag () {
      this.leftTreeRef = this.$refs.leftTreeRef.$el;
      // 去除原始的样式 会影响onmousemov事件产生的效果
      this.leftTreeRef.style.flex = "initial";
      this.leftTreeRef.style.maxWidth = "initial";
      this.leftTreeRef.style.minWidth = "initial";
      const moveRef = this.$refs.moveRef;
      moveRef.onmousedown = e => {
        this.clientStartX = e.clientX;
        e.preventDefault();
        document.onmousemove = e => {
          this.moveHandle(e.clientX, this.leftTreeRef);
        };
        document.onmouseup = () => {
          document.onmouseup = null;
          document.onmousemove = null;
        };
      };
    },
    moveHandle(x, ref) {
      const computedX = x - this.clientStartX;
      const leftWidth = parseInt(ref.style.width);
      let changeWidth = leftWidth + computedX;
      this.treeLayoutWidth = changeWidth - 200 > 0 ? changeWidth - 200 : 0;
      console.log(changeWidth, 'changeWidthchangeWidthchangeWidth')
      if (changeWidth < 280) changeWidth = 280;
      if (changeWidth > 1200) changeWidth = 1200;
      // let rightContainerRef = this.$refs.leftTreeRef.$el
      ref.style.width = changeWidth + "px";
      this.clientStartX = x;
    },
    creatMaster (val) {
      this.showPage = true;
    },
    fileRefSetFile (key, type) {
      const refs = this.$refs
      this.$nextTick(() => {
        if (refs[`rightContentRef${key.key}`].$refs.fileRef) refs[`rightContentRef${key.key}`].$refs.fileRef.activeKey = 'file'
        this.$forceUpdate()
      })
    },
    createServices () {
      const self = this;
      let width = 646;
      let title = '新增服务';
      let content = (
        <AddServces detail={{}} callBack={() => self.getFileList()} />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    getFileList () {
      this.$refs.leftTree.convertTreeData('add')
    },
    onSelectFileTree (key, node, fromTo) {
      this.$refs.leftTree.onSelectFileTree(key, node, fromTo)
    },
    convertTreeData () {
      this.$refs.leftTree.convertTreeData('add', this.$refs.leftTree.selectedKeys)
    },
    getFilePath (node) {
      this.selectFile = node
    },
    getMaster () {
      this.$refs.leftTree.treeSpain = true;
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        this.$refs.leftTree.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          const arr = res.data.results.filter(item => item.status === 'online');
          this.masterList = arr
          if (arr.length > 0) {
            this.setHasMaster(true);
          } else {
            this.setHasMaster(false);
          }
        }
      });
    },
    getHostGroup () {
      this.treeSpain = true;
      const params = {
        keyword: '',
        page_size: 1000,
      };
      this.$axiosGet(global.API.getHostList, params).then((res) => {
        this.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results && res.data.results.length > 0) {
            this.showPage = true
          } else {
            this.showPage = false
          }
        }
      });
    },
    confirm () {
      this.$refs.commondContent.confirm();
    },
    changeTable (e) {
      this.$refs.leftTree.changeTable(e);
    },
    clickDelIcon (e) {
      if (e.e) e.e.stopPropagation();
      this.$refs.leftTree.clickDelIcon(e);
    },
    delAll () {
      this.$refs.leftTree.delAll();
    },
    delOption (index, type) {
      this.$refs.leftTree.delOption(index, type);
    },
  },
  filters: {},
};
</script>
<style lang="less">
.popover-index {
  .more-menu-btn:hover {
    background: #f4f5f7;
    color: #0264c8;
  }
  .ant-popover-inner-content {
    padding: 12px 0;
  }
}
.file-manage-page {
  min-width: 1000px;
  height: 100%;
  min-height: 500px;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  .dot {
    position: relative;
    top: -2px;
    margin-right: 4px;
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
  }
  .sysDevStyle {
    height: calc(100% - 35px) !important;
    overflow-y: scroll;
  }
  .ant-tree-node-selected {
    color: rgba(0, 0, 0, 0.65) !important;
    background: none !important;
  }
  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background: none;
  }
  .hide-point {
    display: none;
  }
  .ant-tree-child-tree-open,
  .ant-tree-node-content-wrapper {
    li:not(.ant-tree-treenode-switcher-open):hover {
      background: #e6e8ed;
    }
    .hide-point {
      display: block;
    }
  }
  .ant-popover-inner-content {
    padding: 6px 0px !important;

    .ant-btn:hover,
    .ant-btn:focus {
      background-color: #f0faff;
    }
  }
  #realtime-components-layout-home {
    height: 100%;
    .ant-menu-item-selected {
      background: #2872e0;
      color: #fff;
    }
    .title-content {
      position: absolute;
      top: 8px;
      left: 20px;
      font-size: 16px;
      color: #1d202d;
      font-weight: 600;
    }
  }

  .ant-menu-item a {
    display: inline-block;
  }
  .tree-sider {
    min-width: 282px !important;
    .tree-sider-title {
      display: flex;
      padding: 8px 12px 0 12px;
    }
    .sider-header-button {
      padding: 16px 8px 5px 0;
      .ant-input-search {
        margin-left: 16px;
        margin-right: 16px;
        .ant-input-affix-wrapper .ant-input-suffix {
          right: 6px;
        }
      }
      .ant-input-affix-wrapper {
        margin-left: 12px;
        margin-right: 4px;
        margin-left: 24px;
      }
      .ant-input {
        border-radius: 2px;
        margin: 0;
        height: 30px;
        line-height: 30px;
        padding-left: 8px;
      }
      .sider-header-btn {
        box-shadow: none;
      }
    }
  }
  .lf {
    position: relative;
    top: 0;
    flex:initial !important;
    max-width:initial!important;
    height: 100%;
    background: #fff;
    border-right: 1px solid rgba(209, 210, 215, 1);
    .left-icon {
      position: absolute;
      right: 2px;
      top: 43%;
      cursor: pointer;
      z-index: 100;
    }
    .ant-tree li ul li {
      padding: 5px 0 5px 16px;
      border-bottom: 1px solid #f6f7fb;
    }
    .ant-tree li ul:not(.ant-tree-child-tree-open) {
      padding: 5px 0 5px 16px;
    }
    .ant-tree-child-tree-open {
      padding: 0;
    }
    .left-tree-container {
      max-height: calc(100% - 55px) !important;
      height: calc(100% - 55px) !important;
      overflow-y: auto;
      margin-left: 14px;
      margin-right: 12px;
    }
  }
  .rf {
    flex: 1;
    overflow: auto;
    height: 100%;
    margin-top: 0;
    background: #fff;
    min-width: 740px;
    .right-icon {
      position: absolute;
      left: -15px;
      z-index: 100;
      top: 43%;
      cursor: pointer;
    }
  }
  // 左边树定制滚动条
  .lf {
    ::-webkit-scrollbar {
      background: #f7f8fa;
    }
   .touch-div {
      position: absolute;
      top: 32px;
      height: calc(100% - 32px);
      right: -5px;
      width: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: col-resize;
    }
  }
  .ant-tabs .ant-tabs-top-content {
    height: calc(100% - 32px);
    .ant-tabs-tabpane-active {
      height: 100%;
    }
  }
}
</style>
    