
<!--
 * @Description: 
 * @Date: 2024-07-25 11:07:32
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-20 17:31:40
-->
<template>
  <div class='wallpaper pt16'>
    <div class="tab bg-color-white">
      <div
        v-for="(item, index) in wallpaperTab"
        :key="item.id"
        :class="['text-color-t2', globalSetting?.wallpaperTab === item.id && 'tab-selected']"
        @click="selectCatatory(item.id)"
      >{{ item.name }}</div>
    </div>
    <component :is='globalSetting?.wallpaperTab' />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import photos from './photos.vue'
import gradient from './gradient.vue'
import dynamic from './dynamic.vue'
import store from '@/store/index'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    photos,
    gradient,
    dynamic
  },
  data () {
    // 这里存放数据
    return {
      wallpaperTab: [
        {
          id: 'photos',
          name: '精选图片'
        },
        {
          id: 'gradient',
          name: '渐变背景'
        },
        {
          id: 'dynamic',
          name: '动态图片'
        },
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeDestroy () { }, // 生命周期 - 销毁之前
  // 方法集合
  methods: {
    selectCatatory (key) {
      store.commit("globalSetting/setWallpaperTab", key)
    },
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.wallpaper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tab {
  // height: 32px;
  margin: 0 auto 16px;
  width: 396px;
  border-radius: 7px;
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: space-around;
  div {
    height: 32px;
    width: 129px;
    text-align: center;
    line-height: 32px;
    border-radius: 6px;
    cursor: pointer;
  }
  &-selected {
    background: rgb(var(--color-m2));
    color: rgb(var(--color-m1));
  }
}
.dark {
  .tab {
    --tw-bg-opacity: 0.2;
  }
}
.light {
  .tab {
    box-shadow: 0 8px 12px -4px #0000001a;
  }
}
</style>
<style>
::-webkit-scrollbar {
  background: transparent!important;;
}
</style>