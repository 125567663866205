import config from '@/config/config';
import Vue from 'vue';

const getDefaultState = () => ({
  taskIndexShow: 'list',
  flowIndexShow: 'list',
  hostGroup: [],
  hostList: [],
  taskInfo: {},
  stepInfo: {
    name: '',
    task_id: '',
    template_id: '',
    taskTemplateDetail: {
      configs: [],
      notify: [],
      operations: [],
      stepInfo: {
        properties: {
          currentStepIndex: 0
        }
      }
    }
  },
  selectStepId: '',
  selectTemplateIndex: null, // 当前选中步骤的index
  addTemplateIndex: -1, // 要追加步骤的index
  showStepsList: true, // 是否展示步骤去选择
  currentOptIndex: 0, // 0 选择应用 1 选择操作 2配置账号 3 配置参数 // 这个是步骤里面操作
  hasChange: false,
  currentPageIndex: -1, // 0 选择应用 1 选择操作 2配置账号 3 配置参数 当前页面操作
  refreshFlag: 0,
  firstEdit: false,
  jumpTaskDetail: null,
  isCreateFlowFlag: false,
});

const state = {
};

const getters = {};

const mutations = {
  initializeModule(state, id) {
    if (!state[id]) {
      Vue.set(state, id, getDefaultState());
    }
  },
  setTaskState(state, { id, key, value }) {
    if (!state[id]) {
      // 如果任务不存在，则初始化它
      Vue.set(state, id, getDefaultState());
    }
    Vue.set(state[id], key, value);
  },
  setStateValue(state, { id, key, value }) {
    if (state[id]) {
      Vue.set(state[id], key, value);
    }
  },
  destroyModule(state, id) {
    if (state[id]) {
      delete state[id];
    }
  },
};

const actions = {
  initializeModule({ commit, state }, id) {
    if (state['id']) return false
    commit('initializeModule', id);
  },
  destroyModule({ commit }, id) {
    commit('destroyModule', id);
  },
  setWinboxTaskInfo({ commit, state }, { id, value }) {
    commit('setTaskState', { id, key: 'taskInfo', value: value });
    if (value && !value.hasChange) {
      commit('setTaskState', { id, key: 'hasChange', value: true });
    } else {
      commit('setTaskState', { id, key: 'hasChange', value: false });
    }
  },
  setWinboxTaskIndexShow({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'taskIndexShow', value: value });
  },
  setWinboxFlowIndexShow({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'flowIndexShow', value: value });
  },
  setWinboxSelectTemplateIndex({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'selectTemplateIndex', value: value });
  },
  setWinboxRefreshFlag({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'refreshFlag', value: value });
  },
  setWinboxStepInfo({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'stepInfo', value: value });
  },
  setWinboxCurrentPageIndex({ commit, state }, { id, value }) {
    if ((state.tasks[id].selectTemplateIndex > 0 && state.tasks[id].selectTemplateIndex !== null) || state.tasks[id].selectTemplateIndex === 0) {
      let taskInfo = _.cloneDeep(state.tasks[id].taskInfo);
      taskInfo.taskTemplateDetail.operations[state.tasks[id].selectTemplateIndex]['currentStepsIndex'] = value;
      commit('setTaskState', { id, key: 'taskInfo', value: taskInfo });
    }
    commit('setTaskState', { id, key: 'currentPageIndex', value: value });
  },
  setWinboxIsCreateFlowFlag({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'isCreateFlowFlag', value: value });
  },
  setWinboxSelectStepId({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'selectStepId', value: value });
  },
  setWinboxJumpTaskDetail({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'jumpTaskDetail', value: value });
  },
  setWinboxCurrentOptIndex({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'currentOptIndex', value: value });
  },
  setWinboxAddTemplateIndex({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'addTemplateIndex', value: value });
  },
  changeWinboxFirstEdit({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'firstEdit', value: value });
  },
  setWinboxShowStepsList({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'showStepsList', value: value });
  },
  setWinboxHostGroup({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'hostGroup', value: value });
  },
  setWinboxHostList({ commit }, { id, value }) {
    commit('setTaskState', { id, key: 'hostList', value: value });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
