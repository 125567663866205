/*
 * @Description: 
 * @Date: 2024-08-16 11:26:25
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-08-16 14:19:16
 */
// 在 main.js 或者其他适当的位置定义自定义指令
export default {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el, binding) {
    // 判断元素是否有垂直滚动条
    const hasScrollbar = el.scrollHeight > el.clientHeight;
    // 将结果绑定到指令的值上
    binding.value = hasScrollbar;
  }
};