
<!--
 * @Description: 
 * @Date: 2024-07-25 14:30:01
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 10:35:16
-->
<template>
  <div class="basic-setting flex flex-col">
    <div class="hi-scroll ph70 pv40">
      <div v-if="user && user.username" class="hi-cell-group border-color-m2 border-opacity-008 border-color-m2 border-opacity-008 bdnone pdb20 mgb20">
        <div class="font-ali-75 text-16 text-color-t1">个人信息</div>
        <div class="pt16">
           <div class="hi-cell mgb20">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">用户名</span>
              <div class="cursor-pointer w180 text-right text-color-t3">
                {{ user.username }}
              </div>
            </div>
          </div>
           <div class="hi-cell mgb20">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">账户密码</span>
              <div class="cursor-pointer w180 text-right text-color-t3" @click="editPassword">
                 <span class="inline-block t2" v-if="!userInfo.no_password">*******</span>
                 <span class="inline-block t2" v-if="userInfo.no_password">尚未设置</span>
                 <a-icon class="text-12 mgl4 text-color-t2" type="right" />
              </div>
            </div>
          </div>
          <div class="hi-cell mgb20">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">手机</span>
              <div class="cursor-pointer w180 text-right text-color-t3 flex items-center justify-end" @click="updatePhone">
                <div v-if="userInfo.phone" class="flex">
                  {{ formatMobile(userInfo.phone) }}
                </div>
                <div v-if="!userInfo.phone" class="flex">
                  尚未绑定
                </div>
                <a-icon class="text-12 text-color-t2 mgl4" type="right" />
              </div>
            </div>
          </div>
          <div class="hi-cell">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">邮箱</span>
              <div class="cursor-pointer w180 text-right text-color-t3 flex items-center justify-end" @click="updateEmail">
                <div v-if="userInfo.email" class="flex">
                  <div class="ell" style="max-width: 160px" :title=userInfo.email>{{ userInfo.email }}</div>
                </div>
                <div v-if="!userInfo.email" class="flex">
                  尚未绑定
                </div>
                <a-icon class="text-12 text-color-t2 mgl4" type="right" />
              </div>
            </div>
          </div>
          <div class="logout text-14 text-color-t2 mgt40 w-full h36" @click="logout">
            退出登录
          </div>
        </div>
      </div>
      <div v-else>
        <div class="hi-cell-group border-color-m2 border-opacity-008 mgb20">
          <div class="flex items-center justify-between cursor-pointer" @click="openLoginModal">
            <svg-icon class="text-color-t4" style="font-size: 40px" icon-class="account-default"></svg-icon>
            <span class="flex-1 text-18 text-color-t1">登录/注册</span>
            <div class="cursor-pointer w180 text-right" >
              <a-icon class="text-12 text-color-t1" type="right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import store from '@/store/index'
import axios from "axios";
import updatePasswordVue from '@/components/accountSetting/updatePassword.vue';
import updateEmialVue from '@/components/accountSetting/updateEmail.vue' 
import updatePhoneVue from '@/components/accountSetting/updatePhone.vue' 
import { mapActions } from 'vuex';
import exitLogin from './exitLogin.vue'
import default_setting from '@/store/modules/defaultGlobalSetting'
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
  },
  data () {
    // 这里存放数据
    // https://api.wetab.link/api/wallpaper/list?client=pc&tag=animals
    return {
      confirmPromise: null
    }
  },
  inject: ['toLogin'],
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
    displayModeText () {
      const obj = this.globalSetting.basic.controlBar.sideBar.displayModeDict.find(item => item.value === this.globalSetting.basic.controlBar.sideBar.displayMode)
     return obj?.label || ''
    },
    user () {
      const user = store.state.account.user
      this.userInfo = user
      return user
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.getPhotosBytag()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    openLoginModal () {
      store.commit("account/setShowLogin", true)
    },
    formatMobile(mobile) {
      if (mobile && mobile.length === 11) {
        return mobile.slice(0, 3) + '****' + mobile.slice(7);
      }
      return mobile;
    },
    closeConfirm () {
      if (this.confirmPromise) {
        this.confirmPromise.destroy();
      }
    },
    destroyWinbox () {
      const stateWindow = store.state.windowStore.windowStore
      for (var i in stateWindow) {
        // stateWindow[i]['window']?.window.remove()
        stateWindow[i]['window'].close()
        // store.dispatch('winboxHostModule/destroyModule', i)
        // store.dispatch('winboxFlowModule/destroyModule', i)
      }
      // store.dispatch('windowStore/setWindowStore', {})
    },
    logout () {
      const self = this;
      let width = 400;
      const className = ["exit-login-modal", this.globalSetting.theme.mode]
      let content = <exitLogin ref="exitLoginRef" closeConfirm={self.closeConfirm} callBack={(isClear) => self.confirmLogout(isClear)} />
      this.confirmPromise = this.$confirm({
        width: width,
        title: '',
        content: content,
        class: className,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    confirmLogout(isClear) {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const self = this;
      axios.post(global.API.loginOut, {}, config).then(function (res) {
        if ([200, 201, 204, 202].includes(res.status)) {
          self.loginOutFlag = true
          self.removeItem()
          if (isClear) {
            localStorage.removeItem('globalSetting')
            console.log(default_setting, 'default_setting')
            store.commit('globalSetting/SET_SETTINGS', default_setting);
          }
          self.closeConfirm()
        }
      });
    },
    removeItem () {
      store.commit('account/setUser', { username: "", isSuperuser: false });
      store.commit("setting/setCurrentTab", '1');
      store.dispatch("fileManage/setClusterId", '');
      localStorage.removeItem('CLUSTER_ID')
      localStorage.removeItem('FILE_SYSTEM_URL')
      localStorage.removeItem('hasMaster')
      localStorage.removeItem('admin.user')
      localStorage.removeItem('token')
      localStorage.removeItem('masterOptions')
      localStorage.removeItem('historyWinboxSize')
    },
    handleChange (val) {
      const basic = this.globalSetting.basic
      basic.controlBar.sideBar.displayMode = val
      store.commit("globalSetting/setBasic", basic)
    },
    getUserInfo () {
      const self = this;
      this.$axiosGet(global.API.getUserInfo, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) { 
          const username = res.data.username
          const isSuperuser = res.data.is_superuser
          store.commit('account/setUser', {...res.data, username, isSuperuser });
          this.userInfo = {...res.data, username, isSuperuser }
          this.$forceUpdate()
        }
      })
    },
    // get
    updateEmail() {
      let type = this.userInfo.email ? 'editEmail' : 'newEmail'
      const self = this;
      let width = 520;
      let title = type.includes('new') ? '绑定' : '修改'
      let content = (
        <updateEmialVue
          detail={this.userInfo}
          editType={type}
          closeConfirm={self.closeConfirm}
          callBack={() => self.getUserInfo()}
        />
      );
      this.confirmPromise = this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
        },
        icon: () => {
          return <div />;
        },
      });
    },
    updatePhone () {
      let type = this.userInfo.phone ? 'editPhone' : 'newPhone'
      const self = this;
      let width = 520;
      let title = type.includes('new') ? '绑定' : '修改'
      let content = (
        <updatePhoneVue
          detail={this.userInfo}
          editType={type}
          closeConfirm={self.closeConfirm}
          callBack={() => self.getUserInfo()}
        />
      );
      this.confirmPromise = this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
        },
        icon: () => {
          return <div />;
        },
      });
    },
    // 修改密码
    editPassword () {
      const self = this;
      let width = 520;
      let title = '修改密码';
      let content = (
        <updatePasswordVue detail={this.userInfo}  
          closeConfirm={self.closeConfirm}
          callBack={() => self.getUserInfo()} />
      );
      this.confirmPromise = this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {},
        icon: () => {
          return <div />;
        },
      });
    },
  }
}
</script>

<style lang='less' scoped>
.h108 {
  height: 108px;
}
.h36 {
  height: 36px;
}
.basic-setting {
  height: calc(100% - 0px);
  border-radius: 12px;
  z-index: 10;
}
.logout {
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  cursor: pointer;
  border: 1px solid rgb(var(--color-b2));
}
.bdnone {
  border-bottom: none;
}
.t2 {
  position: relative;
  top: 2px;
}
</style>
<style lang="less">
  .exit-login-modal {
    .ant-modal-body .ant-modal-confirm-title, .ant-modal-close {
      display: none;
    }
    .ant-modal-confirm-body .ant-modal-confirm-content {
      border-radius: 16px;
      border-top: none!important;
    }
    .ant-modal-content {
      border-radius: 16px!important;
      background: transparent;
      border-radius: 16px;
    }
    .ant-modal-mask {
      background: rgba(0, 0, 0, 0.6);
    }
  }
</style>