<!--
 * @Description: 
 * @Date: 2024-06-24 17:25:55
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-26 15:12:50
-->
<template>
  <div
    ref="windowContainer"
    class="window-container"
  >
  </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store/index'
import {initRouter} from '@/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { getConnection, removeConnection } from '@/utils/axios-peer'

import { cancelUpload } from '@/utils/uploadChannel'
import { cancelDownload } from '@/utils/downloadChannel'

Vue.use(ElementUI);
function createEventBus() {
  return new Vue();
}
// 动态设置 WinBox 窗口的 CSS 变量
function setWinBoxAdaptiveScale(winbox) {
  const baseWidth = 1700;
  const winboxWidth = winbox.body.clientWidth > 1920 ? 1920 : winbox.body.clientWidth < 1000 ? 1000 : winbox.body.clientWidth;
  const scale = winboxWidth / baseWidth;
  winbox.body.style.setProperty('--adaptive-scale', scale);
}

// 监听窗口大小变化
function handleWinBoxResize(winbox) {
  // winbox.onresize = () => {
  //   setWinBoxAdaptiveScale(winbox);
  // };
}

const router = initRouter(store.state.setting.asyncRoutes)
export default {
  name: "GlobalWindow",
  data () {
    return {
      windowId: null,
      components: null,
      propsData: {},
      windowOptions: {},
      appLoaderOptions: {}
    };
  },
  methods: {
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name){
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    windowBlur () {
      let windowStore = _.cloneDeep(store.state.windowStore.windowStore)
      for (var i in windowStore) {
        windowStore[i].window.focused = true
        windowStore[i].window.blur()
      }
    },
    getClusterName () {
      const masterList = store.state.setting.masterOptionsVuex
      const obj = masterList.find (item => item.id === localStorage.getItem('CLUSTER_ID'))
      return obj ? obj.name : ''
    },
    async showWindow () {
      this.windowBlur()
      const menuData = store.state.setting.menuData
      const self = this
      let windowStore = _.cloneDeep(store.state.windowStore.windowStore)
      const localAppLoaderOptions = _.cloneDeep(self.appLoaderOptions); // 使用局部变量隔离
      let windowId = this.windowId
      if (windowStore && windowStore[windowId] && windowStore[windowId]?.window) {
        if (windowStore[windowId]?.window.min) {
          windowStore[windowId].window.restore().focus()
        } else {
          windowStore[windowId].window.focus()
        }
        return false
      }
      store.dispatch('winboxHostModule/initializeModule', windowId)
      store.dispatch('winboxFlowModule/initializeModule', windowId)
      const componentName =localAppLoaderOptions.componentName;
      const component = self.components[componentName];
      if (!component) {
        console.error(`Component ${componentName} not found.`);
        return;
      }
      try {
        // network-skill-icons:c
        const resolvedComponent = await component(); // 异步加载组件

        // 创建组件的构造函数
        const ComponentConstructor = Vue.extend(resolvedComponent.default);
        // 创建组件实例
        const a = {
          ...self.propsData,
          appLoaderOptions: self.appLoaderOptions
        }
        const componentInstance = new ComponentConstructor({
          store,
          router,
          provide: {
            windowEventBus: createEventBus()
          },
          propsData: { 
            ...self.propsData,
            appLoaderOptions: self.appLoaderOptions,
           },
        });
        // 创建并挂载 SvgIcon 组件
        const IconComponent = Vue.extend({
          render(h) {
            return h('svg-icon', {
              props: { iconClass: localAppLoaderOptions.url } // 替换为你的 SVG 图标名称
            });
          }
        });
        // 创建并挂载 在线图标 组件
        const NetWorkIconComponent = Vue.extend({
          render(h) {
            return h('span', {
              style: {
                background: '#fff',
                textAlign: 'center',
                width: '22px',
                height: '22px',
                borderRadius: '4px',
                marginRight: '8px',
                lineHeight: '28px',
                position: 'relative',
                top: '-1px'
              }
            }, [
              h('iconify-icon', {
                style: {},
                 attrs: {
                  icon: self.iconNetWorkName(localAppLoaderOptions.url)
                }
              })
            ]);
          }
        });

        // 创建并挂载 纯色图标 组件
        const ColorIconComponent = Vue.extend({
          render(h) {
           // 动态样式
            const iconStyle = {
              background: localAppLoaderOptions.url,
              textAlign: 'center',
              width: '22px',
              height: '22px',
              borderRadius: '4px',
              marginRight: '8px',
              position: 'relative',
              top: '-1px'
            };
            // 动态字体样式
            const textStyle = {
              color: '#fff',
              fontSize: '10px',
              width: '100%',
              display: 'block',
              height: '100%',
              lineHeight: '22px'
            };
            // 渲染函数返回的虚拟 DOM 结构
            return h('span', {
              style: iconStyle
            }, [
              h('span', {
                style: textStyle
              }, self.iconColorName(localAppLoaderOptions.name))
            ]);
          }
        })
        // 创建并挂载 img 组件
        const ImageComponent = Vue.extend({
          render(h) {
            return h('img', {
              attrs: {
                src: localAppLoaderOptions.url, // 替换为你的图片路径
                alt: 'Icon',
                class: 'icon'
              }
            });
          }
        });
        let SideMenuComponent = null
        if (localAppLoaderOptions.componentName !== 'FilePreview' &&  localAppLoaderOptions?.[localAppLoaderOptions.fileCompontentType]?.isFileGroup) {
          SideMenuComponent =  Vue.extend({
          render(h) {
            return h('side-menu', {
              props: { menuData: menuData, windowAppLoaderOptions: localAppLoaderOptions } // 替换为你的 SVG 图标名称
            });
          }
        });
        }
        // SideMenu
        // 手动挂载组件实例fpropsData
        componentInstance.$mount();
        let x = 'center' 
        let y = 'center'
        for (var i in windowStore) {
          x = windowStore[i]?.window?.x + 20 || 'center'
          y = windowStore[i]?.window?.y + 20 || 'center'
        }
        windowStore[windowId] = {...this.appLoaderOptions}
        // todo: 这个地方还需要优化
        // windowStore[windowId]['instance'] = self
        // 使用 WinBox 打开窗口
        const historyWinboxSize =  self.$store.state.windowStore.historyWinboxSize
        let winboxWidth = self.$store.state.globalSetting.desktopSize?.width || 1200
        let winboxHeight = self.$store.state.globalSetting.desktopSize?.height || 764
        if (windowStore[windowId]?.componentName === "FilePreview" && windowStore[windowId].name.endsWith('.mp3')) {
          winboxWidth = 442
          winboxHeight = 231
          x = 'center'
          y = 'center'
        }
        if (historyWinboxSize && historyWinboxSize[windowId]) {
          winboxWidth = historyWinboxSize[windowId].width
          winboxHeight = historyWinboxSize[windowId].height
          x = historyWinboxSize[windowId].x
          y = historyWinboxSize[windowId].y
        }
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        if (screenWidth < winboxWidth || screenWidth  < x + winboxWidth)  {
          winboxWidth = screenWidth - 30
          x = 'center'
          y = 'center'
        }
        if(screenHeight < winboxHeight || screenHeight  < y + winboxHeight)  {
          winboxHeight = screenHeight
          x = 'center'
          y ='center'
        }
        if (x < 0 || y < 0) { 
          x = 'center'
          y ='center'
        }
        windowStore[windowId]['window'] = new WinBox({
          id: windowId,
          x: x,
          y: y,
          width: winboxWidth,
          height: winboxHeight,
          ...self.windowOptions,
          title: false,
          mount: componentInstance.$el,
          class: 'winbox-custom',
          resizable: true,
          onclose: function () {
            // this 代表的是当前winbox窗口实例
            // self 代表的是当前vue实例
            const stateWindow = self.$store.state.windowStore.windowStore
            stateWindow[this.id]['window'] = null
            delete stateWindow[this.id]
            store.dispatch('windowStore/setWindowStore', stateWindow)
            store.dispatch('winboxHostModule/destroyModule', this.id)
            store.dispatch('winboxFlowModule/destroyModule', this.id)
            componentInstance.$destroy(); // 关闭时销毁实例
            // 关闭窗口的时候如果存在 上传下载的通道需要关闭掉
            self.onCloseChannel(self?.appLoaderOptions, this)
          },
          oncreate: function () {
            const titleContainer = this.window.querySelector('.wb-title');
            const titleContainerLeft = document.createElement('div')
            titleContainerLeft.className = 'wb-title-left';
            let iconInstance = null
            if (localAppLoaderOptions.componentName === 'FilePreview') {
              iconInstance = new IconComponent().$mount()
            } else if (localAppLoaderOptions.componentName === 'ExternalComponent') {
              if (self.IsColorIcon(localAppLoaderOptions.url) && !self.IsNetWorkIcon(localAppLoaderOptions.url)) {
                iconInstance = new ColorIconComponent().$mount()
              } else if (!self.IsColorIcon(localAppLoaderOptions.url) && self.IsNetWorkIcon(localAppLoaderOptions.url)) {
                iconInstance = new NetWorkIconComponent().$mount()
              } else {
                iconInstance = new ImageComponent().$mount()
              }
            } else {
              iconInstance = new ImageComponent().$mount()
            }
            let SideMenuComponentInstance = null
            const titleText = document.createElement('span');
            titleText.textContent = localAppLoaderOptions.name
            titleContainerLeft.appendChild(iconInstance.$el);
            titleContainerLeft.appendChild(titleText);
            if (this.id === 'awx') {
              const clusterText = document.createElement('span');
              clusterText.className = 'winbox-cluster-name'
              clusterText.textContent = '（' + self.getClusterName() + '）'
              titleContainerLeft.appendChild(clusterText);
            }
            if (localAppLoaderOptions.componentName !== 'FilePreview' &&  localAppLoaderOptions?.[localAppLoaderOptions.fileCompontentType]?.isFileGroup) {
              SideMenuComponentInstance = new SideMenuComponent().$mount()
              titleContainerLeft.appendChild(SideMenuComponentInstance.$el);
            }
            titleContainer.appendChild(titleContainerLeft);
            setWinBoxAdaptiveScale(this)
            this.focused = true
          },
          onresize: function () {
            setWinBoxAdaptiveScale(this);
            self.throttleUpdateWindowState(this, windowId, 'RESIZE')
          },
          onmove: function () {
            const windowSelf = this
            setTimeout(() => {
              setWinBoxAdaptiveScale(windowSelf);
            }, 0)
            self.throttleUpdateWindowState(this, windowId, 'MOVE')
          },
          onrestore: function () {
            //  self.throttleUpdateWindowState(this, windowId, 'RESTORE')
          },
          onminimize: function () {
            self.throttleUpdateWindowState(this, windowId, 'MIN')
          },
          onmaximize: function () {
            const windowSelf = this
            self.throttleUpdateWindowState(this, windowId, 'MAX')
          },
          onfocus: function() {
             this.setBackground('#eceff5'); // 激活时设置头部颜色为蓝色
             self.throttleUpdateWindowState(this, windowId, 'FOCUS', this)
          },
          onblur: function() {
            this.setBackground('#f1f1f2'); // 失焦时设置头部颜色为默认颜色（可根据需求更改）
          }
        });
        store.dispatch('windowStore/setWindowStore', windowStore)
      } catch (error) {
        console.error(`Failed to load component ${componentName}:`, error);
      }
    },
    onCloseChannel(appLoaderOptions, window) {
      const clusterId  = this.propsData?.propData?.clusterId ? this.propsData?.propData?.clusterId :localStorage.getItem('CLUSTER_ID') ? localStorage.getItem('CLUSTER_ID') : ''
      console.log('关闭的clusterId', clusterId)
      if (!clusterId) return false
      if (appLoaderOptions.componentName === 'FilePreview') { 
        const previewFile = getConnection(clusterId, window.id)
        if (previewFile) previewFile.close()
        removeConnection(clusterId, window.id)
        return false
      }
      if (['resource', 'awx'].includes(window.id))  {
        const uploadFileList = store.state.fileManage.uploadFileList
        cancelUpload()
        uploadFileList.map(item => {
          const connFile = getConnection(item.clusterId, item.name)
          if (connFile) connFile.close()  
          removeConnection(item.clusterId, item.name)
        })
        const downFileList = store.state.fileManage.downloadFileList
        cancelDownload()
        downFileList.map(item => {
          const conDown = getConnection(item.clusterId, item.name)
          if (conDown) conDown.close()  
          removeConnection(item.clusterId, item.name)
        })
        store.dispatch('fileManage/changeShowFileProgress', false)
        store.dispatch('fileManage/changeUploadFileList', [])
        store.dispatch('fileManage/changeDownloadFileList', [])
      }
    },
    throttleUpdateWindowState: _.debounce(function(window, windowId, TYPE) {
      let windowStoreOld = _.cloneDeep(store.state.windowStore.windowStore)
      if (windowId && window) {
        windowStoreOld[windowId].window = window 
        if (TYPE === 'FOCUS') {
          windowStoreOld[windowId].window.min = false
        }
        if (TYPE === 'MIN') {
          windowStoreOld[windowId].window.min = true
        }
        store.dispatch('windowStore/setWindowStore', windowStoreOld)
      }
    }, 500)
  },
  mounted () {
  },
  beforeDestroy () {
    if (this.$winbox) {
      this.$winbox.close();
    }
  }
};
</script>

<style scoped lang="less">
.window-container {
  display: none;
}
.custom-header {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}
</style>
<style lang="less">
.winbox-custom {
  box-shadow: 0px 6px 30px 0px rgba(0,0,0,0.3);
  background: rgba(241,241,242, 0.9);
  backdrop-filter: blur(100px); /* 高斯模糊效果 */
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 10px;
  .wb-body {
    background: transparent;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .wb-title-left {
    display: flex;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      position: relative;
      top: -1px;
    }
    span {
     .convert-size(font-size, 14px);
      color: #4D4D4D;
      font-weight: 600;
    }
    .svg-icon {
      font-size: 32px;
    }
  }
  &.min {
    display: none;
  }
  .wb-min {
    position: relative;
    left: 22px;
    background-position: center;
    background-size: 16px auto;
    background-image: url("~@/assets/img/desktop/desktop-min.svg");
  }
  .wb-control {
    padding-right: 10px;
    &:hover {
      .wb-min {
        background-image: url("~@/assets/img/desktop/desktop-min-hover.svg");
      }
      .wb-full {
        background-image: url("~@/assets/img/desktop/desktop-max-hover.svg");
      }
      .wb-close {
        background-image: url("~@/assets/img/desktop/desktop-close-hover.svg");
      }
    }
  }
  .wb-control * {
    width: 20px;
    margin-right: 2px;
  }
  .wb-max {
    display: none;
    background-image: url("~@/assets/img/desktop/desktop-max.svg");
  }
   .wb-full {
    position: relative;
    left: -22px; 
    background-position: center;
    background-image: url("~@/assets/img/desktop/desktop-max.svg");
  }
  .wb-close {
    background-size: 16px auto;
    background-position: center;
    background-image: url("~@/assets/img/desktop/desktop-close.svg");
  }
}
</style>