<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-08-13 15:06:33
 * @FilePath: \awx-ui\src\components\workflow-design\components\selectOpt.vue
-->
<template>
  <div class="select-opt-list">
    <a-spin :spinning="spinning">
    <div v-if="selectOptList && selectOptList.length > 0" class="operation-steps-list" :style="operationListStyle">
      <div class="operation-steps-item" v-for="(item, index) in selectOptList" :key="index">
        <div :class="[stepsId === item.id ? 'opt-item-selected' : '', 'opt-item']" @click="selectOpt(item)">
          <div class="name" :title="item.name">{{ item.name }}</div>
          <div class="description" :title="item.description">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <NoData class="no-data" :style="noDataStyle" />
    </div>
  </a-spin>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import NoData from "../components/noData.vue";
import { validateStep } from './validate'

export default {
  name: "OperationList",
  components: { NoData },
  props: {
    winboxHeight: Number,
    taskType: {
      type: String,
      default: "task",
    },
  },
  watch: {
    taskId: {
      handler(val) {
        if (val) {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    // 从模板那边进来的 隐藏右边的模板
    taskType: {
      handler(val) {
        if (val === "template") {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    "task.selectStepId": {
      handler(val) {
        if (val && this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo.id === val) {
          const taskType = this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.type
          // todo  如果是调度的不走这个逻辑
          if (taskType === 'schedule') return false
          this.getOptListByAppId(this.currentAppId)
        }
      },
    },
    "currentStepsIndex": {
      handler(val) {
        if (val === 1 && this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo.id === this.currentAppId) {
          const taskType = this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.type
          // todo  如果是调度的不走这个逻辑
          if (taskType === 'schedule') return false
          this.getOptListByAppId(this.currentAppId)
        }
     
      },
    },
  },
  data() {
    return {
      nextLoading: false,
      eidtFlag: false,
      spinning: false,
      selectOptList: [],
      confirmLoading: false
    };
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],

  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    currentWinboxKey () {
      const currentFocusWindow = this.windowStore.currentFocusWindow;
      let key = null
      for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
    taskId() {
      const taskId = this.windowPropsData.id || "";
      return taskId;
    },
    currentAppId() {
      const currentAppId = this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.application_id || ''
      return currentAppId
    },
    currentStepsIndex () {
      let currentStepsIndex = 0
      currentStepsIndex =  this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.properties['currentStepsIndex'] || 0;
      return currentStepsIndex
    },
    opType () {
      const definition = window[this.currentWinboxKey].designer.getDefinition()
      const seletStepId = window[this.currentWinboxKey].designer.state.selectedStepId
      const arr = definition && definition.sequence ? definition.sequence : []
      const index = arr.findIndex(item => item.id === seletStepId)
      return index === 0 ? '1' : '0'
    },
    stepsId() {
      const id = this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.id || ''
      return id
    },
    noDataStyle () {
     let style = {}
     style.height = (this.winboxHeight - 180) + 'px'
     style['max-height'] = (this.winboxHeight - 180) + 'px'
      return style
    },
   operationListStyle () {
     let style = {}
     style.height = (this.winboxHeight - 180) + 'px'
     style['max-height'] = (this.winboxHeight - 180) + 'px'
      return style
    },
  },
  methods: {
    ...mapActions("task", ["setStepInfo"]),
    ...mapActions("winboxFlowModule", ["setWinboxStepInfo"]),

    getOptListByAppId(id) {
      if (!id) return false
      this.spinning = true;
      this.$axiosGet(global.API.getApp + "/" + id + "/operations?op_type=" + this.opType).then(
        (res) => {
          this.spinning = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            this.selectOptList = res.data.results;
          }
        }
      );
    },
    getConfigByStepsId (id, stepInfo) {
      this.$axiosGet(global.API.getConfigByStepsId + "/" + id +'/').then(
        (res) => {
          this.spinning = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            const obj = {
              ...stepInfo.properties,
              ...res.data,
              icon: stepInfo.properties.icon || res.data.icon,
              application_name: stepInfo.properties.application_name,
              currentStepsIndex: 2
            }
            stepInfo.properties = obj
            let taskInfo = _.cloneDeep(this.winboxTask.stepInfo);
            taskInfo.taskTemplateDetail.stepInfo = stepInfo;
            this.setStepInfo(taskInfo);
            this.setWinboxStepInfo({id: this.currentWinboxKey, value: taskInfo});
            this.$EventBus.$emit('updateStepsProperties', taskInfo.taskTemplateDetail.stepInfo.properties)
            this.$EventBus.$emit('updateStepsNameByStepId', res.data.name)
          }
        }
      );
    },
    // 选择操作
    selectOpt(templateItem) {
      const selectItem = _.cloneDeep(templateItem)
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo)
      const obj = {
        ...stepInfo.taskTemplateDetail.stepInfo.properties,
        icon: stepInfo.taskTemplateDetail.stepInfo.properties.icon || selectItem.icon,
        application_name: stepInfo.taskTemplateDetail.stepInfo.properties.application_name
      }
      stepInfo.taskTemplateDetail.stepInfo.properties = obj
      this.getConfigByStepsId(selectItem.id, stepInfo.taskTemplateDetail.stepInfo)
    },
  },
  mounted() {
    this.getOptListByAppId(this.currentAppId)
  },
};
</script>
<style lang="less" scoped>
.select-opt-list {
  .operation-steps-list {
    overflow-y: auto;
    padding-right: 55px;
    margin-top: 20px;
    .operation-steps-item {
      .opt-item {
        padding: 16px 20px;
        cursor: pointer;
        widows: 100%;
        height: 78px;
        background: #ffffff;
        border: 1px solid rgba(219, 229, 249, 1);
        box-shadow: 0px 2px 6px 0px rgba(200, 212, 233, 0.1);
        border-radius: 6px;
        font-size: 14px;
        color: #7D8292;
        letter-spacing: 0;
        font-weight: 400;
        margin-bottom: 12px;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #242B3A;
          max-width: 610px;
        }
        .description {
          max-width: 610px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #7D8292;
          margin-top: 4px;
        }
      }
      .opt-item-selected, .opt-item:hover {
        border: 1px solid rgba(58, 116, 244, 0.75);
        box-shadow: 0px 2px 8px 0px;
      }
    }
  }
  .actions-empty {
    text-align: center;
    &-icon {
      height: 528px;
      width: 276px;
    }
  }
  .operation-box__tip {
    color: #8a8e99;
    font-size: 14px;
    margin-top: 30px;
    margin: 30px 20px 0 0;
    .devui-link {
      color: #526ecc;
    }
  }
  .submit-template {
    margin: 30px 20px 0 0;
    width: calc(100% - 20px);
    opacity: 0.9;
    background: #eef2f7;
    border-radius: 4px;
    font-size: 14px;
    color: #5a5a69;
    letter-spacing: 0;
    font-weight: 400;
  }
}
</style>