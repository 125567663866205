<!--
 * @Description: 
 * @Date: 2024-06-20 16:22:26
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-11-12 10:38:59
-->
<template>
  <div class='task-bar-container' :style="taskBarStyle" @contextmenu.stop="() => {}" @click.stop="() => {}">
    <TaskbarEntries :displayPosition="displayPosition" />
    <Setting :displayPosition="displayPosition" />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import Setting from '../Setting/Setting.vue'
// 任务栏容器
import TaskbarEntries from './TaskbarEntries'
import store from '@/store/index'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    Setting,
    TaskbarEntries
  },
  data () {
    // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {
    displayPosition () {
      let displayPosition = store.state.globalSetting.basic.controlBar.sideBar.displayPosition
      return displayPosition
    },
    taskBarStyle() {
      let style =  {
        [`${store.state.globalSetting.basic.controlBar.sideBar.displayPosition}`]: '-58px',
        transition: `${store.state.globalSetting.basic.controlBar.sideBar.displayPosition} .4s ease, width .4s ease`
      }
      if (store.state.globalSetting.basic.controlBar.sideBar.displayMode === 'alwaysShow') {
        style[`${store.state.globalSetting.basic.controlBar.sideBar.displayPosition}`] = '10px'
        transition: `${store.state.globalSetting.basic.controlBar.sideBar.displayPosition} .4s ease, width .4s ease`
      }
      if (store.state.globalSetting.basic.controlBar.sideBar.displayPosition === 'bottom') {
       style = {
         [`${store.state.globalSetting.basic.controlBar.sideBar.displayPosition}`]: '-56px',
          transition: `${store.state.globalSetting.basic.controlBar.sideBar.displayPosition} .4s ease, height .4s ease`
       }
      if (store.state.globalSetting.basic.controlBar.sideBar.displayMode === 'alwaysShow') {
        style[`${store.state.globalSetting.basic.controlBar.sideBar.displayPosition}`] = '10px'
        transition: `${store.state.globalSetting.basic.controlBar.sideBar.displayPosition} .4s ease, height .4s ease`
       }
      }
      return style
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {

  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.task-bar-container {
  gap: 20px;
  position: absolute;
  background: rgba(218,218,218,0.2); /* 半透明背景 */
  backdrop-filter: blur(100px); /* 高斯模糊效果 */
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12); /* 添加阴影 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.18);
  border-radius: 8px;
  padding: 10px 0px;
  margin: auto;
}
</style>
