<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-08-12 17:15:57
 * @FilePath: \awx-ui\src\pages\taskManage\historyDetail.vue
-->

<template>
  <div class="history-detail h-full">
    <a-card class="table-card">
      <div class="flex-bewteen-container mgb16">
        <div class="task-name" @click="back">
          <a-icon type="arrow-left" />
          <span class="mgl10">{{vmTaskHistoryDetail.name}} / 执行详情</span>
        </div>
        <div class="opt-warp">
          <a-button class="mgl12" type="primary" @click="runTask">执行任务</a-button>
        </div>
      </div>
      <div class="flex-warp">
        <div class="left-task">
          <OperationList class="h-full" :taskType="'log'" :runStatus="runStatus" />
        </div>
        <div class="right-log">
          <Log type="task" :taskId="vmTaskHistoryDetail.task_id" :taskType="'taskLog'" :runStatus="runStatus" :editorHeight="editorHeight" :noTips="true" />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import Log from "@/components/createTask/components/log.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import OperationList from "@/components/createTask/deploystep/operationList.vue";
export default {
  name: "HistoryDetail",
  components: { OperationList, Log },
  props: {
    taskHistoryDetail: Object,
    taskDetail: Object,
    currentPage: String,
  },
  data() {
    return {
      vmTaskHistoryDetail: this.taskHistoryDetail,
      topButtonTree: [
        // { name: "复制", key: "copy" },
        { name: "删除", key: "del" },
      ],
      runStatus: null,
      historyDetail: {
        bgClass: "",
        iconClass: "",
        runTitle: "",
        startTime: "",
        detail: {
          playbook_counts: {
            play_count: 0,
            task_count: 0,
          },
          host_status_counts: {
            changed: 0,
            dark: 0,
            failures: 0,
            ok: 1,
            skipped: 0,
          },
        },
      },
      loading: false,
    };
  },
  watch: {
    jumpTaskDetail: {
      handler(val) {
        if (val) {
          this.vmTaskHistoryDetail = val;
          this.pollingSearch();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    jumpTaskDetail() {
      const jumpTaskDetail = this.task.jumpTaskDetail || null;
      return jumpTaskDetail;
    },
    editorHeight() {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max) height = document.documentElement.clientHeight
      if (full) height = document.documentElement.clientHeight 
      return `${height - 174}px`;
    },
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "changeFirstEdit",
      "setJumpTaskDetail",
      "setSelectTemplateIndex"
    ]),
    back() {
      if (this.jumpTaskDetail) {
        this.$emit("goBack", this.currentPage, this.jumpTaskDetail);
      } else {
        this.$emit("goBack", this.currentPage);
      }
      this.setJumpTaskDetail(null);
    },
    // 执行任务
    async runTask() {
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${this.vmTaskHistoryDetail.task_id}/start`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("提交成功");
        this.pollingSearch(true);
        this.historyLog = { content: "" };
        this.vmTaskHistoryDetail.history_id =
          res.data.data[this.vmTaskHistoryDetail.task_id].id;
      }
    },
    // 执行之后定时去刷
    pollingSearch(flag) {
      if (!flag) {
        this.getTaskDetail();
        this.setJumpTaskDetail(null);
      }
      let self = this;
      if (self.timer) clearInterval(self.timer);
      self.timer = setInterval(() => {
        // 不存在正在部署的任务
        let flag =
          (this.historyDetail.state === "history" ||
            this.historyDetail.state === "started") &&
          (this.historyDetail.detail.status === "running" ||
            this.historyDetail.detail.status === "waiting");
        if (!flag) {
          clearInterval(self.timer);
          return false;
        }
        self.getTaskDetail(true);
      }, global.intervalTime);
    },
    // 获取流程详情
    async getTaskDetail() {
      // taskId = '6ef3d4c2-b2f6-431e-ab90-13484f98a84b/'
      let res = await this.$axiosGet(
        global.API.curdTasks + `${this.vmTaskHistoryDetail.task_id}/`,
        {
          excludes: "detail",
        }
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        const taskInfo = {
          task_id: res.data.task_id,
          name: res.data.name,
          taskTemplateDetail: {
            operations: res.data.operations,
            configs: res.data.configs,
            notify: res.data.notify,
          },
        };
        this.getStepStatus(taskInfo);
      } else {
        this.$message.error("获取流程详情失败");
      }
    },
    // 查看日志獲取狀態
    async getStepStatus(taskInfo) {
      let res = await this.$axiosGet(
        global.API.curdTasks + `${this.vmTaskHistoryDetail.task_id}/op_runs`,
        { exclude: "result" }
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.setTaskInfo(taskInfo);
        this.runStatus = res.data
        this.setSelectTemplateIndex(0)
      } else {
        this.$message.error("获取流程日志失败");
      }
    },
  },
  mounted() {
    this.pollingSearch();
  },
};
</script>

<style lang="less" scoped>
.history-detail {
  /deep/ .ant-card-body {
    padding: 13px 20px 16px;
    height: calc(100%);
  }
  .task-name {
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }
  .detail-content__header {
    line-height: 40px;
    padding: 0 20px;
    color: #fff;
    &--title {
      font-size: 16px;
      margin: 0 20px 0 10px;
    }
    &--owner {
      margin: 0 20px 0 10px;
    }
    &--failed {
      background: #f66f6a;
    }
    &--successful {
      background: @success-color;
    }
    &--running {
      background: @primary-color;
    }
  }

  .state-icon,
  .curpon {
    cursor: pointer;
  }
  .draft-color {
    color: @configured-status-color;
  }
  .published-color {
    color: #8a8e99;
  }
  .started-color {
    color: @primary-color;
  }
  .start-failed-color {
    color: @error-color;
  }
  .word-pointer {
    cursor: pointer;
    &:hover {
      color: #344899;
      text-decoration: underline;
    }
  }
  .flex-warp {
    display: flex;
    height: calc(100% - 32px);
    .left-task {
      width: 270px;
      height: calc(100% - 16px);
    }
    .right-log {
      flex: 1;
    }
  }
}
/deep/ .ant-modal-body {
  padding: 0;
}
/deep/ .ant-modal {
  top: 40px;
  .ant-modal-content {
    border-radius: 4px;
  }
}
</style>
