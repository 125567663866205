/*
 * @Description: 
 * @Date: 2024-11-01 15:52:52
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-11-01 15:56:01
 */
const mapSizes = new Map([
  ['large', 88],
  ['medium', 72],
  ['small', 60]
]);
const itemSize = new Map([
  ['large', 148],
  ['medium', 132],
  ['small', 120]
]);
// const mapSizes = new Map([
//   ['large', 80],
//   ['medium', 68],
//   ['small', 59]
// ]);
const mapNetFontSizes = new Map([
  ['large', 50],
  ['medium', 44],
  ['small', 40]
]);
const mapColorFontSizes = new Map([
  ['large', 28],
  ['medium', 24],
  ['small', 20]
]);
const mapRadius = new Map([
  ['large', 18],
  ['medium', 14],
  ['small', 12]
]);
export {
  mapSizes,
  itemSize,
  mapNetFontSizes,
  mapColorFontSizes,
  mapRadius
}