<!--
 * @Description: 
 * @Date: 2024-06-24 17:25:55
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-11-14 18:53:33
-->
<template>
  <div ref="TaskbarEntriesRef" :class="['taskbar-entries', displayPosition]">
    <TaskbarEntry v-for="item in windowStore.taskbarEntriesStore" :key="item.id" :taskbarEntry="item" />
  </div>
</template>

<script>
import TaskbarEntry from './TaskbarEntry.vue'
import { mapGetters, mapState } from 'vuex'
export default {
  name: "TaskbarEntriesRef",
  components: {
    TaskbarEntry
  },
  props: {
    displayPosition: String
  },
  data () {
    return {
      taskbarEntries: null,
    };
  },
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    // store.state.windowStore.windowStore
    // store.dispatch('windowStore/setWindowStore', windowStore)

  },
  beforeDestroy () {
  }
};
</script>

<style scoped lang="less">
.taskbar-entries {
  overflow-y: auto;
  scrollbar-width: none;
}
.bottom {
  display: flex;
  padding-top: 2px;
  overflow-x: auto;
  scrollbar-width: none;
}
</style>