<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-16 14:52:58
 * @LastEditTime: 2025-01-21 11:31:30
 * @FilePath: \awx-ui\src\pages\login\register.vue
-->
<template>
  <div class="register-page">
    <div class="register-title text-color-t1">注册账号</div>
      <a-form @submit="onSubmit" :form="form">
      <a-form-item>
        <a-input class="login-input" autocomplete="off"  @input="updateButtonStatus" placeholder="请输入手机号码" v-decorator="['phone', {rules: [{ required: true, message: '请输入手机号码', whitespace: true}]}]"></a-input>
      </a-form-item>
      <div class="code">
        <a-form-item class="code-num">
          <a-input class="login-input"  @input="updateButtonStatus" autocomplete="off" placeholder="验证码" v-decorator="['code', {rules: [{ required: true, message: '验证码', whitespace: true}]}]"></a-input>
        </a-form-item>
        <div :class="['code-btn', isCode && 'code-btn-send']" @click="sendCode">{{ isCode ? codeTime : '发送验证码' }}</div>
      </div>
      <a-form-item>
        <a-input
          v-if="!isShow"
          type="password"
           @input="updateButtonStatus"
          class="login-input"
          autocomplete="off"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="输入密码"
        />
        <a-input
          v-else
          class="login-input"
          autocomplete="off"
           @input="updateButtonStatus"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="再次确认密码"
        />
        <span  style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isShow = !isShow">
          <svg-icon class="text-color-t3" :icon-class="!isShow ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-item>
      <a-form-item>
        <a-input
          v-if="!isShowAgain"
          type="password"
          class="login-input"
          autocomplete="off"
           @input="updateButtonStatus"
          v-decorator="[
            'confirmPassword',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="再次输入密码"
        />
        <a-input
          v-else
          class="login-input"
          autocomplete="off"
           @input="updateButtonStatus"
          v-decorator="[
            'confirmPassword',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="再次输入密码"
        />
        <span  style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isShowAgain = !isShowAgain">
          <svg-icon class="text-color-t3" :icon-class="!isShowAgain ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-item>
      <a-form-item>
        <a-button :loading="logging" class="btn-login" htmlType="submit" :disabled="disabledLogin"  type="primary">{{ logging ? '正在注册中...' : '立即注册' }}</a-button>
      </a-form-item>
    </a-form>
    <div class="login">
      <div class="text-color-t3">
        已有账号？
        <span @click="login">立即登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      logging: false,
      form: this.$form.createForm(this),
      isCode: false,
      codeTime: 60,
      timer: null,
      isShow: false,
      isShowAgain: false,
      disabledLogin: true
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    login() {
      this.$emit("login");
    },
        updateButtonStatus() {
      // 获取表单字段的值
      const { getFieldDecorator, getFieldValue } = this.form;
      const password = getFieldValue('password');
      const phone = getFieldValue('phone');
      const confirmPassword = getFieldValue('confirmPassword');
      const code = getFieldValue('code');

      // 根据表单值更新按钮状态
      this.disabledLogin = !confirmPassword || !password || !phone || !code;
    },
    testPhone () {
      const phoneNumberRegex = /^1[3456789]\d{9}$/;
      let flag = true
      const phone = this.form.getFieldValue("phone");
      if (!phone) {
        this.$message.error("请输入手机号码");
        return false
      }
      if (!phoneNumberRegex.test(phone)) {
        this.$message.error("请输入正确的手机号码");
        return false
      } 
      return flag
    },
    sendCode() {
      const phone = this.form.getFieldValue("phone");
      if (!this.testPhone()) return false
      if (this.isCode) return false;
      this.isCode = true;
      this.$axiosJsonPost(global.API.smsSend, {phone: phone}).then((res) => {
        this.codeTime--;
        this.timer = setInterval(() => {
          if (this.codeTime === 1) {
            this.isCode = false;
            if (this.timer) clearInterval(this.timer);
            this.codeTime = 60;
            return false;
          } else {
            this.codeTime--;
          }
        }, 1000);
      });
    },
    onSubmit(e) {
      e.preventDefault();
      // 修复用户登录菜单不刷新BUG
      // this.$store.commit('setting/setMenuData', undefined)
      this.form.validateFields((err) => {
        if (!err) {
          const phone = this.form.getFieldValue("phone");
          const code = this.form.getFieldValue("code");
          const password = this.form.getFieldValue("password");
          const confirmPassword = this.form.getFieldValue("confirmPassword");
          const phoneNumberRegex = /^1[3456789]\d{9}$/;
          if (!phoneNumberRegex.test(phone)) {
            this.$message.error("请输入正确的手机号码");
            return false
          } 
          if (password !== confirmPassword) {
            this.$message.error("两次密码输入不一致");
            return false
          }
          this.logging = true;
          let config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          const self = this;
          setTimeout(() => {
            this.logging = false
          }, 4000)
          axios.post(global.API.registry, {phone, code, password}, config).then(function (res) {
            self.logging = false
            if ([200, 201, 204, 202].includes(res.status)) {
              if ([0, 200].includes(res.data.code)) {
                /*这里做处理.....*/
                this.$emit("login");
              } else {
                self.$message.error(res.data.msg)
              }
            }
          });
        }
      });
    },
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>
<style scoped lang='less'>
.register-page {
  /deep/ .ant-input {
    font-size: 14px;
  }
  .register-title {
    font-size: 16px;
  }
  .login-input {
    width: 100%;
    height: 42px;
    margin-top: 10px;
    background-color: rgba(var(--color-m2), 0.06);
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 8px;
    text-indent: 12px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgb(var(--color-t2));
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
     &:focus {
      border-color: @primary-color;
    }
    &::placeholder {
      // color: rgba(255,255,255,0.50);
    }
  }
  /deep/ .has-error {
    .login-input {
      border-color: #f5222f;
    }
  }
  .code {
    display: flex;
    .code-num {
      flex: 1;
    }
    .code-btn {
      margin-top: 10px;
      width: 120px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      background-color: rgba(var(--color-m2), 0.06);
      border: 1px solid rgba(255,255,255,0.12);
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      color: rgb(var(--color-t3));
      cursor: pointer;
      margin-left: 20px;
    }
    .code-btn-send {
      color: rgba(0, 0, 0, 0.45);
      border: 1px solid rgba(196, 204, 219, 1);
    }
  }
  .btn-login {
    margin-top: 10px;
    border-radius: 8px;
    width: 100%;
    height: 42px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 400;
    border: none;
  }
  .ant-btn-primary[disabled] {
      background: rgba(var(--color-m2), 0.06);
      color: rgb(199, 199, 204);
    }
  .login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin-top: 14px;
    span {
      cursor: pointer;
      color: #3974F4;
    }
  }
}
</style>