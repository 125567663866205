<!--
 * @Description: 
 * @Date: 2024-08-14 15:34:10
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-25 17:24:54
-->
<template>
  <div :class="['left-menu',  focused && 'left-actived-menu', !isExpand && 'left-collapse']">
    <div v-for="(item, index) in leftMenuList" :key="index" :class="['menu-item', item.name === selectedTitle && 'menu-item-selected']" @click='select(item)'>
      <a-divider type="vertical" />
      <svg-icon :iconClass="item.name === selectedTitle ? item.svgicon + '-blue' : item.svgicon"></svg-icon>
      <div v-if="isExpand">{{ item.name }}</div>
    </div>
    <a-popover v-model="showCluster" title="" overlayClassName="cluster-change-popover" placement="bottomLeft" trigger="click">
      <cluster-dialog :visible="showCluster" slot="content" />
      <div v-if="isLoginFlag" class="toggle" @click="showClusterDialog">
        <svg-icon :iconClass="isExpand ? 'firstlevel-expand' : 'firstlevel-collapse'"></svg-icon>
      </div>
    </a-popover>
  </div>
</template>
<script>
import Vue from 'vue';

//  currentFocusWindowId === windowAppLoaderOptions.id &&  'left-actived-menu'
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import store from '@/store/index'
import clusterDialog from './clusterDialog.vue';
export default {
  props: {
      menuData: {
      type: Array,
      required: true
    },
    windowAppLoaderOptions: Object,
    menuWindowOptions: Object,
    currentWindowId: String
  },
  // import引入的组件需要注入到对象中才能使用
  components: {
    clusterDialog
  },
  inject: ['windowPropsData'],
  computed: {
    isLoginFlag () {
      const isLoginFlag  = store.state.account?.user?.username
      return isLoginFlag
    },
    globalSetting () {
      return {...store.state.globalSetting}
    },
    currentFocusWindowId () {
      let currentFocusWindowId = null
      let currentWinbox = _.cloneDeep(store.state.windowStore.currentFocusWindow)
      for (var i in currentWinbox) {
        currentFocusWindowId = i
      }
      return currentFocusWindowId
    },
    focused () {
      return store.state.windowStore.windowStore[`${this.currentFocusWindowId}`]?.window?.focused
    },
    childMenu() {
      let path = '/' + this.windowAppLoaderOptions[`${this.windowAppLoaderOptions.fileCompontentType}`].FileGroupName
      // dashboard的的fullPath 为空
      let currentMenu = null
      if (path === '/dashboard') {
        currentMenu = this.menuData.filter(item => item.id === 'dashboard')
      } else {
        currentMenu  = this.menuData.filter(item => path.includes(item.fullPath) && item.fullPath !== '/')
      }
      let arr = currentMenu[0].children &&  currentMenu[0].children.length ?  currentMenu[0].children : [...currentMenu]
      return arr.filter(item => !(item.fullPath.includes('-new') || item.fullPath.includes('-edit')))
    },
    options () {
      const arr = _.cloneDeep(this.childMenu)
      let options =  arr
      return options
    },
    // 
    isMenu () {
      return this.currentWindowId === 'awx'
    }
  },
  data () {
    // 这里存放数据
    return {
      showCluster: false,
      isExpand: true,
      selectedTitle: "概览",
      dataWindowAppLoaderOptions: {},
      leftMenuList: [
          {
              url: require("@/assets/img/desktop/desktop-dashboard.svg"),
              svgicon: 'firstlevel-dashboard',
              name: "概览",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isMenu: true,
                MenuName: 'awx-dashboard',
                isFileGroup: true,
                FileGroupName: 'awx-dashboard',
                FileComponentPath: '@/pages/overview/index2'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'awx-dashboard',
          },
         {
              url: require("@/assets/img/desktop/desktop-task.svg"),
              svgicon: 'firstlevel-task',
              name: "任务",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isMenu: true,
                MenuName: 'awx-task-manage',
                isFileGroup: true,
                FileGroupName: 'awx-task-manage',
                FileComponentPath: '@/pages/taskManage/index'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'awx-task-manage',
          },
          {
              url: require("@/assets/img/desktop/desktop-app.svg"),
              svgicon: 'firstlevel-app',
              name: "应用",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isMenu: true,
                isFileGroup: true,
                FileGroupName: 'awx-app-manage',
                FileComponentPath: '@/pages/appManage/index'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'awx-app-manage',
          },
          {
              url: require("@/assets/img/desktop/desktop-resource.svg"),
              svgicon: 'firstlevel-resource',
              name: "资源",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isMenu: true,
                isFileGroup: true,
                FileGroupName: 'awx-resource',
                FileComponentPath: '@/pages/groupManage/index'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'awx-resource',
          },
          {
              url: require("@/assets/img/desktop/desktop-system.svg"),
              svgicon: 'firstlevel-setting',
              name: "系统",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isFileGroup: true,
                isMenu: true,
                FileGroupName: 'awx-system-manage',
                FileComponentPath: '@/pages/securityCenter/user'
              }, 
              fileRenderType: "awx-system-manage",
              componentName: 'MyDynamicComponent',
              id: 'awx-system-manage'
          },
      ]
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 显示切换集群的弹窗
    showClusterDialog () {
      this.showCluster = !this.showCluster
    },
    select (obj) { 
      let path = '/' + obj[`${obj.fileCompontentType}`].FileGroupName
      path = path.includes('awx-') ? path.replace(/awx-/g, '') : path
      let currentMenu = null
      if (path === '/dashboard') {
        currentMenu = this.menuData.filter(item => item.id === 'dashboard')
      } else {
        currentMenu  = this.menuData.filter(item => path.includes(item.fullPath) && item.fullPath !== '/')
      }
      let arr = currentMenu[0].children &&  currentMenu[0].children.length ?  currentMenu[0].children : [...currentMenu]
      let options =  arr.filter(item => !(item.fullPath.includes('-new') || item.fullPath.includes('-edit')))
      this.selectedTitle = obj.name
      const routeArr = options.filter(item => !(item.fullPath.includes('-new') || item.fullPath.includes('-edit')))
      const routerData = JSON.parse(localStorage.getItem('routerData'))
      let groupId = this.isMenu ? obj[`${obj.fileCompontentType}`].FileGroupName : obj.id
      groupId = groupId.includes('awx-') ? groupId.replace(/awx-/g, '') : groupId
      groupId = groupId === 'dashboard' ? '' : groupId
      const group = routerData[0].children.find(item => item.path === groupId)
      const currentRoute = group.children.find(item => item.path === routeArr[0].path)
      const data = {...obj}
      data[`${data.fileCompontentType}`].FileComponentPath = currentRoute.component
      data['currentRoute'] = {...currentRoute}
      this.updateHeaderComponent(data)
    },
    
    updateHeaderComponent(data) {
      const winbox = store.state.windowStore.windowStore[`${this.currentWindowId}`]
      if (winbox) {
        // 这个获取的是头部的图标名称和
         const titleContainer = winbox.window.g.querySelector('.wb-title-left');
         const titleContainerMenu = winbox.window.g.querySelector('.side-menu');
        if (titleContainer) {
          this.mountHeaderComponent(titleContainer,titleContainerMenu, data, this.menuData);
        }
        this.$EventBus.$emit('editwindowAppLoaderOptions', data)
        this.$EventBus.$emit(`editFirstMenuOptions-${this.currentWindowId}`, data, this.currentWindowId)
      }
    },
    mountHeaderComponent(titleContainer, titleContainerMenu, data, menuData) {
      const  SideMenuComponent =  Vue.extend({
        render(h) {
          return h('side-menu', {
            props: { menuData: menuData, windowAppLoaderOptions: {...data} } // 替换为你的 SVG 图标名称
          });
        }
      });
      const SideMenuComponentInstance = new SideMenuComponent().$mount()
      // 替换新的sideMenu组件
      titleContainer.replaceChild(SideMenuComponentInstance.$el, titleContainerMenu);
    }
    
  }
}
</script>

<style lang='less' scoped>
  .toggle {
    position: absolute;
    bottom: 24px;
    left: 18px;
    cursor: pointer;
  }

  .left-menu {
    width: 50px;
    padding-top: 22px;
    height: 100%;
    background: #F1F1F2;
  }
  .left-actived-menu {
    background: #eceff5;
  }
  .menu-item {
    position: relative;
    width: 46px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid rgba(255,255,255,0);
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.02);
    border-top-right-radius: 8px;
    cursor: pointer;
    margin-bottom: 4px;
    border-bottom-right-radius: 6px;
    font-size: 11px;
    color: #656568;
    letter-spacing: 0;
    font-weight: 400;
    padding-left: 4px;
    img {
      position: relative;
      width: 18px;
      margin-bottom: 4px;
      height: 18px;
    }
    /deep/ .ant-divider {
      position: absolute;
      left: -2px;
      margin: 0;
      border-radius: 1px;
      background: transparent;
      width: 3px;
      height: 30px;
      top: 14px;
    }
    svg {
      position: relative;
      margin-bottom: 6px;
      font-size: 20px;
    }
    &-selected {
      background: rgba(255,255,255,0.90);
      border: 1px solid rgba(255,255,255,0.1);
      color: @primary-color;
      svg {
        color: @primary-color;
      }
      .ant-divider {
        background: @primary-color;
      }
    }
  }
.left-collapse {
  width: 50px;
  .menu-item {
    width: 46px;
    height: 40px;
  }
  /deep/ .ant-divider {
    position: absolute;
    left: -2px;
    margin: 0;
    border-radius: 1px;
    width: 3px;
    height: 28px;
    top: 6px;
  }
  svg {
    margin-bottom: 0;
  }
  .toggle {
    left: 18px;
  }
}
</style>
