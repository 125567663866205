<!--
 * @Description: 
 * @Date: 2024-06-18 17:45:28
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-22 16:00:46
-->
<template>
 <!-- :style="{padding: `0 ${padding}px`}" -->
  <div id="custom-file-manage" v-resize="handleResize" :class="['file-manage no-select', taskbarPosition]" :style="fileManageStyle">
    <GridLayout
      :layout="layout"
      @getPadding="getPadding"
      @layoutUpdatedEvent="onLayoutUpdated"
    >
      <template #app="{item}">
        <FileEntry
          v-if="item.w === 1 && item.h === 1 && item.data?.fileCompontentType !== 'AddIcon'"
          ref="FileEntryRef"
          :fileEntry="item.data"
        />
        <FileEntry2
          v-if="item.w === 2 && item.h === 2 && item.data?.fileCompontentType !== 'AddIcon'"
          ref="FileEntryRef"
          :fileEntry="item.data"
        />
        <AddIconToDesktop v-if="item.data?.fileCompontentType === 'AddIcon'" :fileEntry="item.data" />
      </template>
    </GridLayout>
  </div>
</template>
<script>
import GridLayout from "./gridLayout.vue";
import FileEntry from './FileEntry'
import FileEntry2 from './FileEntry/x2y2'
import AddIconToDesktop from './FileEntry/addIconToDesktop'
import _ from "lodash";

import store from '@/store/index'
import { mapState, mapMutations } from 'vuex'
import {mapSizes, itemSize} from '@/utils/desktopIcon-map'

export default {
  components: {
    GridLayout,
    FileEntry,
    FileEntry2,
    AddIconToDesktop,
    // eslint-disable-next-line vue/no-unused-components
    MyDynamicComponent: () => import('@/components/winbox/RenderComponent')
  },
  props: {
    taskbarPosition: String,
  },
  data () {
    return {
      padding: 20,
      show: false,
      layout: []
    }
  },
  watch: {
    desktopIconSize : {
      handler (val) {
       this.handleResize()
      }
    },
  },
  computed: {
    desktopLayout () {
      const desktopLayout = store.state.globalSetting.desktopLayout
      return desktopLayout
    },
    desktopIconSize () {
      return  store.state.globalSetting.desktopIcon.desktopIconSize
    },
    fileManageStyle () {
      let style = {}
      const viewportWidth = window.innerWidth; // 获取视口宽度
      if (viewportWidth > 720) {
        style['padding-left'] = `${this.padding}px`
        style['padding-right'] = `${this.padding}px`
      } 
      if (viewportWidth < 720) {
        style['padding-left'] = `${0}px`
        style['padding-right'] = `${4}px`
      }
      return style
    }
  },
  methods: {
    ...mapMutations('globalSetting', ['setDesktopLayout']),
    getPadding (val) {
      this.padding = val < 0 ? this.padding : val / 2
    },
    getcolNum () {
      let colNum = 14
      const desktopIconSize = itemSize.get(this.desktopIconSize)
      const containerWidth = document.getElementById('custom-file-manage').clientWidth
      colNum =  Math.floor((containerWidth) / desktopIconSize)
      return colNum
    },
    handleResize({ width, height }) {
      this.throttleUpdateWindowState(width, height)
    },
    throttleUpdateWindowState: _.debounce(function(width, height) {
      const containerWidth = document.getElementById('custom-file-manage').clientWidth
      let padding = 0
      const colNum = this.getcolNum()
      padding = containerWidth - colNum * itemSize.get(this.desktopIconSize)
      this.getPadding(padding)
    }, 500),

    sortEmptySlots (slots) {
      // 首先，按照 y 和 x 坐标进行排序
      slots.sort((a, b) => {
        if (a.y !== b.y) {
          return a.y - b.y; // 根据 y 升序
        }
        return a.x - b.x; // 根据 x 升序
      });

      // 然后，更新每一项的 i 属性为当前索引
      slots.forEach((slot, index) => {
        slot.i = index; // 将 i 属性设置为当前索引
      });
      return slots; // 返回排序并更新后的数组
    },
    // 拖拽之后触发
    onLayoutUpdated (newLayout) {
      // todo: 页面刷新的时候就触发了 想办法
      this.setDesktopLayout(newLayout)
    },
    onComponentResize (entries) {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        const arr = _.cloneDeep(this.layout)
      }
    },
  },
  mounted () {
    // this.resizeObserver = new ResizeObserver(this.onComponentResize);
    // this.resizeObserver.observe(this.$refs.FileEntryRef.$el);
  },
  beforeUnmount () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
}
</script>
<style lang="less" scoped>
.file-manage {
  width: 100%;
  .vue-grid-layout {
    min-height: calc(75vh) !important;
  }
  /* 在你的全局样式文件中添加或修改以下样式 */
  .vue-grid-item .grid-placeholder {
    background-color: transparent !important; /* 或者设置为你想要的颜色 */
    border: none !important; /* 如果有边框也可以去掉 */
  }
}
.bottom {
  .vue-grid-layout {
  }
}
</style>
