/*
 * @Description: 
 * @Date: 2024-10-24 10:36:17
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-12-26 15:25:23
 */
import { uploadFilesConcurrently, uploadPeerFile } from '@/utils/uploadChannel'
import { guid } from '@/utils/axios-peer'
const UploadFile = {
  methods: {
    beforeUpload (file) {
      if (file.size > 300 * 1024 * 1000) {
        this.$message.error("文件上传最大仅支持300M");
        return;
      }
      file.status = "notUpload";
      file.loading = false;
      file.progress = 0
      this.hostsImport(file)
      return false;
    },
    handleChange (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        // 为每个文件添加一个基于时间戳的唯一标识
        fileList[i].fileId = guid();
      }
      this.changeShowFileProgress(true)
      this.changeUpload('upload')
      const stateUploadList = this.fileManage.uploadFileList
      let uploadList = []
      Array.from(fileList).map((file) => {
        uploadList.unshift({
          fileId: file.fileId,
          name: file.name,
          size: file.size,
          lastModified: file.lastModified,
          type: file.type,
          progress: 0
        })
      })
      this.changeUploadFileList(uploadList.concat(stateUploadList))
      const url = global.API.getProxyFile + this.treeFilePath + '?filesystem=' + this.treeFileId + '&cluster_id=' + this.clusterId
      uploadFilesConcurrently(fileList, url, () => { this.$emit('refreshRoute', 'upload', this.fileRoute, this.treeFileId) }, 3).then(results => {
        console.log("所有文件上传结果:", results);
      })
        .catch(error => {
          console.error("上传过程中遇到错误:");
        });
    },
    hostsImport (file) {
      const self = this
      const params = {
        filesystem: this.treeFileId
      }
      // changeShowFileProgress
      const formData = new FormData();
      formData.append("file", file);
      self.changeShowFileProgress(true)
      self.changeUpload('upload')
      const fileId = guid()
      uploadPeerFile(global.API.getProxyFile + this.treeFilePath + '?filesystem=' + params.filesystem + '&cluster_id=' + this.clusterId, file, {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`文件 ${file.name} 上传进度: ${percentCompleted}%`);
          const uploadist = self.fileManage.uploadFileList
          const index = uploadist.findIndex(item => item.fileId === fileId)
          if (index === -1) {
            uploadist.unshift({
              name: file.name,
              size: file.size,
              type: file.type,
              progress: percentCompleted,
              fileId
            })
          } else {
            uploadist.splice(index, 1, {
              ...uploadist[index],
              progress: percentCompleted,
            })
          }
          self.changeUploadFileList(uploadist)
        },
        rtcscope: 'file'
      },
        () => { this.$emit('refreshRoute', 'upload', this.fileRoute, this.treeFileId) })
    },
  },
};
export { UploadFile };
