<!--
 * @Description: 
 * @Date: 2024-07-25 09:48:37
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-20 17:12:43
-->
<template>
  <div class="setting-box">
    <div class="setting-user border-color-m2 border-opacity-008">
      <div :class="['ph14 pv14']" @click="handleSelect('userInfo')">
        <div :class="['p12 flex items-center sidebar-menu', 'userInfo' === globalSetting.settingBoxTab && 'selected']">
          <div v-if="user && user.username" class="user-image">
            <img :src="'http://gips3.baidu.com/it/u=3476243082,1256047914&fm=3028&app=3028&f=JPEG&fmt=auto?w=2048&h=2048'" alt="">
          </div>
          <div v-else class="user-image flex items-center">
            <svg-icon style="font-size: 30px; color: rgb(200,200, 204);" icon-class="account-default"></svg-icon>
          </div>
          <div v-if="user && user.username" class="flex flex-1 flex-col text-color-t2 items-start pl16 pr10">
            {{ user.username }}
          </div>
          <div v-else class="flex flex-1 flex-col items-start pl16 pr10">
              <div class="login-txt">登录/注册</div>
              <div class="sync-text">登录以同步数据</div>
          </div>
          <div class="flex items-center">
          </div>
        </div>
      </div>
    </div>
    <div class="setting-menu hi-scroll pl16 pt32 pb40 flex-1">
      <div class="pr16">
        <div v-for='(item, index) in menuList' :class="['h36 pv8 mgb4 text-color-t2 pl12 sidebar-menu', item.key === globalSetting.settingBoxTab && 'selected']" @click="handleSelect(item.key)">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import store from '@/store/index'

export default {

  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      key: 'wallpaper',
      menuList: [{
        title: '基本',
        key: 'basic'
      },{
        title: '壁纸',
        key: 'wallpaper'
      }, {
        title: '主题',
        key: 'theme'
      },{
        title: '消息',
        key: 'message'
      }]
    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
    setting () {
      return {...store.state.setting}
    },
    user () {
      const user = store.state.account.user
      this.userInfo = user
      return user
    },
    isLoginFlag () {
      const isLoginFlag  = store.state.account?.user?.username
      return isLoginFlag
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    handleSelect (key) {
      store.commit("globalSetting/setSettingBoxTab", key)
      store.commit("globalSetting/setSettingStep", 2)
    }
  }
}
</script>

<style lang='less' scoped>
.light {
  .setting-box {
    .selected {
      --tw-bg-opacity: 0.9;
      background: rgba(var(--color-white), var(--tw-bg-opacity));
      color: rgba(var(--color-t1), 1)
    }
    .sidebar-menu {
      border-radius: 6px;
      &:hover {
        --tw-bg-opacity: 0.9;
        background: rgba(var(--color-white), var(--tw-bg-opacity));
        color: rgba(var(--color-t1), 1)
      }   
    }
  }
}
.dark {
  .setting-box {
    .selected {
      --tw-bg-opacity: 0.1;
      background: rgba(var(--color-white), var(--tw-bg-opacity));
      color: rgba(var(--color-t1), 1)
    }
    .sidebar-menu {
      border-radius: 6px;
      &:hover {
        --tw-bg-opacity: 0.1;
        background: rgba(var(--color-white), var(--tw-bg-opacity));
        color: rgba(var(--color-t1), 1)
      }   
    }
  }
}

//@import url(); 引入公共css类
.setting-user {
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  .user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: transform 0.5s;
    &:hover {
      transform: rotate(1turn);
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
.setting-menu {
  position: relative;
  font-size: 14px;
  color: #fff;
  * {
    cursor: pointer;
    border-radius: 6px;
  }
}
.login-txt {
  color: rgb(74, 122, 255);
  font-size: 13px;
}
.sync-text {
  color: rgb(200,200, 204);
  font-size: 12px;
}
</style>

