<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-03 17:14:47
 * @LastEditTime: 2025-01-22 17:20:12
 * @FilePath: \awx-ui\src\pages\appManage\chooesApp.vue
-->
<template>
  <div class="template-list" ref="templateList">
    <div class="system-template">
      <div v-if="systemTAppist.length >0" class="flex-warp">
        <div v-for="(item, index) in systemTAppist" :key="index" class="template-container">
          <chooesAppItem :appItem="item" :appCate='appCate' :selectAppId="selectAppId" :appType="'app'" @addAppToDesktop="addAppToDesktop" @addNewApp="addNewApp" />
        </div>
      </div>
      <div v-else>
        <NoData />
      </div>
    </div>
  </div>
</template>
  <script>
import NoData from "@/components/noData";
import chooesAppItem from "./chooesAppItem.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import AddAppIndex from './addApp/addAppIndex.vue'
import { getGridSize, findFreePosition } from '@/utils/dynamic-desktoplayout';
import { calculateLayout } from '@/utils/dynamic-layout';
export default {
  components: { NoData, chooesAppItem },
  props: {
    systemTAppist: Array,
    appCate: Array,
    current: Number
  },
  data() {
    return {
      hasScroll: false,
      selectAppId: "",
      loadingMore: false, // 防止重复触发加载
      allDataLoaded: false, // 标志所有数据是否已加载完毕
    };
  },
  watch: {},
  inject: ['getApp'],
  computed: {
    ...mapState({
      globalSetting: (state) => state.globalSetting,
      windowStore: (state) => state.windowStore,
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    ...mapMutations('globalSetting', ['setDesktopLayout', 'setAddActiveId', 'setFixedTabar']),
    ...mapActions('windowStore', ['setTaskbarEntriesStore']),
    addAppToDesktop (data) {
      if (this.windowStore.isAddToTaskbar) {
        const fixedBar = this.globalSetting.fixedTabar
        fixedBar.push({
          url: data.icon,
          name: data.name,
          fileCompontentType: "ExternalComponent",
          ExternalComponent: {
            externalUrl: data?.app_addr || '',
            openWith: '_blank'
          }, 
          componentName: 'ExternalComponent',
          id: data.id,
        })
        this.setFixedTabar(fixedBar)
        this.setTaskbarEntriesStore()
        return false
      }
      const { columns, rows } = getGridSize();
      const layout = _.cloneDeep(this.globalSetting.desktopLayout);
      let sum = 0
      layout.map(item => {
        sum  += item.w * item.h
      })
      // 最终确定实际行数
      let realRows = Math.max(
        // 计算实际需要的行数
        Math.ceil(sum / (columns * 1)), 
        // 传入的tableRows
        rows
      );
      const id = data.id + Math.random()
      if ((columns * rows < sum || columns * rows === sum) && this.globalSetting.desktopArrange !== 'horizontal') {
        layout.push({
          i: layout.length,
          x: columns -1,
          y: realRows + 1,
          w: 1,
          h: 1,
          data: {
            url: data.icon,
            name: data.name,
            fileCompontentType: "ExternalComponent",
            ExternalComponent: {
              externalUrl: data?.app_addr || '',
              openWith: '_blank'
            }, 
            componentName: 'ExternalComponent',
            id: id,
          }
        });
        sum = 0
        layout.map(item => {
          sum  += item.w * item.h
        })
        realRows = Math.max(
          // 计算实际需要的行数
          Math.ceil(sum / (columns * 1)), 
          // 传入的tableRows
          rows
        );
        const arr = calculateLayout(layout, columns, realRows, 'vertical') 
        this.setDesktopLayout(arr);
        return false
      }
      const addIconIndex = layout.findIndex(item => item.data.fileCompontentType === 'AddIcon')
      const position = {
        x: layout[addIconIndex] ? layout[addIconIndex].x : 0,
        y: layout[addIconIndex] ? layout[addIconIndex].y : 0,
        i: layout[addIconIndex] ? layout[addIconIndex].i : 0,
      }
      const addPositon = findFreePosition(layout, columns, realRows, this.globalSetting.desktopArrange === 'horizontal');
      layout.push({
        i: position.i,
        x: position.x,
        y: position.y,
        w: 1,
        h: 1,
        data: {
          url: data.icon,
          name: data.name,
          fileCompontentType: "ExternalComponent",
          ExternalComponent: {
            externalUrl: data?.app_addr || '',
            openWith: '_blank'
          }, 
          componentName: 'ExternalComponent',
          id: id,
        }
      });
      layout[addIconIndex] = {
        ...layout[addIconIndex],
        x: addPositon.x,
        y: addPositon.y,
        i: layout.length
      }
      this.setDesktopLayout(layout);
      this.setAddActiveId(id)
       document.addEventListener('click', this.handleClickOutside);
    },
    handleClickOutside(event) {
      this.setAddActiveId(null)
      document.removeEventListener('click', this.handleClickOutside);
    },
    // 新建应用
    addNewApp() {
      const self = this;
      let width = 740;
      let title = "";
      let content = (
        <AddAppIndex 
          creatMaster={() => {
            self.getApp();
          }} />
      );
      this.$confirm({
        width: width,
        class: "host-empty-dialog",
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },

    handleScroll() {
      const container = this.$refs.templateList;
      if (!container || this.loadingMore || this.allDataLoaded) return;
      console.log('滚动中',  container.scrollTop, container.clientHeight, container.scrollHeight);
      // 检查是否滚动到底部
      const isAtBottom = container.scrollTop >= container.scrollHeight -  container.clientHeight;

      if (isAtBottom && !this.loadingMore) {
        this.loadMoreItems();
      }
    },

    loadMoreItems() {
      if (this.loadingMore || this.allDataLoaded) return;
      
      this.loadingMore = true;
      console.log('触发加载更多');

      // 模拟异步加载更多数据
      let current = this.current
      current++
      this.$emit('loadMore', current);
    },
    scrollToTop() {
      const container = this.$refs.templateList;
      if (container) {
        container.scrollTop = 0;
      }
    },
    addScrollListener() {
      const container = this.$refs.templateList;
      if (container) {
        container.addEventListener('scroll', this.handleScroll);
      }
    },

    removeScrollListener() {
      const container = this.$refs.templateList;
      if (container) {
        container.removeEventListener('scroll', this.handleScroll);
      }
    },
  },
  mounted() {
    this.addScrollListener();
  },
  beforeDestroy() {
    this.removeScrollListener();
  },
};
</script>
  <style lang="less" scoped>
.template-list {
    height: 318px;
    margin: 0 0px 0 0;
    padding-right: 0px;
    overflow-y: auto;

  .system-template,
  .custom-template {
    &-title {
      padding-bottom: 16px;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .flex-warp {
    display: grid;
    // max-height: calc(100% - 212px);
    // height: calc(100% - 212px);
    overflow-y: auto;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); /* 150px 是每个元素的最小宽度 */

  }
  .template-container {
      padding: 0 10px 10px 10px;
  }
}
</style>