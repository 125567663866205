<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2025-01-26 10:26:43
 * @FilePath: \awx-ui\src\components\fileProgress\index.vue
-->
<template>
  <div class="file-progress" >
    <div class="collapse-header">
      <a-tabs @click.stop id="a-tabs" v-model="fileManage.uploadTab" @change="(val) => callback(val)" style="width: 100%">
        <a-tab-pane v-for="(item) in tabs" :key="item.key" :tab="item.name">
      </a-tab-pane></a-tabs>
      <span class="close" @click="onClose"><svg-icon icon-class="close"></svg-icon></span>
    </div>
    <div class="file-progress-drawer">
      <div class="file-progress-container" v-if="fileList.length > 0">
        <div class="file-progress-container-content" :key="refreshNum">
          <div class="file-progress-container-item" v-for="(item, index) in fileList" :key="index" style="height: 60px">
          <div class="icon">
            <svg-icon
              :icon-class="getIcon(item.name)"
            ></svg-icon>
          </div>
          <div class="content">
            <div class="header ell" :title="item.name">
              {{item.name}}
            </div>
            <div v-if="!item.status">
              <div class="flex">
                <a-progress :percent="item.progress"/>
                <div v-if="item.progress < 100" class="cancel-text" @click="cancelFilePreview(item)">取消</div>
              </div>
              </div>
            <div v-else>
              <a-progress :percent="item.progress" :status="item.status"/>
            </div>
          </div>
        </div>
        </div>
        <div class="is-empty" @click='clear'>清空</div>
      </div>
      <div v-else class="no-data-page">
        <img src="@/assets/img/no-data-small.png" alt="暂无数据" />
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import moment from "moment";
import { formatBytes } from "@/utils/util";
import { getConnection, removeConnection } from '@/utils/axios-peer'
import { cancelUpload, cancelUploadByKey } from '@/utils/uploadChannel'
import { cancelDownload, cancelDownloadByKey } from '@/utils/downloadChannel'


export default {
  // components: {result},
  props: {
    historyList: {
      type: Array,
      default: () => [],
    },
    currentHistoryIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      moment,
      refreshNum: Math.random(),
      collapseKey: 'open',
      tabs: [
        { key: "upload", name: "上传" },
        { key: "download", name: "下载" }
      ],
      resultLoading: false,
    };
  },
  watch: {
    'runHistory.collapseKey': {
      handler(val) {
        this.collapseKey = val
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      runHistory: (state) => state.runHistory, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      fileManage: (state) => state.fileManage, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    fileList () {
      return this.fileManage.uploadTab === 'upload' ? this.fileManage.uploadFileList : this.fileManage.downloadFileList
    },
    isEmpty () {
      const isEmpty = this.runHistory.runtab === 'file' ? this.runHistory.fileIsEmpty : this.runHistory.runtab === 'task' ? this.runHistory.taskIsEmpty : this.runHistory.cmdIsEmpty
      return isEmpty
    },
  },
  filters: {
    formatStatus(val) {
      if (val === "successful") {
        return "已完成";
      } else if (val === "running") {
        return "运行中";
      } else if (val === "failed") {
        return "未完成";
      }
    },
  },
  methods: {
    ...mapActions("fileManage", [
      "changeUpload",
      "changeShowFileProgress",
      "changeUploadFileList",
      "changeDownloadFileList"
    ]),
    getIcon (name) {
      const arr = name.split(".");
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      return global.utils.mapFileManageIcon(key)
        ? global.utils.mapFileManageIcon(key)
        : "file-question2";
    },
    changeCollaspeKey (val) {
      this.changeCollapseKey(val)
    },
    callback(val) {
      this.changeUpload(val);
    },
    cancelFilePreview (file) {
      const fileObj = {
        ...file,
        status: 'exception',
        progress: 100
      }
      if (this.fileManage.uploadTab === 'upload' ) {
        cancelUploadByKey(file.name)
        const stateUploadList = this.fileManage.uploadFileList
        const index = stateUploadList.findIndex(item => item.fileId === file.fileId)
        stateUploadList[index] = fileObj
        
        this.changeDownloadFileList(stateUploadList);
        this.refreshNum = Math.random()
        const connUpload = getConnection(file.clusterId, file.name)
        if (connUpload) connUpload.close()
        removeConnection(file.clusterId, file.name)
      
      } else {
        cancelDownloadByKey(file.name)
        const stateDownloadList = this.fileManage.downloadFileList
        const index = stateDownloadList.findIndex(item => item.fileId === file.fileId)
        stateDownloadList[index] = fileObj
        
        this.changeDownloadFileList(stateDownloadList);
        this.refreshNum = Math.random()
        const connDown = getConnection(file.clusterId, file.name)
        if (connDown) connDown.close()
        removeConnection(file.clusterId, file.name)
      
      }

    },
    onClose(e) {
      cancelUpload()
      cancelDownload()

      this.fileManage.uploadFileList.map(item => {
        const connFile = getConnection(item.clusterId, item.name)
        if (connFile) connFile.close()
        removeConnection(item.clusterId, item.name)
      })
      
      this.fileManage.downloadFileList.map(item => {
        const connDown = getConnection(item.clusterId, item.name)
        if (connDown) connDown.close()
        removeConnection(item.clusterId, item.name)
      })
      this.changeShowFileProgress(false);
      this.changeUploadFileList([]);
      this.changeDownloadFileList([]);
    },
    clear () {
      this.fileManage.uploadTab === 'upload' ?  this.changeUploadFileList([]) : this.changeDownloadFileList([])
    }, 
    handleClick(item, index) {
    },
  },
};
</script>
<style lang="less" scoped>
.file-progress {
  z-index: 1050;
  position: fixed;
  bottom: 30px;
  right: 12px;
  background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border: 1px solid rgba(208, 214, 223, 1);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  width: 375px;
  max-height: 530px;
  min-height: 54px;
  border-radius: 6px;
  
  /deep/ .ant-collapse-item .ant-collapse-header {
    padding: 0px 0 0 12px;
  }
  /deep/ .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .collapse-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/ .ant-tabs-nav-wrap {
    text-align: center;
    .ant-tabs-nav .ant-tabs-tab {
      margin-right: 12px;
      font-size: 14px;
      color: #34343c;
      font-weight: 400;
      padding: 10px 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      font-size: 14px;
      color: #34343c;
      font-weight: 600;
    }
  }
  /deep/ .ant-tabs-bar {
    margin: 0px;
  }
}
.close {
    position: relative;
    right: 12px;
    cursor: pointer;
  }
.file-progress-drawer {
  .history-head {
    display: flex;
  }
  .file-progress-container {
    &-content {
      max-height: 400px;
      overflow-y: auto;
    }
    &-item {
      display: flex;
      align-items: center;
      padding: 0 12px 0 12px;
      border-bottom: 1px solid#ECF0F4;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: #e6e8ed;
      }
    }
  }

  .icon {
    svg {
      font-size: 50px;
    }
  }
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .header {
      margin-top: 12px;
      width: 290px;
      line-height: 16px;
    }
    /deep/ .ant-progress-line {
      width: 260px;
    }
  }

  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .task-running {
    transform: rotate(360deg);
    animation: rotation 2s linear infinite;
  }
  .no-data-page {
    text-align: center;
    position: relative;
    box-sizing: border-box;
    padding: 13px 10px;
    border: 1px solid transparent;
    border-bottom-color: #adb0b8;
    border-bottom: 1px solid var(--devui-line, #adb0b8);
    cursor: pointer;
    color: #adb0b8;
    &:hover {
      // border-left-color: #adb0b8;
      // border-left: 1px solid var(--devui-line, #adb0b8);
      // border-right-color: #adb0b8;
      // border-right: 1px solid var(--devui-line, #adb0b8);
    }
  }
  .load-more, .is-empty {
    font-size: 12px;
    text-align: center;
    color: #15a5f8;
    cursor: pointer;
    margin: 10px 0;
  }
}
.exit {
  position: relative;
  left: 116px;
  cursor: pointer;
  color: rgba(0, 0 ,0, 0.45);
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 0 3px 0 0;
  &:hover {
    color: rgba(0, 0, 0, 0.65);
    background: rgba(208, 210, 213);
  }
}
.cancel-text {
  height: 21px;
  line-height: 23px;
  margin-left: 6px;
  cursor: pointer;
  width: 40px;
  font-size: 12px;
  color: #80848d;
  font-weight: 400;
}
</style>