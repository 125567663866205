<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2024-07-18 10:34:26
 * @FilePath: \awx-ui\src\components\workflow-design\components\appItem.vue
-->
<template>
  <div class="template-item" :class="{'template-item-selected': selectedTemplateId === templateItem.id,  'already-selected': selectStepId, dragging: !!selectStepId, 'disabled-trigger' :  handlerClass(templateItem)}" @mousedown="($e) => selectedTemplate($e)" @click="($e) => changeTemplate($e)">
    <span class="template-logo">
      <!-- 系统图标 -->
      <img v-if="templateItem.id !== '_blank' && !IsColorIcon(templateItem.icon) && !IsNetWorkIcon(templateItem.icon)" :src="templateItem.icon" alt="" />
      <div v-if="templateItem.id !== '_blank' && IsColorIcon(templateItem.icon) && !IsNetWorkIcon(templateItem.icon)"  class='icon-item' :style="{
        'background': templateItem.icon,
        'text-align': 'center',
        'width': '32px',
        'height': '32px',
        'border-radius': '8px',
        'margin': 'auto'
      }">
        <span :style="{
          'color': '#fff',
          'font-size': iconColorName(templateItem.name).length === 1 ? '16px' : '12px',
          'display': 'block',
          'width': '100%',
          'height': '100%',
          'line-height': '32px'
        }">{{ iconColorName(templateItem.name) }}
         </span>  
      </div>
      <!-- 网络图标 -->
      <iconifyIcon v-if="templateItem.id !== '_blank' && !IsColorIcon(templateItem.icon) && IsNetWorkIcon(templateItem.icon)" :icon="iconNetWorkName(templateItem.icon)" style="font-size: 24px;" />
      <svg-icon v-if="templateItem.id === '_blank'" icon-class="task-blank" style="font-size: 44px;margin: auto;display: inline-block;"></svg-icon>
      </span>
    <div class="template-text-info">
      <div class="template-title over-flow-ellipsis" :style="{'max-width': templateType === 'app' ? '116px': '125px', 'font-size': templateType === 'app' ? '14px': '16px'}" :title="templateItem.name">{{templateItem.name}}</div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Icon, getIcon } from '@iconify/vue2'
import { IconToSvg, getSvgPath } from "@/utils/iconToSvg";

export default {
  props: {
    templateItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedTemplateId: String,
    templateType: {
      type: String,
      default: 'template'
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    currentWinboxKey () {
      // const currentFocusWindow = this.windowStore.currentFocusWindow;
      // let key = null
      // for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
    selectStepId () {
      return this.winboxTask.selectStepId || ''
    },
    opType () {
      const definition = window[this.currentWinboxKey].designer.getDefinition()
      const seletStepId = this.selectStepId
      const arr = definition && definition.sequence ? definition.sequence : []
      const index = arr.findIndex(item => item.id === seletStepId)
      return index === 0
    },
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],

  methods: {
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name){
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    selectedTemplate(e) {
      if (this.selectStepId) return false
      // if (this.opType && !this.templateItem.triggers_count) return false
      // if (!this.opType && !this.templateItem.ops_count) return false
      if (this.IsNetWorkIcon(this.templateItem.icon)) {
        let svg = IconToSvg(this.iconNetWorkName(this.templateItem.icon))
        const child = getSvgPath(svg.body)
        svg.path = child.path
        svg.rect = child.rect
        svg.g = child.g
        svg.defs = child.defs
        console.log(JSON.stringify(svg))
        this.templateItem.netWorkSvg = svg
      }
      console.log(this.templateItem)
      this.$emit("selectedTemplate", e, this.templateItem);
    },
    changeTemplate (e) {
      if (!this.selectStepId) return false
      if (this.opType && !this.templateItem.triggers_count) return false
      if (!this.opType && !this.templateItem.ops_count) return false
      if (this.IsNetWorkIcon(this.templateItem.icon)) {
        let svg = IconToSvg(this.iconNetWorkName(this.templateItem.icon))
        const child = getSvgPath(svg.body)
        svg.path = child.path
        svg.rect = child.rect
        svg.g = child.g
        svg.defs = child.defs
        console.log(svg)
        this.templateItem.netWorkSvg = svg
      }
      console.log(this.templateItem)
      this.$emit("changeTemplate", e, this.templateItem);
    },
    handlerClass (template) {
      let flag = false
      if (!window[this.currentWinboxKey]?.designer?.state?.selectedStepId) return flag
      if (this.opType && !template.triggers_count) flag = true
      if (!this.opType && !template.ops_count) flag = true
      return flag
    }
  },
  mounted() {
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.template-item {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: move;
  padding: 6px 20px;
  color: #adb0b8;
  display: flex;
  align-items: center;
  border: 1px solid rgba(207,213,222,1);
  border-radius: 6px;
  &:hover {
    background: #f4faff;
    border: 1px solid rgba(57,116,244,1);
    box-shadow: 0px 2px 10px 0px rgba(66,82,108,0.2);
    border-radius: 6px;
  }
  .template-logo {
    height: 32px;
    margin: 0px 14px 0 0;
    width: 32px;
    min-width: 32px;
    min-height: 32px;
    background: #FFF; // 这个是图标的颜色定义
    align-items: center;
    justify-content: center;
    // border: 1px solid rgba(207,213,222,1);
    box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    display: flex;
    img {
      width: 32px;
      border-radius: 8px;
      margin: auto;
    }
  }
  .template-title {
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    color: #1d202d;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
  }
  .template-description {
    height: 18px;
    font-size: 12px;
    color: #7D838E;
    letter-spacing: 0;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .circle-border {
    top: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--devui-line, #adb0b8);
    position: absolute;
    right: 10px;
    display: none;
  }
  // 选中的这个模板
  &-selected {
    background: #f4faff;
    border: 1px solid rgba(57,116,244,1);
    box-shadow: 0px 2px 10px 0px rgba(66,82,108,0.2);
    border-radius: 6px;
    .circle-border {
      // display: block;
      // border: 1px solid var(--devui-brand, #5e7ce0);
      .circle-select {
        width: 10px;
        height: 10px;
        margin: 2px auto;
        border-radius: 50%;
        background: var(--devui-brand, #5e7ce0);
      }
    }
  }
}
.already-selected {
  cursor: pointer;
}
.disabled-trigger {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  &:hover {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    border: 1px solid rgba(207,213,222,1);
    box-shadow: none;
  }
}
</style>