<!--
 * @Description: 
 * @Date: 2024-07-24 17:25:56
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 14:30:27
-->
<template>
  <div :class="['width-box ph25 bg-opacity-80']">
    <div class="flex items-center justify-between pt20">
      <span class="block font-ali-75 text-16 text-color-t1 flex-1">图标区域宽度</span>
      <div class="cursor-pointer w180 text-right hi-switch">
        <span class="font-ali-55 text12 text-color-t3 inline-block mgr12">锁定最大宽度</span>
        <a-switch
          class="hi-switch"
          size="small"
          :checked="globalSetting.desktopIcon.iconLock"
          @change="lock"
        />
      </div>
    </div>
     <div class="flex items-center justify-between mgt28 ph28">
      <span class="block font-ali-75 text-14 text-color-t2 flex-1">宽度</span>
      <div class="cursor-pointer w180 text-right hi-slider flex flex-col items-center">
        <div class="flex w-full justify-between font-ali-55 text-12 text-color-t3 mgb4">
          <span>窄</span>
          <span>宽</span>
        </div>
          
         <a-slider size="small" :value="globalSetting.desktopIcon.iconWidth"  :tip-formatter="null" @change="changeWidth" />
      </div>
    </div>
    <div class="mgt20 flex h-50 items-center justify-between footer">
      <div class="flex cursor-pointer items-center text-color-t2" @click="back">
        <a-icon size="small" class='text-10' type="double-left" />
        <span class="pl4">返回</span>
      </div>
      <div class="flex cursor-pointer items-center text-color-blue" @click="complete">
        <span class="pr4">完成</span>
        <a-icon size="small" class='text-10' type="double-right" />
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import store from '@/store/index'
import SettingBoxVue from '../SettingBox/index'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
  },
  data () {
    // 这里存放数据
    return {
      marks: {
        0: "窄",
        100: '宽'
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    changeWidth (val) {
     const desktopIcon = this.globalSetting.desktopIcon
      desktopIcon.iconWidth = val
      desktopIcon.iconLock = false
      store.commit("globalSetting/setDesktopIcon", desktopIcon)
    },
    lock (val) {
      const desktopIcon = this.globalSetting.desktopIcon
      desktopIcon.iconLock = val
      store.commit("globalSetting/setDesktopIcon", desktopIcon)
    },
    openSettingBox () {
      const self = this;
      let width = 720;
      let title = "";
      let content = <SettingBoxVue />
      const className = ['desktop-setting-box']

      this.$confirm({
        width: width,
        title: title,
        content: content,
        class: className,
        closable: false,
        mask: false,
        maskClosable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    back () {
      this.$destroyAll()
      this.openSettingBox()
    },
    complete () {
      this.$destroyAll()
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.width-box {
  .h-50 {
    height: 50px;
  }
  .footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
}
</style>
