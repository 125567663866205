/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-22 21:45:53
 * @LastEditTime: 2024-12-26 18:11:04
 * @FilePath: \awx-ui\src\utils\util.js
 */
import enquireJs from 'enquire.js'
import Vue from 'vue'
import store from '@/store'
import { guid } from './axios-peer'
export function isDef(v) {
  return v !== undefined && v !== null
}

/**
 * Remove an item from an array.
 */
export function remove(arr, item) {
  if (arr.length) {
    const index = arr.indexOf(item)
    if (index > -1) {
      return arr.splice(index, 1)
    }
  }
}

export function isRegExp(v) {
  return _toString.call(v) === '[object RegExp]'
}

export function enquireScreen(call) {
  const handler = {
    match: function () {
      call && call(true)
    },
    unmatch: function () {
      call && call(false)
    }
  }
  enquireJs.register('only screen and (max-width: 720px)', handler)
}
export function formatDuring1(mss) { //天时分秒
  var days = parseInt(mss / (1000 * 60 * 60 * 24));
  var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = parseInt((mss % (1000 * 60)) / 1000);
  return days + "d," + hours + "h," + minutes + "m," + seconds + "s";
}


export const downloadFile = (url, fileName = '', data = {}) => {
  var xhr = new XMLHttpRequest();
  let arr = []
  for (let key in data) {
    let item = key + '=' + data[key]
    arr.push(item)
  }
  xhr.open("get", `${url}?${arr.join('&')}`, true); // get、post都可
  xhr.setRequestHeader('token', localStorage.getItem('token'))
  // xhr.setRequestHeader('Accept-Encoding', 'gzip, deflate, br')
  // xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.responseType = "blob"; // 转换流
  xhr.onload = function () {
    if (this.status == 200) {
      const blob = new Blob([this.response], {
        type: "application/octel-stream"
      });
      var a = document.createElement("a")
      var url = window.URL.createObjectURL(blob)
      a.href = url
      if (fileName) a.download = fileName // 文件名
    }
    a.click()
    window.URL.revokeObjectURL(url)
  }
  xhr.send()
}

// 这个地方下载需要开通道
export const downloadFileBlob = (url, file = '', data = {}, config = {}) => {
  
  store.dispatch('fileManage/changeShowFileProgress', true)
  store.dispatch('fileManage/changeUpload', 'download')
  Vue.prototype.$axiosDownGet(url, {}, {
    token: localStorage.getItem('token'),
    responseType: 'blob',
    onDownloadProgress: function (progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      const uploadist = store.state.fileManage.downloadFileList
      console.log(`下载2222222222222222进度: ${percentCompleted}%`, file, uploadist)
      const index = uploadist.findIndex(item => item.fileId === file.fileId)
      if (index === -1) {
        uploadist.unshift({
          ...file,
          progress: percentCompleted,
          
        })
      } else {
        uploadist.splice(index, 1, {
          ...uploadist[index],
          progress: percentCompleted,
        })
      }
      store.dispatch('fileManage/changeDownloadFileList', uploadist)
    },
    rtcscope: "file"
  }).then(function (res) {
    console.log("the result is ", res);
    if (res.status != 200 && res.status != 201 && res.status != 204 && res.status != 202) {
      return
    }
    const blob = new Blob([res.data], {
      type: 'application/octet-stream'
    });
    const uploadist = store.state.fileManage.downloadFileList
    const index = uploadist.findIndex(item => item.fileId === file.fileId)
    if (index === -1) {
      uploadist.unshift({
        ...file,
        progress: 100,
      })
    } else {
      uploadist.splice(index, 1, {
        ...uploadist[index],
        progress: 100,
      })
    }
    store.dispatch('fileManage/changeDownloadFileList', uploadist)
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // link.innerHTML = "下载";
    link.download = file.name; // 假设文件名是filepath的最后一部分
    link.click(); // 触发下载
    // document.getElementById("downloadResult").innerText = JSON.stringify(res, null, 2);
  }).catch(function (error) {
    console.error("download error", error);
    document.getElementById("downloadResult").innerText = error;
  });
  // let arr = []
  // for (let key in data) {
  //   let item = key + '=' + data[key]
  //   arr.push(item)
  // }
  // fetch(`${url}?${arr.join('&')}`, {
  //     method: 'GET',
  //     headers: {
  //       'token': localStorage.getItem('token')
  //     }
  //   })
  //   .then(response => {
  //     if (response.ok) {
  //       return response.blob();
  //     } else {
  //       throw new Error('下载文件失败');
  //     }
  //   })
  //   .then(blob => {
  //     // 创建一个链接并设置下载属性
  //     var downloadLink = document.createElement('a');
  //     downloadLink.href = URL.createObjectURL(blob);
  //     downloadLink.download = fileName; // 替换为实际的文件名和扩展名
  //     downloadLink.style.display = 'none';
  //     // 添加链接到页面中并触发点击事件
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();

  //     // 清理资源
  //     URL.revokeObjectURL(downloadLink.href);
  //     document.body.removeChild(downloadLink);
  //   })
  //   .catch(error => {
  //     console.error('下载文件失败:', error);
  //   });
}
export function formatDuring3(result) {
  const y = Math.floor((result / (3600 * 24 * 30 * 12 * 1000)));
  const mon = Math.floor((result / (3600 * 24 * 30 * 1000)) % 12);
  const d = Math.floor((result / (3600 * 24 * 1000)) % 30);
  const h = Math.floor((result / 3600 / 1000) % 24);
  const m = Math.floor((result / 60 / 1000) % 60);
  const s = Math.floor((result / 1000) % 60);
  const ms = Math.floor(result % 1000);
  if (ms) {
    result = ms + "ms";
  }
  if (s > 0) {
    result = s + "s " + result;
  }
  if (m > 0) {
    result = m + "m " + result;
  }
  if (h > 0) {
    result = h + "h " + result;
  }
  if (d > 0) {
    result = d + "d " + result;
  }
  if (mon > 0) {
    result = mon + "月 " + result;
  }
  if (y > 0) {
    resulßßt = y + "年 " + result;
  }
  // if (s === 0 && result.length > 3) result = result.split('0')[0] 
  return result;
}
export function formatDuring(result, hide) {
  const units = [{
      label: 'ms',
      duration: 1
    },
    {
      label: 's',
      duration: 1000
    },
    {
      label: 'm',
      duration: 60 * 1000
    },
    {
      label: 'h',
      duration: 60 * 60 * 1000
    },
    {
      label: 'd',
      duration: 24 * 60 * 60 * 1000
    },
    {
      label: '月',
      duration: 30 * 24 * 60 * 60 * 1000
    },
    {
      label: '年',
      duration: 365 * 24 * 60 * 60 * 1000
    }
  ];

  let remainingDuration = result;
  let formattedDuration = '';

  for (let i = units.length - 1; i >= 0; i--) {
    const {
      label,
      duration
    } = units[i];
    if (remainingDuration >= duration) {
      const value = Math.floor(remainingDuration / duration);
      remainingDuration %= duration;
      if (!(label === 'ms' && result > 1000 && hide)) formattedDuration += `${value}${label} `;
    }
  }
  return formattedDuration.trim();
}
//获取滚动条宽度或高度
let scrollbarVerticalSize = 0;
let scrollbarHorizontalSize = 0;
// Measure scrollbar width for padding body during modal show/hide
let scrollbarMeasure = {
  position: 'absolute',
  top: '-9999px',
  width: '50px',
  height: '50px'
};
export function measureScrollbar(_ref, container = document.body) {
  var _ref$direction = _ref.direction,
    direction = _ref$direction === undefined ? 'vertical' : _ref$direction,
    prefixCls = _ref.prefixCls;

  if (typeof document === 'undefined' || typeof window === 'undefined') {
    return 0;
  }
  var isVertical = direction === 'vertical';
  if (isVertical && scrollbarVerticalSize) {
    return scrollbarVerticalSize;
  }
  if (!isVertical && scrollbarHorizontalSize) {
    return scrollbarHorizontalSize;
  }
  var scrollDiv = document.createElement('div');
  Object.keys(scrollbarMeasure).forEach(function (scrollProp) {
    scrollDiv.style[scrollProp] = scrollbarMeasure[scrollProp];
  });
  // apply hide scrollbar className ahead
  scrollDiv.className = prefixCls + '-hide-scrollbar scroll-div-append-to-body';

  // Append related overflow style
  if (isVertical) {
    scrollDiv.style.overflowY = 'scroll';
  } else {
    scrollDiv.style.overflowX = 'scroll';
  }
  container.appendChild(scrollDiv);
  var size = 0;
  if (isVertical) {
    size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    scrollbarVerticalSize = size;
  } else {
    size = scrollDiv.offsetHeight - scrollDiv.clientHeight;
    scrollbarHorizontalSize = size;
  }

  container.removeChild(scrollDiv);
  return size;
}

export function formatBytes(a, b) {
  if (0 == a) return "0 B";
  var c = 1024,
    d = b || 2,
    e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f]
}
const _toString = Object.prototype.toString
// 比较函数的实现
export function compareNames(a, b) {
  // 将名称转换为小写形式，以便忽略大小写进行比较
  a = a.toLowerCase();
  b = b.toLowerCase();

  // 使用正则表达式分离中文、英文和数字部分
  var pattern = /[\u4e00-\u9fa5]/g; // 中文字符的正则表达式
  var aChinese = a.match(pattern).join(''); // 提取a中的中文字符并拼接为字符串
  var bChinese = b.match(pattern).join(''); // 提取b中的中文字符并拼接为字符串

  // 先按中文字符进行比较
  if (aChinese !== bChinese) {
    return aChinese.localeCompare(bChinese, 'zh-CN');
  }

  // 再按英文和数字进行比较
  return a.localeCompare(b, 'en', {
    numeric: true
  });
}