<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-10-26 11:11:25
 * @LastEditTime: 2023-11-03 11:32:20
 * @FilePath: \awx-ui\src\components\workflow-design\components\node\parallel.vue
-->
<template>
  <div class="workflow-node">
    <!-- <div class="name">IF Step</div> -->
    <a-button type="primary" class="mgb8" @click="addBranch">添加分支</a-button>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" class='parallel-form'>
      <div v-for="(item, index) in conditionList" :key="index">
        <a-form-model-item label="分支名称">
          <!-- <a-input :value="item.name" @change="(val) => changeName(item, index, val)" /> -->
          {{item.name}} <span class='mgl8 edit-name' @click="editName(item.name)"><svg-icon icon-class='flow-edit'></svg-icon></span>
        </a-form-model-item>
        <a-form-model-item
          v-for="(child, childIndex) in item.condition"
          style="margin-bottom: 0px"
          :key="childIndex"
          v-bind="childIndex === 0 ? labelCol : formItemLayoutWithOutLabel"
          :label="childIndex === 0 ? '分支条件' : ''"
        >
          <a-row type="flex" style="position: relative">
            <a-col :span="10">
              <a-form-model-item style="width: 97%">
                <a-input
                  v-model="child.key"
                  placeholder="请输入"
                  :disabled="child.key === 'expression'"
                  onkeyup="this.value=this.value.replace(/[^\w_]/g,'');"
                  @change="changeItemCondition(index)"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item style="width: 97%">
                <a-input
                  v-model="child.val"
                  placeholder="请输入"
                  @change="changeItemCondition(index)"
                />
              </a-form-model-item>
            </a-col>
            <span :style="{'position': 'absolute', 'right': item.condition.length > 1 && child.key !== 'expression' ? '0px': '36px', 'top': '1px'}">
              <a-button type="dashed" @click="() => addMultiple(childIndex, index)" style="width: 30px; padding: 0;font-size: 18px;">
                <a-icon type="plus" />
              </a-button>
              <a-button v-show="item.condition.length > 1 && child.key !== 'expression'" class="mgl6" type="dashed" style="width: 30px; padding: 0;font-size: 18px;" @click="() => reduceMultiple(childIndex, index)">
                <a-icon type="minus" />
              </a-button>
            </span>
        </a-row>
      </a-form-model-item>
        <div class="del-container">
          <a-button class="del-branches" @click="delBranches(index, item)">删除分支</a-button>
        </div>
      </div>
    </a-form-model>
  </div>
</template>
<script>
import SvgIcon from '@/icons/SvgIcon.vue';
import { mapState, mapMutations, mapActions } from "vuex";
import AddBranch from './components/addBracnch.vue'
export default {
  components: { SvgIcon },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      conditionList: [],
      oldConditionList: [],
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      form: {
        conditions: "",
      },
    };
  },
  computed: {
     ...mapState({
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    currentWinboxKey () {
      // const currentFocusWindow = this.windowStore.currentFocusWindow;
      // let key = null
      // for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  mounted() {
    const stepInfo = _.cloneDeep(this.winboxTask.stepInfo);
    for (var key in stepInfo.taskTemplateDetail.stepInfo.properties.model.conditions) {
      this.conditionList.push({
        name: key,
        condition: this.handlerObjToArr(stepInfo.taskTemplateDetail.stepInfo.properties.model.conditions[key]),
      });
    }
  },
  methods: {
    ...mapActions("task", ["setStepInfo"]),
    ...mapActions("winboxFlowModule", ["setWinboxStepInfo"]),
    handlerArrToObj (arr) {
      const obj = {}
      for (var i in arr) {
        obj[`${arr[i].key}`] = arr[i].val
      }
      return obj
    },
    handlerObjToArr (obj) {
      const arr = []
      for (var i in obj) {
        if (i) {
          arr.unshift({
            key: i,
            val: obj[i],
            randow: Math.floor(Math.random() * 100000),
          })
        }
      }
      return arr
    },
    changeItemCondition (index) {
      const arr = _.cloneDeep(this.conditionList[index].condition)
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo);
      const conditions = stepInfo.taskTemplateDetail.stepInfo.properties.model["conditions"]
      const obj = this.handlerArrToObj(arr)
      conditions[`${this.conditionList[index].name}`] = obj
      this.setStepInfo(stepInfo);
      this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});
      this.$EventBus.$emit(
        "updateStepsProperties",
        stepInfo.taskTemplateDetail.stepInfo.properties
      );
    },
    reduceMultiple(childIndex, index) {
      this.conditionList[index].condition.splice(childIndex, 1);
      this.changeItemCondition(index)
    },
    addMultiple(childIndex, index) {
      this.conditionList[index].condition.push({
        key: "",
        val: "",
        randow: Math.floor(Math.random() * 100000),
      });
      this.changeItemCondition(index)
    },
    // 删除分支
    delBranches (index, item) {
      const self = this
      if (this.conditionList.length === 1) return this.$message.warning('请至少保留一个分支')
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo);
      const conditions = stepInfo.taskTemplateDetail.stepInfo.properties.model["conditions"]
      this.$confirm({
        title: '确定删除这个分支吗?',
        content: '',
        okText: '确定',
        class:'del-branch',
        cancelText: '取消',
        onOk() {
          self.conditionList.splice(index, 1)
          delete conditions[`${item.name}`]
          self.setStepInfo(stepInfo);
          this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});
          self.$EventBus.$emit(
            "deleteStepsChlidren",
            item.name,
            stepInfo.taskTemplateDetail.stepInfo.properties,
          );
        }
      });
    },
    // 编辑分支名称
    editName (name) {
      const self = this;
      let width = 520;
      let title = "修改名称";
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo);
      const conditions = stepInfo.taskTemplateDetail.stepInfo.properties.model["conditions"]
      let content = <AddBranch name={name} conditions={conditions} callBack={(form, oldName) =>  self.resolveAddBranch(form, oldName)} />
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    // 添加新的分支
    addBranch () {
      const self = this;
      let width = 520;
      let title = "新建分支";
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo);
      const conditions = stepInfo.taskTemplateDetail.stepInfo.properties.model["conditions"]
      let content = <AddBranch conditions={conditions} callBack={(form) =>  self.resolveAddBranch(form)} />
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    resolveAddBranch (form, oldName) {
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo);
      const conditions = stepInfo.taskTemplateDetail.stepInfo.properties.model["conditions"]
      if (oldName) {
        if (oldName === form.name) return false
        const index = this.conditionList.findIndex(item => item.name === oldName)
        this.conditionList[index].name = form.name
        conditions[`${form.name}`] =conditions[`${oldName}`]
        delete conditions[`${oldName}`]
        this.setStepInfo(stepInfo);
        this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});
        this.$EventBus.$emit(
          "updateStepsChildrenByStepId",
          form.name,
          stepInfo.taskTemplateDetail.stepInfo.properties,
          oldName
        );
        return false
      }
      this.conditionList.push({
        name: form.name,
        condition: [{
          key: 'expression',
          val: ''
        }]
      })
      conditions[`${form.name}`] = {expression: ''}
      this.setStepInfo(stepInfo);
      this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});
      this.$EventBus.$emit(
        "updateStepsChildrenByStepId",
        form.name,
        stepInfo.taskTemplateDetail.stepInfo.properties
      );
    }
  },
};
</script>
<style lang="less" scoped>
.workflow-node {
  
  .name {
    font-size: 18px;
    font-weight: 550;
  }
  .parallel-form {
    max-height: calc(100vh - 268px);
    padding-right: 55px;
    overflow-y: auto;
    .edit-name {
      color: @primary-color;
      cursor: pointer;
    }
  }
  .del-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
    .del-branches {
    
    }
  }

}
</style>
<style lang="less">
  .del-branch {
    .ant-modal-confirm-body > .anticon {
      margin-right: 12px;
      margin-left: 6px;
    }
    .ant-modal-confirm-btns {
        display: block;
    }
    .ant-modal-body .ant-modal-confirm-title {
      padding: 0;
      background: none;
    }
    .ant-modal-body {
        padding: 8px 16px 16px 8px!important;
      .ant-modal-confirm-body {
        display: flex;
        justify-items: center;
        align-items: center;
      }
    }
  }
</style>