<!--
 * @Author: mjzhu
 * @describe: step2-主机环境校验 
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2025-01-10 14:49:48
 * @FilePath: \awx-ui\src\pages\appManage\index.vue
-->
<template>
  <div class="network-page h-full" @click="hidePop">
    <a-spin :spinning="spinning" style="width: 100%;height: 100%">
      <div class="rf h-full">
        <a-tabs v-model="appTab" @change="callback" :class="[appTab === 'all' && 'all-tab']" :tabBarStyle="{borderBottom: '1px solid #cfd5de'}">
          <a-tab-pane v-for="(item) in allAppCate" :tab="item.name" :key="item.catagory"></a-tab-pane>
        </a-tabs>
        <!-- <a-dropdown v-if="allAppCate.length > 15" :trigger="['click']">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <span class="tabs-more">更多</span>
            <a-icon class="tabs-icon" type="down" />
          </a>
          <a-menu slot="overlay" v-model="appTabArr">
            <a-menu-item v-for="(item) in moreList" :key="item.id">
              <div @click="changeMore">{{ item.name }}</div>
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
        <ChooseApp ref="ChooseAppRef" :systemTAppist="systemAppList" :current="current" @loadMore="loadMore" :appCate='appCate' @getApp="getApp" />
      </div>
  </a-spin>
    <!-- 新建部署任务 -->
    <a-modal class="task-model" v-if="visible" title="" :visible="visible" :maskClosable="false" :closable="false" :width="clientWidth < 1700 ? 1280 : 1326"  :confirm-loading="confirmLoading" @cancel="handleCancel" :footer="null">
      <CreateNewtask taskType="app" />
    </a-modal>
  </div>
</template>
<script>
import ChooseApp from "./chooesApp";
import CreateNewtask from "@/components/createTask/deploystep/newCreateTask.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { get } from 'js-cookie';
const clientWidth = document.documentElement.clientWidth
export default {
  props: {
    steps1Data: Object,
    kind: {
      default: -1,
      type: Number
    },
    keyword: String
  },
  data() {
    return {
      clientWidth,
      current: 1,
      size: 27,
      total: 0,
      appTab: "all",
      appName: "",
      timer: null,
      spinning: false,
      systemLength: 1,
      customLength: 0,
      cateType: "",
      appCate: [],
      allAppCate: [],
      appTabArr: [],
      selectedTemplateId: "",
      systemAppList: [
      ],
      moreList: [],
      visible: false,
      taskModalTitleL: '新建任务',
      confirmLoading: false,
    };
  },
  provide() {
    return {
      getApp: this.getApp,
      createTask: this.createTask,
      handleCancel: this.handleCancel,
      onlyCancel: this.handleCancel
    };
  },
  watch: {
    // keyword: {
    //   handler(val) {
    //    this.getCatagories();
    //    this.getApp(this.appTab);
    //   }
    // },
    kind: {
      handler(val) {
       this.appTab = 'all'
       this.getCatagories();
       this.reset()
       this.getApp(this.appTab);
      }
    }
  },
  computed: {},
  components: { ChooseApp, CreateNewtask },
  methods: {
    ...mapActions("task", ["setTaskInfo"]),
    handleCancel(e) {
      this.visible = false;
    },
    hidePop () {
      this.$refs.ChooseAppRef.showPopId = ''
    },
    createTask(obj) {
      this.$axiosGet(
          global.API.curdTaskTemplate + obj.template_id + "/",
          {}
        ).then((res) => {
          if (
            [200, 201, 204, 202].includes(res.status) &&
            res.data &&
            res.data.operations &&
            res.data.operations.length > 0
          ) {
            this.visible = true;
            this.taskModalTitle = "新建任务";
            let taskTemplateDetail = res.data
            taskTemplateDetail.operations.forEach(item => {
              if (item.application_id) {
                item['currentStepsIndex'] = 1
              }
              if (item.id) {
                item['currentStepsIndex'] = 2
              }
              if (item.application_id && item.id) {
                item['currentStepsIndex'] =  4
              }
            })
            this.setTaskInfo({
              ...obj,
              installAppid: obj.id,
              taskTemplateDetail: taskTemplateDetail,
            });
          }
        });
    },
    changeMore (item) {
      this.appTab = item.catagory
      this.appTabArr = []
      this.appTabArr.push(item.catagory)
      this.reset()
      this.getApp(item.catagory)
    },
    callback(key) {
      this.appTab = key;
      this.reset()
      this.getApp(key);
    },
    getCatagories() {
      this.$axiosGet(global.API.getAppCatagories, {
        page: 1,
        page_size: 1000,
        kind: this.kind,
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.appCate = [
            { catagory: "all", name: "全部" },
          ];
          res.data.results.map((item) => {
            this.appCate.push(item);
          });
          let arr = _.cloneDeep(this.appCate);
          this.allAppCate = arr
          this.appCate = arr.slice(0, 15);
          this.moreList = arr.slice(15, arr.length);
        }
      });
    },
    loadMore (current) {
      this.current = current
      this.getApp(this.appTab, 'loadMore')
    },
    getApp(key, loadMore) {
      this.spinning = true;
      const params = {
        page_size:this.current === 1 ? this.size : 27,
        page: this.current,
        kind: this.kind,
        keyword: this.keyword || "",
        order_by: 'id'
      };
      if (key && key !== 'all') params.catagory = this.appTab;
      this.$axiosGet(global.API.getApp, params).then((res) => {
        this.spinning = false;
        if (loadMore) this.$refs.ChooseAppRef.loadingMore = false
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results.length === 0 && loadMore) this.$refs.ChooseAppRef.allDataLoaded = true
          if (loadMore) {
            this.systemAppList = this.systemAppList.concat(res.data.results);
          } else {
            this.systemAppList = res.data.results;
            this.systemAppList.unshift({
              "account_need": true,
              "application": "add",
              "description": "设置应用基本信息>选择应用安装模版",
              id: "add",
              name: "新建应用"
            })
          }
        }
      });
    },
    reset () {
      this.current = 1
      this.$refs.ChooseAppRef.scrollToTop()
      this.$refs.ChooseAppRef.allDataLoaded = false
    },
    search () {
       this.getCatagories();
       this.reset()
       this.getApp(this.appTab);
    },
  },
  mounted() {
    this.getCatagories();
    this.reset()
    this.getApp('');
  },
  beforeDestroy() {
  },
};
</script>
<style lang="less" scoped>
.network-page {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  .flex-warp {
    display: flex;
  }
  .rf {
    position: relative;
    padding: 6px 0 0 0;
  }
  .pagination-footer {
    height: 72px;
    border-top: 1px solid #dfe8f3;
    text-align: center;
    padding: 20px 0 0 0;
  }
  /deep/ .ant-spin-container {
    height: 100%;
  }
  /deep/ .ant-tabs-tab {
    font-size: 14px;
    color: #34343c;
  }
  /deep/ .ant-tabs-nav .ant-tabs-tab-active {
    color: #3974f4;
    font-weight: 600;
  }
  /deep/ .ant-tabs-ink-bar {
    height: 3px;
  }
  .all-tab {
    /deep/ .ant-tabs-ink-bar {
      width: 28px !important;
    }
  }
  /deep/ .ant-tabs {
    margin: 0 10px;
  }
  .tabs-more {
    font-size: 14px;
    color: #34343c;
    margin-right: 6px;
  }
  .tabs-icon {
    font-size: 14px !important;
    color: #34343c;
  }
  .ant-dropdown-link {
    position: absolute;
    right: 0;
    top: 18px;
    z-index: 100000;
  }
}
</style>