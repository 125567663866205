<!--
 * @Description: 
 * @Date: 2024-11-08 11:05:35
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 14:14:36
-->
<template>
  <div class='sync-setting-radio bg-color-b5 h-full'>
    <div class="header border-color-m2 border-opacity-008">选择数据</div>
    <div class="pdh24">
      <div class="text-color-t3 mgb16">检测到云端和本地数据不一致，请选择合并或者覆盖。</div>
      <a-radio-group v-model="value" @change="onChange">
        <a-radio class="text-color-t2" :style="radioStyle" value="merge">
          合并数据
        </a-radio>
        <a-radio class="text-color-t2" :style="radioStyle" value="cover">
          覆盖数据
        </a-radio>
      </a-radio-group>
    </div>
    <div class="btn-container">
      <a-button class="btn bg-color-red text-color-white btn-exit" @click="confirm">确定</a-button>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    callBack: Function,
  },
  data () {
    // 这里存放数据
    return {
      value: 'merge',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    onChange (e) {
      this.value = e.target.value
    },
    confirm () {
      this.callBack(this.value)
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.sync-setting-radio {
  border-radius: 16px;
  padding-bottom: 24px;
  .pdh24 {
    padding: 0 24px;
  }
  .mgt18 {
    margin-top: 18px;
  }
  .header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    margin-bottom: 24px;
  }
  .btn-container {
    display: flex;
    margin-top: 24px;
    padding: 0 24px;
    justify-content: flex-end;
    .btn {
      width: 120px;
      padding: 0 20px;
      font-size: 16px;
      line-height: 32px;
      height: 36px;
      margin: 0 0 0 12px;
      border: none;
      border-radius: 6px;
    }
    .btn-cancel {
      &:hover, &:active {
        color: rgba(var(--color-t3), var(--tw-text-opacity));
        background: rgba(var(--color-m1), var(--tw-bg-opacity));
      }
    }
    .btn-exit {
      &:hover, &:active {
        color: #fff;
        background-color: rgb(255, 131 ,121);
      }
    }
  }
}
</style>
