<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-27 16:03:30
 * @LastEditTime: 2024-11-11 17:52:00
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appIcon\solidIcon.vue
-->
<template>
  <div class="solid-icon">
    <div class="icon-warp">
      <div class='icon-item' :style="{
        'background': colors.hex,
        'text-align': 'center',
      }">
      <span :style="{
        'color': '#fff',
        'font-size': app_name.length === 1 ? '22px' : '16px',
        'width': '100%'
      }">{{ app_name }}</span>  
    </div>
    </div>
    <div class='color-picker'>
        <!-- <p>Chrome</p>
        <chrome-picker v-model="colors" /> -->
        <twitter-picker v-model="colors" :defaultColors="colorOptions" :width='400' @input="change" />
      </div>
  </div>
</template>

<script>
import {
  Chrome,
  Twitter,
} from "vue-color";

export default {
  name: "color-picker",
  components: {
    // "chrome-picker": Chrome,
    "twitter-picker": Twitter,
  },
  props: {
    appDetail: Object,
    name: String,
    value: String
  },
  data() {
    return {
      app_name: '',
      colorOptions: ['#3974f4', '#FF6900','#00D084', '#9900EF', '#ff0000', '#00ff00', '#0000ff', "#0693E3", "#ABB8C3", "#EB144C", "#F78DA7", "#16e635", "#e616e2", "#2a16e6", "#333", "#0541ea"], // 自定义颜色选项
      colors:  {
        hex: '#3974f4',
        hex8: '#3974F4FF',
        hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
        hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
        rgba: { r: 25, g: 77, b: 51, a: 1 },
        a: 1
      }
    };
  },
  watch: {
    appDetail: {
      handler (val) {
        const icon = val?.icon || val?.url
        if (val && icon && this.value === '2') {
          this.colors = {
            hex: icon
          }
        }
      },
      deep: true, 
      immediate: true
    },
    value: {
      handler (val) {
        if (val === '2') {
          this.$emit('getIcon', this.colors.hex)
        }
      },
      immediate: true
    },
    name: {
      handler(val) {
        if (val) {
          this.app_name = val.length === 1 ? val : val.substring(0, 2)
        } else {
          this.app_name = ''
        }
      }
    }
  },
  computed: {
  },
  methods: {
    // 值改变事件
    change(value) {
      this.$emit('getIcon', this.colors.hex)
    },
  },
};
</script>

<style scoped lang='less'>
.color {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  div {
    margin: 20px;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid #eee;
    padding: 10px;
  }
}
.solid-icon {
  margin-top: 10px;
  width: 100%;
  height: 204px;
  border: 1px dashed rgba(196, 202, 211, 1);
  border-radius: 0 0 4px 4px;
  padding: 16px 24px;
  .color-picker {
    display: flex;
    justify-content: center;
  }
  .icon-warp {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    .icon-item {
      position: relative;
      cursor: pointer;
      height: 40px;
      margin: 0px 12px 0 0;
      width: 40px;
      min-width: 40px;
      text-align: center;
      line-height: 40px;
      background: #fff; // 这个是图标的颜色定义
      border-radius: 6px;
      display: flex;
    }
  }
}
</style>