<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-27 15:06:50
 * @LastEditTime: 2024-11-12 17:55:43
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appBaseInfo.vue
-->
<template>
    <a-form-model ref="baseInfoFrom" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" class="form-content-app" :rules="rules">
      <a-form-model-item label="链接" prop="app_addr">
        <a-input v-model="form.app_addr" placeholder="请填写链接" @change="changeUrl"/>
      </a-form-model-item>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" placeholder="请填写名称" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" placeholder="请输入描述信息，最多不超过1000个字符" type="textarea" :rows="4" :maxLength="1000" />
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" class="icon-label">图标
        </div>
        <AppIcon :appDetail="appDetail" :name="form.name" :app_addr="form.app_addr" @getIcon="getIcon" />
      </a-form-model-item>
    </a-form-model>
</template>
<script>
import AppIcon from './appIcon/index.vue'
import Regex from '@/utils/regex'
export default {
  props: {
    appDetail: Object,
    formType: {
      type: String,
      default: 'dialog'
    }
  },
  components: {
    AppIcon
  },
  data() {
    const self = this;
    return {
      rules: {
        name: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        name_en: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        app_addr: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
          { validator: this.validateUrl, trigger: ["change", "blur"] },
        ],
        app_version: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        kind: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        categories: [
          {
            required: false,
            trigger: ["change", "blur"],
          },
        ],
        tags: [
          {
            required: false,
            trigger: ["change", "blur"],
          },
        ],
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
      form: {
        name: '',
        // name_en: '',
        app_addr: '',
        // app_version: '',
        description: '',
        icon: '',
        // kind: undefined,
        categories: [],
        tags: []
      },
      categoriesList: [],
      tagsList: [],
      iconType: '1'
    };
  },
  watch: {
    formType: {
      handler (val) {
        if (val === 'page') {
          this.labelCol = {
            xs: { span: 24 },
            sm: { span: 2 },
          }
          this.wrapperCol = {
            xs: { span: 24 },
            sm: { span: 22 },
          }
        }
      },
      immediate: true
    }
  },
  computed: {
  },
  methods: {
    changeIp(e) {
      var reg = /\[(.+?)\]/g;
      if (reg.test(e.target.value)) {
        this.isDisabled = true;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: e.target.value,
          });
        });
      } else {
        this.isDisabled = false;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: "",
          });
        });
      }
    },
    changeUrl () {
      const reg = /^[A-Za-z0-9].*[.:].*/
      if (this.form.app_addr && reg.test(this.form.app_addr)) {
        this.$EventBus.$emit("getfavicons", this.form.app_addr);
      }
    },
    getIcon (icon, type) {
      console.log(type, icon)
      this.iconType = type
      this.form.icon = icon
    },
    validateUrl (rule, value, callback) {
      const reg = /^[A-Za-z0-9].*[.:].*/
      if (!value || (reg.test(value))) {
        callback();
      } else {
        callback(new Error("请输入正确的地址"));
      }
    },
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
  },
  mounted() {
    this.$EventBus.$on("setName", (name) => {
      this.form.name = name
    });
    if (this.appDetail && this.appDetail?.id) { 
      this.form.name = this.appDetail.name
      this.form.id = this.appDetail.id
      this.form.app_addr = this.appDetail?.ExternalComponent?.externalUrl
      this.form.icon = this.appDetail?.url
      this.form.description = this.appDetail.description
    }
  },
  beforeDestroy() {
    this.$EventBus.$off("setName")
  }
};
</script>
<style lang="less" scoped>
.form-content-app {
  max-height: calc(100% - 52px);
  height: calc(100% - 52px);
  overflow-y: auto;
  /deep/ .ant-form-item-label > label {
    color: #34343C;
  }
  .icon-label {
    display: flex;
    align-items: center;
    position: relative;
    top: -42px;
    left: 14px;
    .icon-tips {
      position: relative;
      top: -1px;
      left: 4px;
      display: inline-block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background: #C4CAD3;
      line-height: 14px;
      text-align: center;
      color: #fff;
      font-size: 10px;
    }
  }
}
/deep/.ant-modal-footer {
  display: none;
}
/deep/ .ant-form-item-label > label::after {
  content: '';
}
/deep/ .ant-form-item {
    display: flex;

    .ant-col-sm-2 {
      width: 90px;
    }
    .ant-col-sm-22 {
      width: initial;
      flex: 1;
    }
}
.two-form-item {
  /deep/ .ant-form-item-control-wrapper {
    width: 100%;
  }
}
</style>
