/*
 * @Description: 
 * @Date: 2024-07-05 14:39:50
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-08-16 14:41:21
 */
export const DASHBOARD_COMPONENT = new Map([
  ['task', {
    url: require("@/assets/img/desktop/desktop-task.svg"),
    name: "任务",
    fileCompontentType: "customPageComponent",
    customPageComponent: {
      isFileGroup: false,
      FileGroupName: 'task-manage',
      FileComponentPath: '@/pages/taskManage/index'
    }, 
    componentName: 'MyDynamicComponent',
    id: 'task-manage-dashboard',
  }],
  ['dispatch', {
    url: require("@/assets/img/desktop/desktop-task.svg"),
    name: "调度",
    fileCompontentType: "customPageComponent",
    customPageComponent: {
      isFileGroup: false,
      FileGroupName: 'task-manage',
      FileComponentPath: '@/pages/dispatch/index'
    }, 
    componentName: 'MyDynamicComponent',
    id: 'dispatch-dashboard',
  }],
  ['host', {
    url: require("@/assets/img/desktop/desktop-resource.svg"),
    name: "主机",
    fileCompontentType: "customPageComponent",
    customPageComponent: {
      isFileGroup: false,
      FileGroupName: 'resource',
      FileComponentPath: '@/pages/groupManage/index'
    }, 
    componentName: 'MyDynamicComponent',
    id: 'resource-dashboard',
  }],
  ['master', {
    url: require("@/assets/img/desktop/desktop-resource.svg"),
    name: "网络",
    fileCompontentType: "customPageComponent",
    customPageComponent: {
      isFileGroup: false,
      FileGroupName: 'resource',
      FileComponentPath: '@/pages/securityCenter/master'
    }, 
    componentName: 'MyDynamicComponent',
    id: 'master-dashboard',
  }],
  ['template', {
    url: require("@/assets/img/desktop/desktop-task.svg"),
    name: "模板",
    fileCompontentType: "customPageComponent",
    customPageComponent: {
      isFileGroup: false,
      FileGroupName: 'task-manage',
      FileComponentPath: '@/pages/templateShop/index'
    }, 
    componentName: 'MyDynamicComponent',
    id: 'template-dashboard',
  }]
])