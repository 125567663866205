<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-08-08 16:35:43
 * @FilePath: \awx-ui\src\pages\securityCenter\user.vue
-->

<template>
  <div class="auth-manage-page">
    <a-row type="flex" align="middle">
      <a-col class="flex-wrap" :span="16">
        <a-button @click="onSearch">刷新</a-button>
        <div class="add mgl12">
          <a-button class="btn-add" icon="plus" type="primary" @click.stop="add">新建</a-button>
          <div v-if="showDrawer" class="add-drawer">
            <a-auto-complete
              style="width: 280px"
              placeholder="请选择应用"
              @select="onSelect"
              autoFocus
              :value="selectApp.name"
              @blur="showDrawer = false"
              @search="onAddSearch"
              @change="onChange"
              :open="true"
              :defaultOpen="true"
              class="add-auto"
            >
              <template slot="dataSource">
                <a-select-option v-for="item in dataSource" :key="item.id">
                  <div class="flex-warp-auth-drawer">
                    <span v-if="!IsColorIcon(item.icon) && !IsNetWorkIcon(item.icon)" class="img-custom-warp">
                      <img :src="item.icon" alt />
                    </span>
                    <div v-if="IsColorIcon(item.icon) && !IsNetWorkIcon(item.icon)" class='icon-item' :style="{
                      'background': item.icon,
                      'text-align': 'center',
                      'width': '20px',
                      'height': '20px',
                      'border-radius': '4px',
                      'margin': '0 6px 0 0',
                      'line-height': iconColorName(item.name).length === 1 ? '20px' : '17px',
                    }">
                        <span :style="{
                          'color': '#fff',
                          'font-size': iconColorName(item.name).length === 1 ? '14px' : '10px',
                          'display': 'inline-block',
                        }">{{ iconColorName(item.name) }}</span>  
                    </div>
                     <!-- 网络图标 -->
                    <span  v-if="!IsColorIcon(item.icon) && IsNetWorkIcon(item.icon)" class="img-custom-warp">
                      <iconifyIcon :icon="iconNetWorkName(item.icon)" style="font-size: 14px;" />
                    </span>
                    <span>{{ item.name }}</span>
                  </div>
                </a-select-option>
              </template>
            </a-auto-complete>
          </div>
        </div>
      </a-col>
      <a-col :span="8" style="text-align: right">
        <a-input-search placeholder="请输入关键词查询" class="w470" @change="(value) => getVal(value, 'keyword')" allowClear @search="onSearch" />
      </a-col>
    </a-row>
    <div class="table-info">
      <a-table class="table-15 opt-table" @change="tableChange" :columns="columns" :loading="loading" :dataSource="tableData" rowKey="id" :customRow="handleClickRow" :pagination="pagination"></a-table>
    </div>
    <a-modal v-if="showModal" :title="title" :visible="showModal" :width="640" :maskClosable="false" :closable="true" :confirm-loading="confirmLoading" @cancel="handleCancel" :footer="null">
      <div class="auth-modal">
        <common-form ref="commonForm" :selectApp="selectApp" :authModel="model" :authSelectedTemplateObj="selectedTemplateObj" :authDetail="authDetail" @getFormData="getFormData" />
        <div class="footer-param">
          <a-button class="mgr10" @click="handleCancel">取消</a-button>
          <a-button class="mgr10" type="primary" :loading="confirmLoading" @click="confirmItem">{{ '确定' }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import CommonForm from './commonForm';
import DeleteAuthMoadl from './deleteAuth'
import { mapState, mapGetters } from "vuex";

export default {
  name: "AuthManage",
  data() {
    return {
      authDetail: {},
      params: {},
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      selectApp: {
        name: '',
        id: ''
      },
      title: '新建授权',
      showDrawer: false,
      showModal: false,
      setSelectedIndex: -1,
      confirmLoading: false,
      dataSource: [],
      allDataSource: [],
      tableData: [],
      appId: '',
      loading: false,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 70,
          customRender: (text, row, index) => {
            return (
              <span>
                {parseInt(
                  this.pagination.current === 1
                    ? index + 1
                    : index +
                        1 +
                        this.pagination.pageSize * (this.pagination.current - 1)
                )}
              </span>
            );
          },
        },
        { title: "授权名称", key: "name", dataIndex: "name",
          customRender: (text, row, index) => {
            return (
              <div class="flex-wrap">
                {!this.IsColorIcon(row.app_icon) && !this.IsNetWorkIcon(row.app_icon) ?
                  (<span class="img-custom-warp">
                    <img src={row.app_icon} alt />
                  </span>
                  )
                  : this.IsColorIcon(row.app_icon) && !this.IsNetWorkIcon(row.app_icon) ?
                    (<div class='icon-item'
                      style={
                        {
                          background: row.app_icon,
                          'text-align': 'center',
                          'width': '24px',
                          'height': '24px',
                          'border-radius': '4px',
                          'margin': '0 6px 0 0',
                          'line-height': this.iconColorName(row.name).length === 1 ? '24px' : '21px'
                        }
                      }
                    >
                      <span
                        style={
                          {
                            'display': 'inline-block',
                            'color':'#fff',
                            'font-size': this.iconColorName(row.name).length === 1 ? '15px' : '12px',
                          }
                        }
                      >{ this.iconColorName(row.name) }</span>  
                    </div>)
                    : (
                      <span class="img-custom-warp">
                        <iconifyIcon icon={this.iconNetWorkName(row.app_icon)} style="font-size: 18px;" />
                      </span>
                    )
                } 
                <span>{row.name}</span>
              </div>
            );
          },
        },
        {
          title: "描述",
          key: "description",
          ellipsis: true,
          dataIndex: "description",
          customRender: (text, row, index) => {
            return (
              <div>
               {row.description || '-'}
              </div>
            );
          },
        },
        {
          title: "所属应用",
          key: "app_name",
          dataIndex: "app_name",
          customRender: (text, row, index) => {
            return (
              <div>
               {row.app_name ? row.app_name : '-'}
              </div>
            );
          },
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          ellipsis: true,
          customRender: (text) => {
            return <span title={moment(text).format("YYYY-MM-DD HH:mm:ss")}>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>;
          },
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          customRender: (text, row, index) => {
            return row.userType !== 1 ? (
              <span class="flex-container">
                <a class="btn-opt" onClick={(e) => this.editAuth(row, e)}>
                  编辑
                </a>
                <a-divider type="vertical" />
                <a class="btn-opt" onClick={(e) => this.deleteAuth(row, e)}>
                  删除
                </a>
              </span>
            ) : (
              <span class="flex-container">
                <a class="btn-opt" style="color: #bbb">
                  编辑
                </a>
                <a-divider type="vertical" />
                <a class="btn-opt" style="color: #bbb">
                  删除
                </a>
              </span>
            );
          },
        },
      ],
      isSuperuser: false,
    };
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
    }),
    scrollTable () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max) height = document.documentElement.clientHeight
      if (full) height = document.documentElement.clientHeight
     let scroll = {}
      if (this.dataSource.length > 0)  {
        scroll = {
          y: height - 220,
          x: 1600
        }
      }
      return scroll
    },
  },
  components: {
    CommonForm
  },
  methods: {
    getFormData (formData) {
      this.formData = formData
    },
    async confirmItem () {
      const form = this.$refs.commonForm.form
      if (!form.name) return this.$message.error('请输入授权名称')
      const requiredArr = this.$refs.commonForm.authSelectedTemplateObj.schema?.schema?.required
      const model = { 
        ...this.formData,
      }
      let flag = true
      for (let i = 0; i < requiredArr.length; i++) {
        if (!model[`${requiredArr[i]}`]) {
          flag = false
          let str = ''
          if (this.$refs.commonForm.authSelectedTemplateObj.schema && this.$refs.commonForm.authSelectedTemplateObj.schema.schema && this.$refs.commonForm.authSelectedTemplateObj.schema.schema.properties && this.$refs.commonForm.authSelectedTemplateObj.schema.schema.properties[`${requiredArr[i]}`]) {
            str = '请输入' + this.$refs.commonForm.authSelectedTemplateObj.schema.schema.properties[`${requiredArr[i]}`].title
          }
          if (str) this.$message.error(str)
          break; // 当元素为 3 时跳出循环
        }
      }
      if (!flag) return false
      this.confirmLoading = true
      const params = {
        name: form.name,
        application_id: this.selectApp.id,
        model: model,
        schema: this.selectedTemplateObj.schema,
        description: form.description
      }
      if (this.authDetail.id) {
        params.id = this.authDetail.id
      }
      let res = this.authDetail.id ? await this.$axiosPut(
        global.API.getApp + '/' + this.authDetail.app_id + '/connections/' + this.authDetail.id,
        params
      ) : await this.$axiosJsonPost(
        global.API.getApp + '/' + this.selectApp.id + '/connections',
        params
      );
      this.confirmLoading = false
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success(this.authDetail.id ? "修改成功" : "创建成功");
        // 刷新列表
        this.handleCancel()
        this.selectApp =  {
          name: '',
          id: ''
        }
        this.getConnections()
      }
    },
    handleCancel () {
     this.showModal = false
    },
    add () {
      this.title = '新建授权'
      this.selectApp = {
        name: '',
        id: ''
      }
      this.showDrawer = true
    },
    onSelect(value) {
      console.log('onSelect', value);
      const name = this.dataSource.find(item => item.id === value)?.name || ''
      this.selectApp = {
        id: value,
        name: name
      }
      this.initData(value)
    },
    initData (id) {
      this.spinning = true
      const params = {
        op_type: 2,
        application_id: id
      }
      this.$axiosGet(global.API.getStepList, params).then((res) => {
        this.spinning = false
        if ([200, 201, 204, 202].includes(res.status)) {
         if (res.data.results && res.data.results.length) {
            this.selectedTemplateObj= {
              ...res.data.results[0],
              schema: res.data.results[0].schema
            }
            this.model = res.data.results[0].model
            this.showDrawer = false
            this.showModal = true
            this.authDetail = {}
          } else {
            this.selectApp = {
              id: '',
              name: ''
            }
            this.$message.warning('暂无授权配置信息')
          }
        }        
      });
    },
    handleClickRow(record, index) {
      return {
        style: {
          "background-color":
            index === this.setSelectedIndex ? "#e4eefd " : "#fff",
        },
        on: {
          click: () => {
            this.setSelectedIndex = index;
            // this.editAuth(record);
          },
        },
      };
    },
    clearSelectIndex() {
      this.setSelectedIndex = -1;
    },
    tableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getConnectionsByAppId();
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name){
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    onAddSearch (value) {
      this.dataSource = this.allDataSource.filter((item => item.name.includes(value)))
    },
    onChange (value) {
      this.selectApp = {
        id: '',
        name: value
      }
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.getConnections();
    },
    editAuth (row) {
      this.title = '编辑授权'
      this.selectApp = {
        id: row.application_id,
        name: row.name
      }
      this.selectedTemplateObj= {
        schema: row.schema
      }
      this.authDetail = row
      this.model = row.model
      this.showDrawer = false
      this.showModal = true
    }, 
    deleteAuth(obj, e) {
      if (e) e.stopPropagation()
      const self = this;
      let width = 400;
      let content = (
        <DeleteAuthMoadl
          detail={obj}
          callBack={() => self.getConnections()}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    getConnections() {
      this.loading = true;
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        keyword: this.params.keyword || "",
      };
      this.$axiosGet(global.API.getConnections, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.tableData = res.data.results;
          this.pagination.total = res.data.total;
        }
      });
      console.log(global.API.getUserList, "哇");
    },
    getApp(type) {
      const params = {
        page_size: 1000,
        page: 1,
        kind: -1,
      };
      if (type === 'add') {
        params.optype = 2
      }
      this.$axiosGet(global.API.getApp, params).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (type === 'add') {
            this.dataSource  = res.data.results;
            this.allDataSource = res.data.results
          }
        }
      });
    },
  },
  mounted() {
    this.getConnections()
    this.getApp('add')
  },
};
</script>

<style lang="less" scoped>
.auth-manage-page {
  height: 100%;
  box-shadow: none;
  padding: 16px 14px 16px 16px;
  min-width: 980px;
  overflow-x: auto;
  .table-info {
    margin-top: 10px;
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
  .add {
    position: relative;
    margin-right: 16px;
    width: 280px;
    .btn-add {
      width: 280px;
    }
    .add-drawer {
      top: 40px;
      position: absolute;
      width: 280px;
      z-index: 100;
      background: #fff;
      padding: 0 0 10px 0;
      min-height: 100px;
      box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    } 
  }
  .flex-wrap {
    display: flex;
    align-items: center;
    .img-custom-warp {
      width: 24px;
      height: 24px;
      display: inline-block;
      background: #FFF; // 这个是图标的颜色定义
      text-align: center;
      border-radius: 4px;
      box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        position: relative;
        top: 0px;
        height: 20px;
        margin: auto;
      }
    }
  }
}
</style>
<style lang="less">
.auth-modal {
  padding: 0 0 20px 24px;
  .footer-param {
    display: flex;
    justify-content: center;
  }
}
.ant-select-dropdown {
  .flex-warp-auth-drawer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .img-custom-warp {
      width: 20px;
      height: 20px;
      display: inline-block;
      background: #FFF; // 这个是图标的颜色定义
      text-align: center;
      border-radius: 4px;
      box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
      margin-right: 8px;
      img {
        width: 16px;
        position: relative;
        top: -2px;
        height: 16px;
        margin: auto;
      }
    }
  }

}
</style>