import { render, staticRenderFns } from "./SettingBar.vue?vue&type=template&id=5a453d8c&scoped=true"
import script from "./SettingBar.vue?vue&type=script&lang=js"
export * from "./SettingBar.vue?vue&type=script&lang=js"
import style0 from "./SettingBar.vue?vue&type=style&index=0&id=5a453d8c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a453d8c",
  null
  
)

export default component.exports