<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-01 15:06:48
 * @LastEditTime: 2024-08-13 15:20:54
 * @FilePath: \awx-ui\src\components\workflow-design\components\paramSetting\paramContainer.vue
-->
<template>
  <div class="param-container" :style="parammStyle">
    <a-tabs :activeKey="activeKey" @change="callback" :class="[activeKey === 'custom' ? 'custom-tab' : 'system-tab']">
      <a-tab-pane key="custom" tab="自定义参数">
        <paramSetting v-show="activeKey === 'custom'" :winboxHeight="winboxHeight" />
      </a-tab-pane>
      <a-tab-pane key="system" tab="系统预定义参数"><systemParams v-show="activeKey === 'system'" :winboxHeight="winboxHeight" /></a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import paramSetting from "./paramSetting.vue";
import systemParams from "./systemParams.vue";
export default {
  data() {
    return {
      activeKey: "custom",
    };
  },
  props: {
    winboxHeight: Number
  },
  computed: {
    parammStyle () {
     let style = {}
     style.height = (this.winboxHeight - 140) + 'px'
     style['max-height'] = (this.winboxHeight - 140) + 'px'
      return style
    },
  },
  components: {
    paramSetting,
    systemParams
  },
  methods: {
    callback(key) {
      this.activeKey = key;
    },
  },
};
</script>
<style lang="less" scoped>
.param-container {
  position: relative;
  padding-right: 24px;
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
  /deep/ .ant-tabs-tab {
    padding: 12px 0px 12px;
    font-size: 16px;
    color: #1d202d;
    &:hover {
      color: #1d202d;
    }
  }
  /deep/ .ant-tabs-tab-active {
    font-weight: 600;
  }
  /deep/ .ant-tabs-ink-bar {
    background-color: #1d202d;
    height: 2px;
  }
  .custom-tab {
    /deep/ .ant-tabs-ink-bar-animated {
      width: 80px!important;
    }
  }
}
</style>