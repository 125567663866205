<!--
 * @Description: 
 * @Date: 2024-08-14 15:34:10
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-11-08 18:12:38
-->
<template>
  <div :class="['left-menu', currentFocusWindowId === windowAppLoaderOptions.id &&  'left-actived-menu']">
    <div v-for="(item, index) in leftMenuList" :key="index" :class="['menu-item', item.title === selectedTitle && 'menu-item-selected']" @click='select(item)'>
      <a-divider type="vertical" />
      <svg-icon :icon-class="item.icon"></svg-icon>
      <div>{{ item.title }}</div>
    </div>
  </div>
</template>
<script>
//  currentFocusWindowId === windowAppLoaderOptions.id &&  'left-actived-menu'
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import store from '@/store/index'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  computed: {
    currentFocusWindowId () {
      let currentFocusWindowId = null
      let currentWinbox = _.cloneDeep(store.state.windowStore.currentFocusWindow)
      for (var i in currentWinbox) {
        currentFocusWindowId = i
      }
      return currentFocusWindowId
    },
  },
  data () {
    // 这里存放数据
    return {
      selectedTitle: "网址",
      leftMenuList: [
        {
          title: '网址',
          icon: 'appstore-network'
        },
        {
          title: '应用',
          icon: 'appstore-app'
        },
        {
          title: '插件',
          icon: 'appstore-plugins'
        }
      ]
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    select (item) {
      this.selectedTitle = item.title
      this.$EventBus.$emit('setKey', item.title)
    }
  }
}
</script>

<style lang='less' scoped>
  .left-menu {
    width: 58px;
    height: 380px;
    background: #F1F1F2;
  }
  .left-actived-menu {
    background: rgb(236, 239, 245);
  }
  .menu-item {
    position: relative;
    width: 54px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid rgba(255,255,255,0);
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.02);
    border-top-right-radius: 4px;
    cursor: pointer;
    margin-bottom: 4px;
    border-bottom-right-radius: 4px;
    /deep/ .ant-divider {
      position: absolute;
      left: -1px;
      margin: 0;
      border-radius: 1px;
      background: transparent;
      width: 3px;
      height: 26px;
      top: 14px;
    }
    svg {
      position: relative;
      top: -4px;
      font-size: 30px;
    }
    &-selected {
      background: rgba(255,255,255,0.90);
      border: 1px solid rgba(255,255,255,0.1);
      color: @primary-color;
      svg {
        color: @primary-color;
      }
      .ant-divider {
        background: @primary-color;
      }
    }
  }
</style>
