<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-10-26 11:11:25
 * @LastEditTime: 2024-07-17 10:15:27
 * @FilePath: \awx-ui\src\components\workflow-design\components\node\if.vue
-->
<template>
  <div class="workflow-if-node">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item
        v-for="(child, childIndex) in form.conditions"
        style="margin-bottom: 0px"
        :key="childIndex"
        v-bind="childIndex === 0 ? labelCol : formItemLayoutWithOutLabel"
        :label="childIndex === 0 ? '分支条件' : ''"
      >
        <a-row type="flex" style="position: relative">
          <a-col :span="10">
            <a-form-model-item style="width: 96%">
              <a-input
                v-model="child.key"
                placeholder="请输入"
                :disabled="child.key === 'expression'"
                onkeyup="this.value=this.value.replace(/[^\w_]/g,'');"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item style="width: 96%">
              <a-input
                v-model="child.val"
                placeholder="请输入"
              />
            </a-form-model-item>
          </a-col>
          <span :style="{'position': 'absolute', 'right': form.conditions.length > 1 && child.key !== 'expression' ? '10px': '46px', 'top': '1px'}">
            <a-button type="dashed" @click="() => addMultiple()" style="width: 30px; padding: 0;font-size: 18px;">
              <a-icon type="plus" />
            </a-button>
            <a-button v-show="form.conditions.length > 1 && child.key !== 'expression'" class="mgl6" type="dashed" style="width: 30px; padding: 0;font-size: 18px;" @click="() => reduceMultiple(childIndex)">
              <a-icon type="minus" />
            </a-button>
          </span>
        </a-row>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        conditions: [
          {
            key: 'expression',
            val: ''
          },
          {
            key: 'transition',
            val: ''
          }
        ],
      },
    };
  },
  watch: {
    'form.conditions': {
      handler(val) {
        this.handlerFlowChange()
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    currentWinboxKey () {
      // const currentFocusWindow = this.windowStore.currentFocusWindow;
      // let key = null
      // for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  mounted () {
    const stepInfo = _.cloneDeep(this.winboxTask.stepInfo)
    const obj = stepInfo.taskTemplateDetail.stepInfo?.properties?.model?.conditions?.true || {expression: ''}
    this.form.conditions = this.handlerObjToArr(obj)
  },
  methods: {
    ...mapActions('task', ['setStepInfo']),
    ...mapActions('winboxFlowModule', ['setWinboxStepInfo']),
    reduceMultiple(index) {
      this.form.conditions.splice(index, 1);
    },
    addMultiple() {
      this.form.conditions.push({
        key: "",
        val: "",
        randow: Math.floor(Math.random() * 100000),
      });
    },
    changeCondition(val) {
      this.handlerFlowChange()
    },
    handlerArrToObj (arr) {
      const obj = {}
      for (var i in arr) {
        obj[`${arr[i].key}`] = arr[i].val
      }
      return obj
    },
    handlerObjToArr (obj) {
      const arr = []
      for (var i in obj) {
        // if (i) {
          arr.unshift({
            key: i,
            val: obj[i],
            randow: Math.floor(Math.random() * 100000),
          })
        // }
      }
      return arr
    },
    handlerFlowChange () {
      console.log("handlerFlowChange---IF节点!");
      const stepInfo = _.cloneDeep(this.winboxTask.stepInfo)
      stepInfo.taskTemplateDetail.stepInfo.properties['model']['conditions']['true'] = this.handlerArrToObj(this.form.conditions)
      this.setStepInfo(stepInfo);
      this.setWinboxStepInfo({id: this.currentWinboxKey, value: stepInfo});
      this.$EventBus.$emit('updateStepsProperties', stepInfo.taskTemplateDetail.stepInfo.properties)
    },
  },
};
</script>
<style lang="less" scoped>
.workflow-if-node {
  padding-right: 55px;
  .name {
    font-size: 18px;
    font-weight: 550;
  }
}
</style>