/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-06-14 20:06:51
 * @LastEditTime: 2024-11-15 15:32:30
 * @FilePath: \awx-ui\src\store\modules\task.js
 */
import default_setting from './defaultGlobalSetting'


const state = {
  windowStore: {},
  isAddToTaskbar: false,
  taskbarEntriesStore: _.cloneDeep(default_setting.fixedTabar),
  currentFocusWindow: {},
  historyWinboxSize: {}
}
const getters = {
  currentFocusWindow: (state) => state.currentFocusWindow
}
const mutations = {
  setIsAddToTaskbar(state, IsAddToTaskbar) {
    state.isAddToTaskbar = IsAddToTaskbar;
  }
}
Object.keys(state).forEach((item) => {
  mutations[item] = (state, value) => {
    state[item] = value
  }
})
// 创建一个函数来筛选包含 `window` 属性的对象
function filterWithWindow(obj) {
  const result = {};
  for (const key in obj) {
    if (obj[key].hasOwnProperty('window')) {
      result[key] = obj[key];
    }
  }
  return result;
}
function syncObjects(objA, objB) {
  // 遍历 objA 的所有属性
  for (const key in objA) {
    if (objA.hasOwnProperty(key)) {
      // 如果 objB 中已经存在该属性，更新它
      if (objB.hasOwnProperty(key)) {
        objB[key] = {
          width: objA[key]?.window.width,
          height: objA[key]?.window.height,
          x: objA[key]?.window.x,
          y: objA[key]?.window.y,
         };
      } else {
        // 如果 objB 中不存在该属性，追加它
        objB[key] = {
          height: objA[key]?.window.height,
          width: objA[key]?.window.width,
         };
      }
    }
  }
}
const actions = {
  setWindowStore({
    commit,
    dispatch
  }, obj) {
    commit('windowStore', obj)
    dispatch('setTaskbarEntriesStore')
    dispatch('setCurrentFocuWindow')
    dispatch('setHistoryWinboxSize')
  },
  setHistoryWinboxSize ({
    commit,
    state
  }) {
    const obj = state.historyWinboxSize
    syncObjects(state.windowStore, obj);
    commit('historyWinboxSize', obj)
  },
  setTaskbarEntriesStore({
    state,
    commit,
    rootState
  }, obj) {
    const {fixedTabar} = rootState.globalSetting
    const taskbarEntriesObj = { ...state.windowStore}
    // const taskbarEntriesStore = Object.keys(taskbarEntriesObj).map(key => ({ key, ...taskbarEntriesObj[key] }));
    // 获取 taskbarEntriesObj 的所有条目
    const taskbarEntriesStore = Object.keys(taskbarEntriesObj).reduce((acc, key) => {
      const entry = taskbarEntriesObj[key];
      const index = fixedTabar.findIndex(item => item.id === entry.id);
      // 检查 entry.id 是否存在于 fixedTabar 中
      if (index === -1) {
          acc.push({ key, ...entry });
      }
      return acc;
    }, []);
    // 任务栏将应用商店过滤
    const arr = taskbarEntriesStore.filter(item => item.id !== 'appStore')
    const taskbarEntriesStoreArr = fixedTabar.concat(arr)
    commit('taskbarEntriesStore', taskbarEntriesStoreArr)
  },
  setCurrentFocuWindow ({
    state,
    commit,
    rootState
  }, obj) {
    const {fixedTabar} = rootState.globalSetting
    const taskbarEntriesObj = {...fixedTabar, ...state.windowStore}
    let currentFocuWindow = {}
    const filteredObj = filterWithWindow(taskbarEntriesObj);
    // 使用 reduce 方法遍历对象并找到 index 最大的那个子对象
    const maxIndexObj = Object.entries(filteredObj).reduce((max, [key, value]) => {
      if (value.window.index > max.value.window.index && !value.window.min) {
        return { key, value };
      }
      return max;
    }, { key: null, value: { window: { index: -Infinity } } });

    // 将找到的对象赋值给一个新的对象
    currentFocuWindow = { [maxIndexObj.key]: maxIndexObj.value };
    commit('currentFocusWindow', currentFocuWindow)
  }
}
export default {
  namespaced: 'windowStore',
  state,
  getters,
  actions,
  mutations
}