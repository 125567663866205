<!--
 * @Description: 
 * @Date: 2024-08-14 15:34:43
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-11-11 18:10:00
-->
<template>
  <div class='right-app-store'>
    <Netework v-if='selectKey === "网址"' ref="netWorkRef" :keyword="keyword" :kind='kind' />
    <Netework v-if='selectKey === "应用"' ref="netWorkRef"  :keyword="keyword" :kind="kind" />
    <Plugin v-else-if='selectKey === "插件"' />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import Netework from './netWork/index.vue'
import Plugin from './plugin/index.vue'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    Netework,
    Plugin
  },
  props:  {
    keyword: String,
  },
  data () {
    // 这里存放数据
    return {
      kind: 2,
      selectKey: '网址',
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    const self = this
    this.$EventBus.$on("setKey", (key) => {
      if (key === '网址') self.kind = 2
      if (key === '应用') self.kind = 0
      self.selectKey = key
    });
    this.$EventBus.$on("changeKeyWord", (keyword) => {
      if (keyword === '') {
        if (self.selectKey === '网址') this.kind = 2
        if (self.selectKey === '应用') this.kind = 0
      } else {
        self.kind = -1
      }
      self.$refs.netWorkRef.search()
    });
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {
    this.$EventBus.$off("setKey");
   }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {

  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.right-app-store {
  width: calc(100% - 58px);
  background: #fff;
}
</style>
