<!--
 * @Description: 
 * @Date: 2024-07-24 17:25:56
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 10:40:54
-->
<template>
  <div :class="['settings-box flex flex-col h-full w-full', globalSetting.theme.mode]">
    <div v-if="setting.isMobile" class="top-bar h-50 w-full items-center justify-start bg-color-b3 px-[15px]">
      <div class="flex h-full text-color-t1 items-center cursor-pointer" @click="back">
        <a-icon type="left" />
        返回
      </div>
    </div>
    <div class="flex settings-content">
      <SettingBarVue v-if="showBar" class="settings-bar bg-color-b2 bg-opacity-95" />
      <SettingViewVue v-if="showView" class="settings-view bg-color-b3" />
    </div>

  </div>
</template>

<script>
import store from '@/store/index'
import SettingBarVue from './SettingBar.vue'
import SettingViewVue from './SettingView.vue'
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    SettingViewVue,
    SettingBarVue
  },
  props: {
    router: Object
  },
  data () {
    // 这里存放数据
    return {

    }
  },
  provide () {
    return {
      toLogin: this.toLogin
    }
  },
  // 监听属性 类似于data概念
  computed: {
    setting () {
      return { ...store.state.setting }
    },
    globalSetting () {
      return { ...store.state.globalSetting }
    },
    showBar () {
      let show = true
      if (this.setting.isMobile) { 
        if (this.globalSetting.settingStep === 1) {
          show = true
         } else {
          show = false
         }
      } else {
        show = true
      }
      return show
    },
    showView () {
      let show = true
      if (this.setting.isMobile) { 
        if (this.globalSetting.settingStep === 2) {
          show = true
         } else {
          show = false
         }
      } else {
        show = true
      }
      return show
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    toLogin () {
      this.router.push('/login')
    },
    back () {
      if (this.globalSetting.settingStep === 2) {
        store.commit("globalSetting/setSettingStep", 1)
      } else {
        this.$destroyAll()
      } 
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.settings-box {
  .settings-content {
    max-height: calc(100vh - 88px);
  }
}
.settings-box {
  .h-50 {
    height: 50px;
  }
  .top-bar {
    padding-left: 15px;
    & > div {
      width: 60px;
      text-align: center;
    }
  }
  .settings-bar {
    height: 562px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    width: 220px;
    backdrop-filter: blur(80px);
  }
  .settings-view {
    height: 562px;
    flex: 1;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  @media (max-width: 720px) {
    .settings-bar {
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 100%;
    }
    .settings-view {
      height: 100%;
      flex: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
@media (max-width: 720px) {
  .settings-box {
    .settings-content {
      max-height: calc(100vh - 50px);
      height: calc(100vh - 50px);
    }
  }
}
</style>
