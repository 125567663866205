/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2025-01-17 15:21:52
 * @FilePath: \awx-ui\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import {initRouter} from './router'
import './icons/index'
import './assets/less/index.less'
import Antd from 'ant-design-vue'
import Viser from 'viser-vue'
import '@/api'
import store from './store'
import {initI18n} from '@/utils/i18n'
import '@/utils/index.js'
import 'animate.css/source/animate.css'
import Plugins from '@/plugins'
import bootstrap from '@/bootstrap'
import 'moment/locale/zh-cn'
import '@/assets/fonts/font.css'
import VueDND from 'awe-dnd'
import "./assets/iconfont/font_3618935_h1q1rdzfhzh/iconfont.css"
import configMaster from './components/configMaster'
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
import { message } from 'ant-design-vue';
import { Icon } from '@iconify/vue2'
import VueCompositionAPI from "@vue/composition-api";
import VuePortal from "@linusborg/vue-simple-portal";
import GlobalWindowPlugin from '@/components/winbox/winbox';
import * as directives from '@/directives';
import SideMenu from '@/components/menu/desktopMenu/SideDesktop'
import SimulatedScroll from '@/components/SimulatedScroll'; // Adjust path as needed
import VueLazyload from 'vue-lazyload';
import Contextmenu from "@/plugins/vue-contextmenujs"
const router = initRouter(store.state.setting.asyncRoutes)
const i18n = initI18n('CN', 'US')
message.config({
  top: `53px`,
  duration: 2,
  maxCount: 3,
});
Vue.use(Antd)
Vue.config.productionTip = false
Vue.use(Viser)
Vue.use(Plugins)
Vue.use(VueDND)
Vue.component('configMaster',configMaster)
Vue.component('iconifyIcon',Icon)
Vue.use(Contextmenu);
Vue.use(VueCompositionAPI);
Vue.component('SideMenu', SideMenu);
Vue.component('SimulatedScroll', SimulatedScroll);
Vue.use(VuePortal, {
  name: "portal", // optional, use to rename component
});

Vue.prototype.$EventBus = new Vue()
Vue.prototype.$decodeData = async function decodeData(input) {
  let arrayBuffer;
  let decoder = new TextDecoder('utf-8'); // 可以根据需要更改编码
  if (input instanceof Blob) {
    // 如果输入是 Blob，则先转换为 ArrayBuffer
    arrayBuffer = await input.arrayBuffer();
  } else if (input instanceof Uint8Array || ArrayBuffer.isView(input)) {
    // 如果输入是 Uint8Array 或其他类型的 ArrayBuffer 视图（TypedArray），直接使用
    arrayBuffer = input.buffer;
  } else if (typeof input === 'string') {
    return input;
  } else if (input instanceof ArrayBuffer) {
    // 如果输入已经是 ArrayBuffer，则直接使用
    arrayBuffer = input;
  } else {
    throw new TypeError('Unsupported data type for decoding');
  }
  // 解码 ArrayBuffer 为文本字符串
  const text = decoder.decode(arrayBuffer);
  return text;
}

Vue.use(GlobalWindowPlugin);

// 注册 vue-lazyload 插件
Vue.use(VueLazyload, {
  preLoad: 1.3, // 预加载距离
  attempt: 1, // 尝试加载次数
});

Object.keys(directives).forEach(key => {
  const directive = directives[key];
  Vue.directive(key, directive);
});

bootstrap({router, store, i18n, message: Vue.prototype.$message})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
