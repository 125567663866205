<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-16 14:53:56
 * @LastEditTime: 2025-01-21 17:49:17
 * @FilePath: \awx-ui\src\pages\login\scan-code.vue
-->
<template>
  <div class="scan-code">
    <div class="qr-code">
      <img
       v-if="src"
        :src="src"
        alt=""
      >
    </div>
    <div class="tips text-color-t1">使用微信扫一扫二维码登录</div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { formatRoutes } from "@/utils/routerUtil";
import dynamicComponents from "@/utils/dynamic-components";
import ROUTER_DATA from './routerData.json'

export default {
  props: {
    tab: String,
  },
  data () {
    return {
      src: '',
      timer: null,
      uuid: ''
    };
  },
  components: {},
  mounted () {
    this.uuid = global.utils.generateUUID()
    this.getCode()
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setPermissions"]),

    getCode () {
      clearInterval(this.timer)
      this.timer = null
      let apiAjax = global.API.getQrCode + '?uuid=' + this.uuid
      fetch(apiAjax, {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token')
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          } else {
            this.$message.error('当前二维码获取失败')
          }
        })
        .then(blob => {
          const url = URL.createObjectURL(blob);
          this.src = url
          clearInterval(this.timer)
          this.timer = null 
          this.timer = setInterval(() => {
            if (this.$store.state.setting.currentTab === '3') { 
              this.getStatus()
            } else {
              clearInterval(this.timer)
              this.timer = null
            }
          }, 3000)
        })
        .catch(error => {
          this.$message.error('当前二维码获取失败')
        });
    },
    // 获取状态
    getStatus () {
      const self = this
      this.$axiosGet(global.API.getWxStatus, {uuid: this.uuid}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.code === 200) {
            clearInterval(self.timer);
            self.timer = null
            self.wxLogin(res.data.data)
          }
        }
      });
    },
    wxLogin (data) {
      const self = this
      this.$axiosGet(global.API.wxLogin, { openId: data.openId, uuid: this.uuid }).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          localStorage.setItem("token", res.data.token);
          self.getUserInfo(res);
        }
      });
    },
    getUserInfo() {
      const self = this;
      this.$axiosGet(global.API.getUserInfo, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          self.afterLogin(res);
        }
      });
    },
    afterLogin(res) {
      const username = res.data.username
      const isSuperuser = res.data.is_superuser;
      this.setUser({ ...res.data, username, isSuperuser });
      this.getRouter();
      this.$emit('getMaster')
      this.$message.success("登录成功");
      this.$emit('closeModal')
      this.$emit('openSyncSetting', res.data)
    },
    getRouter() {
      const self = this;
      const results = ROUTER_DATA
      localStorage.setItem("routerData", JSON.stringify(results));
    },
    // 路由过滤  遍历路由 转换为组件对象和路径
    filterASyncRoutes(data) {
      const routes = data.filter((item) => {
        const getComp = dynamicComponents[`${item.component}`];
        item["component"] = getComp;
        // 路由递归，转换组件对象和路径
        if (item["children"] && item["children"].length > 0) {
          item["children"] = this.filterASyncRoutes(item.children);
        }
        return true;
      });
      // 排序
      // routes.sort((a, b) => a['id'] - b['id'])
      return routes;
    },
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  } 
};
</script>
<style scoped lang='less'>
.scan-code {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .qr-code {
    width: 200px;
    height: 200px;
    border: 1px solid rgba(196, 204, 219, 1);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    img {
      height: 196px;
      width: 196px;
      border-radius: 6px;
    }
  }
  .tips {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>