<!--
 * @Description: 
 * @Date: 2024-07-25 14:30:01
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-20 18:04:18
-->
<template>
  <div v-if='isPhoto' class="hi-scroll">
    <div class='wallpaper-photo flex flex-wrap justify-between pb32'>
      <div class="wallpaper-item relative mgb12 h108 w192" v-for="(item, index) in photosList" @click="showPhotoDetail(item)">
        <img :src="item.url" alt="">
        <div class="absolute inset-0 flex cursor-pointer items-center justify-center">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>
</div>
<div v-else class="wallpaper-photo-detail bg-color-b3 flex flex-col absolute inset-0">
  <div class="header border-color-m2 border-opacity-008 flex h52 items-center justify-between">
    <div class="header-left flex items-center" @click="back">
      <a-icon type="left" class="text-color-t1" />
      <span class='mgl8 text-color-t1'>返回分类</span>
    </div>
    <div class='header-center text-color-t1'>
      {{currentPhoto.name}}
    </div>
    <div class='header-right flex items-center'>
      <span class="text-color-t2">随机</span>
      <a-switch size='small' @change="onChange" />
    </div>
  </div>
  <div class="hi-scroll">
    <div class="grid grid-cols-3 gap12 wallpaper-photo-detail-container">
      <div class="wallpaper-item relative h108" v-for="(item, index) in photosDetailList">
        <img class="h-full w-full object-cover transition-[transform]" loading="lazy" src="https://infinitypro-img.infinitynewtab.com/wallpaper/nature/7344.jpg?imageView2/2/w/384/format/webp" alt="" data-v-f7055f04="">
      </div>
    </div>

  </div>
</div>

</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    // https://api.wetab.link/api/wallpaper/list?client=pc&tag=animals
    return {
      isPhoto: true,
      currentPhoto: {
        name: '自然',
        type: 'natrue',
        url: require('@/assets/img/wallpaper/natrue.png')
      },
      photosDetailList: [],
       photosList: [
        {
          name: '自然',
          type: 'natrue',
          url: require('@/assets/img/wallpaper/natrue.png')
        },
        {
          name: '建筑',
          type: 'structure',
          url: require('@/assets/img/wallpaper/structure.png')
        },
        {
          name: '动物',
          type: 'animals',
          url: require('@/assets/img/wallpaper/animal.png')
        },
        {
          name: '旅行',
          type: 'travel',
          url: require('@/assets/img/wallpaper/travel.png')
        },
        {
          name: '美食',
          type: 'food',
          url: require('@/assets/img/wallpaper/food.png')
        },
        {
          name: '动漫',
          type: 'anime',
          url: require('@/assets/img/wallpaper/Anime.png')

        },
        {
          name: '运动',
          type: 'sport',
          url: require('@/assets/img/wallpaper/sport.png')

        },
        {
          name: '技术',
          type: 'technology',
          url: require('@/assets/img/wallpaper/technology.png')

        },
        {
          name: '街头',
          type: 'street',
          url: require('@/assets/img/wallpaper/street.png')

        },
       ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getPhotosBytag()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    onChange (val) {},
    showPhotoDetail (item) {
      this.isPhoto = false
      this.currentPhoto = item
    },
    back () {
      this.isPhoto = true
      this.currentPhoto = {}
    },
    async getPhotosBytag() {
      try {
        const response = await fetch('https://api.wetab.link/api/wallpaper/list?client=pc&pageNo=1&tag=nature', {
          method: 'GET', // 或 'POST'
          headers: {
            'Content-Type': 'application/json', // 根据第三方API的要求设置合适的请求头
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok'); // 处理HTTP错误
        }
        const responseData = await response.json(); // 解析JSON格式的响应数据
        this.photosDetailList = responseData.data.list
        console.log(this.photosDetailList, 'this.photosDetailListthis.photosDetailListthis.photosDetailList')
        }
        catch (error) {
          console.error('Error fetching data:', error);
       }
    }
  }
}
</script>

<style lang='less' scoped>
.hi-scroll {
  overflow: auto;
  flex: 1 1 0%;
}
.h108 {
  height: 108px;
}
//@import url(); 引入公共css类
.wallpaper-photo {
  width: 396px;
  margin: 0 auto;
  .relative {
    position: relative;
  }
  .w192 {
    width: 192px;
  }
}
.wallpaper-photo-detail {
  height: 100%;
  border-radius: 12px;
  z-index: 10;
  .header {
    padding: 0 60px;
    border-width: 0px;
    border-bottom-width: 1px;
    border-style: solid;
    &-left {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      span {
        display: block;
      }
    }
    &-center {
      font-size: 20px;
    }
    &-right {
      font-size: 14px;
       span {
        display: block;
        margin-right: 6px;
      }
      /deep/ .ant-switch {
        background-color: rgb(var(--color-b1), var(--tw-bg-opacity));
      }
      /deep/ .ant-switch-checked {
        background-color: @primary-color;
      }
    }
  }
  .h52 {
    height: 52px;
  }
  .wallpaper-item {
    width: 100%;
  }
  .wallpaper-photo-detail-container {
    padding: 30px 60px 32px;
  }
}
.wallpaper-item {
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden; /* 防止图片溢出容器 */
  img {
    // height: 108px;
    // width: 234px;
    height: auto;
    width: 100%;
    max-width: 100%;
    border-radius: 6px;
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: initial;
  }
  div {
    background: rgba(0, 0, 0, 0.2);
    span {
      font-size: 20px;
      color: #fff;
      font-weight: 600;
    }
  }
  &:hover {
    img {
      transform: matrix(1.2, 0, 0, 1.2, 0, 0);
      transition-timing-function: ease;
    }
  }
}
</style>
