<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-09-10 10:43:35
 * @FilePath: \awx-ui\src\components\empty-host\index.vue
-->
<template>
  <div :class="[hasMaster !=='true' && 'no-master']" >
    <div class="host-empty" v-if="!showImportHost">
      <div class="left">
        <div class="title">主机资源</div>
        <div>
          <svg-icon class="host-empty-img" icon-class="host-empty"></svg-icon>
        </div>
        <div class="desc">在一个主控台完成对名下主机资源的统一管理</div>
      </div>
      <div class="right">
        <div class="import-text">一键导入主机资源</div>
        <div class="import-host" @click="uploadHost">
          <a-button class="import-btn" type="primaty">
            <svg-icon class="import-btn-icon" icon-class="host-import"></svg-icon>导入主机资源
          </a-button>
        </div>
      </div>
    </div>
    <ImportHost :class="[hasMaster !=='true' && 'no-master-host']" v-else :clusterId="clusterId" :isMasterAdd="isMasterAdd" :hasMaster="hasMaster" @creatMaster="onClose" @backToHost="backToHost" />
  </div>
</template>
<script>
import ImportHost from './importHost/index.vue'
export default {
  components: {
    ImportHost
  },
  props: {
    creatMaster: Function,
    hasMaster: String,
    isMasterAdd: Boolean,
    clusterId: [Number, String]
  },
  data() {
    return {
      showImportHost: false,
    };
  },
  watch: {
    isMasterAdd: {
      handler(val) {
        if (val) {
          this.showImportHost = true
        }
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    onClose () {
      this.creatMaster()
    },
    backToHost () {
      this.showImportHost = false
    },
    uploadHost() {
      this.showImportHost = true
      // const self = this;
      // let width = 560;
      // let title = "导入";
      // let content = (
      //   <UploadFile
      //     callBack={() => {
      //       self.$emit("getHostGroup");
      //     }}
      //   />
      // );
      // this.$confirm({
      //   width: width,
      //   title: title,
      //   content: content,
      //   closable: true,
      //   icon: () => {
      //     return <div />;
      //   },
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.host-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .left {
    position: relative;
    top: -16px;
    padding-right: 70px;
    text-align: center;
    border-right: 1px solid #e5e5e5;
    .title {
      font-size: 24px;
      color: #333333;
      font-weight: 400;
    }
    .host-empty-img {
      margin: 56px 0 34px;
      width: 483px;
      max-width: 483px;
      max-height: 332px;
      height: 332px;
    }
    .desc {
      font-size: 15px;
      color: #666666;
      font-weight: 400;
    }
  }
  .right {
    position: relative;
    top: -16px;
    text-align: center;
    margin-left: 100px;
    .import-text {
      font-size: 16px;
      color: #555555;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 46px;
    }
    .import-host {
      .import-btn {
        width: 220px;
        height: 42px;
        background: #3974f4;
        box-shadow: 0px 3px 8px 0px rgba(57, 116, 244, 0.2);
        border-radius: 4px;
        font-size: 17px;
        color: #ffffff;
        font-weight: 500;
        text-align: center;
        .import-btn-icon {
          font-size: 17px;
          margin-right: 6px;
        }
      }
    }
  }
}
.no-master {
  width: 100%;
  height: 100%;
  margin: auto;
  box-shadow: 0 4px 12px rgba(0,0,0,.15);
  .host-empty {
    height: 100%;
  }
}
</style>