/*
 * @Description: 
 * @Date: 2024-07-31 10:34:13
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 14:57:34
 */
import default_setting from './defaultGlobalSetting'
let isFirstSync = false
let timer = null
let firstTimer = null

const state = _.cloneDeep(default_setting);

const mutations = {
  SET_SETTINGS(state, settings) {
    Object.assign(state, settings);
  },
  setBasic(state, basic) {
    state.basic = basic;
  },
  setWallpaper(state, wallpaper) {
    state.wallpaper = wallpaper;
  }, 
  setWallpaperTab(state, wallpaper) {
    state.wallpaperTab = wallpaper;
  },
  setSettingBoxTab(state, key) {
    state.settingBoxTab = key;
  },
  setTheme(state, theme) {
    state.theme = theme;
  },
  setScreenSize(state, screenSize) {
    state.screenSize= screenSize;
  },
  setSettingStep(state, step) {
    state.settingStep = step;
  },

  setDesktopLayout(state, desktopLayout) {
    state.desktopLayout = desktopLayout;
  },
  setAddActiveId(state, addActiveId) {
    state.addActiveId = addActiveId;
  },
  setDesktopIcon(state, desktopIcon) {
    state.desktopIcon = desktopIcon;
  },
  setDesktopSize(state, desktopSize) {
    state.desktopSize = desktopSize;
  },
  setSearchBox(state, searchBox) {
    state.searchBox = searchBox;
  },
  setSearchBoxHistoryList(state, searchBoxHistoryList) {
    state.searchBoxHistoryList = searchBoxHistoryList;
  },
  setDesktopArrange(state, desktopArrange) {
    state.desktopArrange = desktopArrange
  },
  setFixedTabar(state, fixedTabar) {
    state.fixedTabar = fixedTabar
  }
};

const actions = {
  firstSync ({ commit, state, dispatch }) {
    let netWork = true
    isFirstSync = true
    let syncSettings = null
    let networkResponse = null
    const savedSettings = localStorage.getItem('globalSetting');
    const localSettings = savedSettings ? JSON.parse(savedSettings) : {};
    const USER_STROAGE = localStorage.getItem(process.env.VUE_APP_USER_KEY)
    let user = USER_STROAGE ? JSON.parse(USER_STROAGE) : {};
    if (!(user && user.id)) return false
    if (firstTimer) {
      clearTimeout(firstTimer)
      firstTimer = null
    }
    dispatch('messageBox/setMssageBoxShow', true, { root: true })
    dispatch('messageBox/setMssageBoxLoading', true, { root: true })
    dispatch('messageBox/setMessageBoxText', '加载中', { root: true })

    return this._vm.$axiosGet(global.API.updateUser + '/' + user.id + '/').then((res) => {
      dispatch('messageBox/setMssageBoxLoading', false, { root: true })
      if ([200, 201, 204].includes(res.status)) {
        networkResponse = res.data
      } else {
        dispatch('messageBox/setMessageBoxText', '同步失败', { root: true })
      }
    }).catch((err) => {
      netWork = false
      dispatch('messageBox/setMssageBoxShow', false, { root: true })
    }).finally((res) => {
      if (!netWork || !networkResponse) {
        commit('SET_SETTINGS', localSettings);
        dispatch('windowStore/setTaskbarEntriesStore', [], {root: true})
        return false
      }
      syncSettings = {
        ...networkResponse.settings,
        settingBoxTab: localSettings.settingBoxTab || 'basic',
        wallpaperTab: localSettings.wallpaperTab || 'photos',
        searchBoxHistoryList: localSettings.searchBoxHistoryList || [],
        fixedTabar: networkResponse.settings.fixedTabar && networkResponse.settings.fixedTabar.length > 0 ? networkResponse.settings.fixedTabar : state.fixedTabar,
      }
      syncSettings = syncSettings ? syncSettings : localSettings
      const width = window.innerWidth
      const height = window.innerHeight
      const screenSize = networkResponse.settings.screenSize
      if (screenSize.width !== width || screenSize.height !== height) {
        this._vm.$EventBus.$emit('observeScreenSize', syncSettings);
      } else{
        commit('SET_SETTINGS', syncSettings);
      }
      dispatch('windowStore/setTaskbarEntriesStore', [], {root: true})
      dispatch('messageBox/setMessageBoxText', '同步完成', { root: true })
      firstTimer = setTimeout(() => {
        dispatch('messageBox/setMssageBoxShow', false, { root: true })
      }, 2000)
    })
  },
  syncSettings({
    dispatch,
    commit,
    state,
    rootState 
  }, 
   setting
  ) {
    if (isFirstSync) return isFirstSync = false
    const USER_STROAGE = localStorage.getItem(process.env.VUE_APP_USER_KEY)
    let user = USER_STROAGE ? JSON.parse(USER_STROAGE) : {};
    if (!(user && user.id)) return false
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    dispatch('messageBox/setMssageBoxShow', true, { root: true })
    dispatch('messageBox/setMssageBoxLoading', true, { root: true })
    dispatch('messageBox/setMessageBoxText', '正在同步', { root: true })
    const syncSettings = {
      ...setting,
      screenSize: {
        width: window.innerWidth,
        height: window.innerHeight
      },
    }
    return this._vm.$axiosPut(global.API.updateUser + '/' + user.id + '/', {settings: syncSettings}).then((res) => {
      dispatch('messageBox/setMssageBoxLoading', false, { root: true })
      timer = setTimeout(() => {
        dispatch('messageBox/setMssageBoxShow', false, { root: true })
      }, 2000)
      if ([200, 201, 204].includes(res.status)) {
        dispatch('messageBox/setMessageBoxText', '同步完成', { root: true })
      } else {
        dispatch('messageBox/setMessageBoxText', '同步失败', { root: true })
      }
    }).catch((res) => {
      dispatch('messageBox/setMssageBoxLoading', false, { root: true })
      dispatch('messageBox/setMessageBoxText', '同步失败', { root: true })
      timer = setTimeout(() => {
        dispatch('messageBox/setMssageBoxShow', false, { root: true })
      }, 1000)
    })
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
