<template>
  <div class="app-dialog">
    <div class="app-header">
      <svg-icon icon-class="app-base" style="font-size: 20px"></svg-icon>
      <span>设置应用基本信息</span>
    </div>
    <div class="app-config">
      <AppBaseInfo ref="AppBaseInfoRef" :appDetail="appDetail" formType="page" />
      <div class="app-right-bottom">
        <a-button class="btn-next btn-step" @click="nexStep()" type="primary">{{ "保存" }}</a-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import AppBaseInfo from "../../addApp/appModal/appBaseInfo.vue";
export default {
  name: "ADDAPP",
  components: {
    AppBaseInfo,
  },
  props: {
    showPage: String
  },
  provide() {
    return {};
  },
  data() {
    return {
      appDetail: null
    };
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  computed: {
    appId () {
      let appId = this.windowPropsData.appId || ''
      return appId
    },
  },
  watch: {
    showPage: {
      handler(val) {
        if (val === 'base') {
          this.getAppDetail()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {},
  methods: {
    getAppDetail () {
      this.$axiosGet(global.API.getApp + `/${this.appId}`, {})
        .then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            this.appDetail = res.data
          }
        })
        .catch((err) => {});
    },
    nexStep() {
      const self = this;
      self.$refs.AppBaseInfoRef.$refs.baseInfoFrom.validate(async (valid) => {
        if (valid) {
          if (!self.$refs.AppBaseInfoRef.form.icon) {
            self.$message.error("请上传产品图标");
          } else {
            const params = {
              ...self.$refs.AppBaseInfoRef.form,
              categories: self.$refs.AppBaseInfoRef.form.categories
                ? self.$refs.AppBaseInfoRef.form.categories.join(",")
                : "",
              tags: self.$refs.AppBaseInfoRef.form.tags
                ? self.$refs.AppBaseInfoRef.form.tags.join(",")
                : "",
            };
            this.$axiosPatch(global.API.getApp + '/' + this.appId, params).then((res) => {
              if ([200, 201, 204, 202].includes(res.status)) {
                self.$message.success("保存成功");
              }
            });
          }
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.app-dialog {
  height: 100%;
  width: 100%;
  .app-header {
    width: 100%;
    height: 56px;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.1);
    padding: 0 0 0 24px;
    line-height: 55px;
    border-bottom: 1px solid #F7F8FA;      
    span {
      display: inline-block;
      margin-left: 12px;
      font-size: 16px;
      color: #111111;
      letter-spacing: 0;
      font-weight: 500;
    }
  }
  .app-config {
    background: white;
    padding: 0px 10% 0px;
    color: black;
    font-size: 14px;
    height: calc(100% - 76px);
    position: relative;
    .app-right-bottom {
      position: absolute;
      bottom: 16px;
      right: 50%;
      margin-right: -20px;
      .btn-step {
        width: 86px;
        height: 32px;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
      }
      .btn-next {
        color: #ffffff;
        border-color: transparent;
        border: none;
      }
      .btn-cancel {
        background: #ecf1f7;
        font-size: 14px;
        color: #5a5a69;
      }
      .ant-btn[disabled] {
        color: #b2b5b9;
        background: #dee1e5;
      }
    }
    .title {
      font-size: 22px;
      color: #3a74f4;
      letter-spacing: 0;
      font-weight: 600;
      border-bottom: 1px solid #3a74f4;
      padding-bottom: 12px;
      margin-bottom: 16px;
    }
    div {
      &:nth-child(2) {
        font-size: 12px;
      }
    }
  }
  /deep/ .ant-form-explain {
    display: none;
  }
}
</style>