<!--
 * @Description: 
 * @Date: 2023-10-31 09:36:26
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 17:47:31
-->
<template>
  <div :class="['common-layout', globalSetting.theme.mode === 'light' ? 'light-bg' : 'dark-bg', isModal && 'common-layout-modal', globalSetting.theme.mode]">
    <div v-if="isModal" class="absolute model-close" @click="closeModal">
    </div>
    <div class="login">
      <div class="scan" @click="showScanPage">
        <img v-show="showLogin &&  !showScan" :src="globalSetting.theme.mode === 'light' ? scanLight : scanDark" alt="">
        <img v-show="showLogin && showScan" :src="globalSetting.theme.mode === 'light' ? computerLight : computerDark" alt="">
      </div>
      <div class="right">
        <a-tabs v-show="showLogin && !showScan" v-model="activeKey" @change="callback">
          <a-tab-pane key="1" tab="账号登录">
            <account @register="toRegister" @closeModal="closeModal" @openSyncSetting="openSyncSetting" @getMaster="getMaster" />
          </a-tab-pane>
          <a-tab-pane key="2" tab="手机登录" force-render>
            <phone @register="toRegister" @closeModal="closeModal" @openSyncSetting="openSyncSetting" @getMaster="getMaster" />
          </a-tab-pane>
        </a-tabs>
        <register v-show="!showLogin && !showScan"  @login="toLogin" />
        <ScanCode v-if="showLogin && showScan" @closeModal="closeModal" @openSyncSetting="openSyncSetting" @getMaster="getMaster" />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index'
import CommonLayout from "@/layouts/CommonLayout";
import account from "./account.vue";
import phone from './phone.vue'
import ScanCode from './scan-code.vue'
import register from './register.vue'
import { mapState, mapMutations, mapActions } from "vuex";
import syncSettingVue from './syncSetting.vue';
export default {
  name: "Login",
  components: { CommonLayout, account, register, phone, ScanCode },
  data() {
    return {
      activeKey: '1',
      showScan: false,
      showLogin: true,
      scanLight: require('@/assets/img/scan-light.svg'),
      scanDark: require('@/assets/img/scan-dark.svg'),
      computerLight: require('@/assets/img/computer-light.svg'),
      computerDark: require('@/assets/img/computer-dark.svg'),
      confirmPromise: null
    };
  },
  computed: {
    ...mapState("setting", ["footerLinks", "copyright", 'currentTab']),
    globalSetting () {
      return {...store.state.globalSetting}
    },
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions("setting", ["setMasterOptions"]),
   ...mapActions("masterMd", ["setHasMaster"]),
    ...mapActions("fileManage", [
      "setClusterId",
    ]),
    closeModal () {
      store.commit("account/setShowLogin", false)
    },
    openSyncSetting (res) {
      const cloudSetting = res.settings
      const localSetting = this.$store.state.globalSetting
      if (_.isEqual(cloudSetting, localSetting)) return false
      const self = this;
      let width = 400;
      const className = ['exit-login-modal', this.$store.state.globalSetting.theme.mode]
      let content = <syncSettingVue ref="syncSettingVueRef" callBack={(sync) => self.syncSetting(sync,res)} />
      this.confirmPromise = this.$confirm({
        width: width,
        title: '',
        content: content,
        class: className,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },

    customizer  (objValue, srcValue) {
      if (_.isArray(objValue) && _.isArray(srcValue)) {
        const map = new Map();

        // 处理目标数组
        objValue.forEach(item => {
          const key = (item.data && item.data.id) ? item.data.id : item.id ?item.id : JSON.stringify(item);
          map.set(key, item);
        });

        // 处理源数组
        srcValue.forEach(item => {
          const key = (item.data && item.data.id) ? item.data.id : item.id ?item.id : JSON.stringify(item);
          if (map.has(key)) {
            map.set(key, item); // 覆盖
          } else {
            map.set(key, item); // 添加
          }
        });

        // 将 Map 转换回数组
        return Array.from(map.values());
      }
    },

    syncSetting (sync, res) {
      if (this.confirmPromise) {
        this.confirmPromise.destroy()
      }
      const cloudSetting =  _.cloneDeep(res.settings)
      const localSetting = _.cloneDeep(this.$store.state.globalSetting)
      let settings = {}
      // 1. 合并数据
      // 合并数据还是merge数据
      if (sync === 'merge') {
        settings = _.mergeWith(localSetting, cloudSetting, this.customizer)
      }
      if (sync === 'cover') {
        settings = cloudSetting
      }
      this.$store.commit('globalSetting/SET_SETTINGS', settings);
      this.$store.dispatch('windowStore/setTaskbarEntriesStore');
      this.$EventBus.$emit('deleteUpdate', settings?.desktopLayout)
    },
    showScanPage () {
      this.showScan = !this.showScan
      const key = this.showScan ? '3' : this.activeKey
      this.$store.commit("setting/setCurrentTab", key);
    },
    callback(key) {      
      this.activeKey = key
      this.$store.commit("setting/setCurrentTab", key);
    },
    toRegister () {
      this.showLogin = false
    },
    toLogin () {
      this.showLogin = true
    },
    // 初始化 Master 获取集群列表 获取选中的集群
    getMaster () {
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results.length > 0) {
            const onlineCluster = res.data.results.filter(item => item.status === 'online')
            let DefaultMaster = {}
            if (onlineCluster.length > 0) {
              this.setHasMaster(true)
              const arr = onlineCluster.filter(item => item.is_default)
              if (arr.length > 0) {
                DefaultMaster = arr[0]
              } else {
                DefaultMaster = onlineCluster[0]
              }
            } else {
              this.setHasMaster(false)
            }
            localStorage.setItem('masterOptions', JSON.stringify(res.data.results))
            localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr || DefaultMaster?.private_addr || '')
            window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr || DefaultMaster.private_addr || ''
            this.setMasterOptions(res.data.results)
            this.setClusterId(DefaultMaster.id)
          } else {
           this.setMasterOptions([])
           localStorage.setItem('FILE_SYSTEM_URL', '')
            this.setClusterId('')
            window.config.FILE_SYSTEM_URL = ''
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.common-layout {
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%; /* 图片位置居中 */
  justify-content: center;
  .login {
    .scan {
      height: 46px;
      right: 8px;
      top: 8px;
      cursor: pointer;
      position: absolute;
      img {
        height: 100%;
      }
    }
    position: relative;
    margin: auto;
    width: 418px;
    height: 418px;
    background: rgba(29,26,26,0.35);
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 16px;
    display: flex;
    .right {
      flex: 1;
      padding: 40px 42px 0 42px;
      /deep/ .ant-tabs-bar {
        border-bottom: none;
        text-align: center;
      }
      /deep/ .ant-tabs-tab {
        font-size: 18px;
        color: #666666;
        padding: 12px 0 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      /deep/ .ant-tabs-ink-bar {
        height: 3px;
      }
      /deep/ .ant-tabs-nav .ant-tabs-tab:hover, /deep/ .ant-tabs-tab-active {
        font-size: 18px;
        color: #3974F4;
      }
      /deep/ .ant-tabs-tab-active {
        font-weight: 550;
      }
    }
  }
}
.common-layout-modal {
  position: relative;
  height: 418px;
  width: 418px;
  border-radius: 16px;
  .login {
     border-radius: 16px;
     background: transparent;
  }
  .model-close {
    top: 16px;
    left: 16px;
    z-index: 1;
    width: 20px;
    height: 20px;
    
    cursor: pointer;
    background-image: url("~@/assets/img/desktop/desktop-close-hover.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
