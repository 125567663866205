<!--
 * @Description: 
 * @Date: 2024-07-25 14:30:01
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 10:35:25
-->
<template>
  <div :class="['basic-setting h-full flex flex-col', globalSetting.theme.mode]">
    <div class="hi-scroll ph70 pv40 h-full">
      <div class="hi-cell-group border-color-m2 border-opacity-008 pdb20 mgb20">
        <div class="font-ali-75 text-16 text-color-t1">控制栏</div>
        <div class="pt16">
          <div class="hi-cell mgb10">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">侧边栏</span>
              <div class="hi-select">
                <a-select class="text-14 text-color-t2" :dropdownClassName="`hi-select-dropdown ${globalSetting.theme.mode}`" :value="globalSetting.basic.controlBar.sideBar.displayMode" @change="handleChange">
                  <a-select-option class="text-color-t2 bg-color-b5 hi-select-dropdown-item" v-for="(item, index) in globalSetting.basic.controlBar.sideBar.displayModeDict" :key="item.value" :value="item.value">
                    {{ item.label}}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="hi-cell mgb10">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">侧边栏位置</span>
              <div class="hi-select">
                <a-select class="text-14 text-color-t2" :dropdownClassName="`hi-select-dropdown ${globalSetting.theme.mode}`" :value="globalSetting.basic.controlBar.sideBar.displayPosition" @change="handleChangePosition">
                  <a-select-option class="text-color-t2 hi-select-dropdown-item" v-for="(item, index) in globalSetting.basic.controlBar.sideBar.displayPositionDict" :key="item.value" :value="item.value">
                    {{ item.label}}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="hi-cell"></div>
        </div>
      </div>
      <div class="hi-cell-group border-color-m2 border-opacity-008 pdb20 mgb20">
        <div class="font-ali-75 text-16 text-color-t1">图标</div>
        <div class="pt16">
          <div class="hi-cell mgb10">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">图标尺寸</span>
              <div class="hi-select">
                <a-select class="text-14 text-color-t2" :dropdownClassName="`hi-select-dropdown ${globalSetting.theme.mode}`" :value="globalSetting.desktopIcon.desktopIconSize" @change="handledesktopIconSizeChange">
                  <a-select-option class="text-color-t2 hi-select-dropdown-item" v-for="(item, index) in globalSetting.desktopIcon.desktopIconSizeDict" :key="item.value" :value="item.value">
                    {{ item.label}}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="hi-cell mgb20">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">图标区域宽度</span>
              <div class="cursor-pointer w180 text-right" @click="setWidth">
                <a-icon class="text-12 text-color-t2" type="right" />
              </div>
            </div>
          </div>
          <div class="hi-cell mgb10">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">隐藏图标名称</span>
              <div class="cursor-pointer w180 text-right hi-switch">
                 <a-switch size="small" :checked="globalSetting.desktopIcon.iconNameShow" @change="triggerIconName" />
              </div>
            </div>
          </div>
          <div class="hi-cell"></div>
        </div>
      </div>
      <div class="hi-cell-group border-color-m2 border-opacity-008 pdb20 mgb20">
        <div class="font-ali-75 text-16 text-color-t1">窗口</div>
        <div class="pt16">
          <div class="hi-cell mgb20">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">窗口大小</span>
              <div class="cursor-pointer w180 text-right" @click="setWinboxSize">
                <a-icon class="text-12 text-color-t2" type="right" />
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="hi-cell-group border-color-m2 border-opacity-008 pdb20 mgb20">
        <div class="font-ali-75 text-16 text-color-t1">搜索</div>
        <div class="pt16">
          <div class="hi-cell mgb20">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">搜索框样式</span>
              <div class="cursor-pointer w180 text-right" @click="setSearchBox">
                <a-icon class="text-12 text-color-t2" type="right" />
              </div>
            </div>
          </div>
             <div class="hi-cell mgb20">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">搜索建议</span>
              <div class="cursor-pointer w180 text-right hi-switch">
                 <a-switch size="small" :checked="globalSetting.searchBox.searchSuggestShow" @change="triggerSuggest" />
              </div>
            </div>
          </div>
             <div class="hi-cell mgb10">
            <div class="flex items-center justify-between">
              <span class="flex-1 text-14 text-color-t2">搜索历史</span>
              <div class="cursor-pointer w180 text-right hi-switch">
                 <a-switch size="small" :checked="globalSetting.searchBox.searchHistoryShow" @change="triggerHistory" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import store from '@/store/index'
import WidthSetting from './widthSetting.vue'
import WinboxSizeSetting from './winboxSizeSetting.vue'
import SearchBoxSetting from './searchBoxSetting.vue'


// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
  },
  data () {
    // 这里存放数据
    // https://api.wetab.link/api/wallpaper/list?client=pc&tag=animals
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
    displayModeText () {
      const obj = this.globalSetting.basic.controlBar.sideBar.displayModeDict.find(item => item.value === this.globalSetting.basic.controlBar.sideBar.displayMode)
     return obj?.label || ''
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.getPhotosBytag()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    handleChange (val) {
      const basic = this.globalSetting.basic
      basic.controlBar.sideBar.displayMode = val
      store.commit("globalSetting/setBasic", basic)
    },
    handleChangePosition (val) {
      const basic = this.globalSetting.basic
      basic.controlBar.sideBar.displayPosition = val
      store.commit("globalSetting/setBasic", basic)
    },
    handledesktopIconSizeChange (val) {
      const desktopIcon = this.globalSetting.desktopIcon
      desktopIcon.desktopIconSize = val
      store.commit("globalSetting/setDesktopIcon", desktopIcon)
    },
    handledesktopWidth (val) {
      const desktopIcon = this.globalSetting.desktopIcon
      desktopIcon.desktopIconSize = val
      store.commit("globalSetting/setDesktopIcon", desktopIcon)
    },
    handleSearchBoxWidth (val) {
      const searchbox = this.globalSetting.searchbox
      searchbox.searBoxWidth = val
      store.commit("globalSetting/setSearchBox", searchbox)
    },
    triggerIconName (val) {
      const desktopIcon = this.globalSetting.desktopIcon
      desktopIcon.iconNameShow = val
      store.commit("globalSetting/setDesktopIcon", desktopIcon)
    },
    triggerSuggest (val) {
      const searchBox = this.globalSetting.searchBox
      searchBox.searchSuggestShow = val
      store.commit("globalSetting/setSearchBox", searchBox)
    },
    triggerHistory (val) {
      const searchBox = this.globalSetting.searchBox
      searchBox.searchHistoryShow = val
      store.commit("globalSetting/setSearchBox", searchBox)
    },
    setWidth () {
      this.$destroyAll()
      const self = this;
      let width = 460;
      let title = "";
      let content = <WidthSetting />
      const className = ['desktop-setting-box width-setting-box', this.globalSetting.theme.mode]
      this.$confirm({
        width: width,
        title: title,
        content: content,
        class: className,
        closable: false,
        mask: false,
        maskClosable: false,
        icon: () => {
          return <div />;
        },
      });
    },
    setSearchBox () {
     this.$destroyAll()
      const self = this;
      let width = 460;
      let title = "";
      let content = <SearchBoxSetting />
      const className = ['desktop-setting-box width-setting-box winbox-size-setting-box', this.globalSetting.theme.mode]
      this.$confirm({
        width: width,
        title: title,
        content: content,
        class: className,
        closable: false,
        mask: false,
        maskClosable: false,
        icon: () => {
          return <div />;
        },
      });
    },
    setWinboxSize () {
      this.$destroyAll()
      const self = this;
      let width = 460;
      let title = "";
      let content = <WinboxSizeSetting />
      const className = ['desktop-setting-box width-setting-box winbox-size-setting-box', this.globalSetting.theme.mode]
      this.$confirm({
        width: width,
        title: title,
        content: content,
        class: className,
        closable: false,
        mask: false,
        maskClosable: false,
        icon: () => {
          return <div />;
        },
      });
    }
  }
}
</script>

<style lang='less' scoped>
  .h108 {
    // height: 108px;
  }
  .basic-setting {
    border-radius: 12px;
    z-index: 10;
  }
  @media (max-width: 720px) {
    .basic-setting > div.hi-scroll {
      padding: 30px 20px;
    }
  }
</style>
