/*
 * @Description: 
 * @Date: 2022-12-14 16:13:57
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-08-02 15:03:39
 */
import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import localStoragePlugin from './plugins/localStorage';
Vue.use(Vuex)
const store = new Vuex.Store(
 {
  modules,
  plugins: [localStoragePlugin],
 }
)

export default store