<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-03 17:14:47
 * @LastEditTime: 2024-07-15 16:15:48
 * @FilePath: \awx-ui\src\pages\appManage\chooesApp.vue
-->
<template>
  <div class="template-list">
    <div class="system-template">
      <div v-if="systemTAppist.length >0" class="flex-warp">
        <div v-for="(item, index) in systemTAppist" :key="index" class="template-container">
          <chooesAppItem :appItem="item" :appCate='appCate' :selectAppId="selectAppId" :appType="'app'" :showPopId="showPopId" @showPop="showPop" @addApp="addApp" />
        </div>
      </div>
      <div v-else>
        <NoData />
      </div>
    </div>
  </div>
</template>
  <script>
import NoData from "@/components/noData";
import chooesAppItem from "./chooesAppItem.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import AddAppIndex from './addApp/addAppIndex.vue'
export default {
  components: { NoData, chooesAppItem },
  props: {
    systemTAppist: Array,
    appCate: Array
  },
  data() {
    return {
      selectAppId: "",
      showPopId: ""
    };
  },
  inject: ['getApp'],
  watch: {},
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    showPop (id) {
      this.showPopId = id
    },
    // 新建应用
    addApp() {
      const self = this;
      let width = 740;
      let title = "";
      let content = (
        <AddAppIndex 
          creatMaster={() => {
            self.getApp();
          }} />
      );
      this.$confirm({
        width: width,
        class: "host-empty-dialog",
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.template-list {
    height: 100%;
    margin-left: calc(8% - 10px);
    padding-right: calc(8% - 10px);
  .system-template,
  .custom-template {
    &-title {
      padding-bottom: 16px;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .flex-warp {
    display: grid;
    // max-height: calc(100% - 212px);
    // height: calc(100% - 212px);
    overflow-y: auto;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* 150px 是每个元素的最小宽度 */

  }
  .template-container {
      padding: 0 10px 10px 10px;
  }
  // @media (max-width: 1200px) {
  //   .flex-warp {
  //     grid-template-columns: repeat(auto-fill, minmax(calc((100% - 60px) / 4), 1fr));
  //   }
  // }
  // @media (max-width: 992px) {
  //   .flex-warp {
  //     grid-template-columns: repeat(auto-fill, minmax(calc((100% - 40px) / 3), 1fr));
  //   }
  // }
  // @media (max-width: 768px) {
  //   .flex-warp {
  //     grid-template-columns: repeat(auto-fill, minmax(calc((100% - 20px) / 2), 1fr));
  //   }
  // }
  // @media (max-width: 576px) {
  //   .flex-warp {
  //     grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  //   }
  // }
}
</style>