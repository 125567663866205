<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-08-05 15:09:59
 * @FilePath: \awx-ui\src\pages\securityCenter\components\addUser.vue
-->
<template>
  <div style="padding-top: 10px">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content">
      <div v-if="step === 1">
        <div>验证码将发送到手机{{ formatMobile(detail.phone) }}上</div>
        <a-form-item class="code-num mgt20" label="填写验证码">
          <div class="code">
            <a-input class="login-input" size="large" placeholder="验证码" v-decorator="['code', {rules: [{ required: true, message: '验证码', whitespace: true}]}]"></a-input>
            <div :class="['code-btn', isCode && 'code-btn-send']" @click="sendCode">{{ isCode ? codeTime : '发送验证码' }}</div>
          </div>
        </a-form-item>
      </div>
      <div v-if="step === 2">
        <a-form-item v-if="editType.includes('Phone')" label="手机号码">
          <a-input v-decorator="['phone',{ rules: [{ required: true, message: '手机号码不能为空!' }, { validator: checkPhone }] }]" placeholder="请输入手机号码" />
        </a-form-item>
        <a-form-item class="code-num" label="验证码">
          <div class="code">
            <a-input class="login-input" size="large" placeholder="验证码" v-decorator="['code', {rules: [{ required: true, message: '验证码', whitespace: true}]}]"></a-input>
            <div :class="['code-btn', isCode && 'code-btn-send']" @click="sendCode">{{ isCode ? codeTime : '发送验证码' }}</div>
          </div>
        </a-form-item>
      </div>
    </a-form>
    <div class="ant-modal-confirm-btns-new">
      <a-button v-if="step === 2" style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
      <a-button v-if="step === 1" style="margin-right: 10px" type="primary" :disabled="isDisable" @click.stop="nextStep" :loading="nextLoading">下一步</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    callBack: Function,
    editType: String,
    closeConfirm: Function
  },
  data() {
    const self = this
    return {
      step: 2,
      isDisable: true,
      isCode: false,
      codeTime: 60,
      timer: null,
      isShow:false,
      scene: 'any-phone',
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      form: this.$form.createForm(this, {
        onFieldsChange: (_, changedFields) => {
          self.onFieldsChange(changedFields)
        },
      }),
      nextLoading: false,
      loading: false,
    };
  },
  watch: {},
  methods: {
    checkPhone(rule, value, callback) {
      var reg =  /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      }
      if (!value) {
        callback(new Error("请输入手机号码"));
      }
      callback();
    },
    onFieldsChange (changedFields) {
      if (this.step === 1 && changedFields['code'] && changedFields['code']['value']) {
        this.isDisable = false
      }
    },
    formatMobile(mobile) {
      if (mobile && mobile.length === 11) {
        return mobile.slice(0, 3) + '****' + mobile.slice(7);
      }
      return mobile;
    },
    formCancel() {
      this.closeConfirm();
    },
    testPhone () {
      const phoneNumberRegex = /^1[3456789]\d{9}$/;
      let flag = true
      const phone = this.form.getFieldValue("phone");
      if (!phone) {
        this.$message.error("请输入手机号码");
        return false
      }
      if (!phoneNumberRegex.test(phone)) {
        this.$message.error("请输入正确的手机号码");
        return false
      } 
      return flag
    },
    sendCode() {
      const phone = this.form.getFieldValue("phone");
      if (!this.testPhone()) return false
      if (this.isCode) return false;
      this.isCode = true;
      if (this.step === 1) this.scene = 'user-phone'
      if (this.step === 2) this.scene = 'any-phone'
      this.$axiosJsonPost(global.API.userSmsSend, {phone: phone, scene: this.scene}).then((res) => {
        this.codeTime--;
        this.timer = setInterval(() => {
          if (this.codeTime === 1) {
            this.isCode = false;
            if (this.timer) clearInterval(this.timer);
            this.codeTime = 60;
            return false;
          } else {
            this.codeTime--;
          }
        }, 1000);
      });
    },
    confiemUpdate (phone) {
      const _this = this;
      const ajaxApi = global.API.updateUser + `/${this.detail.id}/`
      const params = {};
      params.id = this.detail.id;
      params.email = this.detail.email || ''
      this.loading = true;
      this.$axiosPut(ajaxApi, {phone})
        .then((res) => {
          this.loading = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            this.$message.success("更新成功", 2);
            _this.callBack();
            setTimeout(() => {
              _this.closeConfirm();
            }, 1000)
          }
        })
      .catch((err) => { });
    },
    nextStep () {
      const _this = this
      this.form.validateFields((err, values) => {
        if (!err) {
          const ajaxApi = global.API.checkUserSms
          const params = {};
          if (this.step === 1) params.phone = this.detail.phone
          params.scene = this.scene
          params.code = values.code
          this.nextLoading = true
          this.$axiosJsonPost(ajaxApi, params)
            .then((res) => {
              this.nextLoading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                if (res.data.code === 200) {
                  _this.step = 2
                  _this.scene = 'any-phone'
                  _this.isCode = false
                  _this.codeTime = 60
                  if (_this.timer) {
                    clearInterval(_this.timer);
                    _this.timer = null
                  }
                  this.form.getFieldsValue([
                  "code",
                  "phone"
                  ]);
                  this.form.setFieldsValue({
                    code: '',
                    phone: ''
                  });
                }
              }
            })
          .catch((err) => { });
          }
        });
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const ajaxApi = global.API.checkUserSms
          const params = {};
          if (this.step === 1) params.phone = this.detail.phone
          if (this.step === 2) params.phone = values.phone
          params.scene = this.scene
          params.code = values.code
          this.$axiosJsonPost(ajaxApi, params)
            .then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                if (res.data.code === 200) {
                  _this.confiemUpdate(params.phone);
                }
              }
            })
          .catch((err) => { });
          }
        });
    },
    echoUSer() {
      if (this.detail.phone) {
        this.step = 1
        this.scene = 'user-phone'
        this.form.getFieldsValue([
          "phone",
        ]);
        this.form.setFieldsValue({
          phone: this.detail.phone,
        });
        }
      }
  },
  mounted() {
    this.echoUSer();
  },
};
</script>
<style lang="less" scoped>
  .code {
    display: flex;
    .code-num {
      flex: 1;
    }
    .code-btn {
      width: 120px;
      height: 36px;
      text-align: center;
      line-height: 34px;
      background: #ffffff;
      border: 1px solid rgba(34, 102, 249, 1);
      border-radius: 4px;
      color: #2266f9;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      margin-left: 20px;
      // &:hover {
      //    border: 1px solid rgba(34,102,249,1);
      // }
    }
    .code-btn-send {
      color: rgba(0, 0, 0, 0.45);
      border: 1px solid rgba(196, 204, 219, 1);
    }
  }
</style>
