<template>
  <div class="master-dialog">
    <div class="master-config">
      <div class="master-config-left">
        <div class="master-config-left-title">{{ stepsOptions.title }}</div>
        <div class="master-config-left-desc">{{ stepsOptions.desc }}</div>
        <a-steps class="master-config-left-list" direction="vertical" :current="step">
          <a-step :title="item.label" v-for="(item, index) in stepsOptions.stepList" :key="index" />
        </a-steps>
      </div>
      <div class="master-config-right">
        <div v-if="hasMaster !=='true'" class='exit'></div>
        <div>
          <div class="title">{{ stepsOptions.stepList[step].label }}</div>
          <!-- 标题 -->
          <div v-show="step === 0">
            <!-- 选择master节点 -->
            <SelectMasterNode v-if="showHost" :clusterId="clusterId" @getMasterId="getMasterId" @addNewMaster="addNewMaster" />
            <!-- 了解master安装需求 -->
            <UnderstandDemand v-else />
          </div>
          <!-- 第一步 -->
          <div v-show="step == 1">
            <SetNodeName ref="setNodeNameRef" v-if="!showHost" />
            <SelectImportMethod ref="selectImportMethodRef" v-else @changeOptions="changeOptions" />
          </div>
          <!-- 第二步-->
          <div v-show="step === 2">
            <SetInstallBase ref="SetInstallBaseRef" v-if="!showHost" :masterNodeName="masterNodeName" />
            <template v-else>
              <ManualImport ref="ManualImportRef" v-if="importMethod === 'manual'" :masterId="masterId" />
              <BatchImport ref="BatchImportRef" v-else />
            </template>
          </div>
          <div v-show="step === 3">
            <WaitMasterInstall v-if="!showHost" />
          </div>
          <!-- 第三步 -->
          <div class="master-right-bottom">
            <a-button v-if="hasMaster !== 'true' && step === 0" class="btn-up btn-step mgr12" @click="back">返回</a-button>
            <a-button v-else class="btn-up btn-step mgr12" @click="upStep()" :disabled="step === 0">上一步</a-button>
            <a-button class="btn-next btn-step" @click="nexStep()" type="primary">
              <span v-if="showHost">
                {{
                (step === 2) ? "完成" :"下一步"
                }}
              </span>
              <span v-if="!showHost">
                {{
                (step === 3) ? "保存" :"下一步"
                }}
              </span>
            </a-button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

import SelectMasterNode from "./selectMasterNode.vue"; // 选择master节点
import SelectImportMethod from "./selectImportMethod.vue"; // 选择导入方式
import BatchImport from "./batchImport.vue"; // 模板文件批量导入
import ManualImport from "./manualImport.vue"; // 批量手工导入
import UnderstandDemand from "./understandDemand.vue"; //安装master 了解master安装需求 1
import SetNodeName from "./setNodeName.vue"; // 安装master  设置master节点名称 2
import SetInstallBase from "./setInstallBase.vue"; // 安装master  设置master安装信息 3
import WaitMasterInstall from "./waitMasterInstall.vue"; // 安装master  等待master安装 4
export default {
  name: "configMaster",
  components: {
    SelectMasterNode,
    SelectImportMethod,
    BatchImport,
    UnderstandDemand,
    ManualImport,
    SetNodeName,
    SetInstallBase,
    WaitMasterInstall,
  },
  props: {
    clusterId: [Number, String],
    creatMaster: Function,
    hasMaster: String,
    isMasterAdd: Boolean
  },
  data() {
    return {
      checkNick: false,
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      showHost: true, // 如果为false就展示新建master节点
      step: 0,
      stepsOptions: {
        title: "导入主机",
        desc: "将主机导入到管理节点中进行管理",
        stepList: [
          { label: "选择管理节点", status: "setting" },
          { label: "选择导入方式", status: "notSet" },
          // { label: "模板文件导入", status: "notSet" },
        ],
      },
      configList: [
        "宿主机和其他主机处于同一个局域网内，且能够和其他主机沟通局域网互通",
        "宿主机无需具备公网IP，但必须要能够访问管理服务",
        "宿主机拥有大于100M的可用磁盘空间,以及64M以上的可用内存",
      ],
      plainOptions: ["linux", "windows"],
      value1: "linux",
      value2: "手动",
      installType: ["手动", "自动"],
      shellDes: "",
      masterId: null,
      masterNodeName: "",
      importMethod: "manual",
    };
  },
  watch: {
    isMasterAdd: {
      handler(val) {
        if (val) {
          // this.showHost = false
          const stepsOptions = {
            title: "安装管理节点",
            desc: "在目标网络中安装管理节点",
            stepList: [
              { label: "了解安装需求", status: "setting" },
              { label: "设置节点名称", status: "notSet" },
              { label: "设置安装信息", status: "notSet" },
              { label: "安装", status: "notSet" },
            ],
          }
        this.addNewMaster(stepsOptions)
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.geMasterShell();
  },
  methods: {
    changeOptions(options, importMethod) {
      this.stepsOptions.stepList = [
        { label: "选择管理节点", status: "setting" },
        { label: "选择导入方式", status: "notSet" },
      ];
      this.stepsOptions.stepList.push(options);
      this.importMethod = importMethod;
    },
    // 获取第一步选择到的master节点
    getMasterId(id) {
      this.masterId = id;
    },
    back () {
      this.$emit("backToHost")
    },
    // 展示新建master节点
    addNewMaster(options) {
      this.showHost = false;
      this.stepsOptions = options;
    },
    geMasterShell() {
      let obj = {
        type: this.value1,
      };
      this.$axiosGet(`${global.API.geMasterShell}`, obj).then((res) => {
        this.shellDes = res.data;
      });
    },
    check() {
      this.form.validateFields((err) => {
        if (!err) {
          console.info("success");
        }
      });
    },
    handleChange(e) {
      this.checkNick = e.target.checked;
      this.$nextTick(() => {
        this.form.validateFields(["nickname"], { force: true });
      });
    },
    copyShell() {
      var oInput = document.createElement("input"); //创建一个input标签
      oInput.value = this.shellDes;
      document.body.appendChild(oInput); //
      oInput.select(); // 模拟鼠标选中
      document.execCommand("Copy"); // 执行浏览器复制命令（相当于ctrl+c）
      oInput.style.display = "none"; //
      this.$message.success(`复制成功`);
    },
    refreshList() {
      this.$emit("refreshList", {});
    },
    setMaster() {
      this.step++;
    },
    upStep() {
      this.step--;
    },
    handleSubmit(dataSource) {
      const _this = this;
      dataSource.forEach((item) => {
        if (item.auth_type !== "password") {
          item.private_key = item.password;
          delete item.password;
        }
        return {
          ...item,
        };
      });
      // this.loading = true;
      const ajaxApi = global.API.saveHostByGRoup+'default/hosts?cluster_id=' + this.masterId;
      // return false
      this.$axiosJsonPost(ajaxApi, dataSource)
        .then((res) => {
          this.loading = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            _this.$message.success("导入成功");
            _this.$emit("creatMaster");
            return false
          }
        })
        .catch((err) => {});
    },
    nexStep() {
      const self = this;
      if (self.showHost) {
        // 导入主机
        if (self.step === 0 && this.masterId) return this.step++;
        if (this.step === 0 && !this.masterId)
          return this.$message.warning("请选择管理节点");
        if (this.step === 1) {
          return this.step++
        }
        if (self.step === 2) {
          if (this.importMethod === 'manual') {
            const { flag, dataSource } =
            this.$refs.ManualImportRef.check();
            console.log( flag, dataSource, ' flag, dataSource flag, dataSource')
            if (!flag) {
              this.handleSubmit(dataSource)
            }
          } else {
            const flag = this.$refs.BatchImportRef.loading
            if (flag) return this.$message.warning("主机导入中...");
            self.$emit("creatMaster");
            return false;
          }
        }
      } else {
        // 安装master
        // 了解安装需求
        if (this.step === 0) return this.step++;
        if (this.step === 1) {
          // 设置节点名称
          const nodeName = this.$refs.setNodeNameRef.check();
          console.log(nodeName);
          this.masterNodeName = nodeName;
          if (nodeName) return this.step++;
        }
        if (this.step === 2) {
          const { flag, param, installType, osType } =
            this.$refs.SetInstallBaseRef.check();
          if (installType === "自动" && !flag) {
            this.$axiosJsonPost(global.API.creatMaster + "?type=" +osType.toLowerCase() +"&name=" +this.masterNodeName,param).then((res) => {
            //   if ([200, 201, 204, 202].includes(res.status)) {
            //     this.$message.success("操作成功");
            //     return this.step++;
            // }
            });
            return this.step++;
          } else {
            if (!flag) return this.step++;
          }
        }
        if (this.step === 3) {
          const nodeName = this.$refs.setNodeNameRef.check();
          this.$axiosJsonPost(global.API.getMasterInfo, {name: nodeName})
          this.showHost = true;
          this.step = 0;
        }
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.master-dialog {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .master-config {
    display: flex;
    height: 650px;
    background: #ffffff;
    border-radius: 6px;
    .master-config-left {
      width: 230px;
      padding-top: 40px;
      height: calc(100% + 36px);
      position: relative;
      top: -36px;
      &:first-child {
        background: #447af0;
        color: white;
        font-size: 14px;
        border-radius: 6px 0 0 6px;
      }
      &-title {
        font-size: 24px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 500;
        padding-left: 26px;
      }
      &-desc {
        margin: 20px 0 72px;
        padding-left: 26px;
        padding: 0 26px;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: 400;
      }
      // &-list {
      //   padding-left: 26px;
      //   font-size: 16px;
      //   color: #ffffff;
      //   letter-spacing: 0;
      //   font-weight: 500;
      //   height: 36px;
      //   line-height: 36px;
      //   &-selected {
      //     background: rgba(255, 255, 255, 0.1);
      //     font-weight: 550;
      //   }
      // }
      &-list {
        padding-left: 26px;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 500;
        height: 36px;
        line-height: 36px;
        &-selected {
          background: rgba(255, 255, 255, 0.1);
          font-weight: 550;
        }
        /deep/ .ant-steps-item-title {
          color: #fff;
        }
        /deep/ .ant-steps-item-icon {
          background: transparent;
          background-color: #fff;
          color: #3a74f4;
          .ant-steps-icon {
            color: #3a74f4;
          }
        }
        /deep/ .ant-steps-item-process {
          .ant-steps-item-icon {
            width: 34px;
            height: 34px;
          }
        }
        /deep/ .ant-steps-item-wait .ant-steps-item-icon,
        /deep/ .ant-steps-item-finish .ant-steps-item-icon {
          background: transparent;
          background-color: transparent;
          border: 1px solid #fff;
          .ant-steps-icon {
            color: #fff;
          }
        }
        /deep/ .ant-steps-item-container {
          height: 86px;
        }
        /deep/ .ant-steps-item-process .ant-steps-item-title {
          font-weight: 550;
        }
        /deep/ .ant-steps-item-tail {
          position: absolute;
          top: 37px;
          left: 16px;
          width: 1px;
          height: 40px;
          background: #fff;
          padding: 38px 0 6px;
        }
      }
    }
    .master-config-right {
      background: white;
      padding: 57px 40px 46px;
      color: black;
      font-size: 14px;
      position: relative;
      width: 850px;
      .master-right-bottom {
        position: absolute;
        bottom: 46px;
        right: 40px;
        .btn-step {
          width: 86px;
          height: 32px;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
        }
        .btn-next {
          background: #43b418;
          color: #ffffff;
          border-color: transparent;
          border: none;
        }
        .ant-btn[disabled] {
          color: #b2b5b9;
          background: #dee1e5;
        }
      }
      .title {
        font-size: 22px;
        color: #3a74f4;
        letter-spacing: 0;
        font-weight: 600;
        border-bottom: 1px solid #3a74f4;
        padding-bottom: 12px;
        margin-bottom: 22px;
      }
      div {
        &:nth-child(2) {
          font-size: 12px;
        }
      }
    }
  }
  /deep/ .ant-form-explain {
    display: block;
  }
}
/deep/.ant-form {
  margin-top: 16px;
}
/deep/.ant-form-item {
  margin-bottom: 0;
}
/deep/.ant-form-item-label {
  margin-right: 8px;
}
</style>
<style lang="less" scoped>
  .no-master-host {
    .master-config {
      height: calc(100%)!important;
      &-left {
        height: calc(100%)!important;
        top: 0px!important;
      }
      &-right {
        padding-top: 0px!important;
        .exit {
          margin-bottom: 60px;
          height: 37px;
        }
      }
    }
  } 
</style>