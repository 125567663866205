/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-09-28 10:08:43
 * @LastEditTime: 2024-11-14 16:40:48
 * @FilePath: \awx-ui\src\store\modules\masterMd.js
 */

const state = {
  messageBoxShow: false,
  messageBoxText: '同步完成',
  messageBoxLoading: false,
}

const getters = {
}

const mutations = {}

Object.keys(state).forEach((item) => {
  mutations[item] = (state, value) => {
    state[item] = value
  }
})

const actions = {
  setMssageBoxShow({ commit }, obj) {
    commit('messageBoxShow', obj)
  },
  setMssageBoxLoading({ commit }, obj) {
    commit('messageBoxLoading', obj)
  },
  setMessageBoxText({ commit }, obj) {
    commit('messageBoxText', obj)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}