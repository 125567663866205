/*
 * @Description: 
 * @Date: 2024-07-16 10:03:20
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-09-13 15:00:03
 */
import Vue from 'vue';

const createState = () => ({
  fileRequestLoading: false,
  sysType: 'group',
  realEditList: [],
  showItem: {
    index: 0,
  },
  clusterId: '',
  selectTreeFlag: false,
  searchValue: '',
  drawerVisibleList: [],
  logDrawerVisibleList: [],
  showFileProgress: false,
  uploadFileList: [],
  downloadFileList: [],
  uploadTab: 'upload',
});

const state = {};

const getters = {
};

const mutations = {
  initializeModule(state, id) {
    if (!state[id]) {
      Vue.set(state, id, createState());
    }
  },
  setStateValue(state, { id, key, value }) {
    if (!state[id]) {
      // 如果任务不存在，则初始化它
      Vue.set(state, id, createState());
    }
    Vue.set(state[id], key, value);
  },
  setStateValue2(state, { id, key, value }) {
    if (state[id]) {
      state[id][key] = value;
    }
  },
  destroyModule(state, id) {
    if (state[id]) {
      delete state[id];
    }
  },
};

const actions = {
  initializeModule({ commit, state }, id) {
    if (state['id']) return false
    commit('initializeModule', id);
  },
  setStateValue({ commit }, payload) {
    commit('setStateValue', payload);
  },
  destroyModule({ commit }, id) {
    commit('destroyModule', id);
  },
  setWinboxClusterId({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'clusterId', value });
  },
  setWinboxSelectTreeFlag({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'selectTreeFlag', value });
  },
  setWinboxSearchValue({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'searchValue', value });
  },
  setWinboxFileRequestLoading({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'fileRequestLoading', value });
  },
  setWinboxSysType({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'sysType', value });
  },
  changeWinboxRealEditList({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'realEditList', value });
  },
  changeWinboxShowItem({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'showItem', value });
  },
  changeWinboxShowFileProgress({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'showFileProgress', value });
  },
  changeWinboxUploadFileList({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'uploadFileList', value });
  },
  changeWinboxUpload({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'uploadTab', value });
  },
  changeWinboxDownloadFileList({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'downloadFileList', value });
  },
  setWinboxDrawerVisibleList({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'drawerVisibleList', value });
  },
  setWinboxLogDrawerVisibleList({ dispatch }, { id, value }) {
    dispatch('setStateValue', { id, key: 'logDrawerVisibleList', value });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
