<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-26 19:22:45
 * @LastEditTime: 2024-11-11 15:35:42
 * @FilePath: \awx-ui\src\pages\appManage\chooesAppItem.vue
-->
<template>
  <div :class="['app-item-network', selectAppId === appItem.id ? 'app-item-network-selected' : '', appItem.id === 'add' && 'add-item']" @click.stop="selectedTemplate(appItem)">
    <svg-icon v-if="appItem.installed && appItem.installed > 0"  class="collect" icon-class="installed" />
    <div class="plus-to-desktop">
      <svg-icon v-if="appItem.id !== 'add'" class="plus-to-desktop-icon" icon-class="file-add" />
    </div>
    <span class="app-logo">
      <!-- 系统图标 -->
      <img v-lazy="appItem.icon" v-if="appItem.id !== 'add' && !IsColorIcon(appItem.icon) && !IsNetWorkIcon(appItem.icon)" alt />
      <!-- 纯色图标 -->
      <div v-if="appItem.id !== 'add' && IsColorIcon(appItem.icon) && !IsNetWorkIcon(appItem.icon)"  class='icon-item' :style="{
        'background': appItem.icon,
        'text-align': 'center',
        'width': '54px',
        'height': '54px',
        'border-radius': '8px',
        'margin': 'auto'
      }">
        <span :style="{
          'color': '#fff',
          'font-size': iconColorName(appItem.name).length === 1 ? '26px' : '20px',
          'width': '100%',
          'display': 'block',
          'height': '100%',
          'line-height': '54px'
        }">{{ iconColorName(appItem.name) }}</span>  
      </div>
      <!-- 网络图标 -->
      <iconifyIcon v-if="appItem.id !== 'add' && !IsColorIcon(appItem.icon) && IsNetWorkIcon(appItem.icon)" :icon="iconNetWorkName(appItem.icon)" style="font-size: 40px;" />
      <a-icon v-if="appItem.id === 'add'" type="plus" style="font-size: 38px;margin: auto;display: inline-block;color: #3974F4" />
    </span>
    <div v-if="appItem.id !== 'add'" class="app-text-info">
      <div class="app-title over-flow-ellipsis" :style="{'font-size': appType === 'app' ? '15px': '15px'}" :title="appItem.name">{{appItem.name}}</div>
      <p v-if="appItem.description" class="app-description over-flow-ellipsis" :title="appItem.description">{{appItem.description}}</p>
      <div class="app-categories" v-if="appItem.id !== 'add' && !appItem.description">
        <div class="app-categories-item" v-for="(item, index) in appcategories" :key="index">{{ handler(item)  }} <a-divider v-if="index !== appcategories.length - 1" type="vertical" /></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    appCate: Array,
    appItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectAppId: String,
    appType: {
      type: String,
      default: "template",
    },
  },
  components: {
    MyDynamicComponent: () => import('@/components/winbox/RenderComponent')
  },
  data() {
    return { 
    };
  },
  computed: {
    appcategories () {
      let arr = []
      arr = this.appItem.categories ? this.appItem.categories.split(',') : []
      return arr
    }
  },
  inject: ["createTask"],
  methods: {
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name){
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    selectedTemplate(appItem) {
      if (this.appItem.id === 'add') this.$emit("addNewApp", this.appItem);
      if (this.appItem.id !== 'add') this.$emit("addAppToDesktop", appItem);
    },
    hanlerOption (item) {
      if (item.type === 'del') {
        this.delApp()
      }
      if (item.type === 'add') this.createStep()
      if (item.type === 'import') this.importStep()
      if (item.type === 'edit') this.editApp()
    },
    handler (item) {
      let str = ''
      let obj = undefined
      obj = this.appCate.find(child => child.catagory === item)
      str = obj ? obj.name : item
      return str
    },
    install (item){
      this.createTask(item)
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.app-item-network {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 94px;
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  padding: 20px 12px 20px 20px;
  color: #adb0b8;
  display: flex;
  border: 1px solid rgba(207,213,222,0.5);
  border-radius: 6px;
  .plus-to-desktop {
    display: none;
    color: #fff;
    font-size: 36px;
    position: absolute;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    &-icon {
      font-size: 36px;
    }
  }
  &:hover {
    .plus-to-desktop {
      display: flex;
    }
    background: rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 6px;
    .btn-install {
      color: #fff!important;
      background: #3d77f4;
      border: 1px solid rgba(57, 116, 244, 0.6);
      border: 1px solid rgba(57, 116, 244, 1);
    }
    .more-action {
      display: none;
    }
  }
  .collect {
    position: absolute;
    top: -10px;
    right: -4px;
    font-size: 60px;
    z-index: 10;
  }
  .app-text-info {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .app-logo {
    height: 54px;
    margin: 0px 14px 0 0;
    width: 54px;
    min-width: 54px;
    line-height: 40px;
    background: #FFF; // 这个是图标的颜色定义
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    display: flex;
    img {
      width: 54px;
      border-radius: 8px;
      margin: auto;
    }
  }
  .app-title {
    flex: 1;
    line-height: 24px;
    font-weight: 600;
    font-size: 15px;
    color: #1d202d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
  }
  .app-description {
    margin-top: 6px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #7d838e;
    letter-spacing: 0;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .app-categories {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-item {
      margin-top: 6px;
      font-size: 12px;
      color: #7d838e;
      display: inline-block;
    }
  }
  .more-action {
    display: none;
    width: 18px;
    height: 24px;
    background: #E7EBF4;
    border-radius: 4px;
    text-align: center;
  }
  // 选中的这个模板
  &-selected {
    background: #f4faff;
    border: 1px solid rgba(57, 116, 244, 1);
    box-shadow: 0px 2px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 6px;
  }
  .pop-options {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 100px;
    z-index: 1;
    width: 100%;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 0px 3px 16px 0px rgba(0,0,0,0.2);
    .option-item {
      width: 100%;
      height: 32px;
      line-height: 32px;
      padding-left: 24px;
      font-size: 14px;
      color: #34343C;
      &:hover {
        background: #F3F6F9;
      }
    }
  }
}
.add-item {
  justify-content: center;
  &:hover {
    background: #f4faff;
    border: 1px solid rgba(57, 116, 244, 1);
  }
  .app-logo {
    background: #F2F5F9;
  }
}
</style>