<template>
  <div class="scroll-container" :style="{width: simulatedScrollWidth}">
    <div class="scroll-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimulatedScroll',
  props: {
    simulatedScrollWidth: {
      type: String,
      default: '100%'
    }
  }
};
</script>

<style scoped>
.scroll-container {
  position: relative;
  overflow: hidden; /* Hide actual scrollbars */
  box-sizing: border-box;
  white-space: nowrap; /* Prevent wrapping */
}

.scroll-content {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden; /* Ensure content doesn't scroll */
}
</style>
