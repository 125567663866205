/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2024-11-11 10:35:58
 * @FilePath: \awx-ui\src\store\modules\index.js
 */
import account from './account'
import setting from './setting'
import steps from './steps/steps'
import task from './task'
import quota from './quota'
import base from './base'
import operationConsole from './operationConsole'
import runHistory from './runHistory'
import masterMd from './masterMd'
import fileManage from './fileManage'
import winboxHostModule from './winboxHostModule'
import winboxFlowModule from './winboxFlowModule'
import windowStore from './windowStore'
import schedule from './schedule'
import globalSetting from './globalSetting'
import messageBox from './messageBox'



export default {
  account,
  setting,
  steps,
  task,
  quota,
  base,
  operationConsole,
  runHistory,
  masterMd,
  fileManage,
  schedule,
  windowStore,
  winboxHostModule,
  winboxFlowModule,
  globalSetting,
  messageBox
}