/* eslint-disable no-useless-escape */
/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2025-01-23 10:39:28
 * @FilePath: \awx-ui\src\utils\axios-interceptors.js
 */
import Cookie from 'js-cookie'
import Router from 'vue-router'
import store from '@/store/index.js'
// import {logout} from '@/services/user'

import {
  shouldUsePeerJsForRequest,
  rtcAdapter
} from './axios-peer.js'
// 401拦截
const errorCode = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(response, options) {
    const {
      message
    } = options
    // if (response.code !== 401 && response.code !== 403 && response.data.code !== 200) {
    //   message.error(response.data.msg)
    // }
    return response
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const {
      message
    } = options
    const {
      response
    } = error
    if (response && response.status === 500) {
      // message.error(response.statusText || '')
    }
    if (response && ![200, 201, 204, 202].includes(response.status) && response.data.msg) {
      message.error(response.data.msg || '')
    }
    return Promise.reject(response)
  }
}
// 401拦截
const resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(response, options) {
    const {
      message
    } = options
    if (response.status === 401) {
      removeItem()
    }
    return response
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const {
      message
    } = options
    const {
      response
    } = error
    if (response.status === 401) {
      removeItem()
    }
    return Promise.reject(error)
  }
}

const resp403 = {
  onFulfilled(response, options) {
    const {
      message
    } = options
    if (response.status === 403) {
      removeItem()
    }
    return response
  },
  onRejected(error, options) {
    const {
      message
    } = options
    const {
      response
    } = error
    if (response.status === 403) {
      removeItem()
    }
    return Promise.reject(error)
  }
}
function removeItem () {
  store.commit('account/setUser', { username: "", isSuperuser: false });
  store.commit("setting/setCurrentTab", '1');
  store.dispatch("fileManage/setClusterId", '');
  localStorage.removeItem('CLUSTER_ID')
  localStorage.removeItem('FILE_SYSTEM_URL')
  localStorage.removeItem('hasMaster')
  localStorage.removeItem('admin.user')
  localStorage.removeItem('token')
  localStorage.removeItem('masterOptions')
  localStorage.removeItem('historyWinboxSize')
}

function getFirstPathSegment(inputUrl) {
  const url = new URL(inputUrl);
  const pathSegments = url.pathname.split('/');
  return pathSegments[1];
}
const rtcFileds = ['schedules', 'group', 'host', 'filesystem', 'task', 'quick', 'dashboard']
function checkStringInArray(arr, str) {
  return arr.some(rtcField => str.includes(rtcField));
}
function replaceUrlPath(inputUrl, targetPath, replacementPath) {
  const url = new URL(inputUrl);
  const pathSegments = url.pathname.split('/');
  if (pathSegments[1] === targetPath) {
    pathSegments[1] = replacementPath;
    url.pathname = pathSegments.join('/');
    return url.href;
  } else {
    return inputUrl;
  }
}
const routeTransformations = {
  '^(side\/filesystem|uploadFile\/filesystem|filesystem)': 'side/filesystem',
  '^(awx\/host|host)': 'side/host',
  '^(awx\/group|group)': 'side/group',
  '^(awx\/schedules|schedules)': 'side/schedules',
  '^(awx\/task|task)': 'side/task',
  '^(awx\/quick|quick)': 'side/quick',
  '^(awx\/dashboard|dashboard)': 'side/dashboard'
};

function modifyUrl(url, params, clusterId = localStorage.getItem('CLUSTER_ID')) {
  let transformed = false;
  for (const [pattern, replacement] of Object.entries(routeTransformations)) {
    if (new RegExp(pattern).test(url)) {
      url = url.replace(new RegExp(pattern), replacement);
      transformed = true;
      break;
    }
  }
  // 只有当URL被转换过（即匹配了特定路由规则）才添加cluster_id参数
  if (transformed && !url.includes("cluster_id") && (!params || (params && !params.cluster_id))) {
    url += (url.includes("?") ? '&' : '?') + "cluster_id=" + clusterId;
  }
  return url;
}

function modifyProductUrl(url, params) {
  const filed = getFirstPathSegment(url)
  if (checkStringInArray(rtcFileds, filed)) {
    if (!url.includes("cluster_id") && (!params || (params && !params.cluster_id))) {
      if (url.includes("?")) {
        url += "&cluster_id=" + localStorage.getItem('CLUSTER_ID');
      } else {
        url += "?cluster_id=" + localStorage.getItem('CLUSTER_ID');
      }
    }
    url = replaceUrlPath(url, filed, filed)
  }
  return url;
}

function replaceOrCompleteBaseUrl(originalUrl, newBaseUrl) {
  // 正则表达式匹配http(s)://开头
  var protocolRegex = /^(https?:\/\/)/i;

  // 如果原始URL不以http://或https://开头，则补充新基础URL
  if (!protocolRegex.test(originalUrl)) {
    originalUrl = newBaseUrl + originalUrl; // 直接拼接新基础URL
    return originalUrl
  } else {
    const filed = getFirstPathSegment(originalUrl)
    if (checkStringInArray(rtcFileds, filed)) {
      // 定义正则表达式以匹配协议、域名和端口
      // eslint-disable-next-line no-useless-escape
      var baseUrlRegex = /^(https?:\/\/[^\/]+)(.*)$/i;
      // 使用正则表达式对原始URL进行匹配
      var match = originalUrl.match(baseUrlRegex);

      if (match) {
        // 如果匹配成功，则构造新的URL
        var newPath = match[2]; // 获取路径及查询字符串部分
        var newUrl = newBaseUrl + newPath; // 使用新的基础URL替换
        return newUrl;
      } else {
        return originalUrl
      }
    } else {
      return originalUrl
    }
  }
}
const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config, options) {
    const {
      message
    } = options
    const {
      url,
      xsrfCookieName
    } = config
    let realUrl = ''
    if (process.env.NODE_ENV !== 'development' && url.includes('filesystem')) {
      realUrl = url.replace("/filesystem", localStorage.getItem('FILE_SYSTEM_URL') + '/filesystem');
      if (process.env.NODE_ENV !== 'development' && url.includes('side/filesystem')) {
        realUrl = url.replace("side/filesystem", localStorage.getItem('FILE_SYSTEM_URL') + '/side/filesystem');
      }
    } else if (process.env.NODE_ENV !== 'development' && url.includes('/jobs/')) {
      realUrl = url.replace("/jobs/", localStorage.getItem('FILE_SYSTEM_URL') + '/jobs/');
    } else if (process.env.NODE_ENV !== 'development' && url.includes('/monitor/install')) {
      realUrl = url.replace("/monitor/install", localStorage.getItem('FILE_SYSTEM_URL') + '/monitor/install');
    } else {
      realUrl = url
    }
    // console.log('我的请求原始路径', '-------------------', realUrl)
    if (process.env.NODE_ENV !== 'development') {
      realUrl = replaceOrCompleteBaseUrl(modifyProductUrl(realUrl, config.params), localStorage.getItem('FILE_SYSTEM_URL') + '/side')
    } else {
      realUrl = modifyUrl(realUrl, config.params)
      // if (realUrl.includes('side/')) {
      //   realUrl = realUrl.replace('side/', '/')
      // }
    }
    // console.log('我的请求最终路径', '-------------------', realUrl)
    if (realUrl)
      config.url = realUrl
    if (shouldUsePeerJsForRequest(config)) {
      config.adapter = rtcAdapter
    }
    config.headers['Content-Type'] = config.ContentType ? config.ContentType : 'application/json;charset=UTF-8'
    config.headers['token'] = localStorage.getItem('token')
    if (window.navigator.onLine) {
      // 设备有网络连接，继续发送请求
      return config;
    } else {
      // 设备无网络连接，取消请求并抛出错误
      return Promise.reject(new Error('No network connection'));
    }
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const {
      url
    } = config
    const {
      message
    } = options
    // if (url.indexOf('login') !== -1) {
    //   message.error(error.message)
    // }
    return Promise.reject(error)
  }
}

export default {
  request: [reqCommon], // 请求拦截
  response: [resp401, resp403, errorCode] // 响应拦截
}