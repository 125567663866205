/*
 * @Description: 
 * @Date: 2024-07-04 10:02:03
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-07-04 10:02:05
 */
// directives/resize.js
export default {
  bind(el, binding, vnode) {
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const { width, height } = entry.contentRect;
        // 调用传入的处理函数，并传递新的尺寸
        if (typeof binding.value === 'function') {
          binding.value({ width, height });
        }
      });
    });
    observer.observe(el);
    // 保存observer，以便在unbind时解除观察
    el._vueResizeObserver = observer;
  },
  unbind(el) {
    el._vueResizeObserver.unobserve(el);
    delete el._vueResizeObserver;
  },
};