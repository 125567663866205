<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-08-12 17:09:28
 * @FilePath: \awx-ui\src\components\createTask\deploystep\operationList.vue
-->
<template>
  <div class="operation-list">
    <div v-if="operationList && operationList.length > 0" :class="[taskType === 'log' ? 'operation-log-list' : 'operation-steps-list']">
      <vue-draggable v-if="taskType !== 'log'" tag="div" :list="operationList" filter=".disabbled-node" @end="onEnd" @add="add" @start="onDragStart" @update="onUpdate" :move="onMove" :group="groupOPtions">
        <div :class="['operation-steps-item', index === 0 ? 'disabbled-node' : '']" v-for="(item, index) in operationList" :key="index">
          <drag-template :templateItem="item" :operationList="operationList" :templateItemIndex="index" />
        </div>
      </vue-draggable>
      <template v-else>
        <div :class="['operation-steps-item', index === 0 ? 'disabbled-node' : '']" v-for="(item, index) in operationList" :key="index">
          <logTemplate :templateItem="item" :operationList="operationList" :runStatus="runStatus" :templateItemIndex="index"></logTemplate>
        </div>
      </template>
    </div>
    <div v-else class="actions-empty">
    </div>
  </div>
</template>
<script>
import DragTemplate from "../components/dragTemplate.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import vuedraggable from "vuedraggable";
import logTemplate from "../components/logTemplate.vue";
export default {
  name: "OperationList",
  props: {
    taskType: {
      type: String,
      default: "task",
    },
    runStatus: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  components: { DragTemplate, VueDraggable: vuedraggable, logTemplate },
  watch: {
    taskId: {
      handler(val) {
        if (val) {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    // 从模板那边进来的 隐藏右边的模板
    taskType: {
      handler(val) {
        if (val === "template") {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
  },
  // inject: ['currentStepsSub'],
  data() {
    return {
      nextLoading: false,
      eidtFlag: false,
      spinning: false,
      selectTemplateIndex: -1,
      groupOPtions: {
        pull: true, // 允许从列表中拖出元素
        put: false, // 不允许将元素放入列表中
        name: "nodeItem",
      },
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    taskId() {
      const taskId = this.task.taskInfo.task_id || "";
      return taskId;
    },
    operationList() {
      let operationList = [];
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      )
        operationList = this.task.taskInfo.taskTemplateDetail.operations;
      return operationList;
    },
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setSelectTemplateIndex",
      "setCurrentOptIndex",
      "setCurrentPageIndex",
    ]),
    onDragStart(evt) {},
    onEnd(e, data) {
      if (e.oldIndex === this.task.selectTemplateIndex) {
        this.setSelectTemplateIndex(e.newIndex);
        return;
      }
      if (e.newIndex === this.task.selectTemplateIndex) {
        this.setSelectTemplateIndex(e.oldIndex);
        return;
      }
    },
    onMove(e, data) {
      // 调度的不能拖动  其余的元素不能拖动到调度的位置
      if (e.relatedContext.index === 0) {
        return false;
      }
    },
    onUpdate(e, data) {},
    add(e) {},
    back() {
      const taskInfo = this.task.taskInfo;
      taskInfo.taskTemplateDetail.operations = [];
      this.setTaskInfo(taskInfo);
      this.setSelectTemplateIndex(null);
    },
    async saveCustomTemplate() {
      const self = this;
      let param = _.cloneDeep(this.task.taskInfo);
      if (
        !(
          param.taskTemplateDetail &&
          param.taskTemplateDetail.operations &&
          param.taskTemplateDetail.operations.length > 0
        )
      ) {
        this.$message.warning("步骤尚未执行");
        return false;
      }
      param.operations = param.taskTemplateDetail.operations;
      param.configs = param.taskTemplateDetail.configs;
      delete param.taskTemplateDetail;
      this.nextLoading = true;
      let res = await this.$axiosJsonPost(global.API.curdTaskTemplate, param);
      this.nextLoading = false;
      if ([200, 201, 204, 202].includes(res.status)) {
        console.log(res, "我保存之后的数据");
        this.$message.success("保存成功");
        this.$EventBus.$emit("getTemplates");
      }
    },
  },
  mounted() {
    this.$dragging.$on("dragged", ({ value }) => {
      this.selectTemplateIndex = _.cloneDeep(this.task.selectTemplateIndex);
      console.log(value.item, "item");
      console.log(value.list, "list", this.task);
      console.log(value.templateItemIndex, "templateItemIndex");
    });
    this.$dragging.$on("dragend", (item) => {});
  },
};
</script>
<style lang="less" scoped>
.operation-list {
  .operation-steps-list {
    overflow-y: auto;
    padding-right: 20px;
    max-height: 654px;
  }
  .actions-empty {
    text-align: center;
    &-icon {
      height: 528px;
      width: 276px;
    }
  }
  .operation-box__tip {
    color: #8a8e99;
    font-size: 14px;
    margin-top: 30px;
    margin: 30px 20px 0 0;
    .devui-link {
      color: #526ecc;
    }
  }
  .submit-template {
    margin: 30px 20px 0 0;
    width: calc(100% - 20px);
    opacity: 0.9;
    background: #eef2f7;
    border-radius: 4px;
    font-size: 14px;
    color: #5a5a69;
    letter-spacing: 0;
    font-weight: 400;
  }
  .operation-log-list {
    overflow-y: auto;
    padding-right: 20px;
    height: calc(100%);
    padding-left: 12px;
  }
}
</style>