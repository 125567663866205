
<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-12-01 11:15:48
 * @LastEditTime: 2024-08-15 14:43:31
 * @FilePath: \awx-ui\src\pages\appManage\editApp\index.vue
-->
<template>
  <div class="edit-app-page">
    <div class="flex">
      <div v-show="visible" class='left'>
        <left-app-vue />
        <div
          v-if="visible"
          class='left-icon'
          @click="visible= false"
        >
          <svg-icon
            style="height:40px;margin-right: 0;"
            :icon-class="'arrow-left'"
          />
        </div>
      </div>
      <div class='right'>
        <div
          v-if="!visible"
          class='right-icon'
          @click="visible= true"
        >
          <svg-icon
            style="font-size:40px;margin-right: 0;"
            :icon-class="'arrow-right'"
          />
        </div>
        <right-app-vue />
      </div>
    </div>
  </div>
</template>
<script>
import rightAppVue from './rightStep.vue';
import leftAppVue from './leftStepTree.vue';
export default {
  components: {
    rightAppVue,
    leftAppVue
  },
  data () {
    return {
      tabKey: '2',
      visible: true,
    };
  },
  watch: {
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],

  computed: {
    appId () {
      let appId = this.windowPropsData.appId || ''
      return appId
    },
  },
  methods: {
    backFlow () {
      this.$router.go(-1)
    }
  },
};
</script>
<style lang="less" scoped>
.edit-app-page {
  position: relative;
  height: 100%;
  .flow-back {
    cursor: pointer;
    &-icon {
      font-size: 20px;
      color: #333;
    }
  }
  .task-steps-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #1d202d;
    font-weight: 600;
    padding: 0px 0 0 24px;
    height: 47px;
    padding-right: 16px;
    line-height: 47px;
    background: linear-gradient(
      to right,
      rgba(226, 235, 250, 0.4),
      rgba(240, 243, 247, 0.4)
    );
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #dee8f5;
    &-edit {
      cursor: pointer;
    }
    .task-steps-name-edit {
      color: @primary-color;
      font-size: 16px;
    }
  }
  .flex {
    height: 100%;
  }
  .left {
    position: relative;
    height: 100%;
    width: 270px;
    border-right: 1px solid #d1d2d7;
    .left-icon {
      position: absolute;
      right: -2px;
      top: 43%;
      cursor: pointer;
      z-index: 100;
    }
  }
  .right {
    flex: 1;
    .right-icon {
        position: absolute;
        left: -15px;
        z-index: 100;
        top: 43%;
        cursor: pointer;
      }
  }
  .add-btn {
    position: absolute;
    right: 24px;
    top: 56px;
    z-index: 10000;
  }
}
</style>