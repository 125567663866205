/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2024-11-12 15:50:42
 * @FilePath: \awx-ui\src\api\httpApi\app.js
 */
  
  
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path
export default {
  getfavicons: path + '/favicons',
  getAppCatagories:path+'/app_catagories',  // 应用分类
  getApp: path+'/apps',  // 应用列表
  getConfigByStepsId: path+'/templates/step-template',  // 应用列表
  getIcons: path + '/icons',
  getConnections: path + '/connections',
  getSoftWareByApp: (id) => `path+ /apps/${id}/softwares`,
  getNodeSoftware: path+ '/softwares',
  unstallSoftware: (software_id) => path + `/ws/software/${software_id}/uninstall`,
  installSoftware: (software_id) => path + `/ws/software/${software_id}/install`,
  getSoftwareJobs: path + '/jobs/', //主机安装依赖
  getCronLog: (id) => path + `/crond/${id}/log`,
  getAppSoftWareStatus: (id) => path + `/apps/${id}/software/status`
}
