<!--
 * @Description: 
 * @Date: 2024-06-21 14:14:04
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-06 17:45:02
-->
<template>
    <!-- :style="fileEntryStyle" -->
  <div
    class='file-entry'
    @mousedown="startDrag($event)"
    @mousemove="checkMove($event)"
    @contextmenu="(e) => parentShow(e)"
    @mouseup="endDrag($event)"
  >
    <div class="file-entry-img" :style='borderRadiusStyle'>
      <!-- 图片地址 -->
      <img
        v-if="!IsColorIcon(fileEntry.url) && !IsNetWorkIcon(fileEntry.url)"
        :style="imageStyle"
        :src="fileEntry.url"
        alt=""
      >
      <!-- 纯色图标 -->
      <div
        v-if="IsColorIcon(fileEntry.url) && !IsNetWorkIcon(fileEntry.url)"
        class='color-icon'
        :style="{
        'background': fileEntry.url,
        ...imageStyle }"
      >
        <span :style="colorIconFontStyle">{{ iconColorName(fileEntry.name) }}</span>
      </div>
      <!-- 网络图标 -->
      <div
        class='network-icon'
        v-if="!IsColorIcon(fileEntry.url) && IsNetWorkIcon(fileEntry.url)"
        :style="imageStyle"
      >
        <iconifyIcon
          :icon="iconNetWorkName(fileEntry.url)"
          :style="netIconFontStyle"
        />
      </div>
    </div>
    <div
      v-show="iconNameShow"
      class="name ell"
    >{{ fileEntry.name }}</div>
  </div>
</template>


<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import store from '@/store/index'
import textHide from 'postcss-utilities/lib/text-hide';
import { mapState, mapMutations, mapActions } from "vuex";
import AddAppIndex from '@/pages/appStore/netWork/addApp/appModal/appModalIndex.vue'

import {
  mapSizes,
  itemSize,
  mapNetFontSizes,
  mapColorFontSizes,
  mapRadius
} from '@/utils/desktopIcon-map'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MyDynamicComponent: () => import('@/components/winbox/RenderComponent'),
    ExternalComponent: () => import('@/components/browser/index.vue')
  },
  props: {
    fileEntry: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    // 这里存放数据
    return {
      isDragging: false,
      startX: 0,
      startY: 0,
    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return { ...store.state.globalSetting }
    },
    iconNameShow () {
      return this.globalSetting.desktopIcon.iconNameShow
    },
    desktopIconSize () {
      return this.globalSetting.desktopIcon.desktopIconSize
    },
    fileEntryStyle () {
      return {
        width: (mapSizes.get(this.desktopIconSize) + 20) + 'px',
        height: (mapSizes.get(this.desktopIconSize) + 32) + 'px',
      }
    },
    imageStyle () {
      return {
        width: mapSizes.get(this.desktopIconSize) + 'px',
        height: mapSizes.get(this.desktopIconSize) + 'px',
        'border-radius': mapRadius.get(this.desktopIconSize) + 'px',
      }
    },
    borderRadiusStyle () {
       return {
        'border-radius': mapRadius.get(this.desktopIconSize) + 'px',
      }
    },
    netIconFontStyle () {
      return {
        'font-size': mapNetFontSizes.get(this.desktopIconSize) + 'px',
      }
    },
    colorIconFontStyle () {
    let size =  mapColorFontSizes.get(this.desktopIconSize)
      if (this.containsChineseCharacters(this.fileEntry.name)) {
        size = size
      } else if(this.containsLetters(this.fileEntry.name)) {
        size = size + 2
      } else {
        size = size + 6
      }
      return {
        'font-size': size + 'px',
      }
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.openDynamicWinBox = _.debounce(this.debounceOpenDynamicWinBox, 500);
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    ...mapMutations('globalSetting', ['setDesktopLayout', 'setFixedTabar']),
    ...mapActions('windowStore', ['setTaskbarEntriesStore']),
    IsColorIcon (icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name) {
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon (icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name) {
      return global.utils.iconNetWorkName(name);
    },
    containsLetters(str) {
      // 正则表达式用于匹配英文字母
      const letterRegex = /[a-zA-Z]/;
      // 测试字符串是否包含英文字母
      return letterRegex.test(str);
    },
    containsChineseCharacters(str) {
      // 正则表达式用于匹配汉字
      const chineseRegex = /[\u4e00-\u9fa5]/;

      // 测试字符串是否包含汉字
      return chineseRegex.test(str);
    },
    parentShow (e) {
      console.log(e.button, 'event.buttonevent.buttonevent.button')
      e.stopPropagation()
      if (this.fileEntry.componentName === 'ExternalComponent') {
        e.preventDefault();
        const ExternalComponent = this.fileEntry[`${this.fileEntry.componentName}`]
        let items = [
          {
            label: "编辑图标",
            onClick: () => {
              e.stopPropagation()
              this.editIcon()
            },
          },
          {
            label: "删除图标",
            onClick: () => {
              e.stopPropagation()
              this.deleteIcon()
            },
          },
          {
            label: "添加到任务栏",
            onClick: () => {
              e.stopPropagation()
              this.moveToTaskBar()
            },
          },
          {
            label: "打开方式",
            children: [
              {
                label: "标签",
                icon: ExternalComponent.openWith === '_blank' && 'el-icon-check',
                onClick: () => {
                  e.stopPropagation()
                  this.changeOpeTypeWith('_blank')
                },
              },
             {
               label: "窗口",
               icon: ExternalComponent.openWith === '_target' && 'el-icon-check',
               onClick: () => {
                 e.stopPropagation()
                 this.changeOpeTypeWith('_target')
               },
             }
            ],
          },
        ]
        this.$contextmenu({
          items: items,
          event: e,
          customClass: "custom-contextmenu",
          zIndex: 3000,
          minWidth: 80,
        });
        return false;
      }
    },
    editIcon () {
      const layout = _.cloneDeep(this.globalSetting.desktopLayout);
      // const arr = layout.filter((item) => item.data.id !== this.fileEntry.id);
      const self = this;
      let width = 740;
      let title = "";
      let content = (
        <AddAppIndex 
          appDetail={self.fileEntry} 
        />
      );
      this.$confirm({
        width: width,
        class: "host-empty-dialog",
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    moveToTaskBar () {
      const fixedBar = this.globalSetting.fixedTabar
      const index = fixedBar.findIndex(item => item.id === this.fileEntry.id)
      if (index !== -1 ) return false
      fixedBar.push(
        {...this.fileEntry}
      )
      this.setFixedTabar(fixedBar)
      this.setTaskbarEntriesStore()
    },
    deleteIcon () {
      const layout = _.cloneDeep(this.globalSetting.desktopLayout);
      const arr = layout.filter((item) => item.data.id !== this.fileEntry.id);
      this.$EventBus.$emit('deleteUpdate', arr)
    },
    changeOpeTypeWith (openWith) {
      const layout = _.cloneDeep(this.globalSetting.desktopLayout);
      layout.forEach(item => {
        if (item.data.id === this.fileEntry.id) {
          item.data[`${item.data.componentName}`].openWith = openWith
        }
      })
      this.setDesktopLayout(layout);
    },
    startDrag (event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
    },
    endDrag (event) {
       // 检查鼠标按钮
      if (event.button !== 0) { // 0 是左键
        return; // 如果不是左键，直接返回，不执行后续逻辑
      }
      const endX = event.clientX;
      const endY = event.clientY;
      const movedX = Math.abs(endX - this.startX);
      const movedY = Math.abs(endY - this.startY);
      if (movedX < 5 && movedY < 5) {
        this.openDynamicWinBox();
      }

      this.isDragging = false;
    },
    checkMove (event) {
      if (!this.isDragging) return;
      const currentX = event.clientX;
      const currentY = event.clientY;
      const movedX = Math.abs(currentX - this.startX);
      const movedY = Math.abs(currentY - this.startY);

      if (movedX > 5 || movedY > 5) {
        this.isDragging = false;
      }
    },
    async debounceOpenDynamicWinBox () {
      const componentName = this.fileEntry.componentName;
      const component = this.$options.components[componentName];
      const appLoaderOptions = {
        ...this.fileEntry
      }
      if (this.fileEntry.componentName === 'ExternalComponent' && this.fileEntry[`${this.fileEntry.componentName}`].openWith === '_blank') {
        let url = global.utils.ensureProtocol(this.fileEntry[`${this.fileEntry.componentName}`].externalUrl)
        if (!/^file:\/\//.test(url) && !/^https?:\/\//.test(url) && !url.startsWith('/')) {
            url = 'http://' + url; // Automatically add http://
        }
        return window.open(url) 
      }
      let url = appLoaderOptions?.ExternalComponent?.externalUrl;
      if (url) {
        if (!/^file:\/\//.test(url) && !/^https?:\/\//.test(url) && !url.startsWith('/')) {
            url = 'http://' + url; // Automatically add http://
        }
        return window.open(url, "_blank", {disposition: "window"})
      } else{
        this.$openWindow(this.fileEntry.id, this.$options.components, { propsData: this.fileEntry }, {}, { ...appLoaderOptions });
      }
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.file-entry {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  &:hover {
    background: rgba(218, 218, 218, 0.4);
    border-radius: 4px;
  }
  img {
  }
  &-img {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .color-icon {
    text-align: center;
    width: 56px;
    height: 56px;
    margin: auto;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #fff;
      width: 100%;
      display: block;
    }
  }
  .network-icon {
    height: 56px;
    width: 56px;
    background: #fff;
    align-items: center;
    justify-content: center;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
  }
  .name {
    margin-top: 4px;
    font-family: PingFangSC-Medium;
    font-size: 13px;
    color: #ffffff;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    font-weight: 500;
    line-height: 18px;
    display: block;
    min-width: 58px;
    max-width: 92px;
    text-align: center;
  }
}
</style>
