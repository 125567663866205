<template>
  <grid-layout
    ref="gridLayout"
    :layout="desktopLayout"
    :col-num="colNum"
    :row-height="rowHeight"
    :is-mirrored="isMirrored"
    :vertical-compact="verticalCompact"
    :margin="margin"
    :use-css-transforms="useCssTransforms"
    :useStyleCursor="useStyleCursor"
    :is-draggable="isDraggable"
    :is-resizable="isResizable"
    :prevent-collision="true"
    @layout-updated="layoutUpdatedEvent"
    :responsive="false"
    :is-bounded="true"
  >
    <grid-item
      v-for="(item, index) in desktopLayout"
      :x="item.x"
      :y="item.y"
      :w="item.w"
      :h="item.h"
      :i="item.i"
      :minW="minW"
      :minH="minH"
      :key="index"
      :style="itemStyle(item)"
      :is-draggable="item.data?.fileCompontentType !== 'AddIcon'"
      :class="['custom-grid-item', addActiveId === item.data?.id && 'hover']"
      :id="'custom-grid-id-' + item.data?.id"
      @resized="resize(item.i)"
    >
      <slot
        name="app"
        :item="item"
        :index="index"
      ></slot>
    </grid-item>
  </grid-layout>

</template>
<script>
import GridLayout from 'vue-grid-layout';
import store from '@/store/index';
import { calculateLayout } from '@/utils/dynamic-layout';
import { getGridSize, findFreePosition, adjustLayoutForStrategyTwo, adjustLayoutForStrategyOne, adjustLayoutForStrategyOptimized } from '@/utils/dynamic-desktoplayout';
import { insertElementsIntoGrid } from '@/utils/dynamic-insert';
import {mapSizes, itemSize} from '@/utils/desktopIcon-map'

export default {
  components: {
    GridLayout: GridLayout.GridLayout,
    GridItem: GridLayout.GridItem,
  },
  props: {
    // 布局数据
    layout: {
      type: Array,
      default: () => []
    },
    // 是否可拖拽
    isDraggable: {
      type: Boolean,
      default: true
    },
    // 是否可改变大小
    isResizable: {
      type: Boolean,
      default: false
    },
    // // 多少列
    // colNum: {
    //   type: Number,
    //   default: 12
    // },
    // 每行得高度
    // rowHeight: {
    //   type: Number,
    //   default: 30
    // },
    // 是否可镜像反转
    isMirrored: {
      type: Boolean,
      default: false
    },
    // 标识布局是否垂直压缩
    verticalCompact: {
      type: Boolean,
      default: false
    },
    // 定义栅格中的元素边距
    margin: {
      type: Array,
      default: () => [0, 0]
    },
    // 标识是否使用CSS属性 transition-property: transform;
    useCssTransforms: {
      type: Boolean,
      default: false
    },
    // 是否使用动态鼠标指针样式
    useStyleCursor: {
      type: Boolean,
      default: false
    },
    // 元素最小宽
    minW: {
      type: Number,
      default: 1
    },
    // 最小高
    minH: {
      type: Number,
      default: 1
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colNum: 12,
      bounds: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 100
      },
      height: '',
      option: {},
      gridShow: false,
      desktopLayout: [],
      isFirst: true,
    }
  },
  watch : {
    iconWidth: {
      handler (val) {
       this.$nextTick(() => {
          this.updatecolNum()
       })
      },
      immediate: false
    },
    iconLock: {
      handler (val) {
      this.$nextTick(() => {
        this.colNum = this.getcolNum();
       })
      },
      immediate: true
    },
    desktopArrange: {
      handler (val) {
       this.updatecolNum(this.globalSetting.desktopLayout, 'desktopArrange')
      }
    },
    desktopIconSize : {
      handler (val) {
       this.updatecolNum()
      }
    },
    'globalSetting.desktopLayout': {
      handler(val) {
        this.desktopLayout = val
      },
      deep: true
    }
  },
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
    iconLock () {
      return this.globalSetting.desktopIcon.iconLock
    },
    iconWidth () {
      return this.globalSetting.desktopIcon.iconWidth
    },
    desktopArrange () {
      return this.globalSetting.desktopArrange
    },
    addActiveId () {
      return this.globalSetting.addActiveId
    },
    iconNameShow () {
      return this.globalSetting.desktopIcon.iconNameShow
    },
    desktopIconSize () {
      return this.globalSetting.desktopIcon.desktopIconSize
    },
    rowHeight () {
     return itemSize.get(this.desktopIconSize)
    }
  },
  methods: {
    itemStyle (item) {
      return {
        width: `${itemSize.get(this.desktopIconSize) * item.w}px!important`,
      }
    },
    updateCols() {
      this.colNum = this.getcolNum();
    },
     updatecolNum(arr, desktopArrange) {
      this.colNum = this.getcolNum();
      this.updateLayout(arr, desktopArrange)
    },
    getcolNum () {
      let colNum = 14
      const desktopIconSize = itemSize.get(this.desktopIconSize)
      const containerWidth = document.getElementById('custom-file-manage').clientWidth
      colNum =  Math.floor((containerWidth) / desktopIconSize)
      return colNum
    },
    getColHeight () {

    },
    updateLayout(arr, desktopArrange) {
      // const layout = _.cloneDeep(this.desktopLayout);
      // const { rows } = getGridSize();
      // const newLayout = insertElementsIntoGrid(layout, rows, this.colNum);
      // this.desktopLayout = newLayout
      // store.commit("globalSetting/setDesktopLayout", newLayout)
      const layout = (arr && Array.isArray(arr)) ? arr : this.globalSetting.desktopLayout
      this.calculateLayoutData(this.globalSetting.desktopArrange, layout, desktopArrange)
    },
    calculateLayoutData(mode, desktopLayout, desktopArrange, isScreenChange, syncSettings) {
      let layout = desktopLayout ? desktopLayout : this.globalSetting.desktopLayout
      if (desktopArrange) {
        if (mode !== 'horizontal') { 
          layout = this.sortEmptySlots(layout)
        } else {
          layout = this.sortEmptySlotsByV(layout)
        } 
      } else {
        if (mode === 'horizontal') { 
          layout = this.sortEmptySlots(layout)
        } else {
          layout = this.sortEmptySlotsByV(layout)
        }
      }
    
      // const arr = []
      let { rows } = getGridSize();

      let sum = 0

      layout.map(item => {
        sum  += item.w * item.h
      })

      // 最终确定实际行数
      let realRows = Math.max(
        // 计算实际需要的行数
        Math.ceil(sum / (this.colNum * 1)), 
        // 传入的tableRows
        rows
      );
      const arr = calculateLayout(layout, this.colNum, realRows, mode) 
      this.desktopLayout = arr
      if (isScreenChange) return store.commit("globalSetting/SET_SETTINGS", {...syncSettings, desktopLayout: arr})
      store.commit("globalSetting/setDesktopLayout", arr)
    },
    layoutUpdatedEvent (newLayout) {
      if (this.isFirst) return this.isFirst = false
      this.$emit('layoutUpdatedEvent', newLayout)
    },
    sortEmptySlotsByV(slots) {
      // 首先，按照 y 和 x 坐标进行排序
        const result = slots.sort((a, b) => {
          // 如果 x 相等，按 y 排序
          if (a.x === b.x) {
            return a.y - b.y;  // y 小的排前面
          }
          // 否则按 x 排序
          return a.x - b.x;  // x 小的排前面
      });
      // 然后，更新每一项的 i 属性为当前索引
      result.forEach((slot, index) => {
          slot.i = index; // 将 i 属性设置为当前索引
      });
      return result; // 返回排序并更新后的数组
    },
    sortEmptySlots(slots) {
      // 首先，按照 y 和 x 坐标进行排序
      const result = slots.sort((a, b) => {
        // 如果 y 相等，按 x 排序
        if (a.y === b.y) {
            return a.x - b.x;  // x 小的排前面
        }
        // 否则按 y 排序
        return a.y - b.y;
      });
      // 然后，更新每一项的 i 属性为当前索引
      result.forEach((slot, index) => {
          slot.i = index; // 将 i 属性设置为当前索引
      });
      return result; // 返回排序并更新后的数组
    },
    // 更新resize
    resize (i) {
      this.$emit('resize', i)
    },
    handleDragStop(layout, oldItem, newItem) {
      const containerHeight = this.$refs.gridLayout.$el.clientHeight;
      const rowHeight = this.rowHeight; // 与设置的 row-height 相匹配

      // 确保拖拽元素不会超出容器高度
      if (newItem.y + newItem.h > Math.floor(containerHeight / rowHeight)) {
        newItem.h = Math.floor(containerHeight / rowHeight) - newItem.y;
      }
    },
    // 判断上次同步的窗口视图是不是和当前呢一致
    observableWindowScreensize (syncSettings) {
      this.colNum = this.getcolNum();
      this.calculateLayoutData(syncSettings.desktopArrange, syncSettings.desktopLayout, false, true, syncSettings)
    },
  },
  mounted() {
    this.$EventBus.$on("deleteUpdate", (arr) => {
      this.updatecolNum(arr);
    });
    this.$EventBus.$on("observeScreenSize", (settings) => {
      this.observableWindowScreensize(settings)
    })
    this.desktopLayout = _.cloneDeep(this.globalSetting.desktopLayout);
    this.debouncedUpdateCols = _.debounce(this.updatecolNum, 100); // 创建防抖函数
    window.addEventListener('resize', this.updatecolNum);
    this.colNum = this.getcolNum();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updatecolNum);
    this.$EventBus.$off("deleteUpdate");
    this.$EventBus.$off("observeScreenSize");
  },
}
</script>
<style lang="less" scoped>
.custom-grid-item {
  display: flex;
  align-items: center;
}
</style>