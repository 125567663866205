<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2025-01-21 14:56:52
 * @FilePath: \awx-ui\src\App.vue
-->
<template>
  <a-config-provider :locale="locale" :get-popup-container="popContainer">
    <router-view/>
  </a-config-provider>
</template>

<script>
import {enquireScreen} from './utils/util'
import {mapState, mapMutations} from 'vuex'
import themeUtil from '@/utils/themeUtil';
import {getI18nKey} from '@/utils/routerUtil'
import store from '@/store/index'

import dynamicComponents from '@/utils/dynamic-components'

export default {
  name: 'App',
  data() {
    return {
      locale: {}
    }
  },
  created () {
    this.syncSettinngs()
    this.setHtmlTitle()
    this.setLanguage(this.lang)
    enquireScreen(isMobile => this.setDevice(isMobile))
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenSize);
    this.setWeekModeTheme(this.weekMode)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val)
    },
    lang(val) {
      this.setLanguage(val)
      this.setHtmlTitle()
    },
    $route() {
      this.setHtmlTitle()
    },
    'theme.mode': function(val) {
      let closeMessage = this.$message.loading(`您选择了主题模式 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage)
    },
    'theme.color': function(val) {
      let closeMessage = this.$message.loading(`您选择了主题色 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage)
    },
    'layout': function() {
      window.dispatchEvent(new Event('resize'))
    }
  },
  computed: {
    ...mapState('setting', ['layout', 'theme', 'weekMode', 'lang'])
  },
  methods: {
    
    ...mapMutations('setting', ['setDevice']),
    ...mapMutations('globalSetting', ['setDesktopLayout']),
    // 首次进入同步登录远端设置
    syncSettinngs() {
    // 从 localStorage 恢复 settings 数据
      const savedSettings = localStorage.getItem('globalSetting');
      // 如果这个时候是登录状态的时候操作的远程的和本地的数据是一致的直接取远程
      // 如果是未登录的时候还是以本地的为准
      if (savedSettings) {
        const USER_STROAGE = localStorage.getItem(process.env.VUE_APP_USER_KEY)
        let user = USER_STROAGE ? JSON.parse(USER_STROAGE) : {};
        if (!(user && user.id)) {
          store.commit('globalSetting/SET_SETTINGS', JSON.parse(savedSettings));
          store.dispatch('windowStore/setTaskbarEntriesStore')
        } else {
          store.dispatch('globalSetting/firstSync');
        }
      }
    },
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add('week-mode')
      } else {
        document.body.classList.remove('week-mode')
      }
    },
    updateScreenSize () {
      const width = window.innerWidth
      const height = window.innerHeight
      store.commit("globalSetting/setScreenSize", {width, height})
    },
    setLayout () {
      const savedSettings = JSON.parse(localStorage.getItem('globalSetting'));
      if (savedSettings && savedSettings.desktopLayout && savedSettings.desktopLayout.length === 0) {
        savedSettings.deesktopLayout = [
          {
            "x": 0, "y": 0, "w": 1, "h": 1, "i": "0",
            data: {
              url: require("@/assets/img/desktop/desktop-task.svg"),
              name: "awx",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isMenu: true,
                isFileGroup: true,
                FileGroupName: 'dashboard',
                FileComponentPath: '@/pages/overview/index2'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'awx',
            }
          },

          {
            "x": 1, "y": 0, "w": 1, "h": 1, "i": "0",
            data: {
              url: require("@/assets/img/desktop/desktop-task.svg"),
              name: "任务管理",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isFileGroup: true,
                FileGroupName: 'task-manage',
                FileComponentPath: '@/pages/taskManage/index'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'task-manage',
            }
          },
          {
            "x": 2, "y": 0, "w": 1, "h": 1, "i": "1",
            data: {
              url: require("@/assets/img/desktop/desktop-app.svg"),
              name: "应用管理",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isFileGroup: true,
                FileGroupName: 'app-manage',
                FileComponentPath: '@/pages/appManage/index'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'app-manage',
            }
          },
          {
            "x": 3, "y": 0, "w": 1, "h": 1, "i": "2",
            data: {
              url: require("@/assets/img/desktop/desktop-dashboard.svg"),
              name: "仪表盘",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isFileGroup: true,
                FileGroupName: 'dashboard',
                FileComponentPath: '@/pages/overview/index2'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'dashboard',
            }
          },
          {
            "x": 4, "y": 0, "w": 1, "h": 1, "i": "3",
            data: {
              url: require("@/assets/img/desktop/desktop-resource.svg"),
              name: "资源管理",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isFileGroup: true,
                FileGroupName: 'resource',
                FileComponentPath: '@/pages/groupManage/index'
              }, 
              componentName: 'MyDynamicComponent',
              id: 'resource',
            }
          },
          {
            "x": 0, "y": 0, "w": 1, "h": 1, "i": "4",
            data: {
              url: require("@/assets/img/desktop/desktop-system.svg"),
              name: "系统管理",
              fileCompontentType: "customPageComponent",
              customPageComponent: {
                isFileGroup: true,
                FileGroupName: 'system-manage',
                FileComponentPath: '@/pages/securityCenter/user'
              }, 
              fileRenderType: "system-manage",
              componentName: 'MyDynamicComponent',
              id: 'system-manage'
            }
          },
        ]
        this.setDesktopLayout(savedSettings.deesktopLayout)
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang
      switch (lang) {
      case 'CN':
        this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
        break
      case 'HK':
        this.locale = require('ant-design-vue/es/locale-provider/zh_TW').default
        break
      case 'US':
      default:
        this.locale = require('ant-design-vue/es/locale-provider/en_US').default
        break
      }
    },
    setHtmlTitle() {
      const route = this.$route
      const key = route.path === '/' ? 'home.name' : getI18nKey(route.matched[route.matched.length - 1].path)
      document.title = this.$t(key)
    },
    popContainer() {
      return document.getElementById("popContainer")
    }
  }
}
</script>

<style lang="less" scoped>
  #id{
  }
</style>
