<!--
 * @Description: 
 * @Date: 2024-06-20 16:31:42
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-14 16:24:01
-->
<template>
  <div :class="['taskbar-setting', displayPosition]">
    <a-divider :type="displayPosition === 'bottom' ? 'vertical' : 'horizontal'" :style="dividerStyle" />
    <div 
      class="taskbar-setting-icon"
      @click="openAppShop"
    >
      <svg-icon
        style="font-size: 18px"
        icon-class="task-add"
      ></svg-icon>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import ApplyQuotaVue from "@/components/quota/index.vue";

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    AppStore: () => import('@/pages/appStore/index.vue')
  },
  props: {
    displayPosition: String
  },
  data () {
    // 这里存放数据
    return {
      cluster_id: undefined,
      langList: [
        { key: "CN", name: "简体中文", alias: "简体" },
        { key: "US", name: "English", alias: "English" },
      ],
      showSetting: false,
      searchActive: false,
      masterOptions: []
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState({
      runHistory: (state) => state.runHistory, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      drawer: (state) => state.drawer, // 用于文件管理里面的抽屉
      account: (state) => state.account, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      fileManage: (state) => state.fileManage, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      windowStore: (state) => state.windowStore, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    ...mapGetters("setting", ["isCluster", "runningCluster", "clusterId"]),
    ...mapState("setting", [
      "theme",
      "isMobile",
      "layout",
      "systemName",
      "lang",
      "pageWidth",
      "masterOptionsVuex"
    ]),
    dividerStyle () {
      let style = {
        background: 'rgba(0,0,0,0.50)',
        margin: '0 0 12px'
      }
      if (this.displayPosition === 'bottom') {
        style.margin = '5px 16px 5px 0'
        style['height'] = '24px'
      }
      return style
    },
    headerTheme () {
      if (
        this.layout == "side" &&
        this.theme.mode == "dark" &&
        !this.isMobile
      ) {
        return "light";
      }
      return this.theme.mode;
    },
    langAlias () {
      let lang = this.langList.find((item) => item.key == this.lang);
      return lang.alias;
    },
    menuWidth () {
      const { layout, searchActive } = this;
      const headWidth = "100%";
      return `calc(${headWidth} - 430px)`;
    },
    isLoginFlag () {
      const isLoginFlag  = this.account?.user?.username
      return isLoginFlag
    },
    currentCluster () {
      let arr =
        this.runningCluster.filter(
          (item) => item.value === Number(this.clusterId)
        ) || [];
      return {
        name: arr.length > 0 ? arr[0].label : "",
        clusterId: this.clusterId,
      };
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    if (this.isLoginFlag) this.getMaster()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  // 方法集合
  methods: {
    ...mapActions("runHistory", ["getHistoryList", "changeShowHistory"]),
    ...mapActions("setting", ["setMasterOptions"]),
    ...mapMutations("account", ["setAccountSetting"]),
    ...mapActions("masterMd", ["setHasMaster"]),
    ...mapActions("fileManage", [
      "changeRealEditList",
      "changeShowItem",
      "setClusterId",
    ]),
    toggleCollapse () {
      this.$emit("toggleCollapse");
    },
    // 初始化 Master 获取集群列表 获取选中的集群
    getMaster () {
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results.length > 0) {
            const onlineCluster = res.data.results.filter(item => item.status === 'online')
            let DefaultMaster = {}
            if (onlineCluster.length > 0) {
              this.setHasMaster(true)
              const arr = onlineCluster.filter(item => item.is_default)
              if (arr.length > 0) {
                DefaultMaster = arr[0]
              } else {
                DefaultMaster = onlineCluster[0]
              }
            } else {
              this.setHasMaster(false)
            }
            localStorage.setItem('masterOptions', JSON.stringify(res.data.results))
            localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr || DefaultMaster?.private_addr || '')
            window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr || DefaultMaster.private_addr || ''
            this.setMasterOptions(res.data.results)
          } else {
            localStorage.setItem('FILE_SYSTEM_URL', '')
            window.config.FILE_SYSTEM_URL = ''
          }
        }
      });
    },
    // 设置的时候打开申请配额
    openApplyModal () {
      const self = this;
      let width = 902;
      let title = '申请配额'
      let content = (
        <ApplyQuotaVue quotaType={'apply'} callBack={() => {
          self.getProjectList()
        }} />
      );
      this.$confirm({
        width: width,
        title: title,
        class: 'quota-apply-modal',
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    toSet (e) {
      if (e) e.stopPropagation();
      this.setAccountSetting(false)
      this.changeShowHistory(false)
      this.openApplyModal()

    },
    onSelect (obj) {
      this.$emit("menuSelect", obj);
    },
    windowBlur () {
      for (var i in this.windowStore.windowStore) {
        this.windowStore.windowStore[i].window.focused = true
        this.windowStore.windowStore[i].window.blur()
      }
    },
    openWindow () {
      this.$EventBus.$emit("openAppStore", 'fixedBar');
      // let appLoaderOptions = {
      //   url: require("@/assets/img/desktop/desktop-menu.png"),
      //   name: "应用商店",
      //   fileCompontentType: "customPageComponent",
      //   customPageComponent: {
      //     isFileGroup: false,
      //     FileGroupName: 'appStore',
      //     FileComponentPath: '@/pages/appStore/index.vue'
      //   }, 
      //   componentName: 'AppStore',
      //   id: 'appStore',
      // }
      // const propData = {
      // }
      // this.$openWindow('appStore', this.$options.components, {propsData: propData}, {}, {...appLoaderOptions});
    },
    openAppShop (e) {
      e.preventDefault();
      // 强行让所有的窗口失焦 保证只有一个窗口处在聚焦状态
      this.windowBlur()
      const windowId = 'appStore'
      const windowStore  =  _.cloneDeep(this.windowStore.windowStore)
      const currentWinbox =  _.cloneDeep(this.windowStore.currentFocusWindow)
      if (currentWinbox[windowId] && currentWinbox[windowId].id === windowId) {
        return windowStore[windowId].window.minimize()
      }
      if (!windowStore['appStore']) return this.openWindow()
      const isMinimized = windowStore['appStore'].window.min;
      if (isMinimized) {
        windowStore['appStore'].window.restore().focus()
      } else {
        windowStore['appStore'].window.focus()
      }
    },
    ...mapMutations("setting", ["setLang"]),
    openDrawer (e) {
      if (e) e.stopPropagation();
      const type = this.runHistory.runtab
      this.getHistoryList({ type: type, init: false })
      this.changeShowHistory(true)
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.taskbar-setting {
  text-align: center;
  width: 100%;
  &-icon {
    cursor: pointer;
    background: rgba(0,0,0,0.25);
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    margin: 0px auto 0;
    justify-content: center;
    align-items: center;
     svg {
      color: #fff;
     }
     &:hover {
       background: rgba(0,0,0,0.15);
     }
  }
  padding: 0 8px;
}
.bottom {
  display: flex;
  width: initial;
  .taskbar-setting-icon {
    margin-top: 0;
    margin-right: 4px;
    cursor: pointer;
     svg {
      color: #fff;
     }
  }
}
</style>
