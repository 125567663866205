<template>
  <div class="common-tables h-full">
    <a-tabs type="card" v-model="selectKey" class="common-tabs h-full">
      <a-tab-pane :tab="getTab(item,index)" v-for="(item,index) in panesList" :key="item.key">
        <slot name="rightContent"></slot>
      </a-tab-pane>
      <a-popover placement="bottomRight" overlayClassName="tabs-container" slot="tabBarExtraContent" style="z-index:10000;">
        <template slot="content">
          <!-- <a-input placeholder="请输入流程名称" v-model="searchVal" class="tab-search">
              <a-icon slot="prefix" style="color: rgba(102,102,102,0.65);" type="search" />
          </a-input>-->
          <div style="height:1px;width:100%;background:#EAEDF1;margin-bottom:5px" />
          <div style="max-height:500px; overflow-y:auto; overflow-x:hidden">
            <a-row v-for="(item,index) in newPanesList" :key="item.key" type="flex" align="middle" style="padding:0 15px;width:167px;" class="tab-content">
              <a-col :span="4" style="display:flex;justify-content: center;align-items:center;">
                <svg-icon :style="{color:selectItem ==index ?'#0264C8':'#2872E0'}" style="font-size:14px;" :icon-class="item.svgIcon" />
                <div style="position: absolute;font-size:16px;top:-7px;right:3px" :style="{color:item.edit?'red':'#F2F4F7'}">*</div>
              </a-col>
              <a-col :span="18" @click="changeTable(item,index)" style="text-align: left;cursor: pointer;text-overflow:ellipsis; overflow: hidden;
                  white-space: nowrap;color:#000000A6">
                <span v-if="item.name.indexOf(searchVal)  > -1" :title="item.name">
                  {{item.name.substr(0, item.name.indexOf(searchVal))}}
                  <span style="color: #f50">{{searchVal}}</span>
                  {{item.name.substr(item.name.indexOf(searchVal) + searchVal.length)}}
                </span>
                <a v-else :title="item.name">{{item.name}}</a>
              </a-col>
              <a-col v-if="item.key !== 'groupAll'" :span="2">
                <a-button type="link" class="tab-content-del" style="border-width:0px;font-size:12px;background:#fff" icon="close" @click.stop="delTable(item,index)"></a-button>
              </a-col>
              <!-- <a-divider style="margin: 0px" /> -->
            </a-row>
          </div>
          <a-row type="flex" align="middle" justify="center" style="height:50px">
            <a-button style="padding-top:10px;border-width:1px 0px 0px 0px;border-color:#EAEDF1;color: rgba(102,102,102,0.65);font-size:12px;width:100%" @click="delAllTable()">关闭所有</a-button>
          </a-row>
        </template>
        <div v-if="panesList.length > 9" class="tab-right-layout">
          <a-button :icon="'double-right'" class="right-button-layout" />
        </div>
      </a-popover>
    </a-tabs>
  </div>
</template>
  <script>
const cloneDeep = (obj) => {
  var objString = JSON.stringify(obj);
  return JSON.parse(objString);
};

export default {
  name: "Tables",
  components: {},
  props: {
    value: Array,
    tabsLength: {
      type: Number,
      default: 160,
    },
    defaultSelectedKeys: Object,
    showIcon: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    // 判断是从数据集成还是数据开发还是实时计算过来的
    tabsType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      collapsed: false,
      searchVal: "",
      panesList: cloneDeep(this.value || []),
      newPanesList: cloneDeep(this.value || []),
      selectItem: this.defaultSelectedKeys.index || 0,
      selectKey: this.defaultSelectedKeys.key,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  watch: {
    searchVal(val) {
      this.changePanes();
    },
    value: {
      handler: function (val, old) {
        // 数值改变时 回调函数执行
        if (val) {
          this.panesList = cloneDeep(val);
          this.changePanes();
        }
      },
      deep: true,
    },
    defaultSelectedKeys(val) {
      this.selectItem = Number(val.index);
      this.selectKey = val?.key;
    },
  },
  methods: {
    show(event, index) {
      event.preventDefault();
      this.$contextmenu({
        items: [
          {
            label: "关闭其他",
            onClick: () => {
              this.delOption(index, "other");
            },
          },
          {
            label: "关闭左边",
            onClick: () => {
              this.delOption(index, "left");
            },
          },
          {
            label: "关闭右边",
            onClick: () => {
              this.delOption(index, "right");
            },
          },
          {
            label: "关闭所有",
            onClick: () => {
              this.delAllTable();
            },
          },
        ],
        event,
        customClass: "custom-contextmenu",
        zIndex: 3000,
        minWidth: 160,
      });
      return false;
    },
    changePanes() {
      let arr = [];
      this.panesList.map((item, index) => {
        if (item.name.indexOf(this.searchVal) > -1) {
          arr.push(item);
        }
      });
      this.newPanesList = arr;
    },
    getTab(item, index) {
      return (
        <div
          onClick={() => this.changeTable(item, index)}
          class="tab-layout"
          style={{
            "width": this.tabsLength + 'px'
          }}
          onContextmenu={(e) => this.show(e, index)}
        >
          <div
            class="tab-container"
            style={{
              background: this.selectKey === item.key ? "#fff!important" : "#F7F8FA",
              "border-top-left-radius": this.selectKey === item.key ? "6px!important" : "6px",
              "border-top-right-radius": this.selectKey === item.key ? "6px!important" : "6px",
              "border-top":
                this.selectKey === item.key
                  ? "2px solid #2281E1"
                  : "2px solid #F7F8FA",
              "border-widht":
                this.selectKey === item.key ? "2px 0 0 0" : "0px 0 0px 0",
            }}
          >
            <div style="display:flex;align-items:center;width:calc(100% - 20px)">
              <div class="tab-title-layout">
                <svg-icon
                  icon-class={item.svgIcon}
                  style={{
                    color: this.selectKey === item.key ? "#2872E0" : "#2872E0",
                    margin: "0 10px",
                    "font-size": "14px",
                  }}
                />
                {item.edit && (
                  <span
                    class="table-edit"
                    style={{ color: "red", fontSize: "16px" }}
                  >
                    *
                  </span>
                )}
                {this.isEll(`workName${index}`, item.name) ? (
                  <span id={`workName${index}`} title={item.name}>
                    {item.name}
                  </span>
                ) : (
                  <span id={`workName${index}`} title={item.name}>
                    {item.name}
                  </span>
                )}
              </div>
            </div>
            <div class="hover-hide">
              {this.showIcon && item.key !== "groupAll" && (
                <a-button
                  onClick={(e) => this.delTable(item, index, e)}
                  icon="close"
                  class="btn-close"
                ></a-button>
              )}
            </div>
            {(
              <div
                style={{
                  height: "18px",
                  width: "1px",
                  background: "#DEE1E5",
                  position: "relative",
                  left: "8px",
                  "z-index": "1",
                }}
              />
            )}
          </div>
        </div>
      );
    },
    isEll(id, name) {
      let width = document.getElementById(id);
      if (!width) return false;
      if (width.offsetWidth > 100) {
        width.setAttribute("title", name);
        return true;
      } else {
        return false;
      }
    },
    delTable(item, index, e) {
      if (e) e.stopPropagation();
      this.$emit("clickDelIcon", { item, index, e });
    },
    delAllTable() {
      this.$emit("delAll");
    },
    changeTable(item, index) {
      // this.$emit('hideDrawer')
      this.selectItem = index;
      this.selectKey = item.key;
      this.$emit("changeTable", { item, index });
    },
    delOption(index, type) {
      this.$emit("delOption", { index, type });
    },
    tableClass(item, index) {
      let classArr = [];
      classArr.push(index == this.selectItem ? "table-click" : "");
      return classArr.join(" ");
    },
  },
  filters: {},
};
</script>
  <style lang="less">
.tabs-container.ant-popover-placement-bottomRight {
  top: 76px !important;
}
.ant-popover-inner-content {
  padding: 0;
}
.tab-search {
  width: 168px;
  margin: 1px 0;
  .ant-input {
    border-width: 0px;
  }
}
.tab-content {
  .tab-content-del {
    color: rgba(0, 0, 0, 0);
  }
}
.tab-content:hover {
  background: #f4f5f7;
  color: rgba(0, 0, 0, 0.85);
  .tab-content-del {
    color: #000000a6;
    background: #f4f5f7 !important;
  }
}
.common-tables {
  background: #fff;
  .common-tabs {
    padding: 4px 0px 0 0px;
    background: #fff;
  }
  .tab-right-layout {
    width: 50px;
    height: 32px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .right-button-layout {
      border-width: 0px;
      background: #e6e8ed;
      width: 23px;
      height: 23px;
      font-size: 10px;
      transform: rotate(90deg);
    }
  }
  .ant-tabs-bar {
    margin: 0;
    height: 32px;
    background: #f7f8fa;
  }
  .ant-tabs-nav-container-scrolling {
    padding: 0px;
  }
  .ant-tabs-tab-prev {
    display: none;
  }
  .ant-tabs-tab-next {
    display: none;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-extra-content {
    line-height: 32px;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin-right: 0px;
    padding: 0;
    height: 30px;
    line-height: 30px;
    border-width: 0px;
    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .tab-layout {
    width: 160px;
    height: 30px;
    background-color: #f7f8fa;
    .tab-container {
      position: relative;
      display: flex;
      height: 30px;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 0px 4px 0px;
      margin: 0 7px;
      &:hover {
        background: #e8e8e8!important;
        border-radius: 14px!important;
      }
    }
    .tab-title-layout {
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .run-state {
        position: absolute;
        left: 20px;
        top: 12px;
      }
      .table-edit {
        position: absolute;
        top: -5px;
        left: 23px;
      }
      span {
        position: relative;
        top: 1px;
      }
    }
    .hover-hide .table-edit {
      position: absolute;
      top: 2px;
      right: 0px;
      background: #fff;
    }
    .hover-hide {
      .btn-close {
        background: rgba(0, 0, 0, 0);
        border-width: 0px;
        font-size: 10px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        line-height: 18px;
        i {
          margin-right: 0;
          line-height: 18px;
          position: relative;
          top: -1px;
        }
      }
      &:hover {
        .btn-close {
          color: rgba(0, 0, 0, 0.65);
          background: rgb(216, 216, 218);
        }
        .table-edit-hover {
          display: none;
        }
        .del-icon {
          display: block;
        }
      }
    }
  }
  .ant-tabs-tab .tab-layout .tab-container::before,
  .ant-tabs-tab .tab-layout .tab-container::after {
    position: absolute;
    top: 2px;
    content: "";
    width: 0px;
    height: 28px;
    background: #f7f8fa;
    border-bottom: 1px solid #dee1e5;
    background: #fff;
  }
  .ant-tabs-tab-active {
    height: 32px !important;
    color: #222 !important;
    span {
      color: #222;
    }
  }
  .ant-tabs-tab-active .tab-layout .tab-container::before,
  .ant-tabs-tab-active .tab-layout .tab-container::after {
    position: absolute;
    top: 2px;
    content: "";
    width: 10px;
    height: 28px;
    background: #f7f8fa;
    border-bottom: 1px solid #dee1e5;
    z-index: 2;
  }
  .ant-tabs-tab-active .tab-layout .tab-container::before {
    border-right: 1px solid #dee1e5;
    border-bottom-right-radius: 10px;
    left: -10px;
  }
  .ant-tabs-tab-active .tab-layout .tab-container::after {
    border-left: 1px solid #dee1e5;
    right: -10px;
    border-bottom-left-radius: 10px;
  }
}
</style>
  