<!--
 * @Description: 
 * @Date: 2024-06-21 14:14:04
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-11-15 11:38:40
-->
<template>
    <!-- :style="fileEntryStyle" -->
  <div
    class='file-entry'
    @click="addIcontoDesktop($event)"
    @contextmenu="() => {}">
    <div class="file-entry-img" :style='borderRadiusStyle'>
      <div :style="imageStyle" class="flex items-center justify-center">
        <a-icon type="plus" :style="netIconFontStyle" />
      </div>
    </div>
    <div
      v-show="iconNameShow"
      class="name ell"
    >{{ fileEntry.name }}</div>
  </div>
</template>


<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import store from '@/store/index'
import textHide from 'postcss-utilities/lib/text-hide';
import { mapState, mapMutations, mapActions } from "vuex";
import AddAppIndex from '@/pages/appStore/netWork/addApp/appModal/appModalIndex.vue'

import {
  mapSizes,
  itemSize,
  mapNetFontSizes,
  mapColorFontSizes,
  mapRadius
} from '@/utils/desktopIcon-map'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MyDynamicComponent: () => import('@/components/winbox/RenderComponent'),
    ExternalComponent: () => import('@/components/browser/index.vue')
  },
  props: {
    fileEntry: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    // 这里存放数据
    return {
      isDragging: false,
      startX: 0,
      startY: 0,
    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return { ...store.state.globalSetting }
    },
    iconNameShow () {
      return this.globalSetting.desktopIcon.iconNameShow
    },
    desktopIconSize () {
      return this.globalSetting.desktopIcon.desktopIconSize
    },
    fileEntryStyle () {
      return {
        width: (mapSizes.get(this.desktopIconSize) + 20) + 'px',
        height: (mapSizes.get(this.desktopIconSize) + 32) + 'px',
      }
    },
    netIconFontStyle () {
      return {
        'font-size': 34 + 'px',
        'color': '#fff9'
      }
    },
    borderRadiusStyle () {
       return {
        'border-radius': mapRadius.get(this.desktopIconSize) + 'px',
      }
    },
    imageStyle () {
      return {
        width: mapSizes.get(this.desktopIconSize) + 'px',
        height: mapSizes.get(this.desktopIconSize) + 'px',
        'border-radius': mapRadius.get(this.desktopIconSize) + 'px',
      }
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    ...mapMutations('globalSetting', ['setDesktopLayout']),

    IsColorIcon (icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name) {
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon (icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name) {
      return global.utils.iconNetWorkName(name);
    },
    addIcontoDesktop () {
      this.$EventBus.$emit("openAppStore");
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.file-entry {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  &:hover {
    background: rgba(218, 218, 218, 0.4);
    border-radius: 4px;
  }
  &-img {
    border: 1px solid #fff9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background: #d1d1d6e6;
    backdrop-filter: blur(100px);
  }
  .name {
    line-height: 18px;
    margin-top: 4px;
    font-family: PingFangSC-Medium;
    font-size: 13px;
    color: #ffffff;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    font-weight: 500;
    display: block;
    min-width: 58px;
    max-width: 92px;
    text-align: center;
  }
}
</style>
