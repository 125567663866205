<template>
  <a-layout :class="['admin-layout', 'beauty-scroll', globalSetting.theme.mode]">
    <a-layout class="admin-layout-main beauty-scroll">
      <a-layout-content class="admin-layout-content" :style="`min-height: ${minHeight}px;`">
        <div style="position: relative" @click="hideSet">
          <slot></slot>
        </div>
      </a-layout-content>
      <drawer v-if="!hideSetting" v-model="showSetting" placement="right">
        <div class="setting" slot="handler">
          <a-icon :type="showSetting ? 'close' : 'setting'"/>
        </div>
        <setting />
      </drawer>
      <runTaskHistory v-if="runHistory.showHistory" />
      <FileProgress v-if="fileManage.showFileProgress" />
      <div class="cluster-select" v-if="false">
        <div class="flex-bewteen-container cluster" @click.stop="setShowCluster">
          <div>{{clusterame}}</div>
          <a-icon type="swap" />
        </div>
        <div class="cluster-list" v-if="showCluster"> 
            <div
              :class="['cluster-item', fileManage.clusterId === item.id ? 'cluster-item-selected' : '', item.status !== 'online' && 'disabled']"
              v-for="(item) in masterOptions"
              :key="item.id"
              @click="changeClusterId(item)"
              :value="item.id">
              <span class="" :title="item.name">
                <svg-icon
                  class="mgr8"
                  :icon-class="item.status === 'online' ? 'task-successful' : 'task-init'"
                  :style="{color: item.status === 'online' ? '#5CB863' : '#ED4F50'}"
                ></svg-icon>
                <span class="mgr12">{{ item.name }}</span>
              </span>
            </div>
        </div>
      </div>
      <a-modal
        title=""
        class="login-modal"
        width="418px"
        :visible="account.showLogin"
        :footer="null"
        :mask="true"
      >
        <Login :isModal="true" />
      </a-modal>
    </a-layout>
  </a-layout>
</template>

<script>
import Drawer from '@/components/tool/Drawer'
import Setting from '@/components/setting/Setting'
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex'
import {getI18nKey} from '@/utils/routerUtil'
import runTaskHistory from "@/components/runTaskHistory";
import FileProgress from "@/components/fileProgress";
import accountSetting from "@/layouts/header/accountSetting.vue";
import Login from '@/pages/login/Login.vue'
export default {
  name: 'DesktopLayout',
  components: {Setting, Drawer, accountSetting, runTaskHistory, FileProgress, Login},
  data () {
    return {
      minHeight: window.innerHeight - 64 - 122,
      collapsed: true,
      showSetting: false,
      drawerOpen: false,
      menuDataOptions: [],
      masterOptions: [],
      showCluster: false,
      // childMenu: []
    }
  },
  provide() {
    return {
      adminLayout: this
    }
  },
  watch: {
    $route(val) {
      this.setActivated(val)
    },
    layout() {
      this.setActivated(this.$route)
    },
    'runHistory.showHistory': {
      handler(val) {
        console.log(val, 'showHistoryshowHistoryshowHistory')
      }
    },
    isMobile(val) {
      if(!val) {
        this.drawerOpen = false
      }
    },
    masterOptionsVuex: {
      handler (val) {
        this.masterOptions = val
        this.initClusterid()
      },
      deep: true,
      // immediate: true
    },
    // menuData
    menuData: {
      handler(val) {
        this.menuDataOptions = this.menuData
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState('setting', ['isMobile', 'theme', 'layout', 'footerLinks', 'copyright', 'fixedHeader', 'fixedSideBar',
      'masterOptionsVuex', 'fixedTabs', 'hideSetting', 'multiPage']),
    ...mapGetters('setting', ['firstMenu', 'subMenu', 'menuData']),
    ...mapState({
      account: (state) => state.account, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      drawer: (state) => state.drawer,
      fileManage: (state) => state.fileManage, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      runHistory: (state) => state.runHistory,      
      globalSetting: (state) => state.globalSetting,
    }),
    sideMenuWidth() {
      return this.collapsed ? '64px' : '64px'
    },
    headerStyle() {
      let width = (this.fixedHeader && this.layout !== 'head' && !this.isMobile) ? `calc(100% - ${this.sideMenuWidth})` : '100%'
      let position = this.fixedHeader ? 'fixed' : 'static'
      return `width: ${width}; position: ${position};`
    },
    headMenuData() {
      const {layout, menuDataOptions, firstMenu} = this
      return layout === 'mix' ? firstMenu : menuDataOptions
    },
    clusterame () {
      const obj = this.masterOptions.find (item => item.id === this.fileManage.clusterId)
      return obj ? obj.name : ''
    },
    sideMenuData() {
      const {layout, menuDataOptions, subMenu} = this
      return layout === 'mix' ? subMenu : menuDataOptions
    },
    breadcrumb() {
      let page = this.page
      let breadcrumb = page && page.breadcrumb
      if (breadcrumb) {
        let i18nBreadcrumb = []
        breadcrumb.forEach(item => {
          i18nBreadcrumb.push(this.$t(item))
        })
        return i18nBreadcrumb
      } else {
        return this.getRouteBreadcrumb()
      }
    },
    childMenu() {
      const currentMenu  = this.menuData.filter(item => this.$route.fullPath.includes(item.fullPath))
      return currentMenu[0].children &&  currentMenu[0].children.length ?  currentMenu[0].children : [...currentMenu]
    },
    isLoginFlag () {
      const isLoginFlag  = this.account?.user?.username
      return isLoginFlag
    }
  },
  methods: {
    ...mapMutations("account", ["setAccountSetting", "setUser"]),
    ...mapMutations('setting', ['correctPageMinHeight', 'setActivatedFirst']),
    ...mapActions('schedule', ['setScheduleDict']),
    ...mapActions("runHistory", ["getHistoryList", "changeShowHistory"]),
    ...mapActions("fileManage", [
      "setClusterId",
    ]),
    changeClusterId (obj) {
      if (obj.status !== 'online') return false
      if (obj.id === this.fileManage.clusterId) return false
      const val = obj.id
      const onlineCluster = this.masterOptions.filter(item => item.id === val)
      let DefaultMaster = {}
      if (onlineCluster.length > 0) {
        DefaultMaster = onlineCluster[0]
        const cluster_id = DefaultMaster?.id || undefined
        this.setClusterId(cluster_id)
      }
      localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr || DefaultMaster?.private_addr || '')
      window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr || DefaultMaster.private_addr || ''
      this.showCluster = false
    },
    // 设置默认的master
    setDefaultCluster (row) {
      const _this = this;
      const params = {
        is_default: true,
        name: row.name,
        description: row.description,
      };
      params.id = row.id;
      this.$axiosPatch(global.API.getMasterInfo+'/'+ row.id, params)
    },
    setShowCluster () {
      // 开启的时候去拉取一次master列表
      if (!this.showCluster) {
        this.getMasterList()
      }
      this.showCluster = !this.showCluster
    },
    setMasterOptions (data) {
      this.masterOptions = data
      this.initClusterid()
    },
    SyncUser () {
      const user = localStorage.getItem(process.env.VUE_APP_USER_KEY)
      const userInfo = JSON.parse(user)
      this.setUser({...userInfo });
    },
    getMasterList() {
      const params = {
        page_size: 10000,
        page: 1,
      };
      this.$axiosGet(global.API.getMasterInfo, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.setMasterOptions(res.data.results)
        }
      });
    },
    initClusterid () {
      if (!this.masterOptions) {
        this.setClusterId(undefined)
      }
      const onLlineCluster = this.masterOptions.filter(item => item.status === 'online')
      if (!onLlineCluster.length) this.setClusterId(undefined)

      const index = onLlineCluster.findIndex(item => item.id === localStorage.getItem("CLUSTER_ID"))
      if (index > -1) return this.setClusterId(localStorage.getItem("CLUSTER_ID"))
      // 如果之前没有设置clusterId就获取默认的值
      if (onLlineCluster.length > 0) {
        const DefaultMaster = onLlineCluster.find(item => (item.is_default))
        const cluster_id = DefaultMaster?.id || onLlineCluster[0]?.id || undefined
        this.setClusterId(cluster_id)
      }

    },
    toggleCollapse () {
      this.collapsed = !this.collapsed
    },
    onMenuSelect () {
      this.toggleCollapse()
    },
    hideSet () {
      this.showCluster = false
      if (this.account.accountSetting)  this.setAccountSetting(false)
      this.changeShowHistory(false)
    },
    toPath (menu) {
      this.$router.push({
        path: menu.fullPath,
      });
    },
    getRouteBreadcrumb() {
      const currentMenu  = this.menuData.filter(item => this.$route.fullPath.includes(item.fullPath))
      this.childMenu = currentMenu[0].children
      const currentObj = {
        menuName: currentMenu[0].name,
        icon: this.$route.meta.icon 
      }
      return currentObj
    },
    setActivated(route) {
      if (this.layout === 'mix') {
        let matched = route.matched
        matched = matched.slice(0, matched.length - 1)
        const {firstMenu} = this
        for (let menu of firstMenu) {
          if (matched.findIndex(item => item.path === menu.fullPath) !== -1) {
            this.setActivatedFirst(menu.fullPath)
            break
          }
        }
      }
    },
    // 获取调度图标字典
    getScheduleDict () {
      if (!this.isLoginFlag) return false
      const params = {
        keyword: "",
        application_id: 'schedule'
      };
      this.$axiosGet(global.API.getStepsTemplate, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          const arr = res.data?.results || []
          this.setScheduleDict(arr)
        }
      });
    }
  },
  created() {
    this.correctPageMinHeight(this.minHeight - 24)
    this.setActivated(this.$route)
    this.getScheduleDict()
    this.SyncUser()
  },
  beforeDestroy() {
    this.correctPageMinHeight(-this.minHeight + 24)
  }
}
</script>

<style lang="less" scoped>
  .admin-layout{
    background: linear-gradient(to bottom right, #38496A, #25314D);
    .side-menu{
      &.fixed-side{
        position: fixed;
        height: calc(100vh - 48px);
        left: 0;
        top: 48px;
        background: rgb(35, 43, 58);
      }
    }
    .virtual-side{
      transition: all 0.2s;
    }
    .virtual-header{
      transition: all 0.2s;
      opacity: 0;
      &.fixed-tabs.multi-page:not(.fixed-header){
        height: 0;
      }
    }
    .admin-layout-main{
      background: transparent;
      .admin-header{
        top: 0;
        right: 0;
        overflow: hidden;
        transition: all 0.2s;
        &.fixed-tabs.multi-page:not(.fixed-header){
          height: 0;
        }
      }
    }
    .admin-layout-content{
      overflow-y: auto;
      max-height: calc(100vh);
      height: calc(100vh);
      background: #fff;
      margin: 0;
      padding: 0 0px 0 0;
    }
    .setting{
      background-color: @primary-color;
      color: @base-bg-color;
      border-radius: 5px 0 0 5px;
      line-height: 40px;
      font-size: 22px;
      width: 40px;
      height: 40px;
      box-shadow: -2px 0 8px @shadow-color;
    }
    // 面包屑
    .breadcrumb {
      margin-top: -16px;
      margin-bottom: 4px;
      /deep/ .ant-breadcrumb-link {
        font-size: 12px;
      }
    }
    .current-route {
      font-size: 16px;
      color: #1D202D;
      letter-spacing: 0;
      font-weight: 600;
      padding:9px 0 9px;
      display: flex;
      align-items: center;
      .child-menu {
        .route-tab {
          cursor: pointer;
          width: 100px;
          height: 30px;
          background: #FFFFFF;
          font-size: 14px;
          color: #4D5A77;
          letter-spacing: 0;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
          &:hover {
            background: #639aff;
            color: #fff;
            // font-weight: 600;
            border: 1px solid #639aff;
          }
        }
        .one-route-tab {
          border-radius: 4px;
          border: 1px solid rgba(207,213,222,1);
        }
        .tow-one-route-tab {
          border-radius: 4px 0px 0px 4px;
          border: 1px solid rgba(207,213,222,1);
          border-right: none;
        }
        .two-two-route-tab {
          border-radius: 0px 4px 4px 0px;
          border: 1px solid rgba(207,213,222,1);
          border-left: none;
        }
        &-selected {
          .route-tab {
            background: #3974F4;
            color: #fff;
            // font-weight: 600;
            border: 1px solid #3974F4;
            &:hover {
              background: #3974F4;
              color: #fff;
              // font-weight: 600;
              border: 1px solid #3974F4;
            }
          }
        }
      }
    }
    .cluster-select {
      position: fixed;
      z-index: 1;
      right: 16px;
      top: 16px;
      .cluster {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(100px);
        padding: 10px;
        width: 200px;
        height: 41px;
        border-radius: 16px;
        color: #fff;
        &:hover {
          background: rgba(0,0,0, 0.08);
        }
        i {
        }
      }
      .cluster-list {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(100px);
        padding: 4px;
        width: 200px;
        border-radius: 6px;
        margin-top: 4px;
        color: #fff;
        .cluster-item {
          border-radius: 6px;
          cursor: pointer;
          padding: 4px;
          margin-bottom: 4px;
          &:hover {
            background: rgba(0,0,0, 0.08);
          }
          &-selected {
            background: rgba(0,0,0, 0.08);
          }
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
</style>
