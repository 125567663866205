<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-27 15:06:50
 * @LastEditTime: 2024-08-15 16:40:17
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appBaseInfo.vue
-->
<template>
    <a-form-model ref="baseInfoFrom" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" class="form-content-app" :rules="rules">
      <a-form-model-item label="应用名称" prop="name">
        <a-input v-model="form.name" placeholder="请填写应用名称" />
      </a-form-model-item>
      <a-form-model-item label="应用地址" prop="app_addr">
        <a-input v-model="form.app_addr" placeholder="应用地址" />
      </a-form-model-item>
      <a-form-model-item style="margin-bottom: 0px" label="应用分类" prop="categories">
        <a-row type="flex" style="position: relative">
          <a-col :span="12">
            <a-form-model-item class="two-form-item" :style="{'width': !!editFlag ? '99%' : '97%'}" prop="categories">
              <a-select v-model="form.categories" mode="multiple" placeholder="场景分类">
                <a-select-option :value="item.catagory" v-for="(item, index) in categoriesList" :key="index">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item class="two-form-item mgl6" :style="{'width': !!editFlag ? '99%' : '97%'}" prop="tags">
              <a-select v-model="form.tags" mode="multiple" placeholder="行业分类">
                <a-select-option :value="item.tag" v-for="(item, index) in tagsList" :key="index">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="应用描述" prop="description">
        <a-input v-model="form.description" placeholder="请输入描述信息，最多不超过1000个字符" type="textarea" :rows="4" :maxLength="1000" />
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" class="icon-label">应用图标
        </div>
        <AppIcon :appDetail="appDetail" :name="form.name" @getIcon="getIcon" />
      </a-form-model-item>
    </a-form-model>
</template>
<script>
import AppIcon from './appIcon/index.vue'
import Regex from '@/utils/regex'
export default {
  props: {
    appDetail: Object,
    formType: {
      type: String,
      default: 'dialog'
    }
  },
  components: {
    AppIcon
  },
  data() {
    const self = this;
    return {
      rules: {
        name: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        name_en: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        app_addr: [
          {
            required: false,
            trigger: ["change", "blur"],
          },
          { validator: this.validateUrl, trigger: ["change", "blur"] },
        ],
        app_version: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        kind: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        categories: [
          {
            required: false,
            trigger: ["change", "blur"],
          },
        ],
        tags: [
          {
            required: false,
            trigger: ["change", "blur"],
          },
        ],
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 21 },
      },
      form: {
        name: '',
        // name_en: '',
        app_addr: '',
        // app_version: '',
        description: '',
        icon: '',
        // kind: undefined,
        categories: [],
        tags: []
      },
      categoriesList: [],
      tagsList: [],
      iconType: '1'
    };
  },
  watch: {
    formType: {
      handler (val) {
        if (val === 'page') {
          this.labelCol = {
            xs: { span: 24 },
            sm: { span: 2 },
          }
          this.wrapperCol = {
            xs: { span: 24 },
            sm: { span: 22 },
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    editFlag () {
      const editFlag = this.$route?.query?.id || undefined
      return editFlag
    }
  },
  methods: {
    changeIp(e) {
      var reg = /\[(.+?)\]/g;
      if (reg.test(e.target.value)) {
        this.isDisabled = true;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: e.target.value,
          });
        });
      } else {
        this.isDisabled = false;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: "",
          });
        });
      }
    },
    getIcon (icon, type) {
      console.log(type, icon)
      this.iconType = type
      this.form.icon = icon
    },
    getCatagories() {
      this.$axiosGet(global.API.getCatagories, {
        page: 1,
        pageSize: 1000,
      }).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.categoriesList = res.data.results || []
        }
      });
    },
    getTagsList() {
      this.$axiosGet(global.API.getTagsList, {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.tagsList = res.data || [];
          setTimeout(() => {
            if (this.appDetail) {
              this.form = {
                name: this.appDetail.name,
                app_addr: this.appDetail.app_addr,
                categories: this.appDetail.categories ? this.appDetail.categories.split(',') : undefined,
                tags: this.appDetail.tags ? this.appDetail.tags.split(',') : undefined,
                description: this.appDetail.description,
                icon: this.appDetail.icon,
                iconColor: this.appDetail?.iconColor
              }
            }
          }, 500)
        }
      });
    },
    validateUrl (rule, value, callback) {
      const reg = Regex.urlRegex
      const regIp = Regex.ipRegex
      if (!value || (reg.test(value))) {
        callback();
      } else {
        callback(new Error("请输入正确的应用地址"));
      }
    },
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
  },
  mounted() {
    this.getCatagories();
    this.getTagsList();
  },
};
</script>
<style lang="less" scoped>
.form-content-app {
  max-height: calc(100% - 52px);
  height: calc(100% - 52px);
  overflow-y: auto;
  /deep/ .ant-form-item-label > label {
    color: #34343C;
  }
  .icon-label {
    display: flex;
    align-items: center;
    position: relative;
    top: -42px;
    left: 14px;
    .icon-tips {
      position: relative;
      top: -1px;
      left: 4px;
      display: inline-block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background: #C4CAD3;
      line-height: 14px;
      text-align: center;
      color: #fff;
      font-size: 10px;
    }
  }
}
/deep/.ant-modal-footer {
  display: none;
}
/deep/ .ant-form-item-label > label::after {
  content: '';
}
/deep/ .ant-form-item {
    display: flex;

    .ant-col-sm-2 {
      width: 90px;
    }
    .ant-col-sm-22 {
      width: initial;
      flex: 1;
    }
}
.two-form-item {
  /deep/ .ant-form-item-control-wrapper {
    width: 100%;
  }
}
</style>
