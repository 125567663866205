/*
 * @Description: 
 * @Date: 2024-08-19 16:28:42
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-11-14 14:45:59
 */
// gridUtils.js
import store from "@/store/index";
import {
  mapSizes,
  itemSize,
  mapNetFontSizes,
  mapColorFontSizes,
  mapRadius
} from '@/utils/desktopIcon-map'

function colNum () {
  let colNum = 14
  const desktopIconSize = itemSize.get(store.state.globalSetting.desktopIcon.desktopIconSize)
  const containerWidth = document.getElementById('custom-file-manage').clientWidth
  colNum =  Math.floor((containerWidth) / desktopIconSize)
  return colNum
}

function desktopWidth () {
  return document.getElementById('custom-file-manage').clientWidth
  const iconWidth = store.state.globalSetting.desktopIcon.iconWidth
  const start =  1120
  const end =  1720
  const width = start + (Math.abs(end - start) * (iconWidth / 100))
  const viewportWidth = window.innerWidth; // 获取视口宽度
  const minViewportValue = -200 + viewportWidth; // -266px + 50vw
  const containerWidth = Math.min(width, minViewportValue); // 计算 pa
  return document.getElementsByClassName('vue-grid-layout')[0].clientWidth
}
function rowHeight () {
 return itemSize.get(store.state.globalSetting.desktopIcon.desktopIconSize)
}
export function getGridSize() {
  const gridWidth = desktopWidth();
  const gridHeight = window.innerHeight * 0.75;
  const itemWidth = itemSize.get(store.state.globalSetting.desktopIcon.desktopIconSize)
  const itemHeight = rowHeight()
  const columns = colNum()
  const rows = Math.floor(gridHeight / itemHeight);
  
  return { gridWidth, gridHeight, itemWidth, itemHeight, columns, rows };
}

export function findFreePosition(layout, columns, rows, isRowBased) {
  // 创建一个二维数组来表示布局
  const grid =isRowBased ? Array.from({ length: rows }, () => Array(columns).fill(false)) : Array.from({ length: columns }, () => Array(rows).fill(false));

  // 标记已经被占用的格子
  layout.forEach(item => {
    for (let dx = 0; dx < item.w; dx++) {
      for (let dy = 0; dy < item.h; dy++) {
        const x = isRowBased ? item.x + dx : item.y + dy;
        const y = isRowBased ? item.y + dy : item.x + dx;
        if (isRowBased) {
          if (x >= 0 && x < columns && y >= 0 && y < rows) {
            grid[y][x] = true; // 标记为已占用
          }
        } else {
          if (x >= 0 && x < rows && y >= 0 && y < columns) {
            grid[y][x] = true; // 标记为已占用
          }
        }
      }
    }
  });

  // 寻找第一个空闲的位置
  let found = false;
  let freePosition = { x: 0, y: 0 };
  // 寻找第一个空闲的位置
  if (isRowBased) {
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        if (!grid[i][j]) {
          found = true
          return { x: isRowBased ? j : i, y: isRowBased ? i : j };
        }
      }
    }
  } else {
    for (let i = 0; i < columns; i++) {
      for (let j = 0; j < rows; j++) {
        if (!grid[i][j]) {
          found = true
          return { x: isRowBased ? j : i, y: isRowBased ? i : j };
        }
      }
    }
  }
  // 如果没有找到空闲的位置，追加一行并返回新的位置
  if (!found) {
    freePosition = { x: 0, y: rows };
  }

  return freePosition;
}
// 检查位置是否有效
const isPositionValid = (x, y, cols, rows) => {
  return x >= 0 && x < cols && y >= 0 && y < rows;
};

// 找到下一个可用位置
const findNextAvailablePosition = (layout, cols, rows) => {
  const occupied = new Set(layout.map(item => `${item.x},${item.y}`));
  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      if (!occupied.has(`${x},${y}`)) {
        return { x, y };
      }
    }
  }
  return null;
};


// 策略一：将元素移动到空闲位置
const adjustLayoutForStrategyOne2 = (layout, cols, rows) => {
  const occupied = new Set(layout.map(item => `${item.x},${item.y}`));

  return layout.map(item => {
    let { x, y } = item;

    // 如果元素位置超出边界，尝试在当前行之后找空闲位置
    if (x >= cols || y >= rows) {
      // 从当前位置开始，寻找空闲位置
      for (let newY = y; newY < rows; newY++) {
        for (let newX = (newY === y ? x : 0); newX < cols; newX++) {
          if (!occupied.has(`${newX},${newY}`)) {
            return { ...item, x: newX, y: newY };
          }
        }
      }
    }

    return item;
  });
};
const adjustLayoutForStrategyOne = (layout, cols, rows) => {
  // 创建一个 Set 用于跟踪已占用的位置
  const occupied = new Set(layout.map(item => `${item.x},${item.y}`));

  return layout.map(item => {
    let { x, y } = item;

    // 如果元素位置超出边界，尝试在当前位置的下一个可用位置寻找空闲位置
    if (x >= cols || y >= rows) {
      // 从当前位置开始，寻找空闲位置
      let found = false;
      
      // 尝试在当前行之后的空闲位置
      for (let newY = y; newY < rows; newY++) {
        for (let newX = (newY === y ? x : 0); newX < cols; newX++) {
          if (!occupied.has(`${newX},${newY}`)) {
            found = true;
            return { ...item, x: newX, y: newY };
          }
        }
      }

      // 如果在行内找不到合适的位置，尝试在下一列开始寻找
      if (!found) {
        for (let newX = x; newX < cols; newX++) {
          for (let newY = (newX === x ? y : 0); newY < rows; newY++) {
            if (!occupied.has(`${newX},${newY}`)) {
              return { ...item, x: newX, y: newY };
            }
          }
        }
      }
    }

    return item;
  });
};

const adjustLayoutForStrategyTwo = (layout, cols, rows) => {
  // 对布局按行和列进行排序
  const sortedLayout = layout.slice().sort((a, b) => {
    if (a.y !== b.y) return a.y - b.y;
    return a.x - b.x;
  });

  const newLayout = [];
  const rowsElements = Array.from({ length: rows }, () => []);

  // 将元素分到各行中
  sortedLayout.forEach(item => {
    rowsElements[item.y].push(item);
  });

  for (let y = 0; y < rows; y++) {
    const currentRow = rowsElements[y];
    const nextRow = rowsElements[y + 1] || [];

    if (currentRow.length === 0) continue;

    // 处理当前行的元素
    let i = 0;
    while (i < currentRow.length) {
      const item = currentRow[i];
      if (item.x === cols - 1) { // 当前元素是行末元素
        // 从当前行中移除该元素
        currentRow.splice(i, 1);
        
        // 插入到下一行的第一个位置
        nextRow.unshift({ ...item, x: 0, y: y + 1 });
        
        // 调整下一行其他元素的位置
        for (let j = 0; j < nextRow.length; j++) {
          if (nextRow[j].x > 0) {
            nextRow[j].x++;
          }
        }
      } else {
        i++;
      }
    }

    // 将处理后的当前行元素添加到新布局
    currentRow.forEach((item, index) => {
      newLayout.push({ ...item, x: index % cols, y });
    });

    // 将处理后的下一行元素添加到新布局
    nextRow.forEach((item, index) => {
      newLayout.push({ ...item, x: index % cols, y: y + 1 });
    });

    // 更新 rowsElements
    rowsElements[y + 1] = nextRow.slice();
  }

  return newLayout;
};

const adjustLayoutForStrategyOptimized = (layout, cols, rows) => {
  // 创建一个 Set 用于跟踪已占用的位置
  const occupied = new Set(layout.map(item => `${item.x},${item.y}`));

  // 创建一个二维数组表示网格布局
  const grid = Array.from({ length: rows }, () => Array(cols).fill(null));
  layout.forEach(item => {
    if (item.x < cols && item.y < rows) {
      grid[item.y][item.x] = item;
    }
  });

  const newLayout = [];

  for (const item of layout) {
    let { x, y } = item;

    // 如果元素的 x 坐标超出边界
    if (x >= cols) {
      // 标记是否找到空闲位置
      let found = false;

      // 遍历每一行，查找空闲位置
      for (let newY = 0; !found && newY < grid.length; newY++) {
        for (let newX = 0; newX < cols; newX++) {
          if (!occupied.has(`${newX},${newY}`)) {
            found = true;
            // 更新元素的位置
            x = newX;
            y = newY;
            break;
          }
        }

        // 如果当前行没有空闲位置，新增一行
        if (!found) {
          grid.push(Array(cols).fill(null));
        }
      }
    }

    // 更新元素的位置
    const newItem = { ...item, x, y };
    newLayout.push(newItem);
    occupied.add(`${x},${y}`);
  }

  return newLayout;
};


// const adjustLayoutForStrategyOptimized = (layout, cols, rows) => {
//   // 创建一个 Set 用于跟踪已占用的位置
//   const occupied = new Set(layout.map(item => `${item.x},${item.y}`));

//   // 创建一个二维数组表示网格布局
//   const grid = Array.from({ length: rows }, () => Array(cols).fill(null));
//   layout.forEach(item => {
//     if (item.x < cols && item.y < rows) {
//       grid[item.y][item.x] = item;
//     }
//   });

//   // 用于存储调整后的布局
//   const adjustedLayout = [];

//   // 遍历布局并调整元素位置
//   layout.forEach(item => {
//     let { x, y } = item;

//     // 如果元素的 x 坐标超出边界 或者 容器变大后需要重新分配
//     if (x >= cols || (x >= grid[y].length && y < rows)) {
//       // 标记是否找到空闲位置
//       let found = false;

//       // 遍历每一行，查找空闲位置
//       for (let newY = 0; !found && newY < grid.length; newY++) {
//         for (let newX = 0; newX < cols; newX++) {
//           if (!occupied.has(`${newX},${newY}`)) {
//             found = true;
//             // 更新元素的位置
//             x = newX;
//             y = newY;
//             break;
//           }
//         }

//         // 如果当前行没有空闲位置，新增一行
//         if (!found && newY === grid.length - 1) {
//           grid.push(Array(cols).fill(null));
//         }
//       }
//     }

//     // 更新元素的位置并添加到调整后的布局中
//     adjustedLayout.push({ ...item, x, y });
//     // 更新已占用的位置
//     occupied.add(`${x},${y}`);
//   });

//   return adjustedLayout;
// };



export{
  adjustLayoutForStrategyTwo,
  adjustLayoutForStrategyOne,
  adjustLayoutForStrategyOptimized
}