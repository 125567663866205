import { render, staticRenderFns } from "./rightAppStore.vue?vue&type=template&id=5b9dc684&scoped=true"
import script from "./rightAppStore.vue?vue&type=script&lang=js"
export * from "./rightAppStore.vue?vue&type=script&lang=js"
import style0 from "./rightAppStore.vue?vue&type=style&index=0&id=5b9dc684&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9dc684",
  null
  
)

export default component.exports