<!--
 * @Description: 
 * @Date: 2024-10-30 15:43:53
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 15:09:54
-->
<template>
  <div :class="['home-search top12 absolute-center', globalSetting.theme.mode]" @contextmenu.stop="() => {}" @click.stop="() => {}" :style="{...homeSearchStyle}">
    <div class="search-box bg-color-m1 w-full flex items-center transition-colors" :style="{...opacityStyle, ...focusStyle}">
      <div class="current flex items-center justify-center h-full">
        <div class="icon-box bg-opacity-80 flex items-center justify-center cursor-pointer">
          <div class="hi-icon flex items-center justify-center"></div>
        </div>
      </div>
      <a-input class="flex1 h-full search-input text-color-t1" v-model='keyword' @change="search" @focus="onfocus" @blur="onblur" @keydown="handleKeydown" placeholder="输入搜索内容" allowClear @pressEnter="entrySearch"></a-input>
    </div>
    <div v-if="suggestions.length && keyword && showSuggest && globalSetting.searchBox.searchSuggestShow" class="suggest-box bg-color-m1 font-size14 w-full" :style="{...opacityStyle, ...focusStyle}">
      <div class="wrapper">
        <div v-for="(item, index) in suggestions" :key="index" :class="['list flex items-center bg-color-m2 bg-opacity-0  text-color-t2', { active: selectItem === item || index + 1 === selectedIndex  }]" @click="toSearch(item)">
          <svg-icon icon-class="search" class="mgr16" />
          <div class="ell search-word">{{item}}</div>
        </div>
      </div>
    </div>
    <div v-if="historyList.length && !keyword && showHistory && globalSetting.searchBox.searchHistoryShow" class="histroy-box bg-color-m1 font-size14 w-full" :style="{...opacityStyle, ...focusStyle}">
      <div class="flex-bewteen-container">
        <div class="text-color-t3">搜索历史</div>
        <div class="cursor-pointer text-color-t3" @click.stop="clearHistory">清空</div>
      </div>
      <div class="flex flex-wrap mgt20 gap16">
         <div v-for="(item, index) in historyList" :key="index" :class="['history-tag bg-color-white flex items-center  text-color-t2', { active: selectItem === item }]" @click="toSearch(item)">
            <div class="history-word text-color-t2 ell">{{item}}</div>
            <div class="history-del bg-color-b4 text-color-t4" @click.stop="removeItem(item)">
              <svg-icon icon-class="file-del" />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import store from '@/store/index'

export default {
  
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      keyword: '',
      suggestions: [],
      selectItem: '',
      showSuggest: false,
      focusStyle: {},
      showHistory: false,
      selectedIndex: 0,
      inputValue: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
    historyList () {
      return this.globalSetting?.searchBoxHistoryList || []
    },
    opacityStyle ()  {
      return {
        opacity: this.globalSetting.searchBox.searchBoxOpacity / 100
      }
    },
    homeSearchStyle () {
      let min = 520
      let max = 860
      const globalSetting = localStorage.getItem('globalSetting') ? JSON.parse(localStorage.getItem('globalSetting')) : this.globalSetting
      let searchBoxWidth = this.globalSetting.searchBox.searchBoxWidth
      searchBoxWidth = Math.min(globalSetting.searchBox.searchBoxWidth, searchBoxWidth)
      return {
        width: `${(searchBoxWidth * 3.4) + min}px`,
      }
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    onfocus () {
      this.showSuggest = true
      this.showHistory = true
      this.focusStyle = {
        'opacity': '1!important',
        '--tw-bg-opacity': 0.8
      }
    },
    onblur () {
      this.focusStyle = {}
    },
    handleKeydown (e) {
      const arr = [this.inputValue, ...this.suggestions]
       switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          this.selectedIndex = this.selectedIndex - 1
          if (this.selectedIndex === -1) this.selectedIndex = arr.length - 1
          this.keyword = arr[this.selectedIndex]
          console.log('Selected item:', this.selectedIndex, arr[this.selectedIndex]);
        break;
        case 'ArrowDown':
          event.preventDefault();
          this.selectedIndex = this.selectedIndex + 1
          if (this.selectedIndex === arr.length) this.selectedIndex = 0
          this.keyword = arr[this.selectedIndex]
          console.log('Selected item:',this.selectedIndex, arr[this.selectedIndex]);
        break;
      }
    },
    clearHistory () {
      const searchBoxHistoryList = []
      store.commit("globalSetting/setSearchBoxHistoryList", searchBoxHistoryList)
    },
    removeItem (item) {
      const historyList = _.cloneDeep(this.historyList)
      const arr = historyList.filter(i => i !== item)
      const searchBoxHistoryList = arr
      store.commit("globalSetting/setSearchBoxHistoryList", searchBox)
    },
    addHistory (item) { 
      const historyList = _.cloneDeep(this.historyList)
      if (historyList.includes(item)) return
      historyList.unshift(item)
      const searchBoxHistoryList = historyList
      store.commit("globalSetting/setSearchBoxHistoryList", searchBoxHistoryList)
    },
    entrySearch () {
      if (!this.keyword) return
      this.addHistory(this.keyword)
      window.open(`https://www.baidu.com/s?tn=15007414_15_dg&ie=utf-8&wd=${this.keyword}`)
    },

    toSearch (item) {
      this.addHistory(item)
      this.selectItem = item
      window.open(`https://www.baidu.com/s?tn=15007414_15_dg&ie=utf-8&wd=${item}`)
    },
    search() {
      this.inputValue = this.keyword
      this.selectedIndex = 0
      this.selectItem = ''
      // 如果不开启搜索建议，直接返回
      if  (!this.globalSetting.searchBox.searchSuggestShow) return false
      if (!this.keyword) {
        this.suggestions = [];
        this.showSuggest = false
        return;
      }
      // 定义JSONP回调函数
      window.updateSearchSuggestions = (data) => {
        this.showSuggest = true
        this.suggestions = data.s || [];
        delete window.updateSearchSuggestions; // 清除回调函数，防止内存泄漏
      };
      // 创建并插入script标签
      const searchUrl = `https://suggestion.baidu.com/su?type=cb&cb=updateSearchSuggestions&wd=${encodeURIComponent(this.keyword)}`;
      const script = document.createElement('script');
      script.src = searchUrl;
      document.body.appendChild(script);
      script.onload = () => {
        script.remove(); // 加载完成后移除script标签
      };
    },
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.home-search {
  max-width: 86vw;
  z-index: 1;
  .search-box {
    backdrop-filter: blur(120px);
    box-shadow: 0 4px 16px -4px #0000000d;
    height: 52px;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0,0,0, 0.6);

    .current {
      width: 52px;
      .icon-box {
        width: 28px;
        height: 28px;
        border-radius: 8px;
        &:hover {
          background: rgba(255, 255, 255, 0.8);
        }
        .hi-icon {
          background-color: rgba(0, 0, 0, 0);
          background-image: url('data:image/webp;base64,UklGRj4EAABXRUJQVlA4TDIEAAAvL8ALENVQs21rbSN5cud+Kab5j0ZP0ufCv7e4nFvMoMKmUDOTl6O2kQRp+LPc7005u8fAcdtGklT57/cUvZurBAAAy0ZqmrNt27Zt27Zt27bNt20bZ9u2bwJ87alAGitI0Zj0Dl5MAhf8YBJ2YQw82oVR6AUkml+0WATG2sATjuE6+eOnQQMlJvD3D5iCyAbumoAHRjOEHxoq22ANq0meQIr6oesBN7gq4FAY2hpRGMoQljXq64G81FBkQ0lqaGgBa4H6Eeg4/v6AIIdfI/BO4K8Fqkeg2YIHFkfg/XogrlogzELo/Qi07yy0TysoCWLORF3g2aANR8V7SciCAcYrOARbdxVYEA9zcAk7cEeIWhBDTwEdNOzVOUTgwIMK7gjCPuAB+0kCXyR+04Th85IFXmrx2gZb2Bw8xGTdFSP7JiWUHxO0xApZ/78TzLbdDGJO8BHmYQZua6SsIQdjCIUg0JJI5miUYLeCBSFiu12oa74/EEQ0tus3LOjhJpwlcAztgnbGu9RwDhaeD8kpoUlgTTr3ewJoHoErcJwnqOBLBWuSxCRD2cjD/wY7C4DQPciFdLDeLWiKf1R5N01j9qU6pEna1lBBAkwn+QiPQWUAJh9HoOVEwCRBA7+rfRmu/eDxkhImhJbt/x6OQOsJgUkGX9gbwAro2e2C7x5mFP7ROFfnEIMBnI5Aqae3wtTygGHYPoQ3NA0Frs4hJsqwlol3BbExIVio4AQsZ3hnX1SiBwkQECS2BS/8zNSfIE0NS4YJbRUMYgxzRgUXPIHrwAX0Qx48gqsisA/eF54HAMnfBzCPObgvTejnJNeJljfbKrAOjse/AYkFAqAUaiBVKOxsoI+Qg28ZEO0pgWVwEKi7t4UBZjCVwCls7MIiUfVmu8DlUuQteLXB6KWGiQW/yvfwGsxB5C8QpBEabGEFQvfgZgI7EPMHVC2wB0kCt5OW+/ME6gWNm0ADftUABjuYkBgNKQksaXFZy3IbOIb6112IAswm8EOwWeKECngEtzcTtLitZbVNvodqQdjh+D/0IAlEWuBDewJHoDIrT8Cig8QBY8n3e9YShLU+gfqT+4B5oHM9gStwsEYAlou9WfC+iWge6P2UBJLdBgvKilcvJUZDOFzCNSy+HYE/gnaeRvBTEhgQ3JaowP0Q4gnL6hyy9wl4TKMCU3AEcy2iEXgtCOYJ7P0k2afxugTrn6BIELnUsiyg7vQC8wwe2ySB/4d7m80GLKAUruAUboDPN+D0/DnYQwVcJoELcHZHQQm3B8n32+333y9JspRJAm8FfQ8/ggfXB0ngHLZhDsZhEhZh7/vUCwyA7Ooc0leQYCbQCp/gG3x9C10QDSZEgrACPY2Bxgly4Al3YAzWYBf2d2EIcgj7sQKdbBmmhoBGIogFfluYHhc4WnRaAiADsiAsaBS4OodcUwI=');
          background-size: cover;
          width: 24px;
          height: 24px;
          border-radius: 6px;
        }
      }
    }
    .search-input {
      padding: 10px 42px 10px 4px;
      border-radius: 12px;
      /deep/ .ant-input {
          background: transparent;
          border: none;
          font-size: 16px;
          color: rgba(var(--color-t1), 1);
          &:focus, &:hover {
          background: transparent;
          border: none;
          box-shadow: none;
        }
        &::placeholder {
          color: rgba(var(--color-t1), 0.5);
        }
      }
      /deep/ .ant-input-suffix {
        margin-right: 8px;
        i {
          font-size: 14px;
          color: rgb(200, 200, 204);
        }
      }
    }
    // .light {
    //   /deep/ .ant-input {
    //       background: transparent;
    // }
  }
  .transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
  }
  .suggest-box {
    margin-top: 4px;
    overflow: hidden;
    backdrop-filter: blur(120px);
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 #00000026;
    border: 1px solid rgba(255, 255, 255, 0.1);
    .wrapper {
      padding: 4px 0;
      max-height: calc(88vh - 160px);
      .list {
        border-radius: 4px;
        cursor: pointer;
        margin: 4px 8px;
        padding: 10px 8PX;
        &:hover {
          --tw-bg-opacity: 0.06;
        }
        .search-word {
          max-width: 80%;
          height: 16px;
          font-size: 14px;
        }
      }
      .active {
          --tw-bg-opacity: 0.06;
      }
    }
  }
  .histroy-box {
    margin-top: 4px;
    backdrop-filter: blur(120px);
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 #00000026;
    border: 1px solid rgba(255, 255, 255, 0.1);
    max-height: calc(88vh - 160px);
    padding: 20px;
    .history-tag {
      position: relative;
      border-radius: 8px;
      padding: 0 24px;
      height: 32px;
      max-width: 160px;
      cursor: pointer;
      .history-del {
        text-align: center;
        position: absolute;
        top: -12px;
        left: -12px;
        width: 24px;
        height: 24px;
        display: none;
        line-height: 18px;
        transition-duration: .15s;
        transition-property: opacity;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        box-shadow: 0 4px 16px 0 #00000026;
        border-radius: 50%;
        svg {
          transition: transform 1.2s;
          font-size: 8px;
          &:hover {
            color: rgb(var(--color-t3));
            transform: rotate(1turn);
          }
        }
     
      }
      .history-word {
      }
      &:hover {
        .history-del {
          display: block;
        }
      }
    }
  }
}
.top12 {
  top: 12vh;
}
.dark {
  .history-tag {
    --tw-bg-opacity: 0.1;
  }
}
</style>
