<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-05 09:39:56
 * @LastEditTime: 2024-07-17 11:05:46
 * @FilePath: \awx-ui\src\components\createTask\deploystep\notice\email.vue
-->
<template>
  <div class="email-notice">
    <div class="header flex-container">
      <div class="left">事件类型</div>
      <div class="right">开关</div>
    </div>
    <div class="event-type flex-container" v-for="(item, index) in eventType" :key="index">
      <div class="event-name left">{{ item.name }}</div>
      <div class="event-value right">
        <a-switch v-model="item[`${item.key}`]" size="small"></a-switch>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      eventType: [
        {
          name: "任务执行成功",
          key: "workflow_success",
          workflow_success: false,
        },
        { name: "任务执行失败", key: "workflow_fail", workflow_fail: true },
        {
          name: "更新任务配置",
          key: "workflow_update",
          workflow_update: false,
        },
        { name: "删除任务", key: "workflow_delete", workflow_delete: false },
      ],
    };
  },
  watch: {
    eventType: {
      handler(val) {
        let arr = [];
        val.map((item) => {
          if (item[`${item.key}`]) {
            arr.push(item.key);
          }
        });
        this.$emit("getEventType", arr);
      },
      deep: true,
    },
  },
  computed: {
     ...mapState({
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
     currentWinboxKey () {
      // const currentFocusWindow = this.windowStore.currentFocusWindow;
      // let key = null
      // for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  components: {},
  mounted() {
    this.initEmail();
  },
  methods: {
    initEmail() {
      if (
        this.winboxTask.taskInfo &&
        this.winboxTask.taskInfo.taskTemplateDetail &&
        this.winboxTask.taskInfo.taskTemplateDetail?.notify
      ) {
        const email =
          this.winboxTask.taskInfo.taskTemplateDetail?.notify.filter(
            (item) => item.type === "email"
          ) || [];
        if (!email.length) return false;
        this.eventType.forEach((item) => {
          item[`${item.key}`] = email[0].events.includes(`${item.key}`);
        });
      }
    },
  },
};
</script>
<style scoped lang='less'>
.notice {
  width: 100%;
  .header {
    height: 37px;
    background: #f7f8fa;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #cfd5de;
    line-height: 1;
    color: #242b3a;
    font-weight: 550;
    font-size: 14px;
    .left {
      border-left: none;
    }
    .right {
      border-right: none;
    }
  }
  .event-type {
    height: 37px;
    border-bottom: 1px solid #cfd5de;
    font-size: 14px;
    color: #34343c;
    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }
  }
  .left {
    height: 100%;
    line-height: 36px;
    padding: 0px 18px;
    width: 50%;
    border-left: 1px solid #cfd5de;
    border-right: 1px solid #cfd5de;
  }
  .right {
    height: 100%;
    line-height: 36px;
    padding: 0px 18px;
    flex: 1;
    border-right: 1px solid #cfd5de;
  }
}
</style>