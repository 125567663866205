/*
 * @Description: 下载文件的并发控制和进度跟踪
 * @Date: 2024-05-30 09:37:54
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-17 14:39:55
 */
import axios from 'axios';
import store from '@/store';
import { message } from 'ant-design-vue';

class DownloadSemaphore {
  constructor(max) {
    this.max = max;
    this.active = 0;
  }

  async acquire() {
    while (this.active >= this.max) {
      await new Promise(resolve => setTimeout(resolve, 100)); // 防止忙等待
    }
    this.active++;
  }

  release() {
    this.active--;
  }
  reset() {
    this.active = 0;
  }
}

// 定义最大并发数
const MAX_CONCURRENT_DOWNLOADS = 3; // 调整为适合下载的最大并发数
const downloadSemaphore = new DownloadSemaphore(MAX_CONCURRENT_DOWNLOADS);

let downloadQueue = [];
let isDownloading = true;

const downControllerObj = {};

function cancelDownload() {
  isDownloading = false;
  downloadSemaphore.reset();
  for (var key in downControllerObj) {
    downControllerObj[key].abort();
    delete downControllerObj[key];
  }
}
function cancelDownloadByKey(key) {
  downloadSemaphore.release();
  downControllerObj[key].abort();
  delete downControllerObj[key];
}

async function downloadPeerFile(url, file, params, scope) {
  console.warn(`Starting download for: ${file.name}`);
  const ajaxApi = url + file.fullpath + '?cluster_id=' + params.clusterId + '&filesystem=' + params.filesystem;
  const rtcscope = scope;
  
  // 为每个文件创建一个新的 AbortController 实例
  const controller = new AbortController();
  downControllerObj[file.name] = controller; // 将控制器实例存储起来

  try {
    const response = await axios.get(ajaxApi, {
      headers: {
        token: localStorage.getItem('token')
      },
      responseType: 'blob',
      rtcscope,
      signal: controller.signal, // 将信号传递给请求
      onDownloadProgress: function (progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        const uploadist = store.state.fileManage.downloadFileList
        console.log(`${file.name}: ${percentCompleted}%`, file, uploadist)
        const index = uploadist.findIndex(item => item.fileId === file.fileId)
        if (uploadist[index]?.status === 'exception') return false
        if (index === -1) {
          uploadist.push({
            ...file,
            progress: percentCompleted,
            fileId: file.fileId
          })
        } else {
          uploadist.splice(index, 1, {
            ...uploadist[index],
            progress: percentCompleted,
          })
        }
        store.dispatch('fileManage/changeDownloadFileList', uploadist)
        if(!store.state.fileManage.showFileProgress) store.dispatch('fileManage/changeDownloadFileList', [])
      }
    });
    if (response.status != 200 && response.status != 201 && response.status != 204 && response.status != 202) {
      return
    }
    const blob = new Blob([response.data], {
      type: 'application/octet-stream'
    });
    const uploadist = store.state.fileManage.downloadFileList
    const index = uploadist.findIndex(item => item.fileId === file.fileId)
    if (index === -1) {
      uploadist.push({
        ...file,
        progress: 100,
        fileId: file.fileId
      })
    } else {
      uploadist.splice(index, 1, {
        ...uploadist[index],
        progress: 100,
      })
    }
    store.dispatch('fileManage/changeDownloadFileList', uploadist)
    if(!store.state.fileManage.showFileProgress) store.dispatch('fileManage/changeDownloadFileList', [])
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // link.innerHTML = "下载";
    link.download = file.name; // 假设文件名是filepath的最后一部分
    link.click(); // 触发下载
  } catch (error) {
    console.error(`文件 ${file.name} 下载失败`, error);
    // 更新下载失败状态
    const downloadList = store.state.fileManage.downloadFileList;
    const index = downloadList.findIndex(item => item.fileId === file.fileId);
    if (index !== -1) {
      downloadList.splice(index, 1, {
        ...downloadList[index],
        progress: 100,
        status: 'exception'
      });
      store.dispatch('fileManage/changeDownloadFileList', downloadList);
    }
  } finally {
    delete downControllerObj[file.name]; // 成功或失败后删除控制器实例
  }
}

async function downloadFilesConcurrently(files, url, params, limit = MAX_CONCURRENT_DOWNLOADS) {
  downloadQueue = [...files];
  isDownloading = true;
  while (downloadQueue.length > 0 && isDownloading) {
    const batch = downloadQueue.splice(0, Math.min(downloadQueue.length, limit));
    await Promise.all(batch.map(async file => {
      await downloadSemaphore.acquire(); // 等待信号量可用
      try {
        await downloadPeerFile(url, file, params, file.name); // 使用唯一的rtcscope
      } finally {
        downloadSemaphore.release(); // 无论成功或失败，释放信号量
      }
    }));
  }

  // 清理控制器实例
  for (var key in downControllerObj) {
    downControllerObj[key].abort();
    delete downControllerObj[key];
  }
}

export {
  downloadFilesConcurrently,
  downloadPeerFile,
  cancelDownload,
  cancelDownloadByKey
};