<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-11-06 17:10:24
 * @FilePath: \awx-ui\src\pages\dispatch\index.vue
-->

<template>
  <div class="dispatch-page">
    <a-row type="flex" align="middle">
      <a-col :span="14">
        <a-button @click="delDipath()">删除</a-button>
        <a-button class="mgl10" @click="startBatch('start')">启用</a-button>
        <a-button class="mgl10" @click="startBatch('stop')">停用</a-button>
        <a-button style="margin-left: 10px" @click="onSearch">刷新</a-button>
        <a-button class="mgl10" type="primary" @click="createProject({}, { title: '1' })"><svg-icon class="btn-before-icon mgr6" icon-class="file-add"></svg-icon>添加调度</a-button>
      </a-col>
      <a-col :span="10" style="text-align: right">
        <a-input-search placeholder="请输入关键词查询" class="w470" @change="(value) => getVal(value, 'name')" allowClear v-model="title" @search="onSearch" />
      </a-col>
    </a-row>
    <div class="table-info">
      <a-table class="tab table-15 opt-table" :row-selection="rowSelection"  @change="tableChange" :customRow="handleClickRow" :columns="columns" :loading="loading" :dataSource="dataSource" :scroll="scrollTable" rowKey="id" :pagination="pagination">
        <span slot-scope="text, record" slot="enabled">
          <span>{{ record.enabled ? "启用" : "停用" }}</span>
        </span>
        <span slot="last_job_failed" class="s" slot-scope="text,record">
          <span :class="['beat', 'down', item.status=='successful' && 'beat-success', item.status=='failed' && 'beat-fail']" v-for="item in record.detail.recent_jobs" :key="item.id">
          </span>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import DeleteProject from "./commponents/deletedispatch.vue";
import Detail from "./commponents/detail.vue";
import AC from "./commponents/addDispatch.vue";
import cronstrue from 'cronstrue/i18n';
import { mapState, mapMutations, mapActions } from "vuex";

// import Deletemanagement from "./commponents/deletemanagement.vue";
import moment from "moment";
export default {
  name: "USER",
  data() {
    return {
      flag: true,
      title: "",
      params: {},
      setSelectedIndex: -1,
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      dataSource: [],
      selectedRows: [],
      selectedRowKeys: [],
      loading: false,
      columns: [
        {
          title: "序号",
          key: "index",
          dataIndex: "index",
          width: 70,
          customRender: (text, row, index) => {
            return (
              <span>
                {parseInt(
                  this.pagination.current === 1
                    ? index + 1
                    : index +
                        1 +
                        this.pagination.pageSize * (this.pagination.current - 1)
                )}
              </span>
            );
          },
        },
        { title: "名称", key: "name", dataIndex: "name", ellipsis: true},
        {
          title: "类型",
          ellipsis: true,
          key: "belong_type",
          dataIndex: "belong_type",
          customRender: (text) => {
            return (
              <span>
                {text === "embed_script"
                  ? "脚本"
                  : text === "embed_cmd"
                  ? "命令"
                  : text === "task"
                  ? "任务"
                  : "-"}
              </span>
            );
          },
        },
        {
          title: "活动",
          width: 250,
          ellipsis: true,
          dataIndex: "detail",
          key: "detail",
          scopedSlots: { customRender: "last_job_failed" },
        },
        {
          title: "状态",
          ellipsis: true,
          key: "enabled",
          width: 80,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "执行周期",
          ellipsis: true,
          key: "schedule_type",
          dataIndex: "schedule_type",
          width: 140,
          // sorter: (a, b) => {
          //   const name1 =a.schedule ? a.schedule === 'timer' ? '定时调度' : a.schedule === 'once' ? '一次性调度' : '手动调度' : '-'
          //   const name2 =b.schedule ? b.schedule === 'timer' ? '定时调度' : b.schedule === 'once' ? '一次性调度' : '手动调度' : '-'
          //   return name1.localeCompare(name2, 'zh-Hans-CN', { numeric: true });
          // },
          customRender: (text, row) => {
            return (
              <div class="ell" title={text ? text === 'timer' ? this.humanReadable(row) : text === 'once' ? '一次性调度' : '手动调度'  : '-'}>{text ? text === 'timer' ? this.humanReadable(row) : text === 'once' ? '一次性调度' : '手动调度'  : '-'}</div>
            );
          },
        },
        {
          title: "下次运行时间",
          key: "next_run",
          ellipsis: true,
          width: 140,

          dataIndex: "next_run",
          customRender: (text) => {
            return (
              <span>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "创建时间",
          width: 140,
          key: "create_time",
          dataIndex: "create_time",
          customRender: (text) => {
            return (
              <span>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "更新时间",
          key: "update_time",
          dataIndex: "update_time",
          width: 140,

          customRender: (text) => {
            return (
              <span>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          title: "描述",
          key: "description",
          dataIndex: "description",
          ellipsis: true,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 210,
          customRender: (text, row, index) => {
            return (
              <span class="flex-container">
                <a
                  class="btn-opt"
                  onClick={(e) => this.createProject(row, { title: "2" }, e)}
                >
                  修改
                </a>
                <a-divider type="vertical" />
                <a class="btn-opt" onClick={(e) => this.del(row, e)}>
                  删除
                </a>
                <a-divider type="vertical" />
                {row.enabled ? (
                  <span>
                    <a class="btn-opt" onClick={(e) => this.alter(row, e)}>
                      停用
                    </a>
                    <a-divider type="vertical" />
                  </span>
                ) : (
                  <span>
                    <a class="btn-opt" onClick={(e) => this.alter(row, e)}>
                      启用
                    </a>
                    <a-divider type="vertical" />
                  </span>
                )}
                <a class="btn-opt" onClick={(e) => this.seeDetail(row, e)}>
                  查看
                </a>
              </span>
            );
          },
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(val) {
        // 是从dashBoard进来的
        if (val.params && val.params.obj) {
          this.seeDetail(val.params.obj);
        }
      },
      deep: true,
      immediate: true,
    },
    'fileManage.clusterId': {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.onSearch()
        }
      },
      deep: true
    },
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
      fileManage: (state) => state.fileManage, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    //复选框 需要时候再加
    rowSelection() {
      const { selectedRowKeys } = this;
      let _this = this;
      return {
        selectedRowKeys,
        onSelectAll: (selectedRowKeys, selectedRows) => {
          _this.selectedRows = selectedRows;
        },
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys;
          _this.selectedRows = selectedRows;
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              name: record.name,
            },
          };
        },
      };
    },
    scrollTable () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max) height = document.documentElement.clientHeight
      if (full) height = document.documentElement.clientHeight
     let scroll = {}
      if (this.dataSource.length > 0)  {
        scroll = {
          x: 1632,
          y: height - 220
        }
      }
      return scroll
    }
  },
  methods: {
    handleClickRow(record, index){
      return {
        style: {
        'background-color': index === this.setSelectedIndex ? '#e4eefd ' : '#fff',
        },
        on: {
          click: () => {
          this.setSelectedIndex = index
          this.createProject(record, {title: '2'})
          }
        }
      }
    },
    humanReadable(record) {
      const cron = record.cron
      // 使用 cronstrue 将 Cron 表达式转换为人类可读的形式
      let str = '-'
      try {
        str = cronstrue.toString(cron, { locale: "zh_CN", use24HourTimeFormat: true })
      } catch (err) {
        str = '-'
      }
      return str
    },
    clearSelectIndex () {
      this.setSelectedIndex = -1
    },
    startBatch (enabled) {
      const self = this;
      if (self.selectedRows.length == 0) {
        self.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      self.selectedRows.map((item) => {
        ids.push(item.id);
      });
      if (enabled === 'start') {
        self.$axiosPatch(
          global.API.getDispatchList + `/${ids.join(',')}/enable`,
          {}
        ).then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            self.getDispatchList();
            self.$message.success("操作成功");
            this.selectedRowKeys = []
            self.selectedRows = [];
          }
        });
        return;
      }
      if (enabled === 'stop') {
        self.$axiosPatch(
          global.API.getDispatchList + `/${ids.join(',')}/disable`,
          {}
        ).then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            self.getDispatchList();
            self.$message.success("操作成功");
            this.selectedRowKeys = []
            self.selectedRows = [];
          }
        });
      }
    },
    delDipath(e) {
      if (e) e.stopPropagation()
      const self = this;
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      let width = 400;
      let content = (
        <DeleteProject
          sysTypeTxt="调度"
          detail={{ ids: ids }}
          callBack={() => {
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    del(row, e) {
      if (e) e.stopPropagation()
      const self = this;
      let width = 400;
      let content = (
        <DeleteProject
          sysTypeTxt="调度"
          detail={row}
          callBack={() => self.getDispatchList()}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    seeDetail(row, e) {
      if (e) e.stopPropagation()
      let width = 800;
      let title = "调度详情";
      let content = <Detail detail={row} />;
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    alter(val, e) {
      if (e) e.stopPropagation()
      const params = {
        name: val.name,
        description: val.description,
        rrule: val.rrule,
        belong_type: val.belong_type,
        belong_id: val.belong_id,
      };
      if (!val.enabled) {
        this.$axiosPatch(
          global.API.getDispatchList + `/${val.id}/enable`,
          params
        ).then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            this.getDispatchList();
            this.$message.success("操作成功");
          }
        });
        return;
      }
      if (val.enabled) {
        this.$axiosPatch(
          global.API.getDispatchList + `/${val.id}/disable`,
          {}
        ).then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            this.getDispatchList();
            this.$message.success("操作成功");
          }
        });
      }
    },
    tableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getDispatchList();
    },
    getVal(val, filed) {
      console.log(val.target.value, "查询");
      this.params[`${filed}`] = val.target.value;
      const params = {
        name: val.target.value,
      };
      this.$axiosGet(global.API.getDispatchList, params).then((res) => {
        console.log(res, "查询数据");
      });
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.getDispatchList();
    },
    // 添加
    createProject(row, obj, e) {
      if (e) e.stopPropagation()
      obj.row = row;
      obj.id = row.id;
      const self = this;
      let width = 800;
      let title = obj.title === "1" ? "添加调度" : "编辑调度";
      let content = (
        <AC detail={(row, obj)} clearSelectIndex={() => self.clearSelectIndex()} callBack={() => {self.clearSelectIndex();self.getDispatchList();}} />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
          self.clearSelectIndex()
        },
        icon: () => {
          return <div />;
        },
      });
    },

    getDispatchList() {
      this.loading = true;
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        keyword: this.params.name || "",
        description: this.params.description || "",
        job_limit: 5,
      };
      this.$axiosGet(global.API.getDispatchList, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.dataSource = res.data.results;
          this.pagination.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    if (!this.fileManage.clusterId) return false
    this.getDispatchList();
  },
};
</script>

<style lang="less" scoped>
.dispatch-page {
  height: 100%;
  min-width: 980px;
  overflow-x: auto;
  box-shadow: none;
  padding: 16px 14px 16px 16px;
  .table-info {
    margin-top: 10px;
    /deep/ .ant-table {
      overflow-y: auto;
    }
    /deep/ .ant-table-tbody > tr > td {
      padding: 8px 16px 7px;
    }
  }
  .beat {
    display: inline-block;
    border-radius: 50rem;
    width: 7px;
    height: 21px;
    margin: 4px;
    background-color: #d0cfcf;
    --hover-scale: 1.5;
    &-success {
      background-color: #5cdd8b;
    }
    &-fail {
      background-color: #f92d26;
    }
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
  .active {
    background: #f92d26;
  }
  .active2 {
    background: #57a850;
  }
  .grid {
    display: flex;
    flex-direction: column;
    margin: 1px;
    span {
      width: 20px;
      height: 8px;
      border: 1px solid silver;
    }
  }
  .s {
    display: flex;
  }
}
</style>
