/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-10-24 10:45:47
 * @LastEditTime: 2024-09-27 17:57:24
 * @FilePath: \awx-ui\src\components\workflow-design\flow-design.js
 */
import store from "@/store/index"

export class DesignerStepComponents {

  constructor(
    designer,
    currentWinboxKey
  ) {
    this.currentWinboxKey = currentWinboxKey
    this.designer = designer
  }
  // 更新选中的step的内容
  getWindowId () {
    const currentFocusWindow = store.state.windowStore.currentFocusWindow;
    let windowId = null
    for (var i in currentFocusWindow) { windowId = i }
    return windowId
  }
  updateStepsPropertiesByStepId (properties) {
    const step = this.getStep()
    const stepID = this.getStepId()
    if (!step || !stepID) return false
    step.properties = {...properties}
    this.designer.api.editor.createStepEditorContext(stepID).notifyPropertiesChanged()
  }
  updateStepsNameByStepId (name) {
    const step = this.getStep()
    const stepID = this.getStepId()
    if (!step || !stepID) return false
    step.name = name
    this.designer.api.editor.createStepEditorContext(stepID).notifyNameChanged()
  }
  updateStepsChildrenByStepId (name, properties, oldName) {
    const step = this.getStep()
    const stepID = this.getStepId()
    if (!step || !stepID) return false
    // 名称修改
    step.branches[`${name}`] = oldName ? step.branches[`${oldName}`] : []
    if (oldName)  delete step.branches[`${oldName}`]
    step.properties = {...properties}
    this.designer.api.editor.createStepEditorContext(stepID).notifyChildrenChanged()
  }
  // 更新整个节点
  updateStepSelfChangedByStepId(stepBack) {
    const step = this.getStep()
    const stepID = this.getStepId()
    if (!step || !stepID) return false
    step.name = stepBack.name
    step.icon = stepBack.icon
    step.properties = {...stepBack.properties}
    this.designer.api.editor.createStepEditorContext(stepID).notifyStepSelfChanged()
  }
  deleteStepsChlidren(name, properties) {
    const step = this.getStep()
    const stepID = this.getStepId()
    // 名称修改
    step.branches[`${name}`] = []
    delete step.branches[`${name}`]
    step.properties = {...properties}
    this.designer.api.editor.createStepEditorContext(stepID).notifyChildrenChanged()
  }
  getStep () {
    const windowId = this.getWindowId()
    if (windowId !== this.currentWinboxKey) return null
    return this.designer.view.workspace?.selectedStepComponent?.step || null
  }
  getStepId () {
    const windowId = this.getWindowId()
    if (windowId !== this.currentWinboxKey) return null
    return this.designer.view.workspace?.selectedStepComponent?.step?.id || null
  }
}