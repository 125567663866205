<!--
 * @Description: 
 * @Date: 2024-07-25 14:30:01
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-20 17:54:29
-->
<template>
<div class="wallpaper-gradient-detail flex flex-col">
  <div class="header flex h52 text-color-t2 items-center justify-between">
      头部分类待开发
  </div>
  <div class="hi-scroll">
    <div class="grid grid-cols-3 gap12 wallpaper-gradient-detail-container">
      <div class="grid-item relative h108" v-for="(item, index) in gradientsDetailList" :style="`background-image: ${getBackImage(item)}`" @click="handleSelect(item)">
      </div>
    </div>
  </div>
  <div class='gradient-bottom relative flex items-center justify-around'>
    <div class="flex items-center range-slider">
      <a-slider v-model="hue" :min="0" reverse :tip-formatter="null" :max="1" :step="0.1"  />
      <span class="mgl16 text-color-t2">色彩</span>
    </div>
    <CircleDragger class="relative circle-drag" ref="CircleDraggerRef" :initialAngle="initialAngle" :gradientColors="gradientColors" @setAngel="setInitialAngle" />
    <div class="flex items-center range-slider">
      <span class="mgr16 text-color-t2">亮度</span>
      <a-slider v-model="brightness" :tip-formatter="null" :min="0" :max="1" :step="0.1"  />
    </div>
  </div>
</div>

</template>

<script>
import chroma from 'chroma-js';

import CircleDragger from './CircleDragger.vue'
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    CircleDragger
  },
  data () {
    // 这里存放数据
    // https://api.wetab.link/api/wallpaper/list?client=pc&tag=animals
    return {
      initialAngle: 180,
      gradientColors: [],
      isPhoto: false,
      hue: 1,
      brightness: 0.8,
      currentPhoto: {
        name: '自然',
        type: 'natrue',
        url: require('@/assets/img/wallpaper/natrue.png')
      },
      gradientsDetailList: [
        {
          gradientColors: ['rgb(68, 28, 255)', 'rgb(85, 88, 224)', 'rgb(80, 128, 193)', 'rgb(43, 163, 161)'],
          0: 'linear-gradient(251deg, rgb(99, 99, 196), rgb(125, 123, 205), rgb(148, 147, 214), rgb(171, 172, 223))',
          secai: 'linear-gradient(251deg, rgb(99, 99, 196), rgb(125, 123, 205), rgb(148, 147, 214), rgb(171, 172, 223))',
          liangdu: 'linear-gradient(251deg, rgb(87, 87, 203), rgb(115, 112, 211), rgb(139, 137, 219), rgb(162, 163, 226))',
          100: 'linear-gradient(251deg，rgb(99，94，177), rgb(121,112,185),rgb(142,131,192),rgb(162,150,200))'
        },
        {
          gradientColors: ['rgb(134, 63, 69)', 'rgb(159, 93, 93)', 'rgb(183, 124, 118)', 'rgb(208, 155, 144)'],
          'background-image-color': "linear-gradient(302deg, rgb(134, 63, 69), rgb(159, 93, 93), rgb(183, 124, 118), rgb(208, 155, 144))"
        },
        {
          gradientColors: ['rgb(228, 103, 0)', 'rgb(238, 134, 0)', 'rgb(247, 163, 0)', 'rgb(255, 192, 1)'],

          'background-image-color': "linear-gradient(302deg, rgb(228, 103, 0), rgb(238, 134, 0), rgb(247, 163, 0), rgb(255, 192, 1));"
        },
        {
          gradientColors: ['rgb(0, 48, 212)', 'rgb(78, 85, 224)', 'rgb(113, 122, 235)', 'rgb(141, 160, 246)'],
          'background-image-color': "linear-gradient(302deg, rgb(0, 48, 212), rgb(78, 85, 224), rgb(113, 122, 235), rgb(141, 160, 246));"
        },
         {
          gradientColors: ['rgb(43, 166, 212)', 'rgb(108, 165, 154)', 'rgb(129, 166, 95)', 'rgb(135, 167, 5)'],
          'background-image-color': "linear-gradient(302deg, rgb(43, 166, 212), rgb(108, 165, 154), rgb(129, 166, 95), rgb(135, 167, 5));"
        },
         {
          gradientColors: ['rgb(35, 0, 98)', 'rgb(101, 37, 139)', 'rgb(164, 76, 183)', 'rgb(229, 118, 228)'],
          'background-image-color': "linear-gradient(302deg, rgb(35, 0, 98), rgb(101, 37, 139), rgb(164, 76, 183), rgb(229, 118, 228));"
        },
         {
          gradientColors: ['rgb(123, 131, 255)', 'rgb(184, 170, 210)', 'rgb(220, 212, 162)', 'rgb(245, 255, 102)'],

          'background-image-color': "linear-gradient(302deg, rgb(123, 131, 255), rgb(184, 170, 210), rgb(220, 212, 162), rgb(245, 255, 102));"
        },
         {
          gradientColors: ['rgb(52, 111, 133)', 'rgb(116, 141, 146)', 'rgb(170, 173, 159)', 'rgb(223, 205, 171)'],

          'background-image-color': "linear-gradient(302deg, rgb(52, 111, 133), rgb(116, 141, 146), rgb(170, 173, 159), rgb(223, 205, 171));"
        },
         {
          gradientColors: ['rgb(133, 0, 219)', 'rgb(187, 23, 182)', 'rgb(224, 47, 144)', 'rgb(255, 70, 106)'],
          'background-image-color': "linear-gradient(302deg, rgb(133, 0, 219), rgb(187, 23, 182), rgb(224, 47, 144), rgb(255, 70, 106));"
        },
         {
          gradientColors: ['rgb(184, 156, 131)', 'rgb(169, 173, 103)', 'rgb(150, 188, 70)', 'rgb(124, 203, 13)'],

          'background-image-color': "linear-gradient(302deg, rgb(184, 156, 131), rgb(169, 173, 103), rgb(150, 188, 70), rgb(124, 203, 13));"
        },
         {
          gradientColors: ['rgb(129, 41, 109)', 'rgb(130, 77, 149)', 'rgb(122, 108, 190)', 'rgb(101, 139, 234)'],

          'background-image-color': "linear-gradient(302deg, rgb(129, 41, 109), rgb(130, 77, 149), rgb(122, 108, 190), rgb(101, 139, 234));"
        },
        {
          gradientColors: ['rgb(95, 33, 18)', 'rgb(110, 62, 90)', 'rgb(103, 93, 169)', 'rgb(25, 126, 255)'],

          'background-image-color': "linear-gradient(302deg, rgb(95, 33, 18), rgb(110, 62, 90), rgb(103, 93, 169), rgb(25, 126, 255));"
        },
        {
          gradientColors: ['rgb(104, 61, 46)', 'rgb(152, 84, 45)', 'rgb(203, 107, 39)', 'rgb(255, 132, 27)'],
          'background-image-color': 'linear-gradient(302deg, rgb(104, 61, 46), rgb(152, 84, 45), rgb(203, 107, 39), rgb(255, 132, 27));'
        }
      ],
  
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.getPhotosBytag()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    getBackImage (item) {
      const colors = this.adjustColorsForGradient(item.gradientColors)
      return `linear-gradient(${this.initialAngle}deg, ${colors.join(', ')})`
    },
    setInitialAngle (angle) {
      this.initialAngle = angle
    },
    // 初始化圆点位置、角度显示和背景渐
    adjustColorsForGradient(colors) {
      // 调整固定颜色的亮度和色彩
      const adjustedColors = colors.map(color => {
        const chromaColor = chroma(color);

        // 应用亮度和色彩调整
        return chromaColor
          .set('hsl.h', chromaColor.get('hsl.h') + (this.hue) * 360) // 色调调整
          .set('hsl.l', chromaColor.get('hsl.l') + (this.brightness - 0.5) * 0.5) // 亮度调整
          .hex();
      });

      return adjustedColors;
    },
    onChange (val) {},
    handleSelect (item) {
      const colors = this.adjustColorsForGradient(item.gradientColors)
      this.gradientColors = colors
      this.$nextTick(() => {
        this.$refs.CircleDraggerRef.updateBackgroundGradient(this.initialAngle)
      })
    },
  }
}
</script>

<style lang='less' scoped>
.hi-scroll {
  overflow: auto;
  flex: 1 1 0%;
}
.h108 {
  height: 108px;
}

.wallpaper-gradient-detail {
  height: calc(100% - 52px);
  border-radius: 12px;
  z-index: 10;
  .header {
    padding: 0 60px;
    &-left {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      span {
        display: block;
      }
    }
    &-center {
      font-size: 20px;
    }
    &-right {
      font-size: 14px;
       span {
        display: block;
        margin-right: 6px;
      }
      /deep/ .ant-switch {
        background-color: rgba(255, 255, 255, 0.2);
      }
      /deep/ .ant-switch-checked {
        background-color: #3974f4;
      }
    }
  }
  .h52 {
    height: 52px;
  }
  .grid-item {
    cursor: pointer;
    border-radius: 6px;
    overflow: hidden; /* 防止图片溢出容器 */
    img {
      height: auto;
      width: 100%;
      max-width: 100%;
      border-radius: 6px;
      transition-duration: .3s;
      transition-property: transform;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      transform: matrix(1, 0, 0, 1, 0, 0);
      display: initial;
    }
    div {
      background: rgba(0, 0, 0, 0.2);
      span {
        font-size: 20px;
        color: #fff;
        font-weight: 600;
      }
    }
    &:hover {
      img {
        transform: matrix(1.2, 0, 0, 1.2, 0, 0);
        transition-timing-function: ease;
      }
    }
  }
  .wallpaper-gradient-detail-container {
    padding: 0px 60px 16px;
  }
  .gradient-bottom {
    height: 77px;
    .circle-drag {
      z-index: 10;
      top: -26px;
    }
    .range-slider {
      color: rgb(200, 200, 204);
      /deep/ .ant-slider {
        width: 100px;
        height: 8px!important;
        margin: 0;
        padding: 0;
        .ant-slider-rail {
          height: 8px;
          border-radius: 12px;
          --tw-shadow: inset 0 0 0 1px #09030314;
          --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color);
          background-color: rgba(var(--color-b2), var(--tw-bg-opacity));
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); 
        }
        .ant-slider-track {
          height: 8px;
          background: rgba(var(--color-m2), var(--tw-bg-opacity));
        }
        .ant-slider-handle, .ant-slider-step {
          display: none;
        }
        
      }
      span {
        display: inline-block;
      }
    }
  }
}

</style>
