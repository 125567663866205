<!--
 * @Description: 
 * @Date: 2024-06-24 17:25:55
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-21 15:29:20
-->
<template>
  <div ref="TaskbarEntrRef" class="taskbar-entry">
    
    <div :class="['taskbar-entry-container', taskbarPosition]" @click="hanlderClick" :title="taskbarEntry.name" @contextmenu="(e) => parentShow(e)">
      <div v-if="currentFocusWindowId === taskbarEntry.id" class='focus-bar'></div>
      <div v-else class='point'></div>
      <span v-if="taskbarEntry.componentName === 'ExternalComponent'">
        <!-- 网络图标 -->
        <span class="network-icon" v-if="!IsColorIcon(taskbarEntry.url) && IsNetWorkIcon(taskbarEntry.url)">
          <iconifyIcon :icon="iconNetWorkName(taskbarEntry.url)" style="font-size: 32px;" />
        </span>
        <!-- 纯色图标 -->
        <div v-else-if="IsColorIcon(taskbarEntry.url) && !IsNetWorkIcon(taskbarEntry.url)"  class='color-icon' :style="{
          'background': taskbarEntry.url,
        }">
          <span>{{ iconColorName(taskbarEntry.name) }}</span>  
        </div>
        <img v-else :src="taskbarEntry.url" alt="">
      </span>
      <img v-if="taskbarEntry.componentName === 'MyDynamicComponent'" :src="taskbarEntry.url" alt="">
      <svg-icon v-if="taskbarEntry.componentName === 'FilePreview'" :icon-class='taskbarEntry.url'></svg-icon>
    </div>
  </div>
</template>

<script>
import store from '@/store/index'
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "TaskbarEntry",
  data () {
    return {
    };
  },
  mounted () {
  },
  components: {
    MyDynamicComponent: () => import('@/components/winbox/RenderComponent'),
    ExternalComponent: () => import('@/components/browser/index.vue')
  },
  props: {
    taskbarEntry: Object
  },
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    currentFocusWindowId () {
      let currentFocusWindowId = null
      const currentWinbox =  this.windowStore.currentFocusWindow
      for (var i in currentWinbox) {
        currentFocusWindowId = i
      }
      return currentFocusWindowId
    },
    taskbarPosition () {
      const taskbarPosition = store.state.globalSetting.basic.controlBar.sideBar.displayPosition
      return taskbarPosition
    }
  },
  methods: {
    ...mapMutations('globalSetting', ['setDesktopLayout', 'setFixedTabar']),
    ...mapActions('windowStore', ['setTaskbarEntriesStore']),
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name){
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    windowBlur () {
      for (var i in this.windowStore.windowStore) {
        this.windowStore.windowStore[i].window.focused = true
        this.windowStore.windowStore[i].window.blur()
      }
    },
    parentShow (e) {
      e.stopPropagation()
      if (this.taskbarEntry.componentName === 'ExternalComponent') {
        e.preventDefault();
        const ExternalComponent = this.taskbarEntry[`${this.taskbarEntry.componentName}`]
        let items = [
      
          {
            label: "删除图标",
            onClick: () => {
              e.stopPropagation()
              this.deleteIcon()
            },
          }
        ]
        this.$contextmenu({
          items: items,
          event: e,
          customClass: "custom-contextmenu",
          zIndex: 3000,
          minWidth: 80,
        });
        return false;
      }
    },
    deleteIcon () {
      const fixedBar = store.state.globalSetting.fixedTabar
      const arr = fixedBar.filter(item => item.id !== this.taskbarEntry.id)
      store.commit('globalSetting/setFixedTabar', arr)
      store.dispatch('windowStore/setTaskbarEntriesStore')
    },
    openWindow () {
      if (this.taskbarEntry.componentName === 'ExternalComponent' && this.taskbarEntry[`${this.taskbarEntry.componentName}`].openWith === '_blank') {
        const url = global.utils.ensureProtocol(this.taskbarEntry[`${this.taskbarEntry.componentName}`].externalUrl)
        return window.open(url)
      }
      const windowId = this.taskbarEntry.id
      const fixedTabar  =  _.cloneDeep(store.state.globalSetting.fixedTabar)
      console.log(fixedTabar, 'fixedTabarfixedTabarfixedTabarfixedTabar', store.state.globalSetting)
      let appLoaderOptions = fixedTabar.find(item => item.id === windowId)
      const propData = {
      }
      this.$openWindow(windowId, this.$options.components, {propsData: propData}, {}, {...appLoaderOptions});
    },
    hanlderClick (e) {
      e.preventDefault();
      // 强行让所有的窗口失焦 保证只有一个窗口处在聚焦状态
      this.windowBlur()
      const currentWinbox =  _.cloneDeep(this.windowStore.currentFocusWindow)
      const windowId = this.taskbarEntry.id
      const windowStore  =  _.cloneDeep(this.windowStore.windowStore)
      if (currentWinbox[windowId] && currentWinbox[windowId].id === windowId) {
        return windowStore[windowId].window.minimize()
      }
      if (!windowStore[windowId]) return this.openWindow()
      const isMinimized = windowStore[windowId].window.min;
      if (isMinimized) {
        windowStore[windowId].window.restore().focus()
      } else {
        windowStore[windowId].window.focus()
      }
    }
  },
  beforeDestroy () {
  }
};
</script>

<style scoped lang="less">
.taskbar-entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  margin-bottom: 4px;
  .focus-bar {
    height: 24px;
    background: #418190;
    width: 3px;
    border-radius: 16px;
  }
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 36px;
      height: 36px;
      margin: 4px 5px 4px 3px;
      border-radius: 6px;
    }
    .svg-icon {
      font-size: 44px;
    }
    .point {
      display: none;
      width: 3px;
      height: 3px;
      background: #333;
      border-radius: 50%;
      margin-right: 0px;
    }
   .color-icon {
      text-align: center;
      width: 34px;
      height: 34px;
      border-radius: 6px;
      margin: 4px 5px 4px 3px;
      span {
        color: #fff;
        width: 100%;
        display: block;
        height: 100%;
        font-size: 14px;
        line-height: 36px;
      }
    }
    .network-icon {
      height: 36px;
      width: 36px;
      min-width: 36px;
      line-height: 40px;
      background: #FFF;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
      border-radius: 8px;
      display: flex;
      margin: 4px 5px 4px 3px;
    }
  }
  .left {
    flex-direction: row;
  }
  .right {
    flex-direction: row-reverse;
  }
  .bottom {
    flex-direction: column-reverse;
    .focus-bar {
      height: 3px;
      background: #418190;
      width: 24px;
      border-radius: 16px;
    }
    img {
      width: 36px;
      height: 36px;
      margin: 4px 4px 3px 4px;
      border-radius: 6px;
    }
  }
  &:hover {
    transform: scale(1.2); /* Enlarge the item on hover */
    .point {
      display: block;
    }
  }
}
</style>