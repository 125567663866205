/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-11-28 19:55:19
 * @LastEditTime: 2024-10-25 15:47:06
 * @FilePath: \awx-ui\src\utils\regex.js
 */
// 正则表达式
const Regex = {
  domainRegex: /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,}){1,2}$/,
  urlRegex: /^(?:(?:https?|ftp):\/\/)?(?:[a-zA-Z0-9-]+\.)*(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}|localhost|\d{1,3}(\.\d{1,3}){3})(?::\d{1,5})?(?:\/[^\s]*)?$/,
  // urlRegex:  /^(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}\.?)(?::\d{1,5})?)$|^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)(?::\d{1,5})?$/,
  // urlRegex: /^(?:(?:https?:\/\/)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}|(?:(?:https?:\/\/)?\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d{1,5})?)(?:\/\S*)?$/,
  ipRegex: /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/
}
export default Regex
