<template>
  <div :class="['step-list', isComponet && 'component-step']">
    <div class="step-btns" v-if="!isComponet">
      <a-button class="mgr12" @click="delBatch()">删除</a-button>
      <a-button style="width: 116px" type="primary" @click="importStep">
        <svg-icon style="margin-right: 8px;" class="btn-before-icon" icon-class="host-import"></svg-icon>导入步骤
      </a-button>
      <a-button class="btn-step-add mgl12" icon="plus" type="primary" @click="createStep">新建步骤</a-button>
      <a-input-search v-model="keyword" class="search-btn" placeholder="请输入关键字查询" @search="onSearch" />
    </div>
    <div class="table-info" ref="table">
      <a-table :key="keyId" :scroll="scrollTable" rowKey="id" class="step-list-table table-15" @change="tableChange" :row-selection="{
          onChange: onSelectChange,
          selectedRowKeys: selectedRowKeys
        }" :columns="columns" :loading="loading" :dataSource="dataSource" :pagination="pagination"></a-table>
    </div>
  </div>
</template>

<script>
import { measureScrollbar } from "@/utils/util";

import ImportStepModal from "@/components/importStep/index.vue";
import moment from "moment";
import DeleteStepModal from "./deleteStep.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepManage",
  components: {
    MyDynamicComponent: () => import('@/components/winbox/RenderComponent')
  },
  provide() {
    return {
      handleCancel: this.handleCancel,
      onSearch: null,
    };
  },
  props: {
    isComponet: Boolean,
    optType: String,
    showPage: String
  },
  data() {
    return {
      fileType: ".xls, .xlsx, .csv, .json, .txt",
      fileList: [],
      keyId: 0,
      loading: false,
      keyword: "",
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      selectedRowKeys: [],
      dataSource: [],
    };
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions', '_edit', "refreshStepList"],
  computed: {
    ...mapState({
      windowStore: (state) => state.windowStore,
    }),
    scrollTable () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      let height = winbox?.window?.height || 400
      let width = this.isComponet ? '140%' : 1600
      const max  = winbox?.window?.max
      const full  = winbox?.window?.full
      if (max) height = document.documentElement.clientHeight
      if (full) height = document.documentElement.clientHeight
     let scroll = {}
      if (this.dataSource.length > 0)  {
        scroll = {
          y: height - 220,
          x: width
        }
      }
      return scroll
    },
    columns() {
      const pageColumns = [
        {
          title: "序号",
          key: "index",
          width: 70,
          customRender: (text, row, index) => {
            return (
              <span>
                {index +
                  1 +
                  this.pagination.pageSize * (this.pagination.current - 1)}
              </span>
            );
          },
        },
        {
          title: "名称",
          ellipsis: true,
          key: "name",
          dataIndex: "name",
          width: 200,
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "版本",
          key: "module_version",
          dataIndex: "module_version",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          title: "描述",
          ellipsis: true,
          key: "description",
          dataIndex: "description",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          title: "类型",
          ellipsis: true,
          key: "categories",
          dataIndex: "categories",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "分类",
          key: "type",
          dataIndex: "type",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          title: "标签",
          ellipsis: true,
          key: "tags",
          dataIndex: "tags",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "所属应用",
          key: "application_name",
          dataIndex: "application_name",
          // width: 120,
          sorter: (a, b) => {
            return b.application_name.localeCompare(
              a.application_name,
              "zh-Hans-CN",
              { numeric: true }
            );
          },
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "创建时间",
          key: "create_time",
          dataIndex: "create_time",
          sorter: (a, b) => {
            const format = "HH:mm:ss MM/D/YYYY";
            const start = moment(a.create_time).format(format);
            const end = moment(b.create_time).format(format);
            const diff = moment(end).diff(moment(start), "seconds");
            return diff;
          },
          customRender: (text) => {
            return (
              <span>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "更新时间",
          key: "update_time",
          dataIndex: "update_time",
          sorter: (a, b) => {
            const format = "HH:mm:ss MM/D/YYYY";
            const start = moment(a.update_time).format(format);
            const end = moment(b.update_time).format(format);
            const diff = moment(end).diff(moment(start), "seconds");
            return diff;
          },
          customRender: (text) => {
            return (
              <span>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          title: "操作",
          key: "action",
          width: 180,
          fixed: 'right',
          customRender: (_text, row, _index) => {
            return (
              <span class="flex-container">
                {
                  <span>
                    <a class="btn-opt" onClick={() => this.editStep(row)}>
                      编辑
                    </a>
                    <a-divider type="vertical" />
                    <a class="btn-opt" onClick={(e) => this.copyStep(row)}>
                      复制
                    </a>
                    <a-divider type="vertical" />
                    <a-popconfirm
                      overlayClassName="pop-confrim-modal"
                      title="确定删除吗?"
                      ok-text="是"
                      cancel-text="否"
                      onConfirm={(e) => this.delStep(row)}
                    >
                      <a class="btn-opt">删除</a>
                    </a-popconfirm>
                  </span>
                }
              </span>
            );
          },
        },
      ];
      const componentColumns = [
        {
          title: "序号",
          key: "index",
          width: 70,
          customRender: (text, row, index) => {
            return (
              <span>
                {index +
                  1 +
                  this.pagination.pageSize * (this.pagination.current - 1)}
              </span>
            );
          },
        },
        {
          title: "名称",
          ellipsis: true,
          key: "name",
          dataIndex: "name",
          width: 200,
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "版本",
          key: "module_version",
          dataIndex: "module_version",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          title: "描述",
          ellipsis: true,
          key: "description",
          dataIndex: "description",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          title: "类型",
          ellipsis: true,
          key: "categories",
          dataIndex: "categories",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          title: "标签",
          ellipsis: true,
          key: "tags",
          dataIndex: "tags",
          customRender: (text) => {
            return (
              <span>
                {text ? text : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "创建时间",
          key: "create_time",
          dataIndex: "create_time",
          sorter: (a, b) => {
            const format = "HH:mm:ss MM/D/YYYY";
            const start = moment(a.create_time).format(format);
            const end = moment(b.create_time).format(format);
            const diff = moment(end).diff(moment(start), "seconds");
            return diff;
          },
          customRender: (text) => {
            return (
              <span>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          ellipsis: true,
          title: "更新时间",
          key: "update_time",
          dataIndex: "update_time",
          sorter: (a, b) => {
            const format = "HH:mm:ss MM/D/YYYY";
            const start = moment(a.update_time).format(format);
            const end = moment(b.update_time).format(format);
            const diff = moment(end).diff(moment(start), "seconds");
            return diff;
          },
          customRender: (text) => {
            return (
              <span>
                {text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-"}
              </span>
            );
          },
        },
        {
          title: "操作",
          fixed: 'right',
          key: "action",
          width: 180,
          customRender: (_text, row, _index) => {
            return (
              <span class="flex-container">
                {
                  <span>
                    <a class="btn-opt" onClick={() => this.editStep(row)}>
                      编辑
                    </a>
                    <a-divider type="vertical" />
                    <a class="btn-opt" onClick={(e) => this.copyStep(row)}>
                      复制
                    </a>
                    <a-divider type="vertical" />
                    <a-popconfirm
                      overlayClassName="pop-confrim-modal"
                      title="确定删除吗?"
                      ok-text="是"
                      cancel-text="否"
                      onConfirm={(e) => this.delStep(row)}
                    >
                      <a class="btn-opt">删除</a>
                    </a-popconfirm>
                  </span>
                }
              </span>
            );
          },
        },
      ];
      return this.isComponet ? componentColumns : pageColumns;
    },
    appId() {
      let appId = this.windowPropsData.appId || "";
      return appId;
    },
  },
  mounted() {
    if (!this.isComponet) this.initData();
  },
  methods: {
    handleCancel() {
      this.$destroyAll();
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    delBatch() {
      if (!this.selectedRowKeys.length)
        return this.$message.warning("请选择一条数据");
      const obj = {
        ids: this.selectedRowKeys,
      };
      const self = this;
      let width = 400;
      let content = (
        <DeleteStepModal
          sysTypeTxt="步骤"
          sysType="选中"
          detail={obj}
          callBack={() => {
            self.initData();
            this.selectedRowKeys = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    importStep() {
      const self = this;
      let width = 736;
      let title = "导入数据";
      let content = (
        <ImportStepModal
          appId={undefined}
          callBack={() => {
            self.initData();
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    beforeUpload(file) {
      // if (file.size > 10 * 1024 * 1000) {
      //   this.$message.error("文件上传最大仅支持10M");
      //   return;
      // }
      this.fileList = [file];
      return false;
    },
    handleChange(e) {
      // if(e.file.size > (10*1024*1000)){
      //   return
      // }
      this.file = e.file;
      this.hostsImport();
    },
    hostsImport() {
      const self = this;
      self.loading = true;
      let formdata = new FormData();
      formdata.append("file", this.file);
      this.$axiosFormData(global.API.importStep, formdata)
        .then((res) => {
          self.loading = false;
          if ([200, 201, 202, 204].includes(res.status)) {
            if (res.data.code === 200) {
              self.initData();
            } else {
              self.$message.error(res.data.msg);
            }
          }
        })
        .catch((res) => {
          self.loading = false;
        });
    },
    async delStep(row) {
      let res = await this.$axiosDelete(global.API.updateStep(row.id));
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("删除成功");
        if (this.isComponet) {
          this.refreshStepList(this.showPage, row)
        }
        this.initData();
      }
    },
    initData(keyWord) {
      this.loading = true;
      const params = {
        keyword: this.keyword,
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
      };
      if (this.appId && this.isComponet) {
        (params.application_id = this.appId), (params.op_type = this.optType);
        if (keyWord) params.keyword = keyWord
      }
      this.$axiosGet(global.API.getStepList, params)
        .then((res) => {
          this.dataSource = res?.data?.results || [];
          this.pagination.total = res?.data?.total;
        })
        .finally(() => {
          this.loading = false;
          // this.fixTabelScroll();
        });
    },
    fixTabelScroll() {
      const offsetY = measureScrollbar(
        { direction: "vertical" },
        this.$refs.table
      );
      const $el = this.$refs.table.querySelector(
        ".ant-table-content .ant-table-hide-scrollbar"
      );
      if ($el) {
        $el.style.marginBottom = "-" + offsetY + "px";
        console.log(offsetY, "------");
      }
    },
    onSearch(keyWord) {
      this.initData(keyWord);
    },
    //表格选择
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.initData();
    },
    createStep() {
      let appLoaderOptions = {
        url: require("@/assets/img/desktop/desktop-app.svg"),
        name: "新建步骤",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isFileGroup: false,
          FileGroupName: 'app-manage',
          FileComponentPath: '@/pages/templateStep/modifyStep'
        }, 
        componentName: 'MyDynamicComponent',
        id: 'template-step-new',
      }
      const propData = {
      }
      // this.$router.push("/app-manage/template-step-new");
      this.$openWindow('template-step-new', this.$options.components, {propsData: propData}, {}, {...appLoaderOptions});
    },
    editStep(row) {
      if (this.isComponet) return this._edit(row)
      let appLoaderOptions = {
        url: require("@/assets/img/desktop/desktop-app.svg"),
        name: "编辑步骤",
        fileCompontentType: "customPageComponent",
        customPageComponent: {
          isFileGroup: false,
          FileGroupName: 'app-manage',
          FileComponentPath: '@/pages/templateStep/modifyStep'
        }, 
        componentName: 'MyDynamicComponent',
        id: 'template-step-new',
      }
      const propData = {
        ...row,
        stepId: row.id
      }
      // this.$router.push("/app-manage/template-step-new");
      this.$openWindow('template-step-edit' + row.id, this.$options.components, {propsData: propData}, {}, {...appLoaderOptions});
      // this.$router.push(`/app-manage/template-step-edit?id=${row.id}`);
    },
    copyStep(row) {
      this.$axiosJsonPost(global.API.copyStep(row.id)).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.$message.success("操作成功");
          if (this.isComponet) {
            this.refreshStepList(this.showPage, row)
          }
          this.initData();
        }
      });
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
.step-list {
  padding: 16px 14px 16px 16px;
  height: 100%;
  min-width: 980px;
  overflow-x: auto;
  box-shadow: none;
  .step-btns {
    padding: 0 0 12px 0;
    overflow: hidden;
    .search-btn {
      width: 40%;
      float: right;
    }
  }
}
.component-step {
  padding: 0px 24px 0 0;
  // height: calc(100vh - 154px);
}
</style>
