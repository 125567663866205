<!--
 * @Description: 
 * @Date: 2024-06-18 16:53:43
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-01-22 16:14:04
-->
<template>
  <div class="desktop-container" :style="desktopStyle" @contextmenu="(e) => parentShow(e)" @click="hide">
    <div :class="['container', taskbarPosition]" :style="containerStyle">
      <div :class="['taskbar no-select', autoHide && 'taskbar-auto', alwaysShow && 'always-show']">
        <TaskBar />
      </div>
      <div class="task-manager flex flex-col items-center" :style="taskManageStyle">
        <FileManage :taskbarPosition="taskbarPosition" />
      </div>
      <Setting />
      <Home-search ref="homeSearchRef"></Home-search>
      <MessageBox />
        <!-- :mask="false" -->
      <a-drawer
        title=""
        placement="bottom"
        :closable="false"
        :visible="appStoreVisible"
        @close="onClose"
        height="416"
        wrapClassName="app-store-drawer"
      >
        <AppStore />
      </a-drawer>
    </div>
  </div>
</template>

<script>
import FileManage from './FileManage'
import TaskBar from './TaskBar'
import Setting from './Setting/icon.vue'
import HomeSearch from './HomeSearch'
import store from '@/store/index'
import MessageBox from '@/components/messageBox/index.vue'
// import AppStore from '@/pages/appStore/index.vue'
import {
  mapSizes,
  itemSize,
  mapNetFontSizes,
  mapColorFontSizes,
  mapRadius
} from '@/utils/desktopIcon-map'

export default {
  components: {
    FileManage,
    TaskBar,
    Setting,
    HomeSearch,
    MessageBox,
    AppStore: () => import('@/pages/appStore/index.vue')
  },
  watch: {
    iconLock: {
      handler (val) {
       this.taskManageStyle = this.gettaskManageStyle();
      }
    },
    'globalSetting.wallpaper.backgroundImage': {
      handler (val) {
         let style = {}
        const savedSettings = localStorage.getItem('globalSetting');
        const localSettings = JSON.parse(savedSettings)
        style['background-image'] = localSettings && localSettings.wallpaper.backgroundImage ? localSettings.wallpaper.backgroundImage : this.globalSetting.wallpaper.backgroundImage || 'none'
       this.desktopStyle  = style
      },
      immediate: true
    }
  },
  computed: {
    globalSetting () {
      return {...store.state.globalSetting}
    },
    setting () {
      return {...store.state.setting}
    },
    windowStore () {
      return {...store.state.windowStore}
    },
    iconLock () {
      return this.globalSetting.desktopIcon.iconLock
    },
    autoHide () {
      const autohide = this.globalSetting.basic.controlBar.sideBar.displayMode === 'autoHide'
      return autohide
    },
    alwaysShow () {
      const alwaysShow = this.globalSetting.basic.controlBar.sideBar.displayMode === 'alwaysShow'
      return alwaysShow
    },
    taskbarPosition () {
       const taskbarPosition = this.globalSetting.basic.controlBar.sideBar.displayPosition
      return taskbarPosition
    }, 
    desktopArrange () {
      const desktopArrange = this.globalSetting?.desktopArrange || 'vertical'
      return desktopArrange
    },
    taskbarStyle () {
      let style = {}
      if (this.taskbarPosition === 'bottom') {
        const iconLock = this.globalSetting.desktopIcon.iconLock
        if (!iconLock) { 
          style['width'] = `calc(100% - ${this.padding * 2}px)`
        } else {
          style['width'] = `calc(100% - 200px)`
        }
      }
      return style
    },
    // 这个根据图标的大中小已经图标区域的宽度来算的padding
    containerStyle () {
      const iconWidth = this.globalSetting.desktopIcon.iconWidth
      const iconLock = this.globalSetting.desktopIcon.iconLock
      const iconSize =this.globalSetting.desktopIcon.desktopIconSize
      const subtraction = itemSize.get(iconSize) * 2 - 30
      let style = {}
      const start =  100
      const end =  400
      const width = end - (Math.abs(end - start) * (iconWidth / 100))
      const viewportWidth = window.innerWidth; // 获取视口宽度
      const minViewportValue = -subtraction + 0.5 * viewportWidth;
      let padding = Math.min(width, minViewportValue); // 计算 padding-left
      // if (padding < 100) padding = 100
      this.padding = padding
      if (!iconLock && viewportWidth > 720) {
        style['padding-left'] = `${padding}px`
        style['padding-right'] = `${padding}px`
      } 
      if (viewportWidth < 720) {
        style['padding-left'] = `${8}px`
        style['padding-right'] = `${12}px`
      } 
      return style
    },
  },
  created () {
  },
  mounted() {
    this.taskManageStyle = this.gettaskManageStyle();
    window.addEventListener('resize', this.updatetaskManageStyle);
    this.$EventBus.$on("openAppStore", this.openAppStore);
  },
  beforeDestroy() {
    this.$EventBus.$off("openAppStore", this.openAppStore);
    window.removeEventListener('resize', this.updatetaskManageStyle);
    this.teardownListeners();
  },
  data () {
    return {
      isMouseInRegion: false,
      padding: 100,
      taskManageStyle: {},
      desktopStyle: {},
      appStoreVisible: false
    };
  },
  methods: {
    hide () {
      this.$refs.homeSearchRef.showSuggest =false
      this.$refs.homeSearchRef.showHistory =false
    },
    handleResize() {
      // todo!
      const currentFocusWindow = _.cloneDeep(this.windowStore.currentFocusWindow)
      const windowStore = _.cloneDeep(this.windowStore.windowStore)
      let currentFocusWindowId = ''
      if (currentFocusWindow) {
         for (var i in currentFocusWindow) {
          currentFocusWindowId = i
        }
        if (!currentFocusWindowId) return false
        const winbox = windowStore[`${currentFocusWindowId}`]?.window
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        let winboxWidth =winbox?.width 
        let winboxHeight =winbox?.height
        if (winbox) {
          if (!((screenWidth < winboxWidth || screenWidth  < winbox.x + winboxWidth) || (screenHeight < winboxHeight || screenHeight  < winbox.y + winboxHeight))) return false
          // 更新窗口大小和位置
          winbox.width = '80%';
          winbox.height = '80%';
          winbox.x = '10%';
          winbox.y = '10%';
          // 重新计算窗口大小和位置
          winbox.g.style.setProperty('height', '80%')
          winbox.g.style.setProperty('width', '80%')
          winbox.g.style.setProperty('left', '10%')
          winbox.g.style.setProperty('top', '10%')
          winbox.resize().move();
        }
      }
    },
    updatetaskManageStyle() {
      this.taskManageStyle = this.gettaskManageStyle();
      this.handleResize()
    },

    // 这个是宽度锁定
    gettaskManageStyle () {
      // let { rows } = getGridSize();

      // let sum = 0

      // layout.map(item => {
      //   sum  += item.w * item.h
      // })
      //   // 最终确定实际行数
      // let realRows = Math.max(
      //   // 计算实际需要的行数
      //   Math.ceil(sum / (colNum * 1)), 
      //   // 传入的tableRows
      //   rows
      // );
      const iconWidth = this.globalSetting.desktopIcon.iconWidth
      const iconLock = this.globalSetting.desktopIcon.iconLock
      let style = {}
      const start =  1120
      const end =  1720
      const width = start + (Math.abs(end - start) * (iconWidth / 100))
      const viewportWidth = window.innerWidth; // 获取视口宽度
      const minViewportValue = -200 + viewportWidth; // -266px + 50vw
      const containerWidth = Math.min(1000, minViewportValue); // 计算 padding-left
      const realHeight =  window.innerWidth * 0.74
      
      if (iconLock) {
        style['width'] = `${containerWidth}px`
        style['margin'] = '0 auto'
      }
      return style
    },
    parentShow (e) {
      const self = this
      e.stopPropagation()
        e.preventDefault();
        let items = [
          {
            label: "添加图标",
            onClick: () => {
              e.stopPropagation()
              e.preventDefault();
              self.openAppStore()
            },
          },
          {
            label: "排列方式",
            children: [
              {
                label: "从左到右",
                icon: self.desktopArrange === 'horizontal' && 'el-icon-check',
                onClick: () => {
                  e.stopPropagation()
                  store.commit("globalSetting/setDesktopArrange", 'horizontal')
                },
              }, {
                label: "从上到下",
                icon: self.desktopArrange === 'vertical' && 'el-icon-check',
                onClick: () => {
                  e.stopPropagation()
                  store.commit("globalSetting/setDesktopArrange", 'vertical')

                },
              },
            ],
          },
        ]
        this.$contextmenu({
          items: items,
          event: e,
          customClass: "custom-contextmenu",
          zIndex: 3000,
          minWidth: 80,
        });
        return false;
    },
    windowBlur () {
      for (var i in this.windowStore.windowStore) {
        this.windowStore.windowStore[i].window.focused = true
        this.windowStore.windowStore[i].window.blur()
      }
    },
    onClose () {
      this.appStoreVisible = false
    },
    openAppStore (fixedBar) {
        // 这地方存一下是添加图标到桌面还是加到任务栏
        store.commit("windowStore/setIsAddToTaskbar", fixedBar === 'fixedBar')
        this.appStoreVisible = true
        return false

        // 强行让所有的窗口失焦 保证只有一个窗口处在聚焦状态
        this.windowBlur()
        const windowId = 'appStore'
        const windowStore  =  _.cloneDeep(this.windowStore.windowStore)
        const currentWinbox =  _.cloneDeep(this.windowStore.currentFocusWindow)
        if (currentWinbox[windowId] && currentWinbox[windowId].id === windowId) {
          return windowStore[windowId].window.minimize()
        }
        if (!windowStore['appStore']) return this.openWindow()
        const isMinimized = windowStore['appStore'].window.min;
        if (isMinimized) {
          windowStore['appStore'].window.restore().focus()
        } else {
          windowStore['appStore'].window.focus()
        }
    },
    openWindow () {
      this.$EventBus.$emit("openAppStore");
      // let appLoaderOptions = {
      //   url: require("@/assets/img/desktop/desktop-menu.png"),
      //   name: "应用商店",
      //   fileCompontentType: "customPageComponent",
      //   customPageComponent: {
      //     isFileGroup: false,
      //     FileGroupName: 'appStore',
      //     FileComponentPath: '@/pages/appStore/index.vue'
      //   }, 
      //   componentName: 'AppStore',
      //   id: 'appStore',
      // }
      // const propData = {
      // }
      // this.$openWindow('appStore', this.$options.components, {propsData: propData}, {}, {...appLoaderOptions});
    },
    setupListeners() {
      document.addEventListener('mousemove', this.handleMouseMove);
    },
    teardownListeners() {
      document.removeEventListener('mousemove', this.handleMouseMove);
    },
    handleMouseMove(event) {
      const mouseX = event.clientX;
      this.isMouseInRegion = mouseX >= 0 && mouseX <= 100;
    },
    
  },
};
</script>

<style scoped lang="less">
.desktop-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-size: 100% 100%;
}
.container {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: relative;
  overflow-y: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  .task-manager {
      padding-top: 25vh;
      padding-bottom: 240px;
      height: 100%;
      scrollbar-width: none;
  }
  .taskbar {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    z-index: 999;
  }

  &.left {
    flex-direction: row;
    .taskbar {
      left: 0;
      top: 60px;
      height: calc(100% - 125px);
      width: 20px;
    }
    .task-bar-container  {
      width: 100%;
      max-height: calc(100vh - 125px);
      overflow-y: auto;
      overflow-x: hidden;
     }
    .always-show {
      width: 56px;
    }
    .taskbar-auto {
        &:hover {
        .task-bar-container  {
          left: 10px!important;
          width: 56px;
          transition: left .4s ease, width .4s ease;
        }
      }
    }
  }

  &.right {
    flex-direction: row-reverse;
    .taskbar {
      right: 0;
      top: 60px;
      height: calc(100% - 110px);
      width: 20px;
    }
    .task-bar-container  {
      width: 100%;
      max-height: calc(100vh - 110px);
      overflow: hidden;
    }
    .always-show {
      width: 56px;
    }
    .taskbar-auto {
        &:hover {
        .task-bar-container  {
          width: 56px;
          right: 10px!important;
          transition: right .4s ease, width .4s ease;
        }
      }
    }
  }
  &.top {
    flex-direction: column;
    .taskbar {
      width: 100%;
      height: 56px;
      border-radius: 0px 0px 10px 10px;
    }
  }

  &.bottom {
    // flex-direction: column-reverse;
    // display: flex;
    position: relative;
    .taskbar {
      height: 20px!important;
      margin: 0 auto;
      bottom: 0px!important;
      left: 100px;
      right: 100px;
      border-radius: 10px;
    }
    // .task-manager {
    //   height: calc(100vh);
    // }
     .always-show {
      height: 56px;
    }
    .taskbar-auto {
        &:hover {
        .task-bar-container  {
          height: 56px;
          bottom: 10px!important;
          transition: bottom .4s ease, height .4s ease;
        }
      }
    }
    .task-bar-container {
      height: 56px;
      min-width: 343px;
      max-width: 80vw;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      overflow: hidden;
    }
  }
  .circle-drag {
    position: fixed;
    bottom: 30px;
    right: 30px;
  }
}
</style>
