<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-03 17:14:47
 * @LastEditTime: 2024-07-16 18:40:55
 * @FilePath: \awx-ui\src\components\workflow-design\components\selectApp.vue
-->
<template>
  <div class="template-list">
    <div class="system-template">
      <div v-if="systemTemplateList.length >0" class="flex-warp">
        <div v-for="(item, index) in systemTemplateList" :key="index" class="template-container">
          <TemplateItem :templateItem="item" :selectedTemplateId="selectedTemplateId" :templateType="'app'" @selectedTemplate="selectedTemplate" @changeTemplate="changeTemplate" />
        </div>
      </div>
      <div v-else>
        <NoData />
      </div>
    </div>
  </div>
</template>
  <script>
import NoData from "./noData.vue";
import TemplateItem from "./appItem.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { createTaskStep } from '@/utils/createComponents.js'
export default {
  components: { NoData, TemplateItem },
  props: {
    systemTemplateList: Array,
    customTemplateList: Array,
  },
  data() {
    return {
      selectedTemplateId: "",
    };
  },
  watch: {
    'currentStepsIndex': {
      handler (val) {
        if (val === 0) this.selectedTemplateId = this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.properties?.application_id
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      windowStore: (state) => state.windowStore,
      winboxFlowModule: (state) => state.winboxFlowModule,
    }),
    currentWinboxKey () {
      // const currentFocusWindow = this.windowStore.currentFocusWindow;
      // let key = null
      // for (var i in currentFocusWindow) { key = i }
      return this.windowAppLoaderOptions.id
    },
    winboxTask () {
      return this.winboxFlowModule[this.currentWinboxKey] || {}
    },
    currentStepsIndex () {
      let currentStepsIndex = 0
      currentStepsIndex =  this.winboxTask?.stepInfo?.taskTemplateDetail?.stepInfo?.properties['currentStepsIndex'] || 0;
      return currentStepsIndex
    },
  },
  beforeDestroy() {
    this.$EventBus.$off("clearTemplateId");
  },
  mounted () {
    this.$EventBus.$on("clearTemplateId", () => {
      this.selectedTemplateId = '';
    });
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  methods: {
    changeTemplate (e, templateItem) {
      const obj = _.cloneDeep(templateItem)
      obj.application_id = obj.id
      this.selectedTemplateId = obj.id;
      delete obj.id
      obj['application_name'] = obj.name
      delete obj.name
      const definition = window[this.currentWinboxKey].designer.getDefinition()
      const arr = definition && definition.sequence ? definition.sequence : []
      if (!arr.length) obj.disabledDelete = true
      templateItem = {...templateItem, properties: {...obj}}
      const step = createTaskStep(templateItem)
      this.$EventBus.$emit('updateStepSelfChangedByStepId', e, step)
    },
    selectedTemplate (e, templateItem) {
      const obj = _.cloneDeep(templateItem)
      obj.application_id = obj.id
      this.selectedTemplateId = obj.id;
      delete obj.id
      obj['application_name'] = obj.name
      delete obj.name
      const definition = window[this.currentWinboxKey].designer.getDefinition()
      const arr = definition && definition.sequence ? definition.sequence : []
      if (!arr.length) obj.disabledDelete = true
      templateItem = {...templateItem, properties: {...obj}}
      const step = createTaskStep(templateItem)
      this.$EventBus.$emit('tryDrag', e, step)
    }
  },
};
</script>
  <style lang="less" scoped>
.template-list {
  // padding-right: 50px;
  .system-template,
  .custom-template {
    &-title {
      padding-bottom: 16px;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .flex-warp {
    display: flex;
    flex-wrap: wrap;
    // width: 654px;
    width: 100%;
  }
  .template-container {
    margin: 8px 21px 8px 0;
    width: calc(33.33% - 14px);
    &:nth-child(3n) {
      margin: 8px 0 8px 0;
    }
  }
}
</style>