<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-27 16:03:30
 * @LastEditTime: 2024-11-14 18:45:20
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appIcon\netWorkIcon.vue
-->
<template>
  <div class="system-icon">
    <a-input ref="userNameInput" v-model="iconName" @pressEnter="onSearch" placeholder="搜索图标关键字">
      <svg-icon icon-class="search" class="app-search" slot="prefix"></svg-icon>
    </a-input>
    <div class="icon-warp" v-if="appIconList">
      <div v-for="(item, index) in appIconList" :key="index" class="icon-item" @click="getIconBySelect(item)">
        <div v-if="!item?.isThrid && 'network-'+item.iconPath === IconPath" class="icon-selected">
          <svg-icon class="icon-selected-svg" icon-class="app-installed" />
        </div>
        <div v-if="item?.isThrid && item.iconPath === IconPath" class="icon-selected">
         <svg-icon  class="icon-selected-svg" icon-class="app-installed" />
        </div>
        <icon :icon="item.iconPath" v-if="!item?.isThrid" style="font-size: 30px;" />
        <img :src="item.iconPath" v-if="item?.isThrid" style="width: 100%; height: 100%;" />
      </div>
    </div>
    <NoData v-else />
  </div>
</template>

<script>
import { Icon, getIcon, loadIcon, buildIcon } from "@iconify/vue2";
import NoData from "@/components/noData";

export default {
  data() {
    return {
      iconName: "",
      iconList: [],
      IconPath: "",
      networkList: [],
      spinning: false,
    };
  },
  props: {
    appDetail: Object,
    app_addr: String
  },
  components: {
    Icon,
    NoData,
  },
  watch: {
    'app_addr': {
      handler(val) {
        if (val === '') {
          this.networkList = []
          this.$EventBus.$emit("setName", '');
        }
      }
    },
   appIcon: {
      handler(val) {
        if (val && this.IsNetWorkIcon(this.appIcon)) {
          this.IconPath = val;
          this.iconList.push({
            iconPath: this.iconNetWorkName(this.appIcon),
            isThrid: false
          });
          this.svg = getIcon(this.iconNetWorkName(this.appIcon)).body;
        }
        if (val && val.startsWith('http')) {
          this.IconPath = val;
          this.networkList.push({
            iconPath: val,
            isThrid: true
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    appIconList () {
      return this.networkList.concat(this.iconList)
    },
    appIcon () {
      let appIcon = this.appDetail?.icon || this.appDetail?.url
      return appIcon
    }
  },
  mounted() {
    this.$EventBus.$on("getfavicons", (url) => {
      this.getfavicons(url)
   });
  },
  methods: {
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName(name) {
      return global.utils.iconNetWorkName(name);
    },
    onSearch() {
      this.getAppIcon();
    },
    getfavicons (url) {
      const self = this
      this.spinning = true;
      this.$axiosGet(global.API.getfavicons, {url: url}).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (url !== self.app_addr) return false
          self.IconPath = ''
          self.$emit("getIcon", self.IconPath, "3");
          self.iconList = []
          self.networkList = res.data.icon.map(item => {
            return {
              isThrid: true,
              iconPath: item
            }
          })
          if (res.data.icon.length > 0) {
            self.IconPath = res.data.icon[0]
            self.$emit("getIcon", self.IconPath, "3");
          }
          self.$EventBus.$emit("setName", res.data.name);
        } else {
          self.IconPath = ''
          self.$emit("getIcon", self.IconPath, "3");
          self.iconList = []
          self.networkList = []
          self.$EventBus.$emit("setName", '');
        }
      });
    },
    getIconBySelect(item) {
      this.IconPath = "network-" + item.iconPath;
      if (item?.isThrid) { 
        this.IconPath = item.iconPath;
      }
      this.$emit("getIcon", this.IconPath, "3");
    },
    getAppIcon() {
      const self = this
      this.spinning = true;
      fetch(
        `https://api.iconify.design/search?query=${this.iconName}&limit=1000`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json(); // 将响应体以 JSON 格式解析
        })
        .then((data) => {
          self.spinning = false;
          self.IconPath = ''
          self.$emit("getIcon", self.IconPath, "3");
          self.networkList = []
          self.iconList = data.icons.map(item => {
            return {
              isThrid: false,
              iconPath: item
            }
          });
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    },
  },
  beforeDestroy () {
    this.$EventBus.$off("getfavicons")
  }
};
</script>
<style scoped lang='less'>
.system-icon {
  margin-top: 10px;
  .icon-warp {
    width: 100%;
    height: 172px;
    border: 1px dashed rgba(196, 202, 211, 1);
    border-top: none;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
    padding: 16px 0px 16px 16px;
    display: flex;
    flex-wrap: wrap;
    .icon-item {
      position: relative;
      cursor: pointer;
      height: 38px;
      margin: 0px 12px 0 0;
      width: 38px;
      min-width: 38px;
      background: #fff; // 这个是图标的颜色定义
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: center;
      box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
      .icon-selected {
        position: absolute;
        bottom: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border: 2px solid #fff;
        &-svg {
          font-size: 16px;
        }
      }
      img {
        width: 100%;
        border-radius: 6px;
      }
      &:nth-child(11n) {
        margin-right: 0;
      }
    }
  }
}
</style>