<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-06 14:55:25
 * @LastEditTime: 2024-07-19 16:23:06
 * @FilePath: \awx-ui\src\pages\appManage\editApp\leftStepTree.vue
-->
<template>
  <div class="left-tree" :style="{ height: 'calc(100% - 0px)' }">
    <div class="sider-header-button">
      <a-input style="width: calc(100% - 30px)" v-model="searchValue" @pressEnter="changeSearchValue" placeholder="输入关键词">
        <svg-icon slot="suffix" icon-class="realtime-search" />
      </a-input>
    </div>
    <a-spin :spinning="treeSpain" class="left-tree-container">
      <div :class="'sysDevStyle'" style="overflow-y: auto; padding: 0 0px">
        <a-tree :treeData="treeData" class="a-tree-local-layout" @expand="onExpand" :expandedKeys="expandedKeys" :autoExpandParent="autoExpandParent" :selectedKeys="selectedKeys" defaultExpandAll @select="onSelect">
          <template slot="host" slot-scope="item">
            <div :class="['local-tree-custom']">
              <template>
                <span style="margin: 0 4px 1px;position: relative;top: 3px;">
                  <svg-icon :icon-class="item.svgIcon" style="font-size: 16px"></svg-icon>
                </span>
              </template>
              <span class="local-tree-content" :title="item.title">
                <span v-if="item.title.indexOf(searchValue) == -1">{{ item.title }}</span>
                <span v-else :title="item.title">
                  {{
                  item.title.substr(
                  0,
                  item.title.indexOf(searchValue)
                  )
                  }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{
                  item.title.substr(
                  item.title.indexOf(searchValue) +
                  searchValue.length
                  )
                  }}
                </span>
              </span>
              <span v-if="item.key !== 'base'" class="tree-more hide-point" style="width: 10px; text-align: right">
                <div @click.stop>
                  <a-popover placement="rightTop" class="popover-index" overlayClassName="popover-index" :content="() => getMoreMenu(item)">
                    <a-icon type="more" @click="showPopover(item)" />
                  </a-popover>
                </div>
              </span>
            </div>
          </template>
        </a-tree>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import DeleteStep from './components/deleteStep.vue'
export default {
  data() {
    return {
      searchValue: "",
      autoExpandParent: true,
      selectedKeys: ['base'],
      expandedKeys: ["auth", "opt", "trigger"],
      treefilterData: [],
      treeData: [],
      treeSpain: true,
      selectType: "host",
      groupId: 0,
      application_name: '',
      topButtonTree: [{ name: "新建主机组", key: "add" }],
    };
  },
  mounted() {
    this.convertTreeData();
    this.windowEventBus.$on("setSelectNode", (node) => {
      this.setSelectNode(node);
    });
    this.windowEventBus.$on("refreshTreeList", (node) => {
      this.refreshTreeList(node);
    });
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions', 'windowEventBus'],
  computed: {
    appId () {
      let appId = this.windowPropsData.appId || ''
      return appId
    },
    ...mapState({
      windowStore: (state) => state.windowStore,
    }),
    currentWinboxKey () {
      return this.windowAppLoaderOptions.id
    },
    windowId () {
      const currentFocusWindow = this.$store.state.windowStore.currentFocusWindow;
      let windowId = null
      for (var i in currentFocusWindow) { windowId = i }
      return windowId
    }
  },
  methods: {
    onExpand(expandedKeys, { node }) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    changeSearchValue() {
      const value = this.searchValue
      if(value  == ''){
        this.treeData = this.disTreeData(this.treeData);

        return
      }
      let searchKeys = [];
      //获取展开的keys
      this.treefilterData.map(item =>{
        if(item.title.indexOf(value) > -1){
          searchKeys.push(item.key)
        }
      })
      //重置树
      this.treeData = this.disTreeData(this.treeData,searchKeys);
    },
    disTreeData(data, _expandedKeys) {
      if (data && data.length > 0) {
        data.forEach((item) => {
          if (_expandedKeys && _expandedKeys.indexOf(item.key) < 0) {
            item["style"] = { display: "none" };
          } else {
            item["style"] = { display: "block" };
          }
          if (item.children && item.children.length != 0) {
            this.disTreeData(item.children, _expandedKeys);
          }
        });
      }
      return data
    },
    async initData(optType) {
      this.loading = true;
      const params = {
        keyword: this.searchValue,
        page_size: 10000,
        page: 1,
      };
      params.op_type = optType;
      params.application_id = this.appId
      const res = await this.$axiosGet(global.API.getStepList, params);
      const data = res?.data?.results || [];
      return data
    },
    async convertTreeData(expandedKeys, currentStep) {
      this.treeData = [
        {
          name: '基本信息',
          isLeaf: true,
          key: "base",
          title: '基本信息',
          nodeType: "base",
          style: {display: 'block'},
          svgIcon: "app-base",
          children: [],
          scopedSlots: { title: "host" },
        },
        {
          name: "鉴权",
          isLeaf: false,
          key: "auth",
          title: "鉴权",
          nodeType: "auth",
          svgIcon: "app-auth",
          style: {display: 'block'},
          children: [],
          scopedSlots: { title: "host" },
        },
        {
          name: "操作",
          isLeaf: false,
          key: "opt",
          svgIcon: "app-opt",
          style: {display: 'block'},
          title: "操作",
          nodeType: "opt",
          children: [],
          scopedSlots: { title: "host" },
        },
        {
          name: "触发器",
          isLeaf: false,
          style: {display: 'block'},
          key: "trigger",
          title: "触发器",
          svgIcon: "app-trigger",
          nodeType: "trigger",
          children: [],
          scopedSlots: { title: "host" },
        },
      ];
      this.treeData.map(item => {
        this.treefilterData.push(item)
      })
      this.treeSpain = true;
      const optList = await this.initData(0);
      const triggerList = await this.initData(1);
      const authList = await this.initData(2);
      optList.map((item) => {
        const childNode = {
          name: item.name,
          isLeaf: true,
          key: "opt-" + item.id,
          pid: 0,
          nodeType: "step-opt",
          svgIcon: "app-opt-item",
          style: {display: 'block'},
          title: item.name,
          children: [],
          ...item,
          scopedSlots: { title: "host" },
        }
        this.treeData[2].children.push(childNode);
        this.treefilterData.push(childNode)
      });
      triggerList.map((item) => {
        const childNode = {
          name: item.name,
          style: {display: 'block'},
          isLeaf: true,
          key: "trigger-" + item.id,
          pid: 0,
          nodeType: "step-trigger",
          svgIcon: "app-trigger-item",
          title: item.name,
          children: [],
          ...item,
          scopedSlots: { title: "host" },
        }
        this.treeData[3].children.push(childNode);
        this.treefilterData.push(childNode)
      });
      authList.map((item) => {
        const childNode = {
          name: item.name,
          isLeaf: true,
          style: {display: 'block'},
          key: "auth-" + item.id,
          pid: 0,
          nodeType: "step-auth",
          svgIcon: "app-auth-item",
          title: item.name,
          children: [],
          ...item,
          scopedSlots: { title: "host" },
        }
        this.treeData[1].children.push(childNode);
        this.treefilterData.push(childNode)

      });
      setTimeout(() => {
        this.treeSpain = false;
      }, 1000);
      this.expandedKeys = expandedKeys ? expandedKeys : ["auth", "opt", "trigger"];
    },
    showPopover(item) {
      this.popoverVisible = true;
    },
    refreshPage() {
      this.convertTreeData();
    },
    // r如果編輯的是當前步驟 需要將左側的樹選中
    setSelectNode (node) {
      const currentExpandedKey = node.op_type == '2' ? 'auth' : node.op_type == '0' ? 'opt' : "trigger"
      if (!this.expandedKeys.includes(currentExpandedKey)) this.expandedKeys.push(currentExpandedKey)
      const key = node.op_type == '2' ? 'auth-' + node.id : node.op_type == '0' ? 'opt-' + node.id : 'trigger-' + node.id
      this.selectedKeys = [key]
    },
    refreshTreeList (node) {
      const currentExpandedKey = node.op_type == '2' ? 'auth' : node.op_type == '0' ? 'opt' : "trigger"
      if (!this.expandedKeys.includes(currentExpandedKey)) this.expandedKeys.push(currentExpandedKey)
      this.convertTreeData(this.expandedKeys, node);
    },
    async delStep(row) {
      let res = await this.$axiosDelete(global.API.updateStep(row.id));
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("删除成功");
        this.convertTreeData(this.expandedKeys, row);
        // if (this.windowId !== this.currentWinboxKey) return false
        this.windowEventBus.$emit("refreshStepList", row.nodeType);
      }
    },
    copyStep(row) {
      this.$axiosJsonPost(global.API.copyStep(row.id)).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.$message.success("操作成功");
          this.convertTreeData(this.expandedKeys, row);
          // if (this.windowId !== this.currentWinboxKey) return false
          this.windowEventBus.$emit("refreshStepList", row.nodeType);
        }
      });
    },
    showPopDetail(keys, node) {
      /**
       * 操作集
       */
      // if (this.windowId !== this.currentWinboxKey) return false
      const self = this;
      if (keys === "build") {
        this.windowEventBus.$emit("renderRightPage", 'step-' + node.nodeType, node, 'add');
        this.expandedKeys = this.expandedKeys.filter(item => item !== node.key)
        this.selectedKeys = [node.key]
      }
      if (keys === 'edit') {
        this.windowEventBus.$emit("renderRightPage", 'step-' + node.nodeType, node, 'edit');
        const currentExpandedKey = node.nodeType.split('-')[1]
        if (!this.expandedKeys.includes(currentExpandedKey)) this.expandedKeys.push(currentExpandedKey)
        this.expandedKeys = this.expandedKeys.filter(item => item !== node.key)
        this.selectedKeys = [node.key]
      }
      if (keys === 'copy') {
         this.copyStep(node)
      }
      if (keys === 'delete') {
         let content = (
          <DeleteStep
            node={node}
            callBack={(node) => {
              this.delStep(node)
            }}
          />
        );

        this.$confirm({
            width: 320,
            title: () => {
              return (
                <div>
                  <a-icon
                    type="question-circle"
                    style="color:#2F7FD1 !important;margin-right:10px"
                  />
                  提示
                </div>
              );
            },
            content: content,
            closable: true,
            icon: () => {
              return <div />;
            },
          });
        }
    },
    destroyAll() {
      this.$destroyAll();
    },
    getMoreMenu(props) {
      const { isLeaf, expanded, type, editStatus, name, dataRef, pNodeId } =
        props;
      let arr = [];
      if (["操作", "触发器", "鉴权"].includes(name)) {
        const nodeName = "新建" + name
        arr = [{ name: nodeName, key: "build" }];
      }
      if (!["操作", "触发器", "鉴权"].includes(name)) {
        arr = [
          { name: "编辑", key: "edit" },
          { name: "复制", key: "copy" },
          { name: "删除", key: "delete" },
        ];
      }
      return arr.map((item, index) => {
        return (
          <div key={index}>
            <a-button
              class="more-menu-btn"
              style="border-width:0px;min-width:100px;"
              onClick={() => this.showPopDetail(item.key, props)}
            >
              {item.name}
            </a-button>
          </div>
        );
      });
    },
    onSelect(keys, { node }) {
      // if (['操作', '触发器'].includes(node.dataRef.name)) return false
      // if (node.isLeaf) return this.$message.warning('页面待开发')
      // 点击打开的父节点的时候将父节点选中收起来
      if (node.expanded && !node.isLeaf) {
        this.expandedKeys = this.expandedKeys.filter(
          (item) => item !== node.eventKey
        );
      }
      if (!keys.length) return false;
      this.selectedKeys = keys;
      console.log('keys', keys)
      this.selectType = node.dataRef.nodeType;
      // if (this.windowId !== this.currentWinboxKey) return false
      this.windowEventBus.$emit("renderRightPage", this.selectType, node.dataRef);
    },
    clickPopCommon(item) {
      if (item.key == "search") {
        this.changeSearchValue();
      }
      if (item.key == "sync") {
        this.getHostGroups();
      }
    },
    getAppDetail () {
      this.$axiosGet(global.API.getApp + `/${this.$route?.query?.id}`, {})
        .then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            this.application_name = res.data.name
            this.convertTreeData();
          }
        })
        .catch((err) => {});
    },
  },
  beforeDestroy () {
    this.windowEventBus.$off("setSelectNode");
    this.windowEventBus.$off("refreshTreeList");
  }
};
</script>
<style lang="less" scoped>
// 右边的气泡
.popover-index {
  .more-menu-btn:hover {
    background: #f4f5f7;
    color: #0264c8;
  }
  .ant-popover-inner-content {
    padding: 12px 0;
  }
  /deep/ .ant-popover-arrow {
    top: 20px !important;
    left: 20px !important;
  }
  .ant-popover-inner-content .ant-btn:hover,
  .ant-popover-inner-content .ant-btn:focus {
    background-color: #f4f5f7;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 4px;
    margin-top: 1px;
  }
  .ant-menu-inline-collapsed {
    width: 50px;
  }
  .ant-menu-item-selected {
    background: #2872e0 !important;
    color: #ffffff !important;
  }
}
.left-tree {
  // 搜索框
  .sider-header-button {
    padding: 16px 0px 5px 0;
    .ant-input-search {
      margin-left: 16px;
      margin-right: 16px;
      .ant-input-affix-wrapper .ant-input-suffix {
        right: 6px;
      }
    }
    .ant-input-affix-wrapper {
      margin-left: 16px;
    }
    .ant-input {
      border-radius: 2px;
      margin: 0;
      // background-color: #F2F4F7;
      // border-width:0px
      height: 30px;
      line-height: 30px;
      padding-left: 8px;
    }
    .sider-header-btn {
      box-shadow: none;
    }
  }
  .left-tree-container {
    max-height: calc(100% - 40px);
    overflow-y: auto;
    margin-left: 14px;
    margin-right: 12px;
    .sysDevStyle {
      height: calc(100% - 35px) !important;
      overflow-y: scroll;
    }
    /deep/ .ant-tree li {
      border-bottom: 1px solid #f6f7fb!important;
      ul.ant-tree-child-tree-open {
        li:last-child {
          border-bottom: none!important;
        }
      }
    }
  }
}
</style>